.BetSettingsDesktop {
  margin: 11px 10px 10px;
  // height: 40px;
  display: flex;
  align-items: center;

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    --background: 0;
    --border-style: 0;
    --inner-padding-end: 0;
    --padding-start: 0;
    --min-height: 32px;
    // margin: 10px 0;
    font-size: 14px;

    &__button {
      --background-checked: rgb(44, 41, 41);
      --background: #fff;
      --handle-background-checked: var(--white);
      --handle-background: #fff;
      --handle-box-shadow: 0px 0px 0px 1px #969696;
      --handle-height: 23px;
      --handle-width: 23px;
      height: 23px;
      width: 55px;
      border: 1px solid #969696;
      border-radius: 999px;
      padding: 0;
      padding-inline: 0;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &::after {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        position: absolute;
        content: 'off';
        padding: 0 6px;
        color: var(--gray);
        text-transform: uppercase;
      }

      &.toggle-checked {
        height: 23px;
        width: 55px;
        justify-content: flex-start;
        --handle-box-shadow: none;
        border: 1px solid white;

        &::after {
          content: 'on';
          color: var(--white);
        }
      }
    }
  }
  &__oneClick {
    display: inline-block;
    position: relative;
    //top: 9px;
    left: 15px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 5px;
    color: #626C6F;
  }

  .info-content {
    background: #ffffff;
    border: 2px solid var(--info);
    box-sizing: border-box;
    border-radius: 2px;
    padding: 12px 11px;
    font-size: 10px;
    margin-top: 4px;
  }

  &__fields {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    //   margin: 8px 0 13px;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    height: 21px;
    width: 50px;
    flex: 0 1 calc(1 / 3 * 100% - 10px);
    border: 1px solid var(--black);
    color: var(--black);
    --placeholder-color: var(--one-click-stake);
    --placeholder-opacity: 1;

    > input:disabled {
      background: #fff;
      color: var(--black) !important;
      opacity: 1 !important;
    }

    &.active-stake {
      border: 2px solid var(--black);
      background-color: white !important;
      color: white;
      > input:disabled {
        background: black;
        color: white !important;
        opacity: 1 !important;
      }

      // &:after {
      //   content: "Active Stake";
      //   font-weight: bold;
      //   font-size: 13px;
      //   color: var(--one-click-stake);
      //   position: absolute;
      //   bottom: 5px;
      //   word-break: keep-all;
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      //   max-width: 100%;
      // }
    }
  }

  .on-edit &__input {
    --placeholder-color: var(--gray);
    --placeholder-opacity: 1;
    border: 1px solid var(--gray);
    color: var(--gray);

    &.has-focus {
      border: 2px solid var(--gray);
    }

    &.active-stake {
      align-items: center;
      padding-top: unset !important;
      min-width: 0;
      font-weight: 700 !important;

      &:after {
        display: none;
      }
    }
  }

  &__item {
    flex-direction: column;
    display: flex;
    width: 58%;
  }

  &__btn {
    text-transform: none;
    width: 14%;
    height: 20px;
      background: #6A9772;
      font-size: 11px;
      border-radius: 3px;
      &:hover{
        background: #7baa85;
      }
      &:active{
        background: #64896b;
      }
  }
  

  .apl-icon-info-sign {
    color: var(--info);
    font-size: 19px;
    margin-left: 10px;
    cursor: pointer;
    width: 21px;
    height: 20px;
    border-radius: 999px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-family: 'apollo';

    &:before {
      content: '';
    }
  }

  ion-input {
    --padding-start: 0px !important;
    font-size: 13px !important;
  }
  .native-input.sc-ion-input-md{
    padding: 0;
  }
}

@media screen and (min-width: 1280px) {
  .BetSettingsDesktop__input {
    width: 65px;
  }
}

// @media screen and (min-width: 1440px) {
//   .BetSettingsDesktop__input {
//     width: 80px;
//   }
// }
.newToggler {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  display: flex;
  align-items: center;
  .switcher {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 24px;
    margin: 2px 0;
  }

  /* Hide default HTML checkbox */
  .switcher input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(129, 129, 129);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid #818181;
    -webkit-transition: 0.4s;
    transition: 0.2s;
  }

  input:checked + .slider {
    background-color: #0f2327;
    &::after {
      position: relative;
      z-index: 0;
      float: left;
      top: 5px;
      left: 6px;
      content: 'ON';
      color: white;
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #3c3f42;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    &::after {
      content: 'OFF';
      position: relative;
      top: 5px;
      right: 5px;
      z-index: 0;
      float: right;
      transition-delay: 0.1s;
      color: #656565;
    }
  }

  .slider.round:before {
    border-radius: 50%;
  }


}
