
  .BollywoodCasinoCardsWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .BollywoodCasinoCardsContent{
    padding-top: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    &-info{
      display: flex;
    }
    &-name{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: var(--color-secondary-exchange);
      margin: 0 15px;
      width: 100%;
    }
    &-total{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &__name{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--color-secondary-exchange);
      }
      &__cards{
        height: 33px;
        width: 70px;
        display: flex;
        justify-content: center;
        &-card{
          display: inline-block;
          height: 100%;
          width: 23.5px;
          margin-right: 3px;
        }
      }
    }
  }
  .hidden-card{
    display: none;
  }
