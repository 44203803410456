.LiveTeenpatti-results  {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LiveTeenpattiLastRes {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header{
    padding-top: 10px;
    &__title{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: var(--color-exchange);
      text-transform: uppercase;
    }
    &__gameId{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: var(--color-exchange);
    }
  }
  &__results{
    //padding-top: 40px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &-name{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: var(--color-exchange);
      margin-left: -70px;
      margin-top: 12px;
      }
    &-cards{
      display: flex;
      width: 130px;
      height: 44px;
      justify-content: space-between;
      //margin-top: 15px;
      //margin-bottom: 30px;
      margin-top: 10px;
      margin-bottom: 30px;
      // &::before{
      //   content: 'Player A';
      //   position: absolute;
      //   left: -80px;
      //   top: -3px
      // }
      &-isWinner{
        display: flex;
        width: 130px;
        height: 44px;
        justify-content: space-between;
        //margin-top: 15px;
        //margin-bottom: 30px;
        margin-top: 10px;
        margin-bottom: 30px;
        position: relative;
        &::after{
          content: url('../../../../../assets/images/winnerTropy.svg');
          position: absolute;
          right: -70px;
          top: -5px
        }
      }
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0px 0px 5px grey;
      }
    }
  }

}
.LiveTeenpatti-combination {
  width: 100%;
  height: 31px;
  background-color: var(--background-player-exchange);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 20px 0;
  span {
    color: var(--color-secondary-exchange);
    font-weight: 700;
    font-size: 12px;
    text-transform: capitalize;
  }
}

@media (min-width : 1024px) {
  .LiveTeenpatti-results {
    display: flex;
    margin-top: -10px;
    column-gap: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .LiveTeenpattiLastRes {
    &__results {

      &-name {
        margin-left: unset;
        font-size: 12px;
        line-height: 14px;
      }
      &-cards {
        align-items: center;
        height: auto;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        &__list {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          margin-bottom: 10px;
          padding-right: 5px;
          img {
            margin-left: 5px;
          }
        }
        &-isWinner {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: unset;
          &::after{
            display: none;
          }
          .trophy {
            height: 20px;
            width: 20px;
            position: absolute;
            bottom: -20px;

          }
        }
      }
    }
  }
}
