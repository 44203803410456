@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700&display=swap');

@font-face {
    font-family: 'apollo';
    src: url('./assets/fonts/apollo/apollo-font.woff') format('woff')
}

.page-title {
    font-family: 'Lato', sans-serif !important;
    font-weight: 700;
    margin-top: 20px;
    font-size: 18px;
}

.page-title-wrapper {
    margin: 15px 15px 10px;
}

.RightSide {
    margin-right: var(--menu-width);
}

.ion-page {
    background: var(--main-background);
    background-size: var(--main-background-size);
}

ion-content {
    --background: none;
}

.redux-toastr {
    .top-center {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        left: unset;
        margin-left: 0;

        >div {
            width: 700px;
            max-width: 100%;
        }

    }

    .toastr {
        min-height: 59px;
        padding: 10px 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .rrt-error,
    .rrt-success {

        .rrt-left-container,
        .rrt-right-container {
            display: none;
        }

        .rrt-middle-container {
            width: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 10px 15px;
            margin-left: 0;
        }
    }
}

i {
    font-style: normal;
}

.chevron {
    color: var(--accordion-chevron);
    height: 18px;
    font-weight: normal;
    font-family: 'apollo';

    &:before {
        content: "";
    }

    &.down {
        &:before {
            content: "";
        }
    }
}

.bold {
    font-weight: 700;
}

.lowercase {
    text-transform: lowercase;
}

.eventIcon {
    color: var(--primary);

    &.Cricket {
        color: var(--sport-cricket);
    }

    &.Football {
        color: var(--sport-football);
    }

    &.Tennis {
        color: var(--sport-tennis);
    }

    &.HorseRacing {
        color: var(--greyhound-racing);
    }

    &.GreyhoundRacing {
        color: var(--horse-racing);
    }

    &.ExchangeGames {
        color: var(--exchange-games);
    }

    &.LiveCasino {
        color: var(--exchange-games);
    }
}

.list-md {
    padding-top: 0;
    padding-bottom: 0;
}

.ion-page {

    .default-page__content,
    .MenuEvent,
    .Competitions,
    .EventPage,
    .MyMarket,
    .Scorecard,
    .betting-profit-and-loss-page {
        --padding-bottom: 80px;
        width: 100%;
    }
}

.sc-ion-popover-md-h {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.7);
}

.hideSesPopover {
    .SessionPopover {
        display: none;
    }
}


.hideBetPopover {
    .BetPlacingPopover {
        display: none;
    }
}

.whatsApp {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 3;
    width: 81px;
    height: 81px;

    &_img {
        position: relative;
        z-index: 2;
    }

    // &_box {
    //     // display: none;
    //     padding-left: 0;
    //     padding-right: 0;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     position: relative;
    //     left: 16px;
    //     z-index: 1;
    //     width: 0px;
    //     height: 47px;
    //     background: #2DB642 0% 0% no-repeat padding-box;
    //     border-radius: 25px;
    //     opacity: 0;
    //     bottom: 71px;
    //     text-align: left;
    //     font-size: 12px;
    //     font-family: 'Poppins';
    //     font-weight: 600;
    //     color: #FFFFFF;

    //     // max-width: 0;
    //     &.active {
    //         // display: block;
    //         animation: whatsApp ease 1s;
    //         width: 275px;
    //         opacity: 1;
    //         // padding-left: 60px;
    //         // padding-right: 20px;
    //         position: absolute;
    //         left: 30px;
    //         top: 14px;
    //     }

    //     &.close {
    //         animation: whatsAppClose ease 1s;
    //         width: 0;
    //         opacity: 0;
    //         // padding-left: 0px;
    //         // padding-right: 0px;

    //     }

    //     &_text {
    //         height: 35px;
    //         // min-width: 170px;
    //         width: 220px;
    //         // max-width: 200;
    //         position: absolute;
    //         // top: 50%;
    //         left: 50%;
    //         // transform: translate(-50%, -50%);

    //         &.activeText {
    //             // display: block;
    //             animation: whatsAppText ease 1s;
    //             opacity: 1;
    //             // margin-left: 60px;
    //             left: 15%;
    //             // width: 80%;
    //         }

    //         &.closeText {
    //             animation: whatsAppCloseText ease 1s;
    //             opacity: 0;
    //             // margin-left: 0;
    //         }
    //     }
    // }


    .circleIconPanel {
        width: 265px;
        height: 50px;
        border-radius: 25px;
        z-index: 1;

        position: absolute;
        left: 30px;
        bottom: 19px;
        opacity: 0;

        text-align: start;
        padding: 8px 20px 7px 50px;
        font: normal normal 600 12px/17px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;

        background: #2DB642 0% 0% no-repeat padding-box;
        background-image: url('./assets/images/Lotus365WhatsAppMessage.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;

        &.showMessage {
            opacity: 1;
            animation: showMessage 1s linear;
        }

        &.hideMessage {
            opacity: 0;
            animation: hideMessage 1s linear;
            width: 0;
        }
        }

    @keyframes showMessage {

        0%,
        25% {
            opacity: 0;
            width: 0;
        }

        100% {
                opacity: 1;
            width: 265px;
        }
            }

    @keyframes hideMessage {
        0% {
            opacity: 1;
            width: 265px;
        }

        80%,
        100% {
                opacity: 0;
            width: 0;
        }
    }
}



ion-content {
    animation: fadein 0.5s ease-in;
}

@keyframes whatsApp {

    0% {
        opacity: 0;
        width: 0;
        // padding-left: 0px;
        padding-right: 0px;
    }

    100% {
        opacity: 1;
        width: 275px;
        // padding-left: 60px;
        padding-right: 20px;
    }
}

@keyframes whatsAppClose {
    0% {
        opacity: 1;
        width: 275px;
        // padding-left: 60px;
        padding-right: 20px;
    }

    100% {
        opacity: 0;
        width: 0;
        // padding-left: 0px;
        padding-right: 0px;
    }
}

@keyframes whatsAppText {

    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes whatsAppCloseText {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


@keyframes fadein {
    0% {
        opacity: 0.2;
    }

    25% {
        opacity: 0.5;
    }

    65% {
        opacity: 0.8;
    }

    100% {
        opacity: 1
    }
}

.main {
    height: 100%;
    ;
}

.main_container {
    display: flex;

    &-left {
        width: 0%;
        display: none;
    }

    &-center {
        width: 100%;

        .ion-page {
            z-index: 5;
        }
    }

    &-rigth {
        width: 0%;
        display: none;
    }
}

@media screen and (min-width: 1023px) {
    .ion-page {
        overflow-y: auto !important;
        display: block !important;
    }

    //.ion-page:not(div.ion-page)  {
    //    display: block !important;
    //
    //}
    //.ExchangeGamesLobby {
    //    div.ion-page {
    //        display: flex !important;
    //    }
    //}

    ion-fab {
        display: none;
    }

    .main_container {
        display: flex;
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        min-height: calc(100% - 195px);
        margin-bottom: 15px;

        &-left {
            width: 15%;
            display: unset;
        }

        &-center {
            width: 59%;

            &__big {
                width: 85%;

                &.relative {
                    position: relative;
                }

                .balance-info {
                    width: 312px;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }

        &-rigth {
            width: 26%;
            display: unset;
        }

        .main_container__betslip-desktop_wrapper {
            position: sticky;
            // top: 0;
            z-index: 2;
        }
    }
}


@media screen and (max-width: 1135px) {
    .main_container-left {
        margin-right: 20px;
    }
    .default-page__content {
        .landing-footer-desktop {
            margin-top: 30px;
        }
    }

    .left-side-menu {
        .landing-footer-desktop {
            background: white;
        }
    }

}

::selection {
    background: var(--secondary);
    color: #FFFFFF;
}

.disable-events {
    pointer-events: none;
    overflow-y: hidden !important;
}
.ion-page {
    contain: none !important;
}
