.pop-upLastResult-content__results {
  .pop-upLastResult-content__poker20-winner-pattern {
    width: 100%;
    height: 31px;
    background: var(--background-player-exchange);
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.05em;
    color: var(--color-secondary-exchange);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
}

@media (min-width: 1024px) {
  .pop-upLastResult-content__results-poker20-items-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 420px;
    margin: 20px auto 25px auto;

    .pop-upLastResult-content__results-poker20-item {
      position: relative;
      margin-bottom: 35px;
      text-align: center;
    }
   .pop-upLastResult-content__results-name {
     position: unset !important;
     top: unset !important;
     left: unset !important;
     transform: none !important;
     font-size: 12px;
     display: inline-block;
     margin-bottom: 10px;
    }
    .pop-upLastResult-content__results-cards {
      width: unset !important;
    }
    .pop-upLastResult-content__poker20-board-cards {
      width: unset !important;
    }
    .pop-upLastResult-content__results-poker20-item.poker20-item-board {
      right: unset !important;
    }
    .pop-upLastResult-content__results-cards-card {
      display: block;
      width: 32px;
      height: 46px;
      box-shadow: 0px 0px 5px grey;
    }
    .pop-upLastResult-content__results-cards-card:not(:last-child) {
      margin-right: 5px;
    }
    .pop-upLastResult-content__results-cards.isWinner:before {
      content: '';
      position: absolute;
      background-image: url('../../../../../assets/images/winnerTropy.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      //background-size: auto 100%;
      width: 20px;
      height: 20px;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, 100%);
    }
  }
}


@media (max-width: 1024px) {
  div.pop-upLastResult-content__results-poker20-items-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pop-upLastResult-content__results-name{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: var(--color-exchange);
    }
    .pop-upLastResult-content__results-cards {
      display: flex;
      width: 80px;
      height: unset;
      //height: 44px;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .pop-upLastResult-content__results-cards.isWinner {
      position: relative;
      &::before{
        content: url('../../../../../assets/images/winnerTropy.svg');
        position: absolute;
        left: auto;
        right: -80px;
        top: -3px
      }
    }
    .pop-upLastResult-content__results-cards-card{
      height: 44px;
    }
    .pop-upLastResult-content__results-cards-card:not(:last-child) {
      margin-right: 15px;
    }
    .pop-upLastResult-content__poker20-board-cards {
      width: 225px;
    }
    .pop-upLastResult-content__results-poker20-items-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .pop-upLastResult-content__results-poker20-item {
      position: relative;
      margin-bottom: 35px;
      .pop-upLastResult-content__results-name {
        position: absolute;
        top: 50%;
        left: -15px;
        transform: translate(-100%, -50%);
      }
      .pop-upLastResult-content__results-cards {
        margin: 0;
      }
      .pop-upLastResult-content__results-cards.isWinner {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .pop-upLastResult-content__results-poker20-item.poker20-item-board {
      right: -15px;
    }
  }
}

@media (max-width: 365px) {
  div.pop-upLastResult-content__results {
    .pop-upLastResult-content__results-poker20-item {
      .pop-upLastResult-content__results-cards {
        width: unset;
        justify-content: center;
      }
      .pop-upLastResult-content__results-cards-card {
        width: 30px;
        height: 40px;
      }
      .pop-upLastResult-content__results-cards-card:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
