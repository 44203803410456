@media (min-width: 1024px) {
  .account-statement-page {
    color: #000000;
    .page-title {
      font-weight: 600;
      font-size: 24px;
      padding-bottom: 15px;
      margin-bottom: 12px;
      border-bottom: 1px solid #9AA1A4;
      width: 100%;
      max-width: calc(100% - 292px);
    }
    &-wrapper {
      padding: 0 10px 0 15px;
    }
    &-title-wrapper {
      position: relative;
      margin: 15px 0 20px 0;
      //.account-statement-page-title:after {
      //  content: '';
      //  position: absolute;
      //  bottom: -10px;
      //  left: 0;
      //  right: 0;
      //  width: 100%;
      //  border: 1px solid #9AA1A4;
      //}
    }
    &__filter-content {
      margin-bottom: 45px;
      width: 100%;
      max-width: 360px;
      padding: 0 15px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      position: relative;
      &-error {
        position: absolute;
        bottom: -20px;
        left: 10px;
        font-size: 12px;
        color: #d01901;
      }
      p {
        margin: 0 0 10px 0;
        font-family: 'Lato';
        font-weight: 400;
        font-size: 12px;
      }
      ion-item {
        padding: 0 !important;
        padding-inline-start: 0 !important;
        padding-inline-end: 0 !important;
        --padding-start: 0px !important;
        --inner-padding-end: 0 !important;
        --padding-top: 0 !important;
        --padding-end: 0 !important;
        --padding-bottom: 0 !important;
        --min-height: 30px;
        .item-native {
          padding: 0 !important;
          padding-inline-start: 0 !important;
          padding-inline-end: 0 !important;
          --padding-start: 0 !important;
          --inner-padding-end: 0 !important;
        }
      }

      ion-datetime {
        --padding-start: 20px !important;
        --inner-padding-end: 0 !important;
        --padding-top: 0 !important;
        --padding-end: 0 !important;
        --padding-bottom: 0 !important;
        font-family: 'Lato';
        font-weight: 400;
        font-size: 11px;
        color: #686868;
      }
      .item-datetime {
        width: 100px;
        height: 30px;
        .in-item {
          width: 100%;
        }
        .item-native {
          padding-left: 0;
        }
        button {
          width: 100%;
        }
      }
    }
    &__date-search-button {
      background: var(--cta-primary-darken);
      border-right: 2px solid rgba(0, 0, 0, 0.25);
      border-bottom: 2px solid rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      width: 73px;
      height: 30px;
      font-weight: 700;
      font-size: 12px;
      color: #FFFFFF;
      padding: 0 5px;
      display: inline-flex;
      align-items: center;
      justify-content: space-evenly;
      &:hover{
        background-color: var(--secondary);
        box-shadow: 1.5px 1.5px 0px 0px var(--cta-primary-darken);
      }
      &:active{
        background-color: var(--secondary);
        border-top: 2px solid rgba(0, 0, 0, 0.25);
        border-left: 2px solid rgba(0, 0, 0, 0.25);
        box-shadow: none;
      }
    }
    &__date-search-button:disabled {
      background: #DDDDDD;
      box-shadow: none;
    }
  }
  ion-backdrop {
    --ion-backdrop-color: rgba(0, 0, 0, 0.3) !important;
  }
  ion-picker {
    transform: none !important;

    .picker-wrapper.sc-ion-picker-md {
      top: 30%;
      bottom: auto;
    }
  }
}

@media (max-width: 1024px) {
  .account-statement-page__content {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: var(--text-color);
    // margin: 0 4px 0 4px;
  }
  .account-statement-page__content-group {
    padding: 10px;
  }
  .account-statement-page__filter {
    &-content {
      padding: 15px;
    }
    &-data-piker {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 40px;
    }
  }
  .account-statement-page__item {
    background: var(--white);
    color: var(--black);
    display: flex;
    flex-direction:column;
    gap: 5px;
    // justify-content: space-between;
    padding: 15px;
    // margin-top: 3px;
    & > div {
      display: flex;
      justify-content: space-between;
      gap: 5px;
    }
    &__left-side {
      flex: 1;
    }
    &__right-side {
      width: 100px;
      &.data {
        display: flex;
        flex-direction:column;
      }

      & img {
        margin-right: 5px;
      }
    }
    &__title {
      // color: var(--account-title-color);
      color: var(--black);
    }
    &__remark {
      padding: 0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
      text-align: left;
      font-style: normal;
      font-weight: 700;
      text-decoration: underline;
      color: var(--back-color);
    }
    &__balanse {
      font-style: normal;
      font-weight: 700;
    }
    .time {
      color: var(--account-title-color);
    }
    .red {
      color: #d01901;

    }
    .green {
      color: #2aa033;
    }
  }
  .filter-button {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color);
    background: var(--white);
    border: 1px solid rgba(218, 223, 239, 0.4);
    padding: 8px 23px 8px 10px;
    max-width: 140px;
    width: 100%;
    text-align: left;
    border-radius: 5px;
    &:before {
      content: url("../../assets/images/filter1.svg");
      padding-right: 6px;
      height: 24px;
      position: relative;
      top: 2px;
    }
    &--active {
      &:before {
        content: url("../../assets/images/close1.svg");
        top: 3px;
      }
    }
  }
  .account-statement-page__content-header {
    display: flex;
    background: var(--accordion-background);
    font-style: normal;
    font-weight: normal;
    align-items: center;
    background-color: var(--account-report);
    color: var(--accordion-color);
    padding: 5px 10px 5px 13px;
  }
  .account-statement-page__content--empty {
    font-size: 14px;
    color: black;
    margin: 0 0 0 18px;
  }
  .account-statement-page__content-row-date {
    width: 25%;
  }
  .account-statement-page__content-row-description {
    width: 30%;
  }
  .account-statement-page__content-row-amount {
    width: 45%;
    text-align: right;
  }
  .account-statement-page__list-row {
    .account-statement-page__content-row-date {
      display: flex;
      align-items: center;
    }
    .account-statement-page__content-row-description {
      display: flex;
      align-items: center;
    }
    .account-statement-page__content-row-amount {
      font-weight: 700;
    }
  }
  .account-statement-page__row {
    display: flex;
  }
  .account-statement-page__content-row-header {
    display: flex;
    align-items: center;
    background-color: var(--group-header);
    color: var(--account-header-color);
    padding: 5px 13px;
  }
  .account-statement-page__row {
    padding: 3px 0;
  }
  .react-date-picker__wrapper{
    position: absolute;
    left: 0px;
    top: -30px;
    border: 0px
  }
  .react-date-picker__inputGroup{
    padding: 0px;
    font-size: 16px;
    .react-date-picker__inputGroup__input{
      color: #000;
    }
    .react-date-picker__inputGroup__leadingZero{
      color: #000;
    }
  }
  .account-statement-page__filters-date-input{
    border-bottom: 1px solid rgba(180, 178, 178, 0.849);
    position: relative;
    background: #fff;
  }
  .react-date-picker__inputGroup__divider{
    margin: 0px 1px 0 -1px;
  }
  .account-statement-page__date-search-button{
    background: var(--bet-form-stake-buttons-bg-color);
    width: 100%;
    height: 38px;
    margin-top: 15px;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
