.exchangeGames{
    height: 100%;
    overflow: auto;
    position: relative;
    padding-top: 135px;
    // background: #142326;
    overflow-x: hidden;
    &__bg{
        height: 100%;
        width: 100%;
        &-top{
            // background: url('../../assets/images/exchangeGamesBgTop.svg');
            background: linear-gradient(180deg, #065824 0%, #063E39 100%);
            // background: linear-gradient(83.27deg, rgba(18, 116, 86, 0) 0.58%, #CEFE98 97.8%);
            // max-height: 53vw;
            width: 100%;
            // height: 100%;
            height: 65%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            min-height: 350px;

            &_lines{
                position: absolute;
                width: 100%;
                // top: -29vw;
                z-index: 1;
                &-lines{
                    display: block;
                    width: 100%;
                    margin-top: -200px;
                }
            }
            &_credo{
                // display: flex;
                // position: absolute;
                // width: 161px;
                // height: 2px;
                // background-color: #FFFFFF;
                // left: 90px;
                // top: 317px;
                padding-top: 40px;
                &-img{
                    width: 175px;
                    height: 2px;
                    background: linear-gradient(#FFFFFF, #02205E);
                    margin-top: 15px;
                    margin-left: 88px;
                }
                &-text{
                    // width: 130px;
                    width: 420px;
                    // height: 186px;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 45px;
                    line-height: 62px;
                    text-align: right;
                    letter-spacing: 0.09em;
                    color: #FFFFFF;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    margin-left: 50px;
                    margin-top: 11px;
                }
            }
            &_description{
                // position: absolute;
                //width: 420px;
                width: 100%;
                max-width: 420px;
                //height: 120px;
                position: relative;
                // left: 90px;
                // top: 345px;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                line-height: 30px;
                letter-spacing: 0.09em;
                // color: #02205E;
                color: #86AA16;
                z-index: 2;
                margin-left: 88px;
                margin-top: 31px;
            }
        }
        &-bottom{
            // margin-top: -1px;
            margin-top: -20px;
            width: 100%;
            height: 100%;
            background-size: contain;
            position: relative;
            //&_lines{
            //    position: absolute;
            //    top: 79vw;
            //    width: 100%;
            //    z-index: 1;
            //    &-lines{
            //        display: block;
            //        width: 100%;
            //        background: transparent;
            //    }
            //}
            .exchangeGames__bg-bottom-desktop-background {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .exchangeGames__bg-bottom-desktop-background-img {
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
            .exchangeGames__allgames{
                padding: 55px 90px;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                //background: #142326;
                &-livegames{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 45px;
                    line-height: 54px;
                    letter-spacing: 0.09em;
                    color: #09D2EC;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    position: relative;
                    z-index: 3;
                    max-width: 1440px;
                }
                .exchangeGames__allgames-title {
                    font-family: 'Lato';
                    font-weight: 700;
                    font-size: 45px;
                    letter-spacing: 0.09em;
                    color: #86AA16;
                    margin: 0 0 20px 0;
                    text-align: left;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                }
            }
        }
    }
}

.exchangeGames.unauthorized {
    //overflow: hidden;
    .exchangeGames__bg-bottom {
        background: #142326;
    }
}

.arrowDownEx{
    position: relative;
    left: 50%;
    top: -40px;
    height: 20px;
    // &::before{
    //     content: url('../../assets/images/ExchArrowDown.svg');
    //     width: 20px;
    //     height: 20px;
    //     opacity: 0;
    // }
}

// @media screen and (min-width: 1024px) and (max-width: 1099px) {
//     .exchangeGames__bg-top_credo-text{
//         font-size: 40px;
//         line-height: 50px;
//     }
//     .exchangeGames__bg-top_credo-img {
//         height: 145px;
//     }
//     .exchangeGames__bg-top_credo {
//         top:275px;
//     }
//     .exchangeGames__bg-top_description {
//         top: 475px;
//         font-size: 20px;
//     }
// }

// @media screen and (min-width: 1100) and (max-width: 1199px) {
//     .exchangeGames__bg-top_credo {
//         f
//     }
//     .exchangeGames__bg-top_description {
//         top: 475px;
//     }
// }




// @media screen and (max-width: 1366px) {
//     .exchangeGames__bg-top_lines{
//         top:31%;
//         height: 120%;
//     }
//     .exchangeGames__bg-bottom{
//         .exchangeGames__allgames{
//             padding: 55px 60px;
//             top: -162%;
//         }
//     }
//     .exchangeGames__bg-bottom_lines {
//         top: 60%;
//     }
// }
// @media screen and (min-width: 1300px) and (max-width: 1439px) {
//     .exchangeGames__bg-top_lines{
//         margin-top: -375px;
//     }
// }
// @media screen and (min-width: 1200px) and (max-width: 1299px) {
//     .exchangeGames__bg-top_lines{
//         margin-top: -350px;
//     }
// }
@media (max-width: 1300px) {
    .exchangeGames__bg-top_credo {
        .exchangeGames__bg-top_description {
            font-size: 22px;
        }
    }
}
@media screen and (min-width: 1024px) and (max-width: 1109px) {
    .exchangeGames__bg-bottom{
        .exchangeGames__allgames{
            padding: 0px 40px
        }
    }
}
//     .exchangeGames__bg-top_lines{
//         margin-top: -310px;
//     }
// }

.girl {
    // display: block;
    // width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 10%;
}

.insideRoulette, .outsideRoulette {
    position: absolute;
    width: 1000px;
    left: 15vw;
    top: 25vh;
}

@media screen and (min-width: 1300px) {
    .girl {
            right: 1%;
        }
}

@media screen and (min-width: 1800px) {
    .insideRoulette,
        .outsideRoulette {
        left: 25vw;
    }
        .girl {
            right: 10%;
        }
}

.insideRoulette {
    // transform: rotate(360deg);
    animation: spin 17s infinite linear;
    // transform-style:;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.commonForC {
    // position: absolute;
    // /* height: 100%; */
    // /* width: 50%; */
    // top: 20px;
    // right: 65%;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: -35%;

    $total: 500;
    $size: 20;

    .c {
        position: absolute;
        width: $size+px;
        height: $size+px;
        margin-top: -$size/2+px;
        margin-left: -$size/2+px;
        transform: translate3d(50vw, 50vh, -1000px);
        border-radius: 50%;
        filter:blur(0.5vh)
    }

    @for $i from 1 through $total {
        $color: hsl(($i * .1)+70, 100%, 50%);

        .c:nth-child(#{$i}) {
            animation: anim#{$i} 1.5s infinite alternate;
            animation-delay: $i * -.015s;
            background: $color;
            background: radial-gradient(circle at top left, lighten($color, 15%), $color);
            box-shadow: 0 0 25px 3px lighten($color, 5%);
            border: 1px solid $color;
        }

        @keyframes anim#{$i} {
            80% {
                opacity: 1;
            }

            100% {
                transform: translate3d(random(100)+vw, random(100)+vh, 0);
                opacity: 0;
            }
        }
    }
}


.light {
    position: absolute;
    width: 0px;
    opacity: .75;
    background-color: white;
    box-shadow: #e9f1f1 0px 0px 20px 2px;
    opacity: 0;
    top: 100vh;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
}

.x1 {
    -webkit-animation: floatUp 4s infinite linear;
    -moz-animation: floatUp 4s infinite linear;
    -o-animation: floatUp 4s infinite linear;
    animation: floatUp 4s infinite linear;
    -webkit-transform: scale(1.0);
    -moz-transform: scale(1.0);
    -o-transform: scale(1.0);
    transform: scale(1.0);
}

.x2 {
    -webkit-animation: floatUp 7s infinite linear;
    -moz-animation: floatUp 7s infinite linear;
    -o-animation: floatUp 7s infinite linear;
    animation: floatUp 7s infinite linear;
    -webkit-transform: scale(1.6);
    -moz-transform: scale(1.6);
    -o-transform: scale(1.6);
    transform: scale(1.6);
    left: 15%;
}

.x3 {
    -webkit-animation: floatUp 2.5s infinite linear;
    -moz-animation: floatUp 2.5s infinite linear;
    -o-animation: floatUp 2.5s infinite linear;
    animation: floatUp 2.5s infinite linear;
    -webkit-transform: scale(.5);
    -moz-transform: scale(.5);
    -o-transform: scale(.5);
    transform: scale(.5);
    left: -15%;
}

.x4 {
    -webkit-animation: floatUp 4.5s infinite linear;
    -moz-animation: floatUp 4.5s infinite linear;
    -o-animation: floatUp 4.5s infinite linear;
    animation: floatUp 4.5s infinite linear;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    left: -34%;
}

.x5 {
    -webkit-animation: floatUp 8s infinite linear;
    -moz-animation: floatUp 8s infinite linear;
    -o-animation: floatUp 8s infinite linear;
    animation: floatUp 8s infinite linear;
    -webkit-transform: scale(2.2);
    -moz-transform: scale(2.2);
    -o-transform: scale(2.2);
    transform: scale(2.2);
    left: -57%;
}

.x6 {
    -webkit-animation: floatUp 3s infinite linear;
    -moz-animation: floatUp 3s infinite linear;
    -o-animation: floatUp 3s infinite linear;
    animation: floatUp 3s infinite linear;
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8);
    left: -81%;
}

.x7 {
    -webkit-animation: floatUp 5.3s infinite linear;
    -moz-animation: floatUp 5.3s infinite linear;
    -o-animation: floatUp 5.3s infinite linear;
    animation: floatUp 5.3s infinite linear;
    -webkit-transform: scale(3.2);
    -moz-transform: scale(3.2);
    -o-transform: scale(3.2);
    transform: scale(3.2);
    left: 37%;
}

.x8 {
    -webkit-animation: floatUp 4.7s infinite linear;
    -moz-animation: floatUp 4.7s infinite linear;
    -o-animation: floatUp 4.7s infinite linear;
    animation: floatUp 4.7s infinite linear;
    -webkit-transform: scale(1.7);
    -moz-transform: scale(1.7);
    -o-transform: scale(1.7);
    transform: scale(1.7);
    left: 62%;
}

.x9 {
    -webkit-animation: floatUp 4.1s infinite linear;
    -moz-animation: floatUp 4.1s infinite linear;
    -o-animation: floatUp 4.1s infinite linear;
    animation: floatUp 4.1s infinite linear;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    left: 85%;
}


@-webkit-keyframes floatUp {
    0% {
        top: 100vh;
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        top: 0vh;
        opacity: .8;
    }

    75% {
        opacity: 1;
    }

    100% {
        top: -100vh;
        opacity: 0;
    }
}

@-moz-keyframes floatUp {
    0% {
        top: 100vh;
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        top: 0vh;
        opacity: .8;
    }

    75% {
        opacity: 1;
    }

    100% {
        top: -100vh;
        opacity: 0;
    }
}

@-o-keyframes floatUp {
    0% {
        top: 100vh;
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        top: 0vh;
        opacity: .8;
    }

    75% {
        opacity: 1;
    }

    100% {
        top: -100vh;
        opacity: 0;
    }
}

@keyframes floatUp {
    0% {
        top: 100vh;
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        top: 0vh;
        opacity: .8;
    }

    75% {
        opacity: 1;
    }

    100% {
        top: -100vh;
        opacity: 0;
    }
}
