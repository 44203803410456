  //.showcards__bottom {
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //
  //  background: #1d1d1d;
  //  color: #ffffff;
  //
  //  min-height: 66px;
  //  width: 100%;
  //
  //  font-family: Poppins !important;
  //  font-style: normal;
  //  font-weight: 500;
  //  font-size: 12px;
  //  line-height: 18px;
  //
  //  border-top: 5px solid #00af3c;
  //
  //  span {
  //    font-family: Poppins !important;
  //    font-style: normal;
  //    font-weight: 500;
  //    font-size: 12px;
  //    line-height: 18px;
  //  }
  //}

  .teenpatti2card-live-cards {
    width: 100%;
    max-width: 445px;
  }
.teenpatti2card-players {
  display: flex;
  justify-content: space-around;
  height: 56px;
  margin: 0 auto;

  .block-player {
    display: flex;
    align-items: center;
    column-gap: 15px;
    color: var(--color-secondary-exchange);
    font-family: 'Poppins';
  }
  .cards {
    height: 33px;
    img {
      margin: 0 2.5px;
      width: 24px;
      height: 33px;
      border-radius: 2px;
    }
  }
  .block-player-title-score,
  .block-player-title-score-slash {
    display: none;
  }
}
  @media (min-width: 1024px) {
    //.gameresult.gameresult-teenpatti2card {
    //  height: unset;
    //  .gameresult-cardsplace {
    //    height: 82px;
    //  }
    //}
    .teenpatti2card-players {
      height: 100%;
      justify-content: space-between;
      .block-player {
        display: flex;
        align-items: self-start;
        justify-content: center;
        flex-direction: column;
        grid-column-gap: 15px;
        column-gap: 15px;
        color: var(--color-secondary-exchange);
      }
      .block-player:last-child {
        flex-direction: column-reverse;
      }
      .cards {
        height: unset;
        //img {
        //  margin: 0 5px;
        //  height: 45px;
        //  width: 33px;
        //}
      }
      .block-player-title {
        margin-bottom: 3px;
        padding-left: 3px;
        font-size: 12px;
        font-weight: 500;
      }
      .block-player-title-score-slash {
        display: unset;
      }
      .block-player-title-score {
        display: unset;
        color: var(--color-total-exchange);
      }
    }
  }
  @media (max-width: 1024px) {
    .teenpatti2card-players {
      .block-player-title {
        font-size: 12px;
      }
    }
  }
