@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.ChangePassword {
  --padding-top: 15px;
  --padding-start: 15px;
  --padding-end: 15px;

  &__form {
    width: 100%;
    margin-top: 10px;
  }

  &__list {
    background: none !important;

    ion-item {
      --min-height: 36px;
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --inner-border-width: 0px;

      // ion-input.error {
      //   border: 1px solid var(--ion-color-danger);
      // }
    }

    ion-item+ion-item {
      margin-top: 12px;
    }
  }

  &__button {
    width: 50%;
    margin: 20px 0 auto auto;
    text-transform: none;
    background: var(--secondary);
    color: #fff;
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1), background-color .3s cubic-bezier(.4, 0, .2, 1), color .3s cubic-bezier(.4, 0, .2, 1), -webkit-box-shadow .3s cubic-bezier(.4, 0, .2, 1);
    height: 36px;
    font-weight: 500;
    margin-top: 24px;

    &:hover {
      background: var(--cta-primary-darken);
    }

    &:disabled {
      background: var(--cta-disabled);
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__form-error {
    padding: 0 10px 5px;
    margin-top: -2px;
    font-size: 10px;
    color: var(--negative);

    ion-item+& {
      padding: 0 10px 10px;
      margin-top: 9px;
    }
  }

  ion-item {
    --highlight-background: var(--login-highlight-bg);
    --border-radius: 2px;
  }

  ion-input {
    border-radius: 2px;
    height: 100%;
  }

  .native-input {
    border-radius: 2px;
    height: 100%;

    &:focus {
      border: 1px solid var(--login-item-focused-border);
    }
  }
}

//main sidebar
.sideBarMenu__wrapper {
  height: 100%;
}

.sideBarMenu {
  position: relative;
  height: 100%;
  bottom: 0;
  left: 0;
  width: 175px;
  background-color: #fff;
  background-attachment: fixed;
  // margin-left: 10px;
  overflow-x: hidden;
  // padding-bottom: 60px;
  scroll-behavior: auto;

  border: 1px solid #DDDDDD;

  &::-webkit-scrollbar {
    display: none;
  }

  &-content {
    width: 175px;
    overflow-y: scroll;
    // max-height: 100vh;
    display: flex;
    overflow-x: hidden;

    // overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }

    scroll-behavior: auto;

  }
}

.content-list {
  width: 100%;
  transition: 0.4s linear;
  background-color: #fff;
  background-attachment: fixed;
  border: 1px solid #DDDDDD;
  border-left: none;
  border-right: none;
  cursor: pointer;

  &__item {
    width: 175px;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 12px;
    color: #000000;
    display: flex;
    display: flex;
    border: 0.5px solid #DDDDDD;
    margin-right: 100px;

    &-text {
      display: block;
      margin: 23px 0 23px 0;

      margin-left: 6px;
    }

    &-icon {
      display: block;
      margin: 20px 3px 20px 20px;
      height: 20px;
      width: 20px;
    }

    &:hover {
      background: rgb(236, 235, 235);
      background-size: 80%;
      transition: ease 0.2s;
      transform: scale(1.1);

    }
  }
}

//Competitions

.contentList {
  visibility: visible;
  transition: 0.4s linear;
  cursor: pointer;
  width: 175px;
  border: 1px solid #DDDDDD;
  border-left: none;
  border-right: none;

  &__detailed {
    width: 175px;
    margin-right: 100px;
    padding: 7px 18px;
    font-family: 'Lato';
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    background: #fff;
    color: #000000;
    border-bottom: 1px solid #DDDDDD;
    display: flex;
    position: relative;
    align-items: center;

    &-previous {
      font-family: 'Lato';
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      padding: 7px 18px;
      border-bottom: 1px solid #DDDDDD;
      display: flex;

      img {
        display: block;
        margin-right: 11px;
      }

      span {
        display: block;
        margin-top: 2px;
      }
    }

    &-game {
      color: #fff;
      font-family: 'Lato';
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      padding: 7px 18px;
      border-bottom: 1px solid #DDDDDD;
      display: flex;

      img {
        display: block;
        margin-right: 11px;
        color: #fff;
      }

      span {
        display: block;
        margin-top: 2px;
      }
    }

    span {
      display: block;
      margin-top: 2px;
      margin-right: 8px;
    }

    img {
      display: block;
      margin-right: 11px;
    }

    &:hover {
      background: rgb(236, 235, 235);
      transition: ease 0.2s;
    }
  }
}

//Detailed competitions

.contentListMore {
  width: 175px;
  visibility: visible;
  transition: 0.4s linear;
  cursor: pointer;
  width: 100%;

  &__detailed {
    width: 175px;
    margin-right: 100px;
    padding: 7px 18px;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    background: #fff;
    color: #000000;
    border-bottom: 1px solid #DDDDDD;
    display: flex;
    position: relative;
    align-items: center;

    &-previous {
      font-family: 'Lato';
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      padding: 7px 18px;
      border-bottom: 1px solid #DDDDDD;
      display: flex;

      img {
        display: block;
        margin-right: 11px;
      }

      span {
        display: block;
        margin-top: 2px;
      }
    }

    &-game {
      color: #fff;
      font-family: 'Lato';
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      padding: 7px 18px;
      border-bottom: 1px solid #DDDDDD;
      display: flex;

      img {
        display: block;
        margin-right: 11px;
        color: #fff;
      }

      span {
        display: block;
        margin-top: 2px;
      }
    }

    span {
      display: block;
      margin-top: 2px;
      margin-right: 8px;
    }

    img {
      display: block;
      margin-right: 11px;
    }

    &:hover {
      background: rgb(236, 235, 235);
      transition: ease 0.2s;
    }
  }
}

// add classes

.arrowRight {
  display: block;
  position: absolute;
  right: 0px;
}

.arrowRightWhite {
  display: block;
  position: absolute;
  right: 0;
  margin: 0 7px 0 0;
}

.cricketTitle {
  background: #811F0F;
}

.cricketPrevious {
  background: #F1E9E7;
  color: #811F0F;
}

.cricketCompetition {
  color: #811F0F;

  &:hover {
    background: #F1E9E7;
  }

  .open {
    background: #811F0F;
    color: #fff;
  }
}




.footballTitle {
  background: #000;
}

.footballPrevious {
  background: #E2E2E2;
  color: #000;
}

.footballCompetition {
  color: #000;

  &:hover {
    background: #E2E2E2;
  }
}



.tennisTitle {
  background: #80A74C;
}

.tennisPrevious {
  background: #EBEFE6;
  color: #5F783C
}

.tennisCompetition {
  color: #5F783C;

  &:hover {
    background: #EBEFE6;
  }
}


.horseTitle {
  background: #7D5048;
}

.horsePrevious {
  background: #F2EDEC;
  color: #7D5048;
}

.horseCompetition {
  color: #7D5048;

  &:hover {
    background: #F2EDEC;
  }
}


.greyhoundTitle {
  background: #305765;
}

.greyhoundPrevious {
  background: #EAEEEF;
  color: #305765;
}

.greyhoundCompetition {
  color: #305765;

  &:hover {
    background: #EAEEEF;
  }
}

// active-inactive

.inactiveDet {
  margin-left: -175px;
}

.inactiveDetMax {
  margin-left: -350px;
}

.hidden {
  display: none;
}

.activeDet {
  display: block;
}

// .inactiveMenu{
//   transform: translateX(175px);
//   transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
// }

.navigationOpen {
  animation-duration: 0.4s;
  animation-name: slidein;
  animation-timing-function: linear;
}

@keyframes slidein {
  from {
    margin-left: 175px;
  }

  to {
    margin-left: 0;
  }
}

.navigationBack {
  animation-duration: 0.4s;
  animation-name: slideout;
  animation-timing-function: linear;
}

@keyframes slideout {
  from {
    margin-left: -175px;
  }

  to {
    margin-left: 0;
  }
}



.arrowRightWhite {
  display: block;
  position: absolute;
  right: 0;
}
