
.pop-upLastResult.Sicbo {
  .popUpSicbo-content__results-cards {
    .popUpSicbo-content__card {
      width: 21px;
      height: 21px;
      margin: 0 2.5px;
    }
  }
  .pop-upLastResult-content {
    .pop-upLastResult-content__results {
      padding-top: 32px !important;
      padding-bottom: 60px;
    }
  }
}
