.casino-war-last-result {
  margin-top: unset;
  padding: 10px 0 100px 0;
  display: flex;
  align-items: center;
  &__player {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 12px;
    color: #101010;
    position: relative;
    &-cards {
      margin-top: 10px;
      display: flex;
      &-card{
        display: block;
        width: 26px;
        height: 35px;
        margin: 0 3px;
        box-shadow: 0 0 5px grey;
      }
    }
    &.dealer {
      color: #D8C200;
    }
    &.isWinner:before {
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      background-image: url(/static/media/winnerTropy.7796e64e.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      left: 50%;
      top: 65px;
      transform: translateX(-50%);
    }
  }
}

@media (min-width: 1024px) {
  .casino-war-last-result {
    margin-top: -10px;
    padding: 0 0 60px 0;
    &__player {
      &-cards {
        margin-top: 10px;
        display: flex;
        &-card {
          width: 32px;
          height: 44px;
          margin: 0 7px;
        }
      }
      &.isWinner:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background-image: url(/static/media/winnerTropy.7796e64e.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        left: 50%;
        top: 78px;
        transform: translateX(-50%);
      }
    }
  }
}
