.sportsPage {
    .inplay-item-list {
        margin-left: 10px;
        margin-right: 10px;
    }
    .top-baner-wrapper {
        width: 98.7%;
    }

    .sportsPage-subHeader {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        justify-content: space-between;
        margin: 0 10px 10px 10px;
        padding-left: 5px;

        color: #142326;
        .sportsPage-subHeader-players {
            width: calc((100% - 34px) / 2);
            display: flex;
            span {
                display: inline-block;
                text-align: center;
                color: #0f2327;
            }
        }
        .sportsPage-subHeader-players.tennis {
            span {
                width: 50%;
            }
        }
        .sportsPage-subHeader-players.cricket-football {
            span {
                width: 33.3%;
            }
        }
    }

    .favourites-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
          font-size: 24px;
        line-height: 36px;

        color: #0f2327;
        }

        h1,p {
            margin: 0;
        }


        margin: 0 10px;

        margin-bottom: 10px;
        padding: 10px 0px;
        border-bottom: 1px solid rgba(15, 35, 39, 0.4);
    }

    .sportsPage-headerBet {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;

        color: #142326;
    }

    // .promotion-content {
    //     margin-bottom: 60px;
    // }
}

