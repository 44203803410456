.EventPage {
    --padding-bottom: 2px;
    color: black;
    &__title {
        padding: 12px;
        display: block;
    }

    .favourites-header {
        position: relative;
        //display: flex;
        //justify-content: space-between;
        //align-items: center;
        .favourites-header__inner-wrapper {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .favourites-header__scoreCardInfo-content {
            font-size: 12px;
            line-height: 1;
            padding-left: 12px;
        }
    
        h1 {
            font-size: 24px;
            line-height: 36px;
            color: #0f2327;
            font-weight: 400;
        }
    
        h1,
        p {
            margin: 0;
        }
    
    
        margin: 0!important;
    
        margin-bottom: 10px !important;
        padding: 10px 10px;
        border-bottom: 1px solid rgba(15, 35, 39, 0.4);

        .inplay-item__broadcast-icon {
            width: 20px;
            height: 20px;
            position: absolute;
            //top: 34%;
            //left: -2px;
            left: 0;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }
    
    .sportsPage-headerBet {
        font-family: 'Lato';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
    
        color: #0f2327;

        margin-top: 10px!important;
    }
}

.onscrollHeader {
    transition: all 250ms ease-out;
}

.SandboxRoot.var-static .timeline-LoadMore {
    display: block !important; 
}
