@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.casino-war {
  padding: 34px 10px 30px 10px;
  position: relative;
  .casino-war__body {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    color: var(--color-exchange);
    position: relative;
    .casino-war__body-cards {
      width: 100%;
      padding: 0 7px 27px 7px;
      display: flex;
      .casino-war__body-cards-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .casino-war__body-cards-item {
        text-align: center;
        span {
          display: inline-block;
          width: 100%;
          color: var(--color-title-exchange);
        }
        &-card {
          width: 32px;
          height: 43px;
          margin-bottom: 5px;
        }
      }
    }
    .casino-war__body-players-item-info-sell {
      width: 80px;
      background-color: var(--background-player-exchange);
      border-radius: 5px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      &-price {
        font-weight: 700;
        color: var(--color-secondary-exchange);
        line-height: 1;
      }
    }
    .casino-war__body-players {
      display: grid;
      grid-template-rows: repeat(3, 40px);
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 10px;
      grid-column-gap: 15px;
      .casino-war__body-players-item {
        width: 100%;
        display: flex;
        align-items: center;
        &-name {
          width: calc(100% - 80px);
          height: 36px;
          background-color: var(--background-secondary-player-exchange);
          border-radius: 5px 0 0 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          white-space: nowrap;
          //padding: 0 21px;
        }
      }
    }
    .casino-war__body-players-item-info-sell.lock-ui {
      position: relative;
      pointer-events: none;
      &:hover {
        cursor: unset;
      }
      &:before {
        content: '';
        position: absolute;
        background-image: url('../../../assets/images/exchangeGames/lock-white.svg');
        background-repeat: no-repeat;
        //background-position-x: 50%;
        //background-position-y: 45%;
        background-position: center;
        background-color: var(--background-closed-exchange);
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        z-index: 1;
      }
    }
    .casino-war__body-players-item-info-sell.lock-ui.isExposure::before {
      //background-position-x: 50%;
      //background-position-y: 15%;
      background-position: center;
    }
    .casino-war__body-players-item-info-sell.lock-ui.winner {
      .casino-war__body-players-item-info-sell-price {
        opacity: 0;
      }
      &:before {
        content: '';
        background: url(../../../assets/images/TrophyTeenPattiTest.svg);
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    }
    .casino-war__body-players-item-info-sell.lock-ui.winner.isExposure::before {
      //top: -6px;
    }
    //.BetPlacing-container {
    //  position: absolute;
    //  z-index: 9;
    //  top: -7px;
    //  right: 0;
    //  left: 0;
    //  bottom: 0;
    //  display: flex;
    //  justify-content: center;
    //  align-items: flex-start;
    //  overflow: visible;
    //}

    .casino-war-exposure-box-modern {
      font-size: 11px;
      font-weight: 600;
      font-family: 'Poppins';
      line-height: 1;
      position: relative;
    }
    .casino-war-exposure-modern {
      &--pos {
        //color: #40FF5E;
      color: var(--positive-secondary-exchange);
      }
      &--neg {
        color: var(--negative-secondary-exchange);
      }
    }
  }
}

@media (max-width: 374px) {
  .casino-war {
    .casino-war__body {
      .casino-war__body-players {
        grid-row-gap: 10px;
        .casino-war__body-players-item {
          &-info-sell {
            width: 70px;
          }
          &-name {
            width: calc(100% - 70px);
            padding-left: 15px;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .casino-war {
    padding: 33px 0;
    .casino-war__body {
      font-size: 15px;
      color: var(--color-exchange);
      .casino-war__body-cards {
        width: 100%;
        padding: 0 0 10px 0;
        display: flex;
        .casino-war__body-cards-item-empty {
          width: calc(100% - 597px);
        }
        .casino-war__body-cards-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .casino-war__body-cards-item {
          width: 87px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          &-card {
            width: 45px;
            height: 60px;
          }
        }
      }
      .casino-war__body-players-item-info-sell {
        width: 87px;
        &-price {
          font-weight: 600;
          color: var(--color-secondary-exchange);
          line-height: 1;
        }
      }
      .casino-war__body-players {
        display: grid;
        grid-template-rows: repeat(1, 48px);
        grid-template-columns: unset;
        grid-row-gap: unset;
        grid-column-gap: unset;
        .casino-war__body-players-item {
          width: 100%;
          display: flex;
          align-items: center;
          &-name {
            width: calc(100% - 597px);
            height: 40px;
            background-color: var(--background-secondary-player-exchange);
            border-radius: 5px 0 0 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            color: var(--color-exchange);
            padding: unset;
          }
        }
      }
      .casino-war__body-players-item-sells-wrapper {
        width: 100%;
        height: 49px;
        display: flex;
        justify-content: space-between;
      }
      .casino-war-exposure-box-modern {
        font-size: 11px;
        font-weight: 600;
        font-family: 'Poppins';
        line-height: 1;
        position: relative;
        transform: translateY(5px);
      }
      .casino-war__body-players-item-info-sell.lock-ui {
        position: relative;
        pointer-events: none;
        &:hover {
          cursor: unset;
        }
        &:before {
          content: '';
          position: absolute;
          background-image: url('../../../assets/images/exchangeGames/lock-white.svg');
          background-repeat: no-repeat;
          background-position-x: 50%;
          background-position-y: 45%;
          background-color: var(--background-closed-exchange);
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 5px;
          z-index: 1;
        }
      }
      .casino-war__body-players-item-info-sell.lock-ui.isExposure::before {
        background-position-x: 50%;
        background-position-y: 15%;
      }
      .casino-war__body-players-item-info-sell.lock-ui.winner {
        .casino-war__body-players-item-info-sell-price {
          opacity: 0;
        }
        &:before {
          content: '';
          background: url(../../../assets/images/TrophyTeenPattiTest.svg);
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 1;
        }
      }
      .casino-war__body-players-item-info-sell.lock-ui.winner.isExposure::before {
        top: -6px;
      }
      .casino-war-exposure-modern {
        &--pos {
          color: var(--positive-exchange);
        }
        &--neg {
          color: var(--negative-exchange);
        }
      }
    }
  }
  //.casino-war__body-players-item-info-sell.lock-ui:before {
  //    background-position-y: 25% !important;
  //}
  //.casino-war__body-players-item-info-sell.lock-ui.winner:before {
  //  background-position-y: 50% !important;
  //}
}

@media (min-width: 1024px) and  (max-width: 1350px) {
  .casino-war {
    .casino-war__body {
      .casino-war__body-cards {
        .casino-war__body-cards-item-empty {
          width: calc(100% - 525px);
        }
        .casino-war__body-cards-item {
          width: 75px;
        }
      }
      .casino-war__body-players-item-info-sell {
        width: 75px;
      }
      .casino-war__body-players {
        .casino-war__body-players-item {
          &-name {
            width: calc(100% - 525px);
          }
        }
      }
    }
  }
}
