.balanceInformation{
    margin: 0 10px;
    min-height: 27px;
    //transition: all .2s;
}
.balanceInformation.balanceInformationOpen {
    height: 103px;
}
.balanceInformationHeader{
    display: flex;
    position: relative;
    z-index: 3;
    //justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 27px;
    background-color: var(--cta-primary-darken);
    &:hover{
        background-color: var(--alt-secondary);
    }
}
.balanceInformation__title{
    align-items: center;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    margin: 0 auto;
}
.balanceInformation__downButton{
    position: relative;
    //left: 10%;
    width: 15px;
    height: 15px;
    background-color: white;
    //margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    svg {
        width: 8px;
        position: relative;
        //transition: all linear 0.1s;
    }
    &.downImg{
        //&::before{
        //    content:'\2304';
        //    position: relative;
        //    left: 3.5px;
        //    top: -9px;
        //    color: #000000;
        //}
    }
    &.upImg{
        svg {
            transform: rotate(180deg);
        }
        //&::before{
        //    content:'\2303';
        //    position: relative;
        //    left: 3.5px;
        //    color: #000000;
        //}
    }
}


.detailedBalance{
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #000000;
    padding: 10px;
    transition: .4s;

    &.hidden{
        position: relative;
        margin-top: -200px;
        background: #FFFFFF;
        height: 0;
        overflow: hidden;
    }
    &__title{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
        margin-left: 10px;
        margin-bottom: 3px;
        &-sub{
        font-weight: 500;

        &.detailedGreen{
            color: var(--positive);
            font-weight: 700;
            &::before{
              content: '+'
            }
        }
        &.detailedRed{
          color: var(--negative);
          font-weight: 700;
        }
        }
    }
    &__toggler{
        display: flex;
        color: black;
        
    }
}

.bonusInformation{
    margin: 12px 0 10px 5px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    img{
        cursor: pointer;
        position: relative;
        left: 5px;
        top: 2px;
    }
}

.newTogglerBonus {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  
    &__oneClick{
      font-size: 14px;
  
    }
    .switcher {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 15px;
      margin-left: 9px;
    }
  
    /* Hide default HTML checkbox */
    .switcher input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  
    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgb(255, 255, 255);
      border: 1px solid #969696;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  
    .slider:before {
      position: absolute;
      content: '';
      height: 13px;
      width: 13px;
      left: -1px;
      bottom: -1px;
      background-color: white;
      border: 1px solid #969696
      ;
      -webkit-transition: 0.4s;
      transition: 0.2s;
    }
  
    input:checked + .slider {
      background-color: inherit;
      &::after {
        position: relative;
        z-index: 0;
        float: left;
        top: 3px;
        left: 6px;
        content: 'ON';
        color: #257B24;
      }
    }
  
    input:focus + .slider {
      box-shadow: 0 0 1px #3c3f42;
    }
  
    input:checked + .slider:before {
      -webkit-transform: translateX(32px);
      -ms-transform: translateX(32px);
      transform: translateX(32px);
      background-color: #257B24;
      border: 1px solid #257B24;
      left: -12px;
    }
  
    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
      &::after {
        content: 'OFF';
        position: relative;
        top: 3px;
        right: 3px;
        z-index: 0;
        float: right;
        transition-delay: 0.1s;
        font-style: normal;
        font-weight: 400;
        font-size: 6.5px;
        line-height: 8px;
        text-align: center;
        color: #969696;
      }
    }
  
    .slider.round:before {
      border-radius: 50%;
    }
  }
