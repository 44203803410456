@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.seven-up-down {
  .seven-up-down-table {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    color: var(--white);
    position: relative;
    &__players-wrapper {
      display: grid;
      grid-template-rows: repeat(3, auto);
      grid-row-gap: 15px;
      padding: 33px 13px 40px 13px;
    }
    &__player {
      background: var(--background-player-exchange);
      height: 83px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      padding: 10px;
      cursor: pointer;

      &-cards {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly!important;

        .seven-up-down-table__player-price {
          display: flex;
          flex-direction: column;
          align-items: center;
          &-text {
            margin-bottom: 10px;
          }
        }
      }
      &-title {
        white-space: nowrap;
      }
      &-price {
        font-weight: 700;
        text-align: center;
        line-height: 1;
      }
      &-price-text {

      }
      .seven-up-down-table__player-images {
        img {
          height: 100%;
        }
      }
    }
    &__players-group-first {
      display: grid;
      grid-template-columns:  0.83fr 0.83fr 0.83fr 0.5fr 0.5fr;
      grid-column-gap: 10px;

      &.mob {
        grid-template-columns:  1fr 0.5fr 1fr;

        .seven-up-down-table__player-cards.sec {
          height: 116px;
          width: 76px;
          flex-direction: column;
        }
        .seven-up-down-table__player-cards.thr {
          flex-direction: row-reverse;
        }
      }

      .seven-up-down-table__player {
        &-price {
          padding-top: 11px;
        }
      }
    }

    &__players-group-second {
      display: grid;
      grid-template-columns: 0.55fr 0.55fr 0.7fr 0.7fr 0.7fr 0.7fr;
      grid-column-gap: 10px;

      &.mob {
        grid-template-columns: repeat(4, 1fr);

        .seven-up-down-table__player-images {
          img:last-child {
            height: 26px;
            margin-left: 0;
          }

          &.color {
            height: 18px;
            width: 16px;

            img {
              height: 16px;
              width: 16px;
              margin: 4px;
            }
          }
        }
      }

      // padding: 0 20px;
      .seven-up-down-table__player {
        height: 80px;
        justify-content: unset;
        padding: 15px 0;
        &-price {
          padding-top: 7px;
        }

        &-images {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 24px;
          }
          img:last-child {
            height: 26px;
            margin-left: 10px;
          }
        }
        .seven-up-down-exposure-box-modern {
          padding-top: 7px;
        }
      }
    }

    &__players-group-cards {
      display: grid;
      grid-template-rows: repeat(3, auto );
      grid-row-gap: 15px;
      padding: 0 20px;
      .seven-up-down-table__player {
        height: 116px;
        padding: 15px 0 10px 0;
        justify-content: space-between;
        &-images {
          height: 63px;
          img {
            width: 49px;
            height: 63px;
          }
          img:not(:last-child) {
            margin-right: 20px;
          }
        }
        &-price {
          display: flex;
          .seven-up-down-exposure-box-modern {
            padding-left: 48px;
            padding-top: 1px;
          }
        }
      }
    }
    .seven-up-down-table__player.lock-ui {
      position: relative;
      pointer-events: none;
      cursor: unset;
      &:hover {
        cursor: unset;
      }
      &:before {
        content: '';
        background-image: url('../../../assets/images/exchangeGames/lock-white.svg');
        background-repeat: no-repeat;
        background-position: center;
        //background-color: rgba(0, 0, 0, 0.6);
        background-color: var(--background-closed-exchange);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        z-index: 1;
      }
      &.winner:before {
        content: '';
        background: url(../../../assets/images/TrophyTeenPattiTest.svg);
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 1;
      }
    }
    .seven-up-down-table__players-group-first {
      .seven-up-down-table__player.lock-ui {
        &:before {
          background-position: 50% 50%;
        }
        &.winner:before {
          left: 35px;
          bottom: 0;
        }
      }
      .seven-up-down-table__player-colors.lock-ui {
        &:before {
          background-position: 50% 50%;
        }
        &.winner:before {
          left: 0;
          bottom: -20px;
        }
      }
      .seven-up-down-table__player-colors-text.lock-ui {
        &:before {
          background-position: 50% 50%;
        }
        &.winner:before {
          left: 0;
          bottom: -20px;
        }
      }
      .seven-up-down-table__player-cards.sec.lock-ui {
        &.winner:before {
          left: 0;
          bottom: -36px;
        }
      }
      .seven-up-down-table__player-cards.thr.lock-ui {
        &.winner:before {
          left: -30px;
          bottom: 0;
        }
      }

    }

    .seven-up-down-table__players-group-second {
      .seven-up-down-table__player-cards.lock-ui {
        &:before {
          background-position: 50% 50%;
        }
        &.winner:before {
          left: 30px;
          bottom: 0;
        }
      }
      .seven-up-down-table__player-colors-text.lock-ui {
        &:before {
          background-position: 50% 50%;
        }
        &.winner:before {
          left: 0;
          bottom: -20px;
        }
      }
    }

    &__players-group-cards {
      .seven-up-down-table__player.lock-ui.winner:before {
        bottom: -40px;
      }
    }
    .seven-up-down-table__player.winner {
      .seven-up-down-table__player-price {
        opacity: 0;
      }
    }
    //.BetPlacing-container {
    //  position: absolute;
    //  width: calc(100% - 20px);
    //  margin: 0 auto;
    //  z-index: 9;
    //  top: 30px;
    //  right: 0;
    //  left: 0;
    //  bottom: 0;
    //}
    .seven-up-down-exposure-box-modern {
      font-size: 11px;
      position: unset;
      font-weight: 600;
      font-family: 'Poppins';
    }
    .seven-up-down-exposure-modern {
      &--pos {
        color: var(--positive-exchange);
      }
      &--neg {
        color: var(--negative-exchange);
      }
    }
  }
}

@media (max-width: 1024px) {
  .seven-up-down {
    .seven-up-down-table__players-wrapper {
      padding: 33px 13px 0 13px;
    }
  }

  .seven-up-down.virtual {
    margin-top: -10px;
    padding-top: 10px;
    background: linear-gradient(-181.68deg, #0B3114 50.02%, #40783B 100%);
  }
  .seven-up-down.virtual.fullHeight {
    min-height: 100%;
    margin-top: unset;
  }
}
