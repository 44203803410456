.baccarat-last-result {
  padding: 30px 0;
  &__player {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 15px;
    color: var(--color-exchange);
    margin: 0 30px 37px 0;
    &-name {
      width: 50px;
    }
    &-cards {
      display: flex;
      height: 44px;
      margin-left: 17px;
      &-isWinner{
        display: flex;
        height: 44px;
        margin-left: 17px;
        position: relative;
        &::before{
          content: url('../../../../../assets/images/winnerTropy.svg');
          position: absolute;
          right: -65px;
          top: -5px
        }
      }
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0 0 5px grey;
      }

      &-card.rotated {
        transform: rotate(90deg) translateY(-5px);
      }
    }
    .baccarat-last-result__player-cards-card:not(:last-child){
      margin-right: 15px;
    }
  }
}

@media (min-width: 1024px) {
  .baccarat-last-result {
    padding: 20px 0 65px 0;
    display: flex;
    .baccarat-last-result__player-cards-isWinner:before {
      content: '';
      background-image: url('../../../../../assets/images/winnerTropy.svg');
      background-position: center;
      background-size: cover;
      position: absolute;
      right: 50%;
      top: 57px;
      z-index: 1;
      width: 20px;
      height: 20px;
      transform: translateX(49%);
  }
    &__player {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 12px;
      margin: 0 20px;
      &-name {
        width: unset;
        padding-bottom: 10px;
        padding-left: 20px;
      }
      &-cards,
      &-cards-isWinner {
        display: flex;
        height: 46px;
        margin-left: 17px;
        position: relative;
        &-card {
          height: 46px;
        }
        &-card.rotated {
          transform: rotate(90deg) translateY(-5px);
        }
        .baccarat-last-result__player-cards-card:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }
  .baccarat-last-result__player-first {
    .baccarat-last-result__player-cards,
    .baccarat-last-result__player-cards-isWinner {
      flex-direction: row-reverse;
      .baccarat-last-result__player-cards-card.rotated {
        transform: rotate(90deg) translateY(7px);
      }
      .baccarat-last-result__player-cards-card {
       margin: unset;
      }
    }
    .baccarat-last-result__player-cards-card:not(:last-child) {
      margin-left: 15px;
    }
  }
}
