@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.landing-footer-social-button {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin: 0 5px;
  //transition: all 0.4s;
  position: relative;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
}
.landing-footer-social-button span {
  font-size: 10px;
  font-weight: 700;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: var(--white);
  opacity: 0;
}
.landing-footer-social-button.instagram {
  background-image: url("../../assets/images/footer/social/instagramGray.svg");
}
.landing-footer-social-button.instagram:hover {
  background-image: url("../../assets/images/footer/social/instagramBlue.svg");
}
.landing-footer-social-button.instagram-col {
  background-image: url("../../assets/images/footer/social/insta-color.png");
}
.landing-footer-social-button.instagram:hover span, .landing-footer-social-button.instagram-col:hover span {
  opacity: 1;
}
.landing-footer-social-button.fb {
  background-image: url("../../assets/images/footer/social/fbGray.svg");
}
.landing-footer-social-button.fb:hover {
  background-image: url("../../assets/images/footer/social/fbBlue.svg");
}
.landing-footer-social-button.fb-col {
  background-image: url("../../assets/images/footer/social/fb-classic.png");
}
.landing-footer-social-button.fb:hover span, .landing-footer-social-button.fb-col:hover span {
  opacity: 1;
}
.landing-footer-social-button.telegram {
  background-image: url("../../assets/images/footer/social/telegramGray.svg");
}
.landing-footer-social-button.telegram:hover {
  background-image: url("../../assets/images/footer/social/telegramBlue.svg");
}
.landing-footer-social-button.telegram-col {
  background-image: url("../../assets/images/footer/social/telegram-color.png");
}
.landing-footer-social-button.telegram:hover span, .landing-footer-social-button.telegram-col:hover span {
  opacity: 1;
}
.landing-footer-social-button.whatsapp {
  background-image: url("../../assets/images/footer/social/whatsappGray.svg");
}
.landing-footer-social-button.android {
  background-image: url("../../assets/images/footer/downloadAndroid.svg");
}

.landing-footer-social-button.whatsapp:hover {
  background-image: url("../../assets/images/footer/social/whatsappBlue.svg");
}
.landing-footer-social-button.whatsapp-col {
  background-image: url("../../assets/images/footer/social/wats-green.png");
}
.landing-footer-social-button.whatsapp:hover span, .landing-footer-social-button.whatsapp-col:hover span {
  opacity: 1;
}
.landing-footer-social-button.mail {
  background-image: url("../../assets/images/footer/social/mailGray.svg");
}
.landing-footer-social-button.mail:hover {
  background-image: url("../../assets/images/footer/social/mailBlue.svg");
}
.landing-footer-social-button.mail-col {
  background-image: url("../../assets/images/footer/social/gmail.png");
}
.landing-footer-social-button.mail:hover span, .landing-footer-social-button.mail-col:hover span {
  opacity: 1;
}

.landing-footer-mobile {
  width: 100%;
  background: var(--black);
}
.landing-footer-mobile__companies {
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-footer-desktop__number{
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.android-button {
  height: 50px;
  width: 135px;
  background: url("../../assets/images/footer/downloadAndroid.svg");
  background-repeat: no-repeat;
}


.android-button-2 {
  height: 50px;
  width: 135px;
  background: url("../../assets/images/footer/downloadScore.svg");
  background-repeat: no-repeat;
}

.landing-footer-mobile__companies-img {
  height: 142px;
  width: 27.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}
.landing-footer-mobile__companies-menu {
  display: flex;
  color: var(--white);
  width: 80%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .menu-row {
    min-width: 150px;
    &:last-child {
      @media (max-width: 700px) {
        padding-right: 40px;
      }
    }
    .with-rows {
      display: flex;
      .menu-row-item {
        margin-right: 50px;
      }
    }
    &-heading {
      text-transform: uppercase;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 19px;
      margin-top: 20px;
      margin-bottom: 15px;
    }
    &-item {
      width: 100%;
      height: 19px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      margin: 7px 0;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.landing-footer-mobile__companies-info {
  color: var(--white);
  width: 72.5%;
  // padding-right: 44px;
  text-align: left;
  font-weight: 400;
  font-size: 13px;
  font-family: 'Helvetica Neue';
  line-height: 19px;
  margin: 30px auto;
}
.landing-footer-mobile__companies-logos-rows-wrapper {
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: 11px;
  padding: 16px 44px 0 73px;
  // border-bottom: 1px solid #282828;
}
.landing-footer-mobile__companies-logos-row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 11px;
}
.landing-footer-mobile__companies-logos-row.last {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 50%;
  margin: 0 auto;
  padding-bottom: 21px;
  /*border-bottom: 1px solid #282828;*/
}
.landing-footer-mobile__companies-logos-row img {
  align-self: center;
  justify-self: center;
}

.landing-footer-mobile__social-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 135px;
  padding: 24px 0;
  font-size: 13px;
  font-weight: 400;
  font-family: Roboto;
}
.landing-footer-mobile-social-button {
  display: inline-block;
  height: 25px;
  width: 26px;
  margin: 0 10px;
  transition: all 0.4s;
  position: relative;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center;
}
.landing-footer-mobile__social-faq {
  cursor: pointer;
  color: var(--white);
  padding: 30px 0 11px 0;
}
.landing-footer-mobile__social-copyright {
  color: #7C7C7C;
}

@media (max-width: 850px){
  .landing-footer-mobile__companies {
    padding-top: 15px;
  }
  .landing-footer-mobile__companies-logos-rows-wrapper {
  }
  .landing-footer-mobile__companies-logos-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 11px;
    grid-row-gap: 23px;
  }
  .landing-footer-mobile__companies-logos-row.last {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
  }
}

@media (max-width: 764px){
  .landing-footer-mobile {
    display: flex;
    flex-direction: column;
  }
  .landing-footer-mobile__companies-wrapper {
    order: 2;
  }
  .landing-footer-mobile__social-wrapper {
    order: 1;
    height: 70px;
  }
  .landing-footer-mobile__social-copyright {
    display: none;
  }
  .landing-footer-mobile__social-faq {
    order: 1;
    padding: 67px 0 32px 0;
  }
  .landing-footer-social {
    order: 2;
    display: block;
  }
  .landing-footer-social-button {
    display: inline-block;
    height: 25px;
    width: 25px;
    margin: 0 15px;
    transition: all 0.4s;
    position: relative;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center;
  }
  .landing-footer-social-button span {
    top: 35px;
    font-size: 8px;
  }
  .landing-footer-mobile__companies {
    flex-direction: column;
  }
  .landing-footer-mobile__companies-img {
    width: 275px;
    height: 100px;
  }
  .landing-footer-mobile__companies-logos-row img {
    align-self: center;
    justify-self: start;
  }
  .landing-footer-mobile__companies-info {
    padding: 17px 0 45px 0;
    width: 90%;
  }
  .landing-footer-mobile__companies-logos-rows-wrapper {
    grid-row-gap: 27px;
    padding: 0 2% 50px 10%;
  }
  .landing-footer-mobile__companies-logos-row {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 27px;
    grid-column-gap: unset;
  }
  .landing-footer-mobile__companies-logos-row.last {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 27px;
    grid-column-gap: unset;
  }
}

@media (min-width: 1024px) {
  .landing-footer-desktop {
    width: 100%;
    background: var(--black);
    a {
      text-decoration: none;
      color: inherit;
    }
    p {
      margin: 0;
      padding: 0;
    }
    .landing-footer-mobile__companies-logos-rows-wrapper {
      max-width: 1100px;
      margin: 40px auto;
      padding: 0;
    }
    .landing-footer-desktop__section {
      width: 100%;
      padding-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .landing-footer-desktop__section.tutorials {
      flex-direction: column;
      .tutorials{
        display: flex;
        justify-content: center;
        padding: 20px 0;
        .tutorial-btn{
          background-color: var(--cta-primary);
          height: 30px;
          width: 140px;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: var(--white);
          text-align: center;
          margin: 0 7.5px;
        }
        .tutorial-btn:hover {
          border-right: 2px solid rgba(0, 0, 0, 0.25);
          border-bottom: 2px solid rgba(0, 0, 0, 0.25);
        }
      }
      .landing-footer-desktop__number {
        font-weight: 700;
        font-size: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--white);
        cursor: pointer;
        &-img {
          width: 40px;
          height: 40px;
          margin-right: 7px;
          position: relative;
          &:before {
            content: "";
            width: 40px;
            height: 40px;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url("../../assets/images/footer/social/wats-green.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
    .landing-footer-desktop__section.companies {
      @media (min-width: 1250px) {
        padding: 0 100px 40px 100px;
        max-width: 1300px;
      }
      justify-content: space-between;
      align-items: flex-start;
      width: 80%;
      margin: 0 auto;
      .landing-footer-desktop__companies-img {
        width: 260px;
        height: 78px;
        // background-color: #FFFFFF;
        border-radius: 4px;
        margin-top: 25px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .landing-footer-desktop__companies-info {
      width: calc(100% - 310px);
      font-family: 'Helvetica Neue';
      font-weight: 400;
      font-size: 13px;
      color: var(--white);
      margin: 0 auto;
      max-width: 1300px;
    }
    .landing-footer-desktop__section.social {
      .landing-footer-desktop__social-button {
        display: inline-block;
        width: 30px;
        height: 30px;
        position: relative;
        margin: 0 7.5px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        span {
          font-size: 10px;
          font-weight: 700;
          position: absolute;
          top: 35px;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
          color: var(--white);
          opacity: 0;
        }
      }
      .landing-footer-desktop__social-button.instagram {
        background-image: url("../../assets/images/footer/social/insta-color.png");
        &:hover span {
          opacity: 1;
        }
      }
      .landing-footer-desktop__social-button.telegram {
        background-image: url("../../assets/images/footer/social/telegram-color.png");
        &:hover span {
          opacity: 1;
        }
      }
    }
  }
}
