
.race-page__content-upcoming-races-table-row {
  .race-page__content-upcoming-races-table-row-header {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    padding: 8px 20px;
    margin-bottom: 2px;
    cursor: pointer;
    display: flex;
    position: relative;
  }
  .flag {
    margin-right: 15px;
    position: relative;
    top: -3px;
  }
  .race-page__content-upcoming-races-table-row-header-text {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    span:first-child {
      font-weight: 700;
      color: #005ba2;
    }
    span:last-child {
      font-weight: 400;
      color: #0f2327;
    }
  }
  .race-page__content-upcoming-races-table-row-header-arrowContainer {
    cursor: pointer;
    position: relative;
    margin-left: auto;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    //height: 40px;
  }
  .race-page__content-upcoming-races-table-row-header-arrow {
    position: relative;
    width: 10px;
    height: 10px;
    border: 1px solid #000;
    border-left: none;
    border-top: none;
    transform: rotate(45deg);
    transition: transform .2s ease-out;
  }
  .race-page__content-upcoming-races-table-row-header-arrow.active {
    transform: rotate(-135deg);
  }
  .race-page__content-all-suspend-cover {
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    //width: 136px;
    color: rgba(15,35,39, 0.5);
    background: rgb(221, 221, 221);
    font-size: 12px;
  }
  .race-page__content-upcoming-races-table-row-inner {
    .race-page__content-all-suspend-cover {
      width: 136px;
    }
  }
  .race-page__content-upcoming-races-table-row-items-wrapper.all-suspended {
    position: relative;
  }
  .race-page__content-upcoming-races-table-row-inner {
    max-height: 0;
    opacity: 0;
    background-color: #f3f4f5;
    padding: 1px;
    overflow: hidden;
    -webkit-transition: max-height 0.4s, opacity 300ms ease-in-out;
    -moz-transition: max-height 0.4s, opacity 300ms ease-in-out;
    -o-transition: max-height 0.4s, opacity 300ms ease-in-out;
    transition: max-height 0.4s, opacity 300ms ease-in-out;
  }
  .race-page__content-upcoming-races-table-row-inner.open {
    max-height: 208px;
    opacity: 1;
    -webkit-transition: max-height 0.4s, opacity 300ms ease-in-out;
    -moz-transition: max-height 0.4s, opacity 300ms ease-in-out;
    -o-transition: max-height 0.4s, opacity 300ms ease-in-out;
    transition: max-height 0.4s, opacity 300ms ease-in-out;
  }
}


