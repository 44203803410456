.pop-upLastResult-content__results {
  &-name{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: var(--color-exchange);
  }
  &-cards{
    display: flex;
    width: 80px;
    height: 44px;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
    &-isWinner-poker{
      display: flex;
      width: 80px;
      height: 44px;
      justify-content: space-between;
      //margin-top: 10px;
      //margin-bottom: 20px;
      position: relative;
      &::before{
        content: url('../../../../../assets/images/winnerTropy.svg');
        position: absolute;
        left: auto;
        right: -60px;
        top: -3px
      }
    }
    &-card{
      display: block;
      width: 32px;
      height: 44px;
      box-shadow: 0px 0px 5px grey;
    }
  }
  .pop-upLastResult-content__poker20-board-cards {
    width: 225px;
  }
  .pop-upLastResult-content__results-poker20 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
  }
  .pop-upLastResult-content__results-poker20-item {
    position: relative;
    margin-bottom: 35px;
    .pop-upLastResult-content__results-name {
      position: absolute;
      top: 50%;
      left: -15px;
      transform: translate(-100%, -50%);
    }
    .pop-upLastResult-content__results-cards {
      margin: 0;
    }
  }
  //.pop-upLastResult-content__results-poker20-item.poker20-item-board {
  //  right: -15px;
  //}
}

.pokerResult{
  width: 100%;
  height: 31px;
  background: var(--background-player-exchange);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-secondary-exchange);
  //margin: 10px 10px 20px 10px;
  margin-bottom: 30px;
  &-content{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
  }
}

@media (max-width: 375px) {
  div.pop-upLastResult-content__results {
    .pop-upLastResult-content__results-poker20-item {
      margin-bottom: 25px;
      .pop-upLastResult-content__results-cards {
        width: unset;
        justify-content: center;
        &-isWinner{
          width: 70px;
          height: 44px;
        }
      }
      .pop-upLastResult-content__results-cards-card {
        width: 30px;
        height: 40px;
      }
      .pop-upLastResult-content__results-cards-card:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .pokerResult{
    margin: 0px 10px 10px 10px;
  }
}

@media (min-width: 1024px) {
  .pokerResult {
    //display: none;
  }
  .pop-upLastResult-content__results-poker20 {
    flex-direction: row !important;
    margin-top: 15px;
    .pop-upLastResult-content__results-poker20-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 41px;
      margin-bottom: 55px;
      &.poker20-item-board {
        margin-right: unset;
        margin-left: 20px;
      }
      .pop-upLastResult-content__results-name {
        position: unset;
        top: unset;
        left: unset;
        transform: unset;
        margin-bottom: 10px;
        font-size: 12px;
      }
      .pop-upLastResult-content__results-cards-isWinner-poker,
      .pop-upLastResult-content__results-cards {
        display: flex;
        width: unset;
        height: 46px;
        justify-content: center;
        .pop-upLastResult-content__results-cards-card:not(:last-child) {
          margin-right: 5px;
        }
        .pop-upLastResult-content__results-cards-card {
          width: 32px;
          height: 46px;
        }
      }
     .pop-upLastResult-content__results-cards-isWinner-poker:before {
       left: auto;
       right: 50%;
       top: 45px;
       transform: translateX(50%) scale(0.5);
      }
    }
  }

}
