


/* css variables*/
$white-color: #ffffff;
$close-button-background: #DADADA;
$dark-text-color: #102327;
$grey-text-color: #AFAFAF;
$tabs-border-color: #EFEFEF;
$input-border-color: #E2E2E2;
$input-background-color: #F9F9F9;
$submit-color: #6BA47D;
$warning-color: #E90038;
$hover-color: #9BB349;
/* end css variables*/

/* Main TransactionPopup styles  */
.transactionPopupFormContainer {
    width: 100%;
    max-width: 830px;
    margin: 10vh auto 0 auto;
    padding: 0 15px;
}
.transactionPopupFormBox {
    border-radius: 20px;
    padding: 30px;
    background: $white-color;
    position: relative;
    & &__close-button {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 38px;
        right: 30px;
        transition: 0.4s;
        cursor: pointer;
    }
    & &__close-icon:hover {
        fill: $hover-color;
    }
    //& &__close-button:hover svg path {
    //    fill: $hover-color;
    //}
    & &__title {
        font-weight: 700;
        font-size: 20px;
        margin: 0;
    }
    & &__subtitle {
        color: $grey-text-color;
        font-size: 14px;
        margin: 5px 0 15px 0;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    p {
        margin: 0;
    }
    & &__form-tabs-wrapper {
        display: flex;
        font-size: 16px;
        font-weight: 700;
        color: $grey-text-color;
        border-bottom: 1px solid $tabs-border-color;
        margin-bottom: 20px;
    }
    & &__form-tabs-item {
        padding: 10px 15px;
    }
    & &__form-tabs-item:first-child {
        padding-left: 0;
    }
    & &__form-tabs-item.active {
        color: $dark-text-color;
    }
    & &__form-tabs-item.active span {
        position: relative;
    }
    & &__form-tabs-item.active span:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        height: 3px;
        background: $submit-color;
    }
    & &__form-input-wrapper {
        position: relative;
        margin-bottom: 30px;
    }
    & &__form-input-label {
        display: flex;
        flex-direction: column;
    }
    & &__form-input-label-text {
        margin: 0 0 5px 0;
        font-weight: 700;
        font-size: 14px;
    }
    & &__form-input {
        width: 100%;
        max-width: 380px;
        background: $input-background-color;
        border: 1px solid $input-border-color;
        box-sizing: border-box;
        border-radius: 5px;
        height: 55px;
        font-size: 14px;
        font-weight: 700;
        padding: 15px;
        outline: none;
    }
    //& &__form-input.active {
    //    border-color: $warning-color;
    //}
    & &__form-input::-webkit-input-placeholder {
        font-weight: 400;
        letter-spacing: 0.02em;
        font-size: 14px;
        color: $grey-text-color;
        text-transform: capitalize;
    }
    & &__form-input::-moz-placeholder {
        letter-spacing: 0.02em;
        font-weight: 400;
        font-size: 14px;
        color: $grey-text-color;
        text-transform: capitalize;
        opacity: 1;
    }
    & &__form-input-warning {
        font-size: 14px;
        color: $warning-color;
        position: absolute;
        left: 0;
        bottom: -16px;
    }
    & &__form-bottom-desbscription {
        font-size: 14px;
        margin-bottom: 20px;
    }
    & &__form-bottom-desbscription p:last-child {
        color: $submit-color;
        margin-top: 2px;
    }
    & &__form-logos-wrapper {
        margin-bottom: 50px;
    }
    & &__form-logos-list {
        display: flex;
        align-items: center;
    }
    & &__form-logos-item:not(:last-child) {
        padding-right: 25px;
    }
    & &__form-button {
        //font-family: "Roboto";
        border-radius: 5px;
        width: 110px;
        height: 35px;
        color: $white-color;
        font-size: 14px;
        text-transform: capitalize;
        transition: 0.4s;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    & &__form-button.form-submit-button {
        background: $submit-color;
        margin-right: 10px;
    }
    & &__form-button.form-cancel-button {
        background: $warning-color;
    }
    & &__form-button:hover {
        background: $hover-color;
    }
}

/* Responsiveness Responsiveness Responsiveness*/
@media(max-width: 480px) {
    .transactionPopupFormContainer {
        padding: 0 10px;
    }
    .transactionPopupFormBox {
        text-align: center;
        padding: 55px 15px;
        & &__close-button {
            top: 10px;
            right: 10px;
        }
        & &__title {
            font-size: 18px;
        }
        & &__form-tabs-item {
            width: 50%;
        }
        & &__form-tabs-item:first-child {
            padding-left: 15px;
        }
        & &__form-tabs-item.active {
            position: relative;
        }
        & &__form-tabs-item.active:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 3px;
            background: $submit-color;
        }
        & &__form-tabs-item.active span:after {
            display: none;
        }
        & &__form-input-label-text {
            text-align: left;
        }
        & &__form-input {
            height: 45px;
        }
        & &__form-logos-list {
            justify-content: center;
        }
        & &__form-buttons-wrapper {
            display: flex;
        }
        & &__form-button {
            width: 50%;
            height: 45px;
            font-size: 16px;
        }
    }
}
/* End TransactionPopup styles */

/* Transaction Result Styles*/
.transactionPopupResult {
    .transactionPopupFormBox {
        padding: 80px 30px;
        &__subtitle {
            width: 100%;
            max-width: 335px;
            margin: 20px 0;
        }
        //&__subtitle.active {
        //    color: $warning-color;
        //}
        & .form-back-button {
            color: $dark-text-color;
            background: $close-button-background;
        }
        & .form-back-button:hover{
            color: $white-color;
            background: $hover-color;
        }
        &__transaction-result-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 335px;
            margin-bottom: 85px;
        }
        & .result-descr {
            font-weight: 400;
            font-size: 16px;
            padding-right: 20px;
        }
        & .result-sum {
            font-weight: 700;
            font-size: 14px;
            padding-left: 20px;
        }
        //& .result-sum.active {
        //    color: $warning-color;
        //}
        &__transaction-result-line {
            height: 1px;
            width: 100%;
            background: $tabs-border-color;
        }
        &__result-icon-wrapper {
            width: 46px;
            height: 46px;
            margin-bottom: 20px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        /* Result box and icon change / use class "transaction-declined" in html on state change */
        &__result-icon-wrapper.transaction-succesful {
            background: #D2F0DC;
        }
        &__result-icon-wrapper.transaction-succesful .declined-icon {
            display: none;
        }
        &__result-icon-wrapper.transaction-declined {
            background: #FBD4D5;
        }
        &__result-icon-wrapper.transaction-declined .success-icon {
            display: none;
        }
        /* End Result box and icon change */
    }
}
/* Responsiveness Responsiveness Responsiveness*/
@media(max-width: 480px) {
    .transactionPopupResult {
        .transactionPopupFormBox {
            padding: 95px 15px;
            &__subtitle,
            &__transaction-result-wrapper {
                max-width: 100%;
            }
            &__result-icon-wrapper {
                width: 50px;
                height: 50px;
                margin: 0 auto 20px auto;
            }
            &__subtitle {
                font-size: 16px;
            }
        }
    }
}
/* End Transaction Result Styles*/
