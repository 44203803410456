.RaceInplayItem {
  width: 100%;
  border-color: transparent;
  border-bottom: 1px solid var(--border);

  &.inactive {
    color: #878484;
    --ion-item-color: #878484;
  }

  .inplay-item__status {
    width: 136px;
  }

  &.active {
    color: var(--text-color);
    --ion-item-color: var(--text-color);
  }

  .odd-button {
    width: 66px;
    height: 100%;

    &.active {
      .odd-button__inner {
        position: absolute;
        border-bottom: 1px solid var(--white);
        width: 100%;
        z-index: 3;
        height: calc(100% + 1px);
      }
    }
  }

  // .BetPlacing {
  //   margin-top: -3px;
  // }

  div+div {
    border-color: transparent;
  }

  .inplay-item__player span {
    color: inherit;
    font-size: 14px;
    line-height: 17px;
  }

  .inplay-item__score {
    color: inherit;
  }

  .inplay-item__player {
    font-weight: 400 !important;
  }

  .odd-button__volume {
    font-size: 9px;
  }

  .inplay-item__players {
    color: inherit;
    justify-content: center;
    font-size: 14px;
    line-height: 17px;
    padding-left: 0;

    span {
      color: inherit;
      padding-left: 0;

      &.jockey {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  .inplay-item__back {
    flex: 0 1 136px;
    width: 136px;
    margin: 0 0 0 auto;

    &-inner {
      &.odds {
        margin-left: auto;
      }

      width: unset;

      &__status {
        font-size: 14px;
        line-height: 17px;
        color: inherit;
      }
    }
  }

  .score-content.racing {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: inherit;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  &:last-child {
    border-bottom: none;
  }
}

@media (max-width: 1024px)  {
  .RaceInplayItem {
    background: #fff;
    padding: 5px;
  }
}
