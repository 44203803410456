.headerDesktop{
  width: 100%;
  height: 120px;
  background: var(--primary);

  .not-auth {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 4px;
      width: 12px;
      height: 12px;
    }
    a {
      color: unset;
      text-decoration: unset;
    }
  }
  .login-icon-desktop,
  .signUp-icon-desktop {
    position: relative;
    display: flex;
    margin-left: 10px;
    img {
      opacity: 0;
    }
  }
  //.login-icon-desktop:after {
  //    content: "";
  //    width: 12px;
  //    height: 12px;
  //    position: absolute;
  //    //background-color: var(--header-icon-color-desctop);
  //    mask-image: var(--log-in-svg-icon);
  //    mask-size: cover;
  //    mask-position: center;
  //    mask-repeat: no-repeat;
  //    top: 0;
  //    left: 0;
  //}
  //.signUp-icon-desktop:after {
  //  content: "";
  //  width: 12px;
  //  height: 12px;
  //  position: absolute;
  //  background-color: var(--header-icon-color-desctop);
  //  mask-image: var(--sign-up-svg-icon);
  //  mask-size: cover;
  //  mask-position: center;
  //  mask-repeat: no-repeat;
  //  top: 0;
  //  left: 0;
  //}
  &-content{
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;
    &__search{
      margin-top: -35px;
    }
    &__date{
      padding-top: 5px;
      position: relative;
      .date-small{
        color: #fff;
        font-family: 'Lato';
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        display: inline-block;
        margin-right: 3px;
        &__yellow{
          color: #FCEDCA;
          font-size: 12px;
          cursor: pointer;
          &-symbol{
            display: inline-block;
            margin: 0 5px;
          }
          &-symbolright{
            display: inline-block;
            margin-left: 10px;
          }
          &-arrow{
            &::after{
              content: '\2304';
              position: absolute;
              top: 7px;
              right: 5px;
            }
          }
          &:hover{
            color:#fff;
          }
        }
      }
      .date-big{
        color: #fff;
        font-family: 'Lato';
        font-weight: 700;
        font-size: 15px;
        line-height: 14px;
      }
    }
    &__loged{
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #FFFFFF;
    }
    &__loged{
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      color: #FFFFFF;
      .headerDesktop-content__loged-loginName {
        font-size: 12px;
      }
      .headerDesktop-content__lastloged {
        font-size: 11px;
      }
    }
    &__button{
      height: 28px;
      width: 80px;
      background: var(--cta-primary-darken);
      //box-shadow: inset -2px -2px 0px #54785B;
      //border-right: 1px solid var(--cta-primary-darken);
      //border-bottom: 1px solid  var(--cta-primary-darken);
      border-right: 2px solid rgba(0, 0, 0, 0.25);
      border-bottom: 2px solid rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      color: #fff;
      text-align: center;
      font-family: 'Lato';
      font-weight: 700;
      font-size: 12px;
      line-height: 26px;
      margin-top: 3px;
      cursor: pointer;
      &:hover {
        background-color: var(--alt-secondary);
        box-shadow: 1.5px 1.5px 0px 0px var(--cta-primary-darken);
      }
      &:active{
        background-color: var(--primary);
        color: var(--bet-form-stake-buttons-color);
        border-top: 1px solid var(--cta-primary-darken);
        border-left: 1px solid var(--cta-primary-darken);
        box-shadow: none;
        border-bottom: none;
        border-right: none;
      }
    }
    &__account{
      display: flex;
      margin-top: 6px;
    }
  }
  .native-input.sc-ion-input-md{
    padding-left: 30px;
  }
}

.ion-toolbar__wrapper-desktop{
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  background: #142326;
  border: 3px solid #142326;
  margin-bottom: -25px;
  .notification__desktop{
    background: #142326;
    padding: 7px 0;
    font-size: 14px;
  }
}

.notification__container__message-desktop{
  color: #fff;
  font-size: 12px;
  margin-bottom: 7px;
}
.notification__container__marquee-desktop{
  position: relative;
  width: auto;
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
  padding-left: 1440px;
  animation: marquee 60.012s infinite linear;
  will-change: transform;
}

// .searchInput{
//   height: 30px;
//   width: 150px;
//   padding: 0 0 0 30px;
//   border-radius: 3px;
//   background: #fff;
//   border: solid 1px #f2f2f2;
//   position: relative;
//   outline: none;
//   &__img{
//     position: relative;
//     top: 9px;
//     float: right;
//     right: 140px;
//     font-family: 'Lato';
//     font-style: normal;
//     font-weight: 700;
//     font-size: 14px;
//     line-height: 21px;
//     color: #40424f;
//   }
//   &:focus{
//     // outline: none;
//     border: 0px;
//   }
// }

// .serach-events__text{
//     color: #827f7f;
//     position: relative;
//     top: 22px;
//     left: 28px;
//     // z-index: 3;
//     transition: linear 0.3s;
//     font-size: 12px;
// }

// .serach-events__text-active{
//   color: #fff;
//   position: relative;
//   top: -5px;
//   left: 28px;
//   z-index: 3;
//   transition: linear 0.3s;
//   font-size: 12px;
// }

.form-row {
	position: relative;
  margin-top: 17px;
}

.form-label {
	position: absolute;
  font-size: 10px;
  top: 9px;
  left: 30px;
  color: #999;
  cursor: text;
  transition: all .15s ease-in-out 0s;
}

.form-textbox,
.form-submit {
	width: 100%;
  max-width: 215px;
  padding: 5px 5px 5px 30px;
  outline: none;
  border: 0px;
}

.form-textbox:focus ~ .form-label,
.form-textbox:valid ~ .form-label {
	top: -1.5em;
	left: 0;
	font-size: .8em;
	color: inherit;
	cursor: pointer;
  color: #fff;
  border: 0px;
}

.search-event__img{
  position: relative;
  z-index: 3;
  top: 43px;
  left: 9px;
}



.headerDesktop-content__logo-img{
  background: var(--logo-bg-img) no-repeat;
  background-size: contain;
  width: 144px;
  height: 27px;
}

// .ion-toolbar__search-desk {
//   display: flex;
//   align-items: center;
//   flex: 1 1;
// }
.text{
    color: var(--header-icon-color-desctop);
    text-align: center;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    margin-left: 3px;
    position: relative;
    cursor: pointer;
    &-img{
      margin-right: 3px;
      margin-top: 2px;
    }
    &:hover{
      color: var(--header-icon-color-desctop-hover);
      text-decoration: underline;
        path {
          fill: var(--header-icon-color-desctop-hover);
        }
    }
}

.headerDeskNav{
  background: #fff;
  margin-top: -14px;
  height: 29px;
  &-content{
    &__list{
      display: flex;
      justify-content: space-around;
      padding-top: 7px;
      margin: 0 auto;
      max-width: 100%;
      .list-item{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 14px;
        color: #440a57;
        text-shadow: 0 1px 0 #f6e4fc;
        text-transform: uppercase;
        position: relative;
        cursor: pointer;
        &::before{
          content: url('../../assets/images/ellipseHeader.svg');
          position: absolute;
          left: -17px;
          top: -2px
        }
        &:first-child::before{
          content: '';
        }
        &:first-child{
          color: #C95C54;
        }
        &:nth-child(8){
          color: #C95C54;
        }
        &:nth-child(9){
          color: #C95C54;
        }
      }
    }
  }
}
.activated{
  // background: #000;
}
.menuAccount {
  position: absolute;
  top: 15px;
  right: -35px;
  z-index: 2;
  width: 130px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menuAccount.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.poligon{
  margin-bottom: -6px;
}

.menuList {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #ffffff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
    li {
    padding: 10px;
    border-bottom: 1px solid #dddddd;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #102327;
    display: block;
    text-align: left;
    &:first-child{
      border-radius: 8px 8px 0 0
    }
    &:last-child{
      border-radius: 0 0 8px 8px
    }
    &:hover{
      background: #F4F8F8;
      background-size: contain;
    }
  }
}


.timeutc {
  position: absolute;
  top: 20px;
  right: 0px;
  z-index: 2;
  width: 60px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.timeutc.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.poligon{
  //margin-bottom: -4px;
  padding: 0 0 0 25px;
}

.timeList {
  position: relative;
  right: 82px;
  top: -10px;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  height: 100%;
  width: 230px;
  cursor: pointer;
    &-text {
    font-family: 'Lato';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000;
    display: block;
    text-align: left;
    cursor: pointer;
    &:last-child{
      border-bottom: 0px;
    }
    &:hover{
      background: #e1e4e4;
      background-size: contain;
    }
  }
}


@media screen and (min-width: 1366px) {
  .headerDesktop{
    &-content{
      width: 100%;
      max-width: 1270px;
      margin: 0 auto;
      &__deposit{
        margin-left: 5px;
        width: 90px;
      }
      &__account{
        margin-right: -12px;
      }
    }
  }
  .searchInput{
    width: 250px;
    height: 32px;
    // margin-top: -5px;
    &__img{
      position: relative;
      z-index: 3;
      top: 6px;
      float: left;
      left: 25px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #40424f;
    }
  }
  .text{
    margin-right: 20px;
  }
  .headerDeskNav{
    &-content{
      &__list{
        max-width: 1130px;
        .list-item{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          color: #3E0F54;
          text-transform: uppercase;
        }
      }
    }
  }
}

.searchList{
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  margin-top: 0px;
  background: #FFFFFF;
  border: 1px solid #D5D6D8;
  box-shadow: 0px 4px 30px rgba(7, 26, 82, 0.14);
  border-radius: 5px;
}































// .ion-toolbar {
//   --min-height: unset;
//   // --border-width: 0px !important;

//   --padding-top: 0px !important;
//   --padding-bottom: 0px !important;
//   --padding-start: 0px !important;
//   --padding-end: 0px !important;
// }

// .ion-toolbar__wrapper {
//   display: flex !important;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 15px;
//   background: var(--header-background);

//   &.main {
//     height: 61px;
//   }

//   &.one-click {
//     background: var(--negative);
//     color: var(--white);
//     justify-content: flex-start;
//     padding: 10px 15px;
//     position: relative;

//     .toolbar-toggle {
//       --handle-box-shadow: inset 0px 0px 0px 2px var(--white);
//       border: 0;
//       // --handle-box-shadow: inset 0px 0px 0px 2px #fff;
//       // position: absolute;
//       // left: 15px;
//       // --handle-width: 19px;

//     }
//   }

//   .one-click-text {
//     font-size: 10px;
//     margin: 0 auto;
//     color: var(--white);
//     border-bottom: 1px solid var(--white);
//   }

//   &.toolbar__tabs {
//     flex-flow: row nowrap;
//     position: relative;
//     padding: 0;
//   }

//   .toolbar__tabs {
//     &__bar {
//       position: relative;
//       display: flex;
//       flex-flow: row nowrap;
//       justify-content: stretch;
//       width: 100%;
//       background: transparent;

//       &__tab {
//         background: transparent;
//         position: relative;
//         flex-grow: 1;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         padding: 15px 15px 13px;
//         cursor: pointer;
//         font-family: 'Lato', sans-serif;
//         font-style: normal;
//         font-weight: 700;
//         font-size: 10px;
//         text-transform: uppercase;
//         color: var(--event-tab-color);
//         // border-bottom: var(--event-tab-border-bottom);
//         border-bottom: 3px solid var(--header-background);

//         &.active {
//           border-bottom-color: var(--event-tab-active-border-bottom-color);
//           color: var(--event-tab-active-color);
//         }
//       }
//     }
//   }

// }

// @keyframes marquee {
//   0% {
//     transform: translate(0, 0);
//   }

//   100% {
//     transform: translate(-100%, 0)
//   }
// }

// .notification {
//   padding: 10px 0;
//   font-size: 14px;
//   color: var(--header-secondary-color);
//   background: var(--event-info-background-color);

//   &__container {
//     flex: 1 1 100%;
//     overflow: hidden;
//     display: flex;
//     flex-wrap: nowrap;
//     position: relative;

//     &__marquee {
//       position: relative;
//       width: auto;
//       display: flex;
//       white-space: nowrap;
//       flex-wrap: nowrap;
//       padding-left: 600px;
//       animation: marquee 17.012s infinite linear;
//       will-change: transform
//     }

//     &__message {
//       padding-left: 15px;
//       padding-right: 15px;
//       position: relative;
//       display: flex;
//       flex-wrap: nowrap;

//       &:not(:last-of-type) {
//         border-right: 1px solid var(--header-secondary-color);
//       }
//     }
//   }

//   &__close {
//     flex: 1 1;
//     margin-left: auto;
//     padding: 0 16px;
//     background: var(--event-info-background-color);
//     cursor: pointer;
//   }
// }

// .menu-icon {
//   width: 19px;
//   height: 12.83px;
// }

// .toolbar-logo {
//   display: inline-block;
//   background: left center no-repeat var(--logo-bg-img);
//   width: var(--logo-width);
//   height: var(--logo-height);
//   vertical-align: middle;
//   background-size: contain;
//   margin-left: 20px;
//   // width: 120px;
//   // height: 37px;
//   cursor: pointer;
// }

// .toolbar-altertitle {
//   color: var(--white);
//   font-size: 18px;
//   font-weight: 700;
//   margin-left: 15px;
// }

// .user-profile-btn {
//   margin: 0 !important;
//   --border-radius: 2px;
//   height: 40px;
//   width: 69px;
//   --background: var(--header-right-button-menu-bg-color);
//   --color: var(--header-color);
// }

// .user-profile-btn__wrapper {
//   display: flex;
//   flex-direction: column;
//   align-content: center;

//   width: 100%;
//   align-items: center;

//   font-family: 'Lato', sans-serif;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 10px;
//   line-height: 12px;

//   span {
//     padding-top: 3px;
//   }
// }

// .user-profile-btn__icon {
//   width: 13px;
//   height: 13px;
// }

// .search-icon {
//   width: 19px;
//   height: 19px;
// }

// .ion-toolbar__left {
//   display: flex;
//   align-items: center;

//   ion-button+ion-img {
//     padding-left: 16px;
//   }

//   ion-button {
//     margin: 0;
//     --background: transparent;
//   }
// }

// .ion-toolbar__right {
//   display: flex;
//   align-items: center;

//   ion-img+ion-button {
//     margin-left: 16px !important;
//   }
// }

// .ion-toolbar__search {
//   display: flex;
//   align-items: center;
//   flex: 1 1;

//   ion-img+ion-button {
//     margin-left: 16px !important;
//   }

//   &__input {
//     margin-left: 25px;
//     background-color: transparent !important;
//     color: var(--white);;
//     // text-decoration: underline;
//     --placeholder-opacity: 1;

//     &.changed {
//       // text-decoration: underline;
//     }
//   }
// }

// .menu-btn {
//   --background-hover: none;
//   --background-focused: none;
// }
