
.casino-war-cards {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &-total__cards-card {
    width: 24px;
    height: 33px;
  }
}
//
//@media (min-width: 1024px) {
//  .gameresult.gameresult-casino-war {
//    height: unset;
//    .gameresult-cardsplace {
//      height: 82px;
//    }
//  }
//  .casino-war-cards {
//    &-total__cards-card {
//      width: 45px;
//      height: 60px;
//    }
//  }
//}
