@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.matka {
  position: relative;
  .matka-table {
    padding: 43px 10px 0 10px;
    ul, li {
      margin: unset;
      padding: unset;
      list-style: none;
    }
    .matka-table__header {
      font-size: 12px;
      font-weight: 500;
      font-family: Poppins;
      color: var(--color-secondary-exchange);
      padding-bottom: 33px;
      &-row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 5px;
      }
      &-item {
        background-color: #3F7FA7;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        border-radius: 5px;
        &.active {
          background-color: var(--background-player-exchange);
        }
      }
    }
    .matka-table__body {
      font-family: Poppins;
      font-weight: 500;
      font-size: 18px;
      &-price {
        position: absolute;
        top: -21px;
        color: #444444;
        left: 50%;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 700;
        transform: translateX(-50%);
      }
      &-sells {
        display: grid;
        grid-template: repeat(2, auto) / repeat(5 ,auto);
        grid-gap: 5px 5px;
        position: relative;
        &-item {
          background-color: var(--background-player-exchange);
          border-radius: 5px;
          height: 50px;
          padding: 7px 0 2px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          line-height: 1;
          .matka-exposure-box-modern {
            padding-top: 7px;
          }
        }
      }
      &-player {
        display: grid;
        grid-template-rows: auto auto;
        grid-row-gap: 36px;
        color: var(--color-secondary-exchange);
        &-buttons {
          position: relative;
          &-item {
            background-color:var(--background-player-exchange);
            border-radius: 5px;
            height: 70px;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 10px 0 0 0;
          }
          &-title {
            font-weight: 400;
            font-size: 13px;
            padding: 5px 0 0 0;
          }
        }
      }
      &-player-single {
        .matka-table__body-player-buttons {
          display: grid;
          grid-template: repeat(2, 1fr) / repeat(2, 1fr);
          grid-gap: 5px 5px;
          &-item {
            justify-content: center;
            padding: 10px 0 5px 0;
            line-height: 1;
            &-title {
             padding-top: 8px;
              font-size: 13px;
              font-weight: 400;
            }
            .matka-exposure-box-modern {
              padding-top: 7px;
            }
          }
        }
      }
      &-player-sp,
      &-player-dp {
        grid-row-gap: 15px;
        .matka-table__body-player-buttons {
          &-item {
            justify-content: center;
            height: 60px;
            padding: 10px 0 0 0;
          }
        }
      }
      &-player-trio {
        display: block;
        .matka-table__body-player-buttons {
          &-item {
            justify-content: center;
            height: 60px;
            padding: 10px 0 0 0;
          }
        }
      }

      &-sells-item.lock-ui,
      &-player-buttons-item.lock-ui {
        position: relative;
        pointer-events: none;
        &:hover {
          cursor: unset;
        }
        &:before {
          content: '';
          position: absolute;
          background-image: url('../../../assets/images/exchangeGames/lock-white.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-color: var(--background-closed-exchange);
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 5px;
          z-index: 1;
        }
        &.winner:before {
          content: '';
          background: url(../../../assets/images/TrophyTeenPattiTest.svg);
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 1;
        }
      }

      &-sells-item.lock-ui {
        &:before {
          //background-position-x: 50%;
          //background-position-y: 30%;
        }
        //&.winner:before {
        //  background-position-x: 50%;
        //  background-position-y: 30%;
        //}
        &.winner {
          span {
            opacity: 0;
          }
        }
      }
      .matka-table__body-player-single {
        .matka-table__body-player-buttons-item.lock-ui {
          &:before {
            background-position-x: 50%;
            background-position-y: 50%;
          }
          &.winner {
            &:before {
              background-position-x: 50%;
              background-position-y: 50%;
            }
            span {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .matka-exposure-box-modern {
    font-size: 11px;
    position: unset;
    font-weight: 600;
    font-family: 'Poppins';
    min-height: 15%;
  }
  .matka-exposure-modern{
    &--pos {
      color: var(--positive-exchange);
      min-height: auto;
    }
    &--neg {
      color: var(--negative-exchange);
      min-height: auto;
    }
  }
  @media (min-width: 1024px) {
    .matka-table {
      padding: 33px 0 60px 0;
      .matka-table__header {
        font-size: 15px;
        padding-bottom: 29px;
        &-item {
          height: 57px;
        }
      }
      .matka-table__body {
        font-family: Poppins;
        font-weight: 500;
        font-size: 18px;
        &-price {
          top: unset;
          bottom: -30px;
          font-size: 15px;
          font-weight: 500;
        }
        &-sells {
          grid-gap: 10px 5px;
          &-item {
            height: 73px;
            padding-top: 7px;
            line-height: 32px;
            font-size: 46px;
            font-weight: 400;
          }
        }
        &-player {
          display: grid;
          grid-template-rows: 1fr;
          grid-row-gap: unset;
          grid-template-columns: 4.53fr 2.69fr;
          grid-column-gap: 20px;
          &-buttons {
            &-item {
              background-color: var(--background-player-exchange);
              border-radius: 5px;
              height: 73px;
              padding: 5px 0 0 0;
              justify-content: center;
              font-size: 24px;
              font-weight: 400;
              &-title {
                font-size: 13px;
                padding: 5px 0 0 0;
              }
            }
          }
        }
        &-player-single {
          .matka-table__body-player-buttons {
            grid-gap: 10px 5px;
            &-item {
             justify-content: center;
              padding: 5px 0;
            }
          }
        }
        &-player-sp,
        &-player-dp {
          .matka-table__body-price {
            top: unset;
            bottom: -30px;
            left: 85%;
          }
          grid-row-gap: 15px;
          .matka-table__body-player-buttons {
            &-item {
              height: 100%;
              padding-top: 0px;
            }
          }
        }
        &-player-trio {
          display: block;
          .matka-table__body-player-buttons {
            &-item {
              height: 77px;
              padding-top: 10px;
            }
          }
        }
      }
    }
    .matka-exposure-box-modern {
      min-height: 8%;
      font-size: 13px;
    }
  }
}
@media (max-width: 1024px) {
  .matka.virtual {
    margin-top: -10px;
    padding-top: 10px;
    min-height: calc(100% - 380px);
    background: linear-gradient(-181.68deg, #655E51 50.02%, #D1C085 100%);
    .matka-table__body-price {
      color: var(--color-secondary-exchange);
    }
  }
  .matka.virtual.fullHeight {
    min-height: 100%;
    margin-top: unset;
  }
}


