.roulette-betting {
  display: flex;
  min-width: 300px;
  justify-content: space-between;
  &__games {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .roulette__block {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-weight: 600;
    color: var(--white);
    font-size: 11px;
    line-height: 16px;
    width: 36px;
    height: 36px;
    border-radius: 8px;
  }
  .roulette__block__Red {
    background-color: var(--danger);
  }
  .roulette__block__Green {
    background-color: var(--positive);
  }
  .roulette__block__Black {
    background-color: var(--black);
  }
  &__info-column {
    min-width: 120px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 70%;
      text-align: right;
      font-weight: 400;
      color: var(--background-secondary-player-exchange);
    }
    &-results {
      width: 30%;
      font-weight: 500;
      color: var(--white);
      padding-left: 4px;
    }
  }
}

@media (max-width: 1280px) {
  div.roulette-betting {
    flex-direction: column;

    .roulette-betting__games {
      margin-bottom: 15px;
    }
    .roulette-betting__info-row-title,
    .roulette-betting__info-row-results{
      width: unset;
    }
  }
}
