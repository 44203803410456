.cricket-match-stats {
    width: 100%;
    color: var(--black);

    @media (max-width: 550px) {
        max-width: 550px;
        width: 100%;
    }

    &-slides {
        background-color: var(--white);
        min-height: 200px;
        height: 100%;

        .swiper-slide {
            font-size: 12px;
        }

        .swiper-wrapper {
            padding-bottom: 25px;
        }
    }
    &-overs {
        width: 100%;
    }

    &-over {
        width: calc(100% - 6px);
        border: 1px solid var(--border);
        margin: 3px;
        padding: 8px;

        &__title {
            font-weight: 700;
            margin: 0;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
        }

        &__balls {
            display: flex;
            margin: 6px 0;
            border-bottom: 1px solid var(--border);
            padding-bottom: 8px;
        }

        &__ball {
            color: var(--white);
            font-weight: 700;
            border-radius: 20px;
            min-width: 22px;
            min-height: 21px;
            text-align: center;
            padding: 4px 4px 0;
            margin-right: 4px;
            font-size: 10px;

            &.WICKET {
                background-color: var(--ball-w-background);
                border: 1px solid var(--ball-w-border);
            }

            &.BYE,
            &.LEGBYE,
            &.NOBALL,
            &.WIDE {
                background-color: var(--ball-b-background);
                border: 1px solid var(--ball-b-border)
            }

            &.INFIELDBOUNDARY {
                &_0 {
                    background-color: var(--ball-0-background);
                    border: 1px solid var(--ball-0-border);
                }

                &_1,
                &_2,
                &_3,
                &_5 {
                    background-color: var(--ball-1-background);
                    border: 1px solid var(--ball-1-border);
                }

                &_4 {
                    background-color: var(--ball-4-background);
                    border: 1px solid var(--ball-4-border);
                }

                &_6 {
                    background-color: var(--ball-6-background);
                    border: 1px solid var(--ball-6-border);
                    //color: var(--ball-6-color)
                }
            }
        }

        &__runs {
            display: flex;
            margin-top: 8px;

            &__delimeter {
                margin: -5px 10px;
                font-size: 18px;
            }
        }
    }

    &__second {
        flex-direction: column;
        display: flex;
        width: 100%;
        margin-top: 3px;
        padding: 6px 10px 0;
        border-top: 1px solid var(--border);
        border-bottom: 1px solid var(--border);
    }

    &__statistics {
        display: flex;
        width: 100%;
        padding-bottom: 6px;

        &__group {
            background: var(--white);
            border-right: 1px solid var(--border);
            padding: 6px;
            text-align: center;
            min-width: 65px;
            font-size: 12px;

            &:last-child {
                width: 100%;
                border-right: 0;
            }

            &__label {
                font-size: 10px;
            }
        }
    }

    &__scores {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 6px 0;
        border-top: 1px solid var(--border);

        &__header,
        &__content {
            display: flex;
        }

        &__header {
            color: var(--match-stats-batsmen-bowler-row);
            padding-bottom: 5px;
            padding: 4px 0 6px;
        }

        &__content {
            padding-bottom: 3px;
        }

        &__item {
            text-align: center;
            width: 14%;

            &.athlete {
                width: 30%;
                text-align: left;
            }
        }
    }

    .swiper-pagination-bullet {
        margin-top: 1px;
        background: var(--gray-50);
        opacity: 1;
        border: 1px solid var(--gray-50);
        margin: 0 5px;
    }

    .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 5px;
        cursor: pointer;
    }

    .swiper-pagination-bullet-active {
        background: var(--gray-30);
        border: 1px solid var(--gray-30);
    }
}

.cricket-match-stats.statsInVideoTab {
    width: 50%;
}

@media (max-width: 1024px) {
    .LiveSection {
        .LiveSection__content.LiveSection__content-withStats {
            .cricket-match-stats {
                .cricket-match-stats-over__title {
                    font-size: 10px;
                }
            }
        }
        .swiper-pagination-fraction,
        .swiper-pagination-custom,
        .swiper-container-horizontal>.swiper-pagination-bullets {
            bottom: 2px;
        }
        .cricket-match-stats-over__ball {
            border-radius: 20px;
            min-width: 20px;
            min-height: 20px;
            padding: 0;
            font-size: 10px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
        .cricket-match-stats-over__runs {
            font-size: 11px;
        }
    }
}
