
.muflis-teenpatti-cards {
  width: 100%;
  height: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  &__player {
    display: flex;
    align-items: center;
    &-name {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 12px;
      color: var(--color-secondary-exchange);
      padding-right: 15px;
      white-space: nowrap;
    }
    &-name.second-player {
      padding: 0 0 0 15px;
    }
    &-cards {
      height: 33px;
      display: flex;
      &-card {
        height: 33px;
        width: 24px;
        margin-right: 5px;
      }
    }
  }
}

//@media (min-width: 1024px) {
//  .gameresult.gameresult-mulfis {
//    height: unset;
//    .gameresult-cardsplace {
//      height: 82px;
//    }
//  }
//  .muflis-teenpatti-cards {
//    width: 100%;
//    max-width: unset;
//    padding: 0 55px;
//    &__player {
//      &-name {
//        font-size: 15px;
//        padding-right: 30px;
//      }
//      &-name.second-player {
//        padding: 0 0 0 30px;
//      }
//      &-cards {
//        height: 62px;
//        display: flex;
//        &-card {
//          height: 62px;
//          width: 46px;
//          margin-right: 10px;
//        }
//      }
//    }
//  }
//}
