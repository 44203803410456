 //.LiveTeenpattiCardsWrapper{
//
//.gameresult{
//    width: 100%;
//    height: 66px;
//    margin-top: -10px;
//    &-greenline{
//      height: 5px;
//      background: #00AF3C;
//    }
//    &-cardsplace{
//      height: 61px;
//      background: #1D1D1D;
//    }
//    .gameresult-cardsplace.cardsplace-roulette {
//      height: 84px;
//    }
//  }

  .LiveTeenpattiCardsWrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
  }

  .LiveTeenpattiCardsContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 150px;
    &-info{
      display: flex;
    }
    &-name{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: var(--white);
      //margin: 0 15px;
      width: 100%;
    }
    &-total{
      //height: 100%;
      //width: 100%;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      &__name{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--white);
      }
      &__cards{
        height: 33px;
        display: flex;
        justify-content: center;
        &-card{
          display: inline-block;
          width: 24px;
          height: 33px;
          margin-right: 3px;
        }
      }
    }
  }
  .hidden-card{
    display: none;
  }

  @media screen and (min-width: 768px) {
    .TrapCardsContent{
      padding: 0px 56px;
      &-total{
        width: 90px;
        //&__name{
        //  font-size: 15px;
        //  line-height: 22px;
        //}
        //&__card{
        //  font-size: 25px;
        //}
      }
    }
    //.TrapCardsContent-total__cards{
      //height: 40px;
      //width: 58px;
      //padding: 0 0 3px 0;
    //}
  }

  //@media (max-width: 1024px) {
  //  div.gameresult {
  //    .gameresult-cardsplace.cardsplace-roulette {
  //      height: 60px;
  //    }
  //  }
  //}

  @media (min-width: 1024px) {
    //.LiveTeenpattiCardsWrapper {
    //  height: 82px;
    //  background: #1D1D1D;
    //}
    .LiveTeenpattiCardsContent {
      &-name {
        //margin: 0 30px;
        //font-size: 15px;
        //line-height: 22px;
        &.b {
          text-align: end;
        }
      }
      //&-total {
        //display: unset;
        //&__cards {
          //width: auto;
          //&-card {
          //  height: 62px;
          //  width: 46px;
          //  margin-right: 10px;
          //}
        //}
      //}
    }
  }
