//general styles
.AmarAkbarAnthony-table {
  padding: 35px 0 70px 0;
  .AmarAkbarAnthony-table__row {
  }
  .AmarAkbarAnthony-table__big-items-wrapper {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  .AmarAkbarAnthony-table__row-item {
    height: 81px;
    padding: 12px 20px;
    background-color: var(--background-player-exchange);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .AmarAkbarAnthony-table__row-item-name {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    color: var(--white);
  }
  .AmarAkbarAnthony-table__row-item-back-lay-wrapper {
    width: 100%;
    height: 100%;
    max-width: 132px;
    display: flex;
    justify-content: space-between;
  }
  .AmarAkbarAnthony-table__row-item-back,
  .AmarAkbarAnthony-table__row-item-lay {
    width: calc((100% / 2) - 6px);
    height: 100%;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  .AmarAkbarAnthony-table__row-item-back {
    background-color: var(--back-color-exchange);
  }
  .AmarAkbarAnthony-table__row-item-lay {
    background-color: var(--lay-color-exchange);
  }
  .AmarAkbarAnthony-table__row-item-price {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
  }
  .AmarAkbarAnthony__big-item {
    .AmarAkbarAnthony-table__row-item-price {
      color: var(--color-exchange);
    }
  }
  .AmarAkbarAnthony-table__small-items-wrapper {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 10px;
  }
  .AmarAkbarAnthony-table__small-items-wrapper-inner {
    display: flex;
    justify-content: space-between;
  }
  .AmarAkbarAnthony-table__row-item-small {
    height: 81px;
    padding: 10px 0 5px 0px;
    background-color: var(--background-player-exchange);
    border-radius: 10px;
    width: calc(50% - 5px);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    .AmarAkbarAnthony-table__row-item-price {
      color: var(--white);
      display: inline-block;
      margin-bottom: 5px;

    }
    .AmarAkbarAnthony-table__colors {
      img:first-child {
        margin-right: 10px;

      }
    }
  }
}
// end general styles

// lock, winner cup
.AmarAkbarAnthony-table {
  .AmarAkbarAnthony-table__row-item.lock-ui {
    .AmarAkbarAnthony-table__row-item-back,
    .AmarAkbarAnthony-table__row-item-lay {
      cursor: auto;
    }
    .AmarAkbarAnthony-table__row-item-back:after,
    .AmarAkbarAnthony-table__row-item-lay:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-closed-exchange);
      background-image: url("../../../assets/images/tptLock.svg");
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 3px;
    }
  }
  .AmarAkbarAnthony-table__row-item-small.lock-ui {
    cursor: auto;
  }
  .AmarAkbarAnthony-table__row-item-small.lock-ui:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--background-closed-exchange);
    background-image: url("../../../assets/images/tptLock.svg");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
  }
  @media (min-width: 1024px) {
    .AmarAkbarAnthony-table__row-item.WINNER:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-closed-exchange);
      background-image: url("../../../assets/images/TrophyTeenPattiTest.svg");
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 10px;
    }
  }
  .AmarAkbarAnthony-table__row-item-small.WINNER {
    .AmarAkbarAnthony-table__row-item-price {
      opacity: 0;
    }
  }
  .AmarAkbarAnthony-table__row-item-small.WINNER:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    background-image: url("../../../assets/images/TrophyTeenPattiTest.svg");
    background-repeat: no-repeat;
    background-position: bottom 20px center;
    border-radius: 10px;
  }
}
// end lock, winner cup

//exposure
.AmarAkbarAnthony-table {
  .exposure-box-modern {
    font-family: 'Poppins';
    font-weight: 600;
    text-align: center;
  }
  .AmarAkbarAnthony__big-item {
    .exposure-box-modern {
      font-size: 11px;
    }
  }
  .AmarAkbarAnthony-table__row-item-small {
    .exposure-box-modern {
      font-size: 10px;
      margin-top: -5px;
    }
  }
  .exposure-modern--pos {
    color: var(--positive-exchange);
  }
  .exposure-modern--neg {}
  color: var(--negative-exchange);
}
//end exposure

@media (max-width: 1300px) {
  div.AmarAkbarAnthony-table {
    .AmarAkbarAnthony-table__row-item {
      padding: 12px 15px;
    }
  }
}

@media (max-width: 1250px) and (min-width: 1024px) {
  div.AmarAkbarAnthony-table {
    .AmarAkbarAnthony-table__row-item {
      padding: 12px 7px;
    }
    .AmarAkbarAnthony-table__row-item-back-lay-wrapper {
      max-width: 110px;
    }
  }
}

@media (max-width: 1024px) {
  .AmarAkbarAnthony__main-wrapper {
    position: relative;
  }
  div.AmarAkbarAnthony-table {
    padding: 30px 10px 40px 10px;

    .AmarAkbarAnthony-table__big-items-wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
    .AmarAkbarAnthony-table__row-item {
      height: 62px;
      border-radius: 5px;
      padding: 8px 30px;
    }
    .AmarAkbarAnthony-table__row-item-name {
      font-size: 12px;
    }
    .AmarAkbarAnthony-table__row-item-price {
      font-weight: 700;
      font-size: 12px;
    }
    .AmarAkbarAnthony-table__small-items-wrapper {
      grid-template-columns: repeat(4,1fr);
      grid-template-rows: repeat(2,1fr);
      grid-gap: 20px 10px;
    }
    .AmarAkbarAnthony-table__row-item-small {
      border-radius: 5px;
    }
    .AmarAkbarAnthony-table__small-items-wrapper-inner:first-child {
      grid-column: 1/3;
      grid-row: 1;
    }
    .AmarAkbarAnthony-table__small-items-wrapper-inner:nth-child(2) {
      grid-column: 3/5;
      grid-row: 1;
    }
    .AmarAkbarAnthony-table__small-items-wrapper-inner:nth-child(3) {
      grid-column: 1/5;
      grid-row: 2;
      height: 60px;
    }
    .AmarAkbarAnthony-table__row-item-small-under,
    .AmarAkbarAnthony-table__row-item-small-over {
      height: 62px;
      padding: 0 15px;
      flex-direction: row;
      align-items: center;
      .AmarAkbarAnthony-table__row-item-price {
        margin-bottom: 0;
      }
    }
    .AmarAkbarAnthony-table__row-item-name-wrapper {
      .exposure-box-modern {
        margin-top: 0px;
      }
    }
    .AmarAkbarAnthony-table__row-item.WINNER {
      .AmarAkbarAnthony-table__row-item-name {
        position: relative;
      }
      .AmarAkbarAnthony-table__row-item-name:after {
        content: "";
        position: absolute;
        top: 50%;
        right: -25px;
        height: 25px;
        width: 25px;
        transform: translate(100%, -50%);
        background-image: url("../../../assets/images/TrophyTeenPattiTest.svg");
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 10px;
      }
    }
    .AmarAkbarAnthony-table__row-item-small.WINNER:after {
      border-radius: 5px;
    }
    .AmarAkbarAnthony-table__row-item-small-under.WINNER:after,
    .AmarAkbarAnthony-table__row-item-small-over.WINNER:after {
      position: absolute;
      top: 50%;
      left: unset;
      right: 20px;
      transform: translateY(-50%);
      width: 25px;
      height: 25px;
      background-position: center;
    }
  }
}

//@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
//
//.AmarAkbarAnthonyWrapper{
//
//    padding: 35px 0 70px 0;
//}
//
//.AmarAkbarAnthony-game-title{
//  font-family: 'Poppins';
//  font-style: normal;
//  font-weight: 500;
//  font-size: 25px;
//  line-height: 38px;
//  color: var(--black);
//  text-transform: uppercase;
//
//  text-align: center;
//}
//
//.AmarAkbarAnthony-game-round-id{
//  font-family: 'Roboto';
//  font-style: normal;
//  font-weight: 400;
//  font-size: 15px;
//  line-height: 18px;
//  color: var(--black);
//  margin-bottom: 20px;
//  margin-left: 13px;
//  text-align: center;
//}
//
//.AmarAkbarAnthony-table{
//
//    .AmarAkbarAnthony-table__item-wrapper {
//        display: grid;
//        grid-template-columns: repeat(3, 1fr);
//        grid-gap: 10px;
//        margin-bottom: 20px;
//    }
//    .AmarAkbarAnthony-table__row.AmarAkbarAnthony-table__row-wrapper {
//        display: grid;
//        grid-template-columns: repeat(3, 1fr);
//        grid-gap: 10px;
//    }
//    &__item{
//        width: 100%;
//        height: 80px;
//        background: #29516A;
//        border-radius: 10px;
//        display: flex;
//        justify-content: space-between;
//        align-items: center;
//        padding: 10px 15px;
//        position: relative;
//        &-title{
//            font-family: 'Poppins';
//            font-style: normal;
//            font-weight: 500;
//            font-size: 14px;
//            //line-height: 18px;
//            color: #FFFFFF;
//            position: relative;
//        }
//        .trophyy{
//            display: flex;
//            align-items: center;
//
//        }
//        .trophyyAnthony{
//            display: flex;
//            align-items: center;
//
//        }
//        &-odds{
//            //height: 46px;
//            height: 100%;
//            width: 130px;
//            display: flex;
//            justify-content: space-between;
//            &__back{
//
//                border-radius: 3px;
//
//                height: 100%;
//                width: 46%;
//                display: flex;
//                justify-content: center;
//                align-items: center;
//                position: relative;
//                &-price{
//                    font-family: 'Poppins';
//                    font-style: normal;
//                    font-weight: 600;
//                    font-size: 14px;
//                    line-height: 18px;
//                    text-align: center;
//                    color: #263238;;
//                }
//            }
//
//            &__back,
//            &__back.backSuspended {
//                background: var(--back-color-exchange)
//            }
//            .backSuspended {
//                &::after{
//                    content: "";
//                    position: absolute;
//                    left: 0;
//                    top: 0;
//                    height: 100%;
//                    width: 100%;
//                    background-color: var(--background-closed-exchange);
//                    background-image: url("../../../assets/images/tptLock.svg");
//                    background-repeat: no-repeat;
//                    background-position: center;
//                    border-radius: 3px;
//                }
//            }
//
//
//            &__lay{
//
//                border-radius: 3px;
//
//                width: 46%;
//                display: flex;
//                justify-content: center;
//                align-items: center;
//                position: relative;
//                &-price{
//                    font-family: 'Poppins';
//                    font-style: normal;
//                    font-weight: 600;
//                    font-size: 14px;
//                    line-height: 18px;
//                    text-align: center;
//                    color: #263238;
//                }
//            }
//
//            &__lay,
//            &__lay.laySuspended {
//                background: var(--lay-color-exchange)
//            }
//            .laySuspended {
//                &::after {
//                    content: "";
//                    position: absolute;
//                    left: 0;
//                    top: 0;
//                    height: 100%;
//                    width: 100%;
//                    background-color: var(--background-closed-exchange);
//                    background-image: url("../../../assets/images/tptLock.svg");
//                    background-repeat: no-repeat;
//                    background-position: center;
//                    border-radius: 3px;
//                }
//            }
//        }
//    }
//    &__row{
//        display: flex;
//        justify-content: space-between;
//        &__specialItem{
//
//            &__flex{
//                display: flex;
//                justify-content: space-between;
//            }
//            &__title{
//                font-family: 'Poppins';
//                font-style: normal;
//                font-weight: 500;
//                font-size: 14px;
//                line-height: 21px;
//                color: #444444;
//                text-align: center;
//                margin-bottom: 3px;
//                position: relative;
//            }
//            &__box{
//                background: #29516A;
//                border-radius: 10px;
//                width: 48%;
//                height: 80px;
//                display: flex;
//                justify-content: center;
//                align-items: center;
//                position: relative;
//                color: #FFFFFF;
//                &__content{
//                    &-name{
//                        font-family: 'Poppins';
//                        font-style: normal;
//                        font-weight: 500;
//                        font-size: 14px;
//                        //line-height: 18px;
//                        width: 54px;
//                        height: 23px;
//                        text-align: center;
//                        margin-bottom: 5px;
//                        &-img{
//                            display: inline-block;
//                            margin: 0 5px 0px 5px;
//                            height: 17px;
//                            width: 17px;
//                        }
//                    }
//                    &-odds{
//                        font-family: 'Poppins';
//                        font-style: normal;
//                        font-weight: 600;
//                        font-size: 14px;
//                        //line-height: 18px;
//                        text-align: center;
//                    }
//                }
//            }
//            .winnerBoxPlus{
//                background: #29516A;
//                //border-radius: 5px;
//                width: 48%;
//                height: 80px;
//                display: flex;
//                justify-content: center;
//                align-items: center;
//                position: relative;
//                color: #fff;
//                &::after{
//                    content: url("../../../assets/images/TrophyTeenPattiTest.svg");
//                    display: block;
//                    position: absolute;
//                    left: 0;
//                    top: 10px;
//                    height: 100%;
//                    width: 100%;
//                    border-radius: 3px;
//                    display: flex;
//                    flex-direction: revert;
//                    justify-content: center;
//                    align-items: center;
//                }
//            }
//            .boxSuspended{
//                &::after{
//                    content: "";
//                    position: absolute;
//                    left: 0;
//                    top: 0;
//                    height: 100%;
//                    width: 100%;
//                    background-color: var(--background-closed-exchange);
//                    background-image: url("../../../assets/images/tptLock.svg");
//                    background-repeat: no-repeat;
//                    background-position: center;
//                    border-radius: 3px;
//                }
//            }
//            .boxSuspendedCard{
//                &::after{
//                    content: "";
//                    position: absolute;
//                    left: 0;
//                    top: 0;
//                    height: 100%;
//                    width: 100%;
//                    background-color: var(--background-closed-exchange);
//                    background-image: url("../../../assets/images/tptLock.svg");
//                    background-repeat: no-repeat;
//                    background-position: center;
//                    border-radius: 3px;
//
//                }
//            }
//            .boxSuspendedExp{
//                &::after{
//                    content: url("../../../assets/images/tptLock.svg");
//                    display: block;
//                    position: absolute;
//                    left: 0;
//                    top: 3px;
//                    height: 100%;
//                    width: 100%;
//                    border-radius: 3px;
//                    display: flex;
//                    flex-direction: revert;
//                    justify-content: center;
//                    align-items: center;
//
//                }
//            }
//
//            &__boxCard{
//                background: #29516A;
//                border-radius: 10px;
//                width: 48%;
//                height: 94px;
//                display: flex;
//                justify-content: center;
//                align-items: center;
//                position: relative;
//                color: #FFFFFF;
//                &__content{
//                    &-name{
//                        font-family: 'Poppins';
//                        font-style: normal;
//                        font-weight: 500;
//                        font-size: 12px;
//                        line-height: 18px;
//                        &-img{
//                            display: inline-block;
//                            margin: 0 5px;
//                        }
//                    }
//                    &-odds{
//                        font-family: 'Poppins';
//                        font-style: normal;
//                        font-weight: 600;
//                        font-size: 12px;
//                        line-height: 18px;
//                        text-align: center;
//                    }
//                }
//            }
//        }
//        &__underOver{
//            background: #29516A;
//            border-radius: 10px;
//
//            width: 48%;
//            position: relative;
//            color: #fff;
//            &-content{
//                display: flex;
//
//                justify-content: center;
//                align-items: center;
//                flex-direction: column;
//                padding: 0 15px;
//                width: 100%;
//                height: 100%;
//                position: relative;
//                text-align: center;
//                &__title{
//                    font-family: 'Poppins';
//                    font-style: normal;
//                    font-weight: 500;
//                    font-size: 14px;
//
//                    margin-bottom: 7px;
//                }
//                &__odd{
//                    font-family: 'Poppins';
//                    font-style: normal;
//                    font-weight: 600;
//                    font-size: 14px;
//
//                }
//            }
//        }
//        .boxSuspendedd{
//
//            &::after{
//
//                content: "";
//                position: absolute;
//                left: 0;
//                top: 0;
//                height: 100%;
//                width: 100%;
//                background-color: var(--background-closed-exchange);
//                background-image: url("../../../assets/images/tptLock.svg");
//                background-repeat: no-repeat;
//                background-position: center;
//                border-radius: 3px;
//
//            }
//        }
//        .winnerBoxAAA{
//            background: #29516A;
//            border-radius: 10px;
//            width: 48%;
//
//            display: flex;
//            justify-content: center;
//            align-items: center;
//            position: relative;
//            color: #fff;
//            .AmarAkbarAnthony-table__row__underOver-content__odd {
//                opacity: 0;
//            }
//            &::after{
//                content: url("../../../assets/images/TrophyTeenPattiTest.svg");
//
//                position: absolute;
//
//                top: 50%;
//                left: 50%;
//                transform: translate(-50%, -15%);
//            }
//        }
//    }
//}
//
//
//
//
//.exposure-box-AAA{
//    font-family: 'Poppins';
//    font-style: normal;
//    font-weight: 600;
//    font-size: 11px;
//    line-height: 16px;
//
//    position: absolute;
//
//    top: 10px;
//    left: 50%;
//    transform: translateX(-50%);
//}
//.exposure-box-AAASp{
//    text-align: center;
//    font-family: 'Poppins';
//    font-style: normal;
//    font-weight: 600;
//    font-size: 11px;
//    line-height: 16px;
//    padding-top: 3px;
//}
//.exposure-AAA{
//    &--pos{
//        color: var(--positive-exchange);
//    }
//    &--neg{
//        color: var(--negative-exchange);
//    }
//}
//
//@media (min-width: 1025px) {
//    .AmarAkbarAnthony-table__item.trophyy::after  {
//        content: url("../../../assets/images/TrophyTeenPattiTest.svg");
//        position: absolute;
//        left: 0;
//        top: 0;
//        height: 100%;
//        width: 100%;
//        border-radius: 10px;
//        display: flex;
//        flex-direction: revert;
//        justify-content: center;
//        align-items: center;
//        background: rgba(23, 44, 58, 0.6);
//    }
//    .AmarAkbarAnthony-table__item.trophyyAnthony::after {
//        content: url("../../../assets/images/TrophyTeenPattiTest.svg");
//        position: absolute;
//        left: 0;
//        top: 0;
//        height: 100%;
//        width: 100%;
//        border-radius: 10px;
//        display: flex;
//        flex-direction: revert;
//        justify-content: center;
//        align-items: center;
//        background: rgba(23, 44, 58, 0.6);
//    }
//}
//
//@media (max-width: 1199px) {
//    div.AmarAkbarAnthonyWrapper {
//        padding: 30px 10px 40px 10px;
//    }
//    .AmarAkbarAnthony-table {
//        .AmarAkbarAnthony-table__item-wrapper {
//            grid-template-columns: repeat(1, 1fr);
//        }
//    }
//}
//
@media (max-width: 1024px) {
  .AmarAkbarAnthony__main.virtual {
    margin-top: -10px;
    padding-top: 10px;
    min-height: calc(100% - 380px);
    background: linear-gradient(-181.68deg, #0B3114 50.02%, #40783B 100%)
  }
}
//    .AmarAkbarAnthonyWrapper.virtual.fullHeight {
//        min-height: 100%;
//        padding-top: 20px;
//        margin-top: unset;
//    }
//    div.AmarAkbarAnthonyWrapper {
//        div.AmarAkbarAnthony-table__row.AmarAkbarAnthony-table__row-wrapper {
//            display: grid;
//            grid-template-columns: repeat(4, 1fr);
//            grid-template-rows: repeat(2, 1fr);
//            grid-gap: 20px 10px;
//        }
//        .AmarAkbarAnthony-table__row__specialItem:first-child {
//            grid-column: 1 / 3;
//            grid-row: 1;
//        }
//        .AmarAkbarAnthony-table__row__specialItem:nth-child(2) {
//            grid-column: 3 / 5;
//            grid-row: 1;
//        }
//        .AmarAkbarAnthony-table__row__specialItem.AmarAkbarAnthony-table__row {
//            grid-column: 1 / 5;
//            grid-row: 2;
//            height: 60px;
//        }
//        .AmarAkbarAnthony-table__row__specialItem__box,
//        .AmarAkbarAnthony-table__row__underOver {
//            width: calc((100% /2) - 5px);
//        }
//
//        .AmarAkbarAnthony-table__item {
//            height: 60px;
//            padding: 5px 25px;
//        }
//        .AmarAkbarAnthony-table__row__underOver {
//            padding: 3px;
//        }
//        .AmarAkbarAnthony-table__item,
//        .AmarAkbarAnthony-table__row__specialItem__box,
//        .AmarAkbarAnthony-table__row__underOver-content,
//        .AmarAkbarAnthony-table__row__specialItem .boxSuspended,
//        .AmarAkbarAnthony-table__row__underOver {
//            border-radius: 5px !important;
//        }
//        .AmarAkbarAnthony-table__item-title,
//        .AmarAkbarAnthony-table__row__specialItem__box__content-name,
//        .AmarAkbarAnthony-table__row__underOver-content__title {
//            font-size: 12px !important;
//        }
//        .AmarAkbarAnthony-table__item-odds__back-price,
//        .AmarAkbarAnthony-table__item-odds__lay-price,
//        .AmarAkbarAnthony-table__row__specialItem__box__content-odds,
//        .AmarAkbarAnthony-table__row__underOver-content__odd {
//            font-weight: 700 !important;
//            font-size: 12px !important;
//        }
//        .AmarAkbarAnthony-table__row__underOver-content-inner {
//            width: 100%;
//            display: flex;
//            justify-content: space-between;
//        }
//        .AmarAkbarAnthony-table__row__underOver-content__title {
//            margin-bottom: 5px;
//        }
//       .AmarAkbarAnthony-table__item.trophyy::after  {
//            content: url("../../../assets/images/TrophyTeenPattiTest.svg");
//            position: absolute;
//            left: 70px;
//            align-items: center;
//        }
//        .AmarAkbarAnthony-table__item.trophyyAnthony::after {
//            content: url("../../../assets/images/TrophyTeenPattiTest.svg");
//            position: absolute;
//            left: 85px;
//            align-items: center;
//        }
//
//        .AmarAkbarAnthony-table__row {
//            .winnerBoxAAA::after {
//                content: url("../../../assets/images/TrophyTeenPattiTest.svg");
//                position: absolute;
//                top: 50%;
//                left: unset;
//                right: 20px;
//                transform: translate(0%, -50%);
//            }
//        }
//
//        .AmarAkbarAnthony-table__row__specialItem.AmarAkbarAnthony-table__row {
//            .AmarAkbarAnthony-table__row__underOver.boxSuspendedd::after {
//                transform: translate(-50%, -50%);
//            }
//        }
//    }
//}
