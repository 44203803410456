@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.muflis-teenpatti {
  .teenpatti-muflis-table {
    &__body {
      position: relative;
      margin: 35px 0 50px 0;
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 19px;
      &-players {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      &-players-header {
        display: flex;
        font-family: Poppins;
        font-weight: 500;
        font-size: 15px;
        color: var(--color-title-secondary-exchange);
        padding-bottom: 5px;
      }
      &-player-row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        column-gap: 8px;
      }
      &-player-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        background-color: var(--background-player-exchange);
        position: relative;
        height: 81px;
        padding: 0 28px;
      }
      &-player-info-text {
        font-family: Poppins;
        font-weight: 500;
        font-size: 15px;
        color: var(--color-secondary-exchange);
      }
      &__player-price {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      }
      &-player-wrapper.lock-ui {
        position: relative;
        pointer-events: none;
        &:hover {
          cursor: unset;
        }
        &:before {
          content: '';
          background-image: url('../../../assets/images/exchangeGames/lock-white.svg');
          background-color: var(--background-closed-exchange);
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 1;
          border-radius: 5px;
        }
      }
    }
    &__player-price {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
    .bold {
      font-weight: 600;
    }
  }
  .exposure-box-modern {
    font-size: 13px;
    position: unset;
    font-weight: 600;
    font-family: 'Poppins';
  }
  .exposure-modern{
    &--pos {
      color: var(--positive-exchange);
    }
    &--neg {
      color: var(--negative-exchange);
    }
  }
  @media (max-width: 1024px) {
    .teenpatti-muflis-table {
      &__body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: none;
        grid-gap: 9px;
        padding: 27px 10px 0 10px;
        margin: unset;
        &-players-header {
          font-size: 14px;
        }
        &-player-row {
          grid-template-rows: repeat(3, 1fr);
          row-gap: 20px;
          grid-template-columns: none;
        }
        &-player-wrapper {
          width: 100%;
          border-radius: 5px;
          padding: 0 15px;
          max-height: 62px;
        }
        &-player-info-text {
          font-size: 12px;
        }
        .bold {
          font-weight: 700;
        }
      }
    }
    .exposure-box-modern {
      font-size: 12px;
    }
  }
}



