@import '../../flags.scss';

.RaceEventDropdown {
    --border-color: transparent;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
    padding: 0 4px;
    margin: 3px 0;
    cursor: pointer;

    &__container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        width: 100%;
        position: relative;
    }

    &__header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        position: relative;

        &.open {
            padding-bottom: 0;
        }

        .flag-row {
            display: flex;
            flex-flow: row nowrap;
        }

        &__title {
            flex: 1 1 0;
        }

        &__event {
            display: flex;
            flex-flow: column nowrap;

            &__venue {
                font-family: 'Lato', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: #000000;
            }

            &__time {
                font-family: 'Lato', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
            }
        }

        &__time {
            margin-left: auto;
            margin-right: 0;
            font-family: 'apollo';
        }

        &__arrowContainer {
            cursor: pointer;
            position: relative;
            margin-left: auto;
            margin-right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 40px;

            &.with-time {
                margin-left: 14px;
                margin-right: 0;
            }
        }

        &__arrow {

            position: relative;
            width: 10px;
            height: 10px;
            border: 2px solid #000;
            border-left: none;
            border-top: none;
            transform: rotate(45deg);
            transition: transform .3s ease-out;

            &.open {
                transform: rotate(-135deg);
            }
        }
    }

    &__list {
        padding: 0 4px;

        &__desc {
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 13px;
            margin: 6px;
        }

        &__ionlist {
            width: 100%;
            margin: 3px;
        }
    }
}

// Racing sportmartket items styles
.favourites-header.racing-header {
    padding: 10px 0px !important;

    h1 {
        font-weight: 600;
        font-size: 24px;
        margin: 0 !important;
    }

    .sportsPage-headerBet {
        display: none;
    }
}

.favourites-header.racing-header.withPlay {
    h1 {
        padding-left: 20px;
    }
}

.eventPage-racing {
    .RaceEventDropdown__list__desc {
        margin: 0;
    }

    .RaceEventDropdown__list {
        padding: 0;

        .odd-button {
            width: 63px;
            height: 100%;
        }
    }

    .RaceEventDropdown__showPageHeader {
        font-weight: 400;
        font-size: 15px;
        color: #0f2327;
    }

    .RaceEventDropdown__top-table-header {
        font-weight: 700;
        font-size: 12px;
        color: #0f2327;
        padding: 10px 10px 10px 10px;
        display: flex;
        justify-content: space-between;

        p {
            margin: 0;
            width: calc(100% - 125px);
        }

        div {
            width: 125px;
            display: flex;
            justify-content: space-around;
        }
    }

    .SportEvent__market__row {
        height: 26px;
    }

    .SportEvent__market__row.showJockeyName {
        height: 43px;
    }

    .SportEvent__market__title {
        min-height: 26px;
        width: unset;
    }
    .SportEvent__market__row.CLOSED .SportEvent__market__title,
    .SportEvent__market__row.CLOSED .SportEvent__market__status {
        color: rgba(15,35,39, 0.5);
    }

    .SportEvent__market__title__text {
        font-size: 12px;
    }

    .SportMarketStandard {
        margin-bottom: 0;
    }
    //.SportEvent__market__row.showJockeyName {
    //    .SportEvent__market__title__text div span:first-child {
    //        padding-left: 15px;
    //    }
    //    .SportEvent__market__title__text div span:last-child {
    //        padding-left: 15px;
    //    }
    //}
    .RaceEventDropdown__header {
        background: #FFFFFF;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        padding: 8px 20px;
        margin-bottom: 2px;
        cursor: pointer;
        display: flex;
        position: relative;
    }

    .flag {
        margin-right: 15px;
        position: relative;
        top: -3px;
    }

    .RaceEventDropdown__header__event {
        display: flex;
        flex-direction: column;

        .RaceEventDropdown__header__event__venue {
            font-weight: 700;
            color: #235B9D;
            font-size: 12px;
        }

        .RaceEventDropdown__header__event__time {
            font-weight: 600;
            color: #142326;
            font-size: 12px;
        }
    }

    .RaceEventDropdown__header__arrowContainer {
        height: unset;
    }

    .RaceEventDropdown {
        --background: transparent;
    }

    .SportMarketStandard {
        margin-bottom: 15px;
    }

    .RaceEventDropdown__list__ionlist {
        --background: transparent;
        --ion-item-background: transparent;
    }

    .SportEvent__market__row.OPEN,
    .SportEvent__market__row.ACTIVE {
        height: 41px;
    }

    div.SportEvent__market__row.SUSPENDED {
        height: 26px;
    }

    div.SportEvent__market__row.SUSPENDED.showJockeyName {
        height: 43px;
    }

    @media screen and (min-width: 1024px) {
        .RaceEventDropdown__top-table-header {
            p {
                width: calc(100% - 378px);
            }

            div {
                width: 378px;
                padding-left: 15px;
            }
        }
    }
}

.racing-header {
    position: relative;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // align-items: center;

    h1 {
        font-size: 24px;
        line-height: 36px;
        color: #0f2327;
        font-weight: 400;
    }

    h1,
    p {
        margin: 0;
    }

    margin: 0 !important;
    margin-bottom: 10px !important;
    padding: 10px 10px 10px 0;
    border-bottom: 1px solid rgba(15, 35, 39, 0.4);

    .EventHeader__details__additional {
        // margin-left: 12px;
        font-size: 15px;
        line-height: 20px;
        color: #0f2327;
        margin-top: 3px;
        margin-left: 3px;
        // margin-bottom: 0;
        // border-bottom: none;
        // display: inline-block;
    }
}

// End Racing sportmartket items styles
