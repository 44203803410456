.LoaderContent {
    position: fixed;
    top: 0;
    left: 0;
    // width: 100%;
    // height: 100%;
    // background-color: var(--spinner-background-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.notEventPopUp{
    height: 260px;
    width: 600px;
    background: #fff;
    position: fixed;
    top: 208px;
    left: 46%;
    transform: translateX(-50%);
    padding: 50px 90px 40px 57px;
    &-wrapper{
        display: flex;
        &__image{
            height: 100%;
            width: 50%;
        }
        &__info{
            height: 100%;
            width: 50%;
            &-title{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                letter-spacing: -0.04em;
                color: #B2493E;
                margin-bottom: 20px;
            }
            &-description{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                color: #000000;
                margin-bottom: 50px;
            }
            &-button{
                background: #6A9772;
                box-shadow: inset -2px -2px 0px #54785B;
                border-radius: 3px;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 20px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 28px;
                width: 80px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .notEventPopUp{
        left: 50%;
    }
}

@media (min-width: 1023px) and (max-width: 1280px) {
    .notEventPopUp{
        width: 520px;
        transform: translateX(-52%);
    }
}

.LoaderContentMob {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--spinner-background-color);
    display: flex;
    justify-content: center;
    align-items: center;
}