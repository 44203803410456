.OpenBetsTable {
  font-size: 12px;
  font-weight: 400;
  font-family: Lato;

  ul, li, p {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__table-header {
    background-color: #DDDDDD;;
    display: grid;
    grid-template-columns: 1.7fr 7fr 1.3fr 1.2fr 1.5fr 1.5fr 2.2fr;
    height: 33px;
    align-items: center;
    li {
      color: #0F2327;
      padding-left: 10px;
      font-weight: 600;
    }
    li:last-child {
      padding-right: 10px;
      text-align: right;
      white-space: nowrap;
    }
  }

  &__table-body {
    ul {
      background-color: #F0F0F0;
    }
    ul:nth-child(even) {
      background-color: #FFFFFF;
    }

    &-row {
      display: grid;
      grid-template-columns: 1.7fr 7fr 1.3fr 1.2fr 1.5fr 1.5fr 2.2fr;
      align-items: center;
      li {
        min-height: 60px;
        font-size: 12px;
        font-weight: 400;
        color: #0F2327;
        overflow-wrap: anywhere;
        padding: 5px 0 5px 10px;
      }
      li:last-child {
        padding-right: 10px;
        text-align: right;
        white-space: nowrap;
      }
      &-date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
          white-space: nowrap;
        }
      }

      &-description {
        display: flex;
        flex-direction: column;
        &-name {
          cursor: pointer;
          font-weight: 600;
        }
        &-name:hover {
          text-decoration-line: underline;
        }
        //span:last-child {
        //  padding-left: 10px;
        //}
        &-matched-time {
          padding-left: 10px;
        }
      }

      &-info {
        display: flex;
        align-items: center;
      }
      &-profit {
        display: flex;
        align-items: center;
        justify-content: end;
      }
      .text-color {
        color: #909090;
      }
      .text-bold {
        font-weight: 600;
      }
    }
    &-no-bets {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 60px;
    }
  }
}
