

.gameresult{
  width: 100%;
  height: 66px;
  margin-top: -10px;
  position: relative;

  &-greenline{
    height: 5px;
    background: #00AF3C;
  }
  &-greenline.virtual{
    display: none;
    background: #1D1D1D !important;
  }
  &-cardsplace{
    height: 61px;
    background: #1D1D1D;
  }
  //.gameresult-cardsplace.cardsplace-roulette {
  //  height: 84px;
  //}

  &.cardsplace-casinoMeter, .cardsplace-casinoMeter {
    height: auto;
  }
}
.gameresult.gameresult-race20 {
  height: unset;
  .gameresult-cardsplace {
    height: 100px;
    padding: 5px 0 5px 0;
  }
}

  div.gameresult {
    .gameresult-cardsplace.cardsplace-roulette,
    .gameresult-cardsplace.cardsplace-sicbo {
      height: 0;
    }
  }
  div.gameresult.gameresult-roulette,
  div.gameresult.gameresult-sicbo {
    height: 0;
  }
