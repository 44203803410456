.seven-up-down-last-result {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  margin-top: -15px;
  &-title {
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #101010;
    margin-bottom: 10px;
  }
  &__player {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 15px;
    color: #101010;
    margin-bottom: 37px;
    &-cards {
      // margin-left: 15px;
      // display: flex;
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0 0 5px grey;
      }
    }

  }
}

@media (min-width : 1024px) {
  .seven-up-down-last-result {
    margin-top: 20px;
  }
}
