.pop-upLastResult-content__results{
  &-name{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: var(--color-exchange);
  }
}

.pop-upLastResult-content__results-teenpattiT20-items-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 260px;
  margin: 0 auto;

  .pop-upLastResult-content__results-teenpattiT20-item {
    position: relative;
    margin-bottom: 50px;
    text-align: center;
  }
  .pop-upLastResult-content__results {
    flex-direction: row;
  }
  .pop-upLastResult-content__results-name {
    font-weight: 500;
    font-size: 12px;
    display: inline-block;
    margin-bottom: 10px;
    color: var(--color-exchange);
  }
  .pop-upLastResult-content__results--cards-inner {
    display: flex;
  }
  .pop-upLastResult-content__results-cards-card {
    display: block;
    width: 32px;
    height: 46px;
    box-shadow: 0px 0px 5px grey;
    border-radius: 2px;
  }
  .pop-upLastResult-content__results-cards-card:not(:last-child) {
    margin-right: 5px;
  }
  .pop-upLastResult-content__results-teenpattiT20-item.isWinner {
    position: relative;
  }
  .pop-upLastResult-content__results-teenpattiT20-item.isWinner:before {
    content: '';
    position: absolute;
    background-image: url('../../../../../assets/images/winnerTropy.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    //background-size: auto 100%;
    width: 20px;
    height: 20px;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 100%);
  }
}

.pop-upLastResult-content__results-teenpattiT20-wrapper {
  width: 100%;
  padding-top: 20px;
  .pop-upLastResult-content__results-teenpattiT20-winner-pattern {
    width: 100%;
    height: 31px;
    background: var(--background-player-exchange);
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.05em;
    color: var(--color-secondary-exchange);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  div.pop-upLastResult-content__results-teenpattiT20-items-wrapper {
    flex-direction: column;
    padding: 0 0 20px 0;

    .pop-upLastResult-content__results-teenpattiT20-item {
      margin-bottom: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pop-upLastResult-content__results-name {
      margin: 0 15px 0 0;
      font-size: 15px;
    }
    .pop-upLastResult-content__results-cards-card:not(:last-child) {
      margin-right: 15px;
    }
    .pop-upLastResult-content__results-teenpattiT20-item.isWinner:before {
      width: 35px;
      height: 42px;
      top: 0;
      right: -17px;
      left: unset;
      transform: none;
    }
  }
}
