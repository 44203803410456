.dragon-tiger-last-result {
  margin-top: -20px;
  padding: 5px 0;
  display: flex;
  align-items: end;
  flex-direction: row;
  &__player {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #101010;
    padding: 30px;
    margin-bottom: 30px;

    &-cards {
      margin-top: 10px;
      display: flex;
      &-isWinner{
        margin-top: 10px;
        display: flex;
        justify-content: center;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          width: 24px;
          height: 24px;
          background-image: url('../../../../../assets/images/winnerTropy.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          left: 2px;
          top: 50px
        }
      }
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0 0 5px grey;
      }
    }

  }
}
