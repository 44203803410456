
.matka-cards {
  height: 100%;
  &__player {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-secondary-exchange);
    &-card {
      height: 33px;
      width: 24px;
    }
    &-card:not(:last-child) {
      margin-right: 5px;
    }
    span {
      padding-right: 5px;
    }
    &-card-result {
      height: 34px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-secondary-exchange);
      border: 2px solid #00af3c;
      border-radius: 2px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #00af3c;
    }
  }
}

//@media (min-width: 1024px) {
//  .gameresult.gameresult-matka {
//    height: unset;
//    .gameresult-cardsplace {
//      height: 82px;
//    }
//  }
//  .matka-cards {
//    &__player {
//      font-size: 15px;
//      &-card {
//        height: 62px;
//        width: 46px;
//      }
//      &-card:not(:last-child) {
//        margin-right: 10px;
//      }
//      span {
//        padding-right: 10px;
//      }
//      &-card-result {
//        height: 62px;
//        width: 46px;
//        border: 3px solid #00af3c;
//      }
//    }
//  }
//}
