
.race-page {
  font-family: 'Lato';
  padding: 0 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-bottom: 80px;
  color: #000000;

  &__header {
    padding: 15px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #9AA1A4;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__header-title {
    font-weight: 400;
    font-size: 24px;
    margin: 0;
    color: #0f2327;
  }
  &__header-subtitle {
    //font-weight: 700;
    font-size: 12px;
    color: #0f2327;
  }
  &__content-header {
    margin-bottom: 15px;
    font-weight: 400;
    color: #0f2327;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .race-page__header-subtitle {
      font-weight: 700;
    }
  }
  &__content-header-next-race {
    font-size: 15px;
  }
  &__content-rase-odds-table-header {
    font-weight: 700;
    font-size: 12px;
    color: #0f2327;
    padding: 10px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
      width: calc(100% - 125px);
    }
    div {
      width: 125px;
      display: flex;
      justify-content: space-around;
    }
  }
  &__content-rase-odds-table-wrapper {
    margin-bottom: 25px;
  }
  .race-page__content-all-suspend-cover {
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 301px;
    color: #899192;
    background: rgb(221, 221, 221);
    font-size: 12px;
  }
  .race-page__content-rase-odds-table-wrapper.all-suspended {
    .race-page__content-rase-odds-table-header {
      p {
        width: calc(100% - 301px);
      }
      div {
        width: 301px;
      }
    }
    .race-page__content-rase-odds-table {
      position: relative;
    }
  }
  //.race-page__content-rase-odds-table-wrapper.all-suspended {
  //  .race-page__content-rase-odds-table-header {
  //    p {
  //      width: 45%;
  //    }
  //    div {
  //      width: 55%;
  //    }
  //  }
  //  .race-page__content-rase-odds-table {
  //    position: relative;
  //  }
  //}
}

@media screen and (min-width: 1024px) {
  .race-page__content-all-suspend-cover {
  width: 301px !important;
  }
  .race-page__content-rase-odds-table-header {
    p {
      width: unset !important;
      }
    div {
      width: 301px !important;
      // margin-right: 5%;
    }
}
  .SportMarketStandard__markets {
    position: relative;
    .SportMarketStandard__markets-suspended {
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1e3f5a;
      background: #dddddd;
      font-size: 12px;
      border: 1px solid #ddd;
    }
  }
}
