.casino-32-card-cards {
  width: 100%;
  height: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 5px;
  margin: 0 auto;
  &__player {
    display: flex;
    align-self: center;
    flex-direction: column;
    min-width: 45px;
    &-title {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 12px;
      color: var(--color-secondary-exchange);
      white-space: nowrap;
      line-height: 16px;
      &-total {
        color: var(--color-total-exchange);
      }
    }
    &-cards {
      height: 33px;
      display: flex;
      &-card {
        height: 33px;
        width: 25px;
        margin-right: 5px;
      }
    }
  }
}
@media (min-width: 1024px) {
  .casino-32-card-cards {
    width: 100%;
    height: 100%;
    padding: unset;
    max-width: unset;
    display: flex;
    justify-content: space-around;
    padding-bottom: 5px;
    margin: 0 auto;
    .casino-32-card-cards__player {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      min-width: 45px;
      color: var(--color-secondary-exchange);
      font-weight: 900;
      font-size: 14px;
      span {
        padding: 0 4px;
      }
      &-default-card {
        height: 33px;
        width: 25px;
        background-color: var(--color-secondary-exchange);
        border-radius: 2px;
        font-weight: 700;
        font-size: 14px;
        color: #264E47;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-title {
        padding-top: 3px;
        .casino-32-card-cards__player-title-total {
          color: var(--color-total-exchange);
          font-weight: 500;
          font-size: 14px;
          padding: unset;
        }
      }
      &-cards {
        height: 33px;
        display: flex;
        &-card {
          height: 33px;
          width: 25px;
          margin-right: 5px;
        }
      }
    }
  }
}
