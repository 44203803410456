@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.teenPattiTest-table {
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
  &__column {
    width: calc((100% / 7 ) - 7px);
  }
  &__player-names {
    padding-top: 25px;
  }
  &__title {
    color: var(--color-title-secondary-exchange);
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 14px;
  }
  &__bet-title {
    font-size: 13px;
    font-family: 'Poppins';
  }
  &__player-name {
    font-size: 15px;
  }
  &__bets-column-header {
    text-align: center;
    margin-bottom: 5px;
  }
  &__player-name {
    height: 68px;
  }
  &__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  &__bet-cell {
    background: var(--background-player-exchange);
    border-radius: 10px;
    font-size: 24px;
    position: relative;
    cursor: pointer;
    height: 68px;
    display: flex;
    text-align: center;
    flex-direction: column;
    line-height: 1;
  }
  &__bet-cell.block {
    // background: rgba(0, 0, 0, 0.6);
    //background: #12232e;
    cursor: auto;
    pointer-events: none;
    &::after{
      //content: url("../../../assets/images/tptLock.svg");
      //display: block;
      //position: absolute;
      //left: 0;
      //top: 0;
      //height: 100%;
      //width: 100%;
      //border-radius: 5px;
      //display: flex;
      //flex-direction: revert;
      //justify-content: center;
      //align-items: center;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-closed-exchange);
      background-image: url("../../../assets/images/Lockk.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15px;
      border-radius: 5px;
      display: flex;
      flex-direction: revert;
      justify-content: center;
      align-items: center;
    }
    .teenPattiTest-table__price {
      opacity: 0;
    }
  }
  &__bet-cell.blockUp {
    // background: rgba(0, 0, 0, 0.6);
    background: #12232e;
    cursor: auto;
    pointer-events: none;
    &::after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-closed-exchange);
      background-image: url("../../../assets/images/Lockk.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15px;
      border-radius: 5px;
      display: flex;
      flex-direction: revert;
      justify-content: center;
      align-items: center;
    }
    .teenPattiTest-table__price {
      opacity: 0;
    }
  }
  &__bet-cell.winnerTPT{
    background: var(--background-player-exchange);
    &::after{
      content: url('../../../assets/images/TrophyTeenPattiTest.svg');
      position: absolute;
      right: 5px;
    }
  }
  &__price {
    font-weight: 400;
    font-size: 15px;
    color: var(--color-secondary-exchange);
    font-family: 'Poppins';
  }
  &__outcome-layout {
    font-weight: 400;
    font-size: 24px;
  }
}
.exposure-box-teenpattitest {
  font-size: 11px;
  position: absolute;
  width: auto;
  bottom: 3px;
  right: 50%;
  transform: translateX(50%);
  font-weight: 600;
  font-family: 'Poppins';
  bottom: 5px;
  line-height: 20px;
}
.exposure-teenpattitest{
  &--pos {
    color: var(--positive-exchange);
  }
  &--neg {
    color: var(--negative-exchange);
  }
}

@media (max-width: 1920px) {
  div.teenPattiTest-table {
    &__column {
      width: calc((100% / 7 ) - 5px);
    }
    &__player-name {
      .teenPattiTest-table__title {
        font-size: 15px;
      }
    }
    .teenPattiTest-table__bet-title {
      font-size: 13px;
      font-weight: 500;
    }
    &__cell {
      margin-bottom: 30px;
    }
    &__bet-cell {
      font-size: 14px;
      height: 50px;
      border-radius: 5px;
    }
    .teenPattiTest-table__price {
      font-weight: 600;
      font-size: 15px;
      font-family: 'Poppins';
    }
    .teenPattiTest-table__outcome-layout {
      font-size: 14px;
    }
    &__player-name {
      height: 50px;
    }
    .exposure-box-teenpattitest {
      font-size: 13px;
      font-weight: 600;
      bottom: 2px;
    }
  }
}

.nonCollapsed{
  position: fixed;
  top: 182px;
  z-index: 15;
  width: 94%;
  transition: linear 0.2s;
  left: 3%;
  right: 3%;
}
.collapsed{
  position: fixed;
  top: 80px;
  z-index: 15;
  width: 94%;
  transition: linear 0.2s;
  left: 3%;
  right: 3%;
}

@media (min-width: 1024px) and (max-width: 1350px) {
  div.teenPattiTest-table {
    &__bets-column-header {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__player-names {
      padding-top: 45px;
    }
  }
}

@media (max-width: 2920px)  {
  div.teenPattiTest-header {
    margin-bottom: 0;
  }
  div.teenPattiTest-table {
    flex-direction: column !important;
    padding-top: 15px;
    &__column {
      width: 100%;
      display: flex;
      padding: 0 10px;
    }
    &__player-names {
      padding-top: 0;
      justify-content: flex-end;
    }
    &__cell {
      margin-bottom: 0;
    }
    &__player-name {
      height: 37px;
      width: 25%;
    }
    &__bets-column-header {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 0;
      height: 50px;
    }
    &__bets-column-header:after {
      content: '';
      position: absolute;
      top: 7px;
      left: 4px;
      bottom: 7px;
      right: 0px;
      border-radius: 4px 0px 0px 4px;
      background: var(--background-secondary-player-exchange);
      z-index: 1;
    }
    .teenPattiTest-table__bet-title {
      position: relative;
      z-index: 2;
      font-size: 11px;
      font-family: 'Poppins';
      font-weight: 500;
    }
    &__bets-column {
      margin-bottom: 3px;
    }
    &__bets-column-bets-wrapper {
      width: 75%;
      display: flex;
      justify-content: space-between;
    }
    &__bet-cell {
      width: calc((100% / 3) - 1.2px);
    }
  }
}
