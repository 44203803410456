.muflis-teenpatt-last-result {
  padding: 30px 0 10px 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  &__player {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 15px;
    color: #101010;
    margin: 0 30px 37px 0;
    &-cards {
      display: flex;
      width: 110px;
      height: 44px;
      justify-content: space-between;
      margin-left: 17px;
      &-isWinner{
        display: flex;
        width: 110px;
        height: 44px;
        margin-left: 17px;
        justify-content: space-between;
        position: relative;
        &::before{
          content: url('../../../../../assets/images/winnerTropy.svg');
          position: absolute;
          left: 120px;
          top: -5px
        }
      }
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0 0 5px grey;
      }
    }

  }

  .muflis-teenpatt-last-result--combination {
    width: 100%;
    height: 31px;
    background-color: var(--background-player-exchange);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    span {
      color: var(--color-secondary-exchange);
      font-weight: 700;
      font-size: 12px;
      text-transform: capitalize;
    }
  }
}

@media (min-width: 1024px) {
  .muflis-teenpatt-last-result {
    padding: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &__player {
      font-size: 12px;
      margin: 0 0 57px 0;
      flex-direction: column;
      &-cards {
        display: flex;
        width: 116px;
        height: unset;
        justify-content: space-between;
        margin-left: unset;
        padding-top: 10px;
        &-isWinner{
          display: flex;
          width: 116px;
          height: unset;
          margin-left: unset;
          justify-content: space-between;
          position: relative;
          padding-top: 10px;
          &::before{
            content: "";
            position: absolute;
            background-image: url('../../../../../assets/images/winnerTropy.svg');
            background-position: center;
            background-size: cover;
            left: 50%;
            top: 65px;
            width: 20px;
            height: 20px;
            z-index: 1;
            transform: translateX(-50%);
          }
        }
        &-card{
          display: block;
          width: 32px;
          height: 46px;
          box-shadow: 0 0 5px grey;
        }
      }
    }
    &__player:first-child {
      margin-right: 40px;
    }
  }
}
