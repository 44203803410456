.gameresult__gametimer {
  font-family: 'Gemunu Libre';
  font-weight: 700;
  font-size: 26px;
  color: #00AF3C;
  position: absolute;
  z-index: 1;
  top: 0;
  transform: translate(50%, 50%);
  background-color: #0a191b;
  height: 25px;
  padding: 0 3px 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    height: 20px;
    line-height: 1;
    display: inline-flex;
    align-items: center;
  }

}
