@import url('https://fonts.googleapis.com/css2?family=Lexend&display=swap');

.exchangeGames-contentDesktop {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0px 0 55px 0;
  justify-content: center;

  & .exchangeGames-itemDesktop {
    cursor: pointer;
    width: 340px;
    margin: 20px 30px;
  }
}
.exchangeGames-img-bodyDesktop {
  width: 100%;
  height: 154px;
  background-color: transparent;
  background-repeat: no-repeat;
  border-radius: 12px;
  background-size: cover;
}

.inplay-item-list-exchange__header {
min-height: 32px;
padding: 5px 0;
margin-top: 8px;
--background: white !important;
--color: var(--text-color);
&-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
&-logo {
  display: flex;
  align-items: center;

  padding-left: 9px;

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
}
.exchangeGames-gamename-bodyDesktop{
  display: none;
}


.inplay-content__logo-icon {
position: relative;
padding-right: 8px;
font-weight: normal;
font-family: 'apollo';
font-style: unset;

width: 24px;
height: 24px;
&--casino:before {
  content: "";
}
&--exchange-games:before {
  content: "";
}

}

.inplay-color-icon {
color: var(--in-play-block-color);
}

.exchangeGames-gamename-bodyDesktopName{
  font-family: 'Lexend';
  font-weight: 700;
  font-size: 20px;
  line-height: 12px;
  color: #FFFFFF;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 374px) and (min-width: 320px) {
  .exchangeGames-content {
    grid-template-columns: 46vw 46vw;
    grid-column-gap: 1vw;
    grid-row-gap: 7px;
    & .exchangeGames-img-body {
      height: 21vw;
    }
  }
}

@media screen and (min-width: 1300px) and (max-width: 1439px) {
  .exchangeGames-contentDesktop{
    .exchangeGames-itemDesktop {
        width: 300px;
    }
    .exchangeGames-img-bodyDesktop{
      height: 140px;
      background-size:contain;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1299px) {
  .exchangeGames-contentDesktop{
    .exchangeGames-itemDesktop {
        width: 250px;
    }
    .exchangeGames-img-bodyDesktop{
      height: 115px;
      background-size:contain;
    }
  }
}

@media screen and (max-width: 1024px) {
  .exchangeGames-contentDesktop{
    margin-top: 15px;
    justify-content: space-between;
    .exchangeGames-itemDesktop {
        width: 150px;
        height: 123px;
        margin: 0px;
        margin-bottom: 15px;
        background: #40424F;
        border-radius: 10px;
    }
    .exchangeGames-img-bodyDesktop{
      width: 100%;
      height: 100%;
      background-size: cover;
      border-radius: 10px;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: end;
    }
    .exchangeGames-gamename-bodyDesktop{
      font-family: 'Lexend';
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      color: #FFFFFF;
      margin-bottom: -10px;
      display: unset;
    }
  }
}
@media screen and (max-width: 360px) {
  .exchangeGames-contentDesktop {
    justify-content: center;
  }
}

/* @media(min-width: 425px) {
  .exchangeGames-content {
    grid-template-columns: 200px 200px;
    grid-row-gap: 7px;
    & .exchangeGames-img-body {
      height: 91px;
    }
  }
} */

// @media screen and (max-width: 424px) and (min-width: 376px) {
//   .exchangeGames-content {
//     grid-template-columns: 46vw 46vw;
//     grid-column-gap: 1vw;
//     grid-row-gap: 7px;
//     & .exchangeGames-img-body {
//       height: 21vw;
//     }
//   }
// }

// @media(min-width: 644px) {
//   .exchangeGames-content {
//     grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
//     grid-column-gap: 10px;
//     & .exchangeGames-item {
//       width: 200px;
//   }
//     & .exchangeGames-img-body {
//       height: 91px;
//     }
//   }
// }

// @media screen and (max-width: 643px) and (min-width: 426px) {
//   .exchangeGames-content {
//     grid-template-columns: 30vw 30vw 30vw;
//     grid-column-gap: 1vw;
//     grid-row-gap: 7px;
//     & .exchangeGames-img-body {
//       height: 14vw;
//     }
//   }
// }
