@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.resultsList{
  display: flex;
  justify-content: center;
  margin: 0 10px;
  ul{
    margin: 30px auto;
    width:100%;
    display: flex;
    padding: 0;
    justify-content: center;
    // flex-wrap: wrap;
  }
  .none {
    display: none;
  }
}
.displayResult{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  width: 40px;
  height: 40px;
  background: var(--primary);
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  margin: 0 2.5px 5px;
  span {
    color: inherit;
  }
  &.sicbo {
    width: 32px;
    height: auto;
  }
}
.displayResult.Black {
  background: #1D1D1D;
}
.displayResult.Red {
  background: #FF0000;
}
.displayResult.Green {
  background: #32CDA8;
}
.sicbo-lastResult-item {
  // background: #264E47;
  background: var(--primary);

  //border: 2px solid #FF7A40;
  border-radius: 6.5px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  padding: 3px;
  ul,
  p {
    list-style: none;
    margin: 0;
    padding: 0;

  }
  p {
    width: 25px;
    height: 25px;
  }
  ul {
    display: flex;
    flex-direction: column;
  }
  li {
    width: 25px;
    height: 25px;
    background-color: var(--secondary);
    // opacity: 0.5;

    border-radius: 5px;
  }
  li.double-dice {
    background-color: var(--cta-primary-darken);
    // background: #4D9C8F;
    // opacity: 0.8;
  }
  li:not(:last-child) {
    margin-bottom: 3px;
  }
}
.displayResult.displayResult-sicbo {
  width: unset;
  height: unset;
}

.displayResult {
  .displayResult__race20-button {
    font-size: 24px;
  }
}

.resultsList.not-game {
  display: flex;
  justify-content: center;
  margin: 0 10px;
  ul {
    margin: 10px auto 13px auto;
  }
  .displayResult {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin: unset;
  }
  .displayResult:not(:last-child) {
    margin: 0 4px 0 0;
  }
}
@media (min-width: 1024px) {
  .resultsList.sicbo {
    height: 140px;
    .displayResult-sicbo {
      ul {
        margin: unset;
      }
      .sicbo-lastResult-item {
        font-size: 16px;
      }
    }
  }
  li.displayResult.displayResult-sicbo {
    width: 30px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1200px){
  .sicbo-lastResult-item {
    font-size: 16px;
    padding: 1px;
  }
  li.displayResult.displayResult-sicbo {
    width: 25px !important;
  }
}
