.popUpTrap-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__results{
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &-cards{
      display: flex;
      justify-content: center;
      &-isWinner{
        display: flex;
        justify-content: center;
        position: relative;
        &::after{
          content: url('../../../../../assets/images/winnerTropy.svg');
          position: absolute;
          right: -50px;
          top: -3px
        }
      }
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0px 0px 5px grey;
        margin: 0 5px;
      }
    }
    &-description{
      height: 31px;
      width: 100%;
      padding: 0px 10px;
      background: var(--background-player-exchange);
      margin: 40px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &-text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        color: var(--color-secondary-exchange);
        text-transform: uppercase;
      }
    }
  }
}
.popUpTrap-content__wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 35px;
  padding-right: 65px;
  .popUpTrap-content__player-title {
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    color: var(--color-exchange);
    padding-right: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .popUpTrap-content__results-cards-isWinner::after{
    content: url('../../../../../assets/images/TrophyTrap.svg');
    top: 54px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    right: 50%;
    transform: translateX(50%);
  }
  .popUpTrap-content__player-title {
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-exchange);
    padding-bottom: 10px;
  }
}
