  .queenCardsContent{
    padding: 5px 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    &-total{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 4px;
      &__name{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--color-secondary-exchange);
      }
      &__name-score {
        color: var(--color-total-exchange);
      }
      &__cards{
        display: flex;
        &-card{
          display: inline-block;
          height: 33px;
          width: 24px;
        }
        &-card:nth-child(2){
          margin-left: 5px;
        }
      }
    }
  }
