@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.LiveTeenpattiWrapper{
  background: var(--white);
  padding-top: 9px;

  @media (min-width: 1024px ) {
    background: unset;
  }
}
.LiveTeenpatti-game-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: var(--color-exchange);
  text-transform: uppercase;
//   padding-top: 9px;
  text-align: center;
}

.LiveTeenpatti-game-round-id{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-exchange);
  margin-bottom: 20px;
  margin-left: 13px;
  text-align: center;
}

.LiveTeenpatti-table{
    margin: 21px 10px 30px 10px;
    &__matchOdds{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #444444;
        text-align: center;
    }
    &__content{
        margin-top: 3px;

        &-players {
            @media (min-width: 1024px )  {
                display: flex;
                justify-content: space-between;
                .LiveTeenpatti-table__content-player {
                    width: 49%;
                    &__title {
                        font-size: 14.4922px;
                        line-height: 22px;
                    }
                }
            }
        }
        &-player{
            width: 100%;
            height: 62px;
            background: var(--background-player-exchange);
            border-radius: 5px;
            padding: 8px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            position: relative;
            &__title{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: var(--white);

                .exposure-box-liveteenpatti {
                    margin-top: 2px;
                    margin-right: 7px;
                    text-align: center;
                }
            }
            &__trophy{
                &::after{
                    content: url(../../../assets/images/TrophyTeenPattiTest.svg);
                    position: absolute;
                    top: 19px;
                    left: 85px;
                }
            }
            &__odds{
                width: 130px;
                height: 46px;
                display: flex;
                justify-content: space-between;
                .back{
                    height: 100%;
                    width: 60px;
                    //background: var(--back-color-exchange);
                    border-radius: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                }
                .lay{
                    height: 100%;
                    width: 60px;
                    //background: var(--lay-color-exchange);
                    border-radius: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .suspendedBack{
                    //background: rgba(167, 216, 253, 0.5);
                    border-radius: 3px;
                    height: 100%;
                    width: 60px;
                    border-radius: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    //&::after{
                    //    content: url("../../../assets/images/tptLock.svg");
                    //    display: block;
                    //    position: absolute;
                    //    left: 0;
                    //    top: 0;
                    //    height: 100%;
                    //    width: 100%;
                    //    border-radius: 3px;
                    //    display: flex;
                    //    flex-direction: revert;
                    //    justify-content: center;
                    //    align-items: center;
                    //}
                }
                .back,
                .suspendedBack {
                    position: relative;
                    background: var(--back-color-exchange);
                }
                .suspendedBack {
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        background-color: var(--background-closed-exchange);
                        background-image: url("../../../assets/images/tptLock.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        border-radius: 3px;
                    }
                }
                .suspendedLay{
                    //background: rgba(255, 187, 203, 0.5);
                    border-radius: 3px;;
                    height: 100%;
                    width: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    //&::after{
                    //    content: url("../../../assets/images/tptLock.svg");
                    //    display: block;
                    //    position: absolute;
                    //    left: 0;
                    //    top: 0;
                    //    height: 100%;
                    //    width: 100%;
                    //    border-radius: 3px;
                    //    display: flex;
                    //    flex-direction: revert;
                    //    justify-content: center;
                    //    align-items: center;
                    //}
                }
                .lay,
                .suspendedLay {
                    background: var(--lay-color-exchange);
                    position: relative;
                }
                .suspendedLay {
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        background-color: var(--background-closed-exchange);
                        background-image: url("../../../assets/images/tptLock.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        border-radius: 3px;
                    }
                }
                .oddNumber{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                    color: #263238;
                    @media (min-width: 1024px )  {
                        font-weight: 600;
                        font-size: 14.0582px;
                        line-height: 21px;
                    }
                }
            }
            // .exposure-box-liveteenpatti{
            //     // position: relative;
            //     font-family: 'Poppins';
            //     font-style: normal;
            //     font-weight: 600;
            //     font-size: 11px;
            //     line-height: 16px;
            //     width: auto;
            //     // right: -20px;
            //     // top: -11px
            //   }
            //   .exposure-liveteenpatti{
            //     &--pos {
            //       color: #38FF7C;
            //     }
            //     &--neg {
            //       color: #FF8787;
            //     }
            //   }
        }
    }
    &__contentRow{
        display: flex;
        justify-content: space-between;
        &-playerPlus{
            height: 62px;
            width: 49%;
            background: var(--background-player-exchange);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            color: var(--white);
            &__title{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                @media (min-width: 1024px) {
                    font-size: 14.4922px;
                    line-height: 22px;
                }
            }
            &__trophyPlus{
                &::after{
                    content: url(../../../assets/images/TrophyTeenPattiTest.svg);
                    position: relative;
                    left: -8px;
                    top: 2px;
                }
            }
            &__odds{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
                text-align: right;
                @media (min-width: 1024px )  {
                    font-weight: 600;
                    font-size: 14.0582px;
                    line-height: 21px;
                }
            }
        }
    }
}
.susp {
    //background: rgba(0, 5, 17, 0.9);
    //color: rgba(255, 255, 255, 0.5);
    position: relative;
    &::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: var(--background-closed-exchange);
        background-image: url("../../../assets/images/tptLock.svg");
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 3px;
    }
}

// .exposure-box-modernn{
//     position: relative;
//     top: 11px;
// }
.exposure-box-modernnn{
    position: relative;
    top: 25px;
    left: 100%;
}

.exposure-box-liveteenpatti{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    width: auto;
    margin-right: -5px;
  }
  .exposure-liveteenpatti{
    &--pos {
      color: var(--positive-exchange);
    }
    &--neg {
      color: var(--negative-exchange);
    }
  }

  .exposure-box-liveteenpattiPlus{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    width: auto;
    margin-top: 5px;
    margin-right: 7px;
    text-align: center;
  }
  .exposure-liveteenpattiPlus{
    &--pos {
      color: var(--positive-exchange);
    }
    &--neg {
      color: var(--negative-exchange);
    }
  }

@media (max-width: 1024px) {
    .LiveTeenpattiWrapper.virtual {
        margin-top: -10px;
        padding-top: 10px;
        min-height: calc(100% - 380px);
        background: linear-gradient(197.32deg, #1C0B31 50.02%, #523B78 100%);
        .LiveTeenpatti-table__matchOdds {
         color: var(--white);
        }
        .LiveTeenpatti-table {
          margin-top: 10px;
        }
    }
    .LiveTeenpattiWrapper.virtual.fullHeight {
        min-height: 100%;
        margin-top: unset;
    }
}
