.matka-last-result {
  padding-bottom: 55px;
  &__player {
    &-cards {
      display: flex;
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0 0 5px grey;
      }
      &-card:not(:last-child){
        margin-right: 15px;
      }
    }
  }
}
@media (min-width: 1024px) {
  .matka-last-result {
    padding-top: 30px;
  }
}
