.BollywoodCasinoLastRes {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header{
    padding-top: 10px;
    &__title{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #101010;
      text-transform: uppercase;
    }
    &__gameId{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #101010;
    }
  }
  &__results{
    //padding-top: 40px;
    margin-top: -25px;
    // padding-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &-name{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #101010;
      margin-left: -70px;
      margin-top: 12px;
      }
    &-cards{
      display: flex;
      width: 130px;
      height: 44px;
      justify-content: center;
      //margin-top: 15px;
      //margin-bottom: 30px;
      margin-top: 10px;
      margin-bottom: 20px;
      &::before{
        content: 'Player A';
        position: absolute;
        left: -80px;
        top: -3px
      }
      &-isWinner{
        display: flex;
        width: 130px;
        height: 44px;
        justify-content: space-between;
        //margin-top: 15px;
        //margin-bottom: 30px;
        margin-top: 10px;
        margin-bottom: 20px;
        position: relative;
        &::after{
          content: url('../../../../../assets/images/winnerTropy.svg');
          position: absolute;
          right: -70px;
          top: -5px
        }
      }
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0px 0px 5px grey;
      }
    }
  }
}
