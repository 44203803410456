@media (min-width: 1024px) {
  .ChangePassword-desktop {
    --padding-top: 15px;
    --padding-start: 15px;
    --padding-end: 15px;
    // &____form-surccess{
    //   font-size: 12px;
    // }
    .ChangePassword-desktop-page-title {
      padding-bottom: 15px;
      border-bottom: 1px solid #9AA1A4;
      font-size: 24px;
      margin-bottom: 12px;
      margin-top: 0;
      width: 100%;
      max-width: calc(100% - 292px);
    }
    &__form {
      width: 300px;
      &-surccess{
        font-size: 12px;
        font-weight: 700;
      }
    }
    &__list {
      background: none !important;
      ion-item {
        --min-height: 27px;
        --padding-start: 0px;
        --padding-end: 0px;
        --inner-padding-end: 0px;
        --inner-border-width: 0px;
      }
    }
    &__button {
      width: 47px;
      margin: 20px 0 auto auto;
      text-transform: none;
      // background: var(--alt-secondary);
      background: #77A37F;
      color: var(--btn-text-color);
      border-radius: 3px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1), background-color .3s cubic-bezier(.4, 0, .2, 1), color .3s cubic-bezier(.4, 0, .2, 1), -webkit-box-shadow .3s cubic-bezier(.4, 0, .2, 1);
      box-shadow: inset -2px -2px 0px #54785B;
      height: 28px;
      font-weight: 700;
      font-size: 12px;
      margin-top: 24px;
      &:hover {
        background: var(--cta-primary-darken);
      }
      &:disabled {
        background: var(--cta-disabled);
        cursor: default;
      }
      &:focus {
        outline: none;
      }
    }
    &__form-error {
      padding: 0 10px 5px;
      margin-top: -2px;
      font-size: 12px;
      font-weight: 700;
      // color: var(--negative);
      color: #B1473C;
      ion-item+& {
        padding: 0 10px 10px;
        margin-top: 9px;
      }
    }
    ion-item {
      --highlight-background: var(--login-highlight-bg);
      --border-radius: 3px;
      overflow: visible;
      margin-top: 25px;
      position: relative;
      .ChangePassword-desktop__form-label {
        position: absolute;
        bottom: 18px;
        left: 6px;
        font-size: 10px;
        font-weight: 600;
      }
      .ChangePassword-desktop__form-error {
        position: absolute;
        right: 0;
        transform: translateX(100%);
        top: 10px;
        font-size: 12px;
      }
      .ChangePassword-desktop__form-surccess {
        position: absolute;
        right: 0;
        transform: translateX(100%);
        top: 10px;
        color: black;
        font-size: 12px;
      }
    }
    ion-input {
      border-radius: 3px;
      height: 100%;
      --padding-start: 6px !important;
    }
    .native-input {
      border-radius: 3px;
      height: 100%;
      border: 2px solid #DDDDDD;
      overflow: visible;
      color: #666666;
      font-weight: 600;
      font-size: 12px;
      &:focus {
        border: 2px solid var(--login-item-focused-border) !important;
        &::placeholder {
          color: transparent;
        }
      }
    }
  }
}
@media (max-width: 1023px) {
  .ChangePassword {
    --padding-top: 15px;
    --padding-start: 15px;
    --padding-end: 15px;
    &__form {
      width: 100%;
      margin-top: 10px;
    }
    &__list {
      background: none !important;
      ion-item {
        --min-height: 36px;
        --padding-start: 0px;
        --padding-end: 0px;
        --inner-padding-end: 0px;
        --inner-border-width: 0px;
      }
      ion-item+ion-item {
        margin-top: 12px;
      }
    }
    &__button {
      width: 50%;
      margin: 20px 0 auto auto;
      text-transform: none;
      background: var(--alt-secondary);
      color: #fff;
      border-radius: 2px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1), background-color .3s cubic-bezier(.4, 0, .2, 1), color .3s cubic-bezier(.4, 0, .2, 1), -webkit-box-shadow .3s cubic-bezier(.4, 0, .2, 1);
      height: 36px;
      font-weight: 500;
      margin-top: 24px;
      &:hover {
        background: var(--cta-primary-darken);
      }
      &:disabled {
        background: var(--cta-disabled);
        cursor: default;
      }
      &:focus {
        outline: none;
      }
    }
    &__form-error {
      padding: 0 10px 5px;
      margin-top: -2px;
      font-size: 10px;
      color: var(--negative);
      ion-item+& {
        padding: 0 10px 10px;
        margin-top: 9px;
      }
    }
    ion-item {
      --highlight-background: var(--login-highlight-bg);
      --border-radius: 2px;
    }
    ion-input {
      border-radius: 2px;
      height: 100%;
    }
    .native-input {
      border-radius: 2px;
      height: 100%;

      &:focus {
        border: 1px solid var(--login-item-focused-border);
      }
    }
  }
}

