@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.trio-table {
  text-align: center;
  padding: 0 30px;
  .trio-table__body .trio-table__row-margin:first-child .trio-table__body-row .trio-table__body-item {
    width: calc((100% / 4) - 5px);
  }
  &__row-margin {
    min-height: 115px;
  }
  &__row-margin:first-child {
    margin-bottom: 10px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
  }
  &__item {
    font-size: 15px;
  }
  &__item-header {
    font-weight: 400;
    font-size: 10px;
    color: #263238;
    font-family: 'Poppins';
  }
  &__item-bets-wrapper {
    background: var(--background-player-exchange);
    border-radius: 5px;
  }
  &__item.trio-table__body-item {
    width: calc((100% / 4) - 5px);
  }
  &__body {
    .trio-table__item-bets-wrapper {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .trio-table__item-bet-cell {
      height: 60px;
      position: relative;
      cursor: pointer;
      font-family: 'Poppins';

    }
  }
  &__item-bet-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    border-radius: 5px;
    line-height: 1;
  }
  &__item-bet-cell.bet-back,
  &__item-bet-cell.bet-lay {
    width: calc((100% / 2) - 7.5px);
  }
  &__item-bet-cell.bet-back,
  &__item-bet-cell.backSuspended {
    background: var(--back-color-exchange);
  }
  &__item-bet-cell.bet-lay,
  &__item-bet-cell.laySuspended {
    background: var(--lay-color-exchange);
  }

  &__item-bet-cell.backSuspended {
    width: calc((100% / 2) - 7.5px);
    &::after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-closed-exchange);
      background-image: url("../../../assets/images/Lockk.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      border-radius: 5px;
      display: flex;
      flex-direction: revert;
      justify-content: center;
      align-items: center;
    }
    &.winner:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-closed-exchange);
      background-image: url(../../../assets/images/TrophyTeenPattiTest.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 25px;
      border-radius: 5px;
      display: flex;
      flex-direction: revert;
      justify-content: center;
      align-items: center;
    }
  }

  &__item-bet-cell.laySuspended{
    width: calc((100% / 2) - 7.5px);
    &::after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-closed-exchange);
      background-image: url("../../../assets/images/Lockk.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      border-radius: 5px;
      display: flex;
      flex-direction: revert;
      justify-content: center;
      align-items: center;
    }
    &.winner:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-closed-exchange);
      background-image: url(../../../assets/images/TrophyTeenPattiTest.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 25px;
      border-radius: 5px;
      display: flex;
      flex-direction: revert;
      justify-content: center;
      align-items: center;
    }
  }

  &_footer {
    margin-top: 20px;
    .trio-table__row-margin {
      margin-bottom: 0;
    }
    .trio-table__row {
      justify-content: center;
      column-gap: 10px;
    }
  }
  &__item.trio-table__footer-item {
    width: calc((100% / 5) - 7px);
  }
  &__item-bet-cell.footer-bet {

    flex-direction: column;

    .trio-table__item-bet-cell-price {
      font-family: Poppins;
      font-weight: 700;
      font-size: 12px;
    }
  }
  &__item-bets-wrapper.footer-bets-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 60px;
    cursor: pointer;
    color: var(--color-secondary-exchange);
    .exposure-box-modern {
      bottom: 0;
      margin-top: 5px;
    }
  }
  &__item-bets-wrapper.footerSuspended-bets-wrapper {
    background:var(--background-player-exchange);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 60px;
    cursor: pointer;
    //color:rgba(255, 255, 255, 0.4);
    &::after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-closed-exchange);
      background-image: url("../../../assets/images/Lockk.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      border-radius: 5px;
      display: flex;
      flex-direction: revert;
      justify-content: center;
      align-items: center;
    }
    &.winner:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-closed-exchange);
      background-image: url(../../../assets/images/TrophyTeenPattiTest.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 25px;
      border-radius: 5px;
      display: flex;
      flex-direction: revert;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 768px) {
    div.trio-table {
    padding: 30px 10px;
      &__body {
        .trio-table__item-bet-cell {
          height: 40px;
          border-radius: 5px;
          width: calc((100% / 2) - 5px);

          span {
            color: var(--color-exchange);
            font-family: Poppins;
            font-weight: 700;
            font-size: 12px;
          }
        }
        .trio-table__item-bet-cell.bet-back {
          border: 2px solid var(--back-border-color-exchange);
        }
        .trio-table__item-bet-cell.backSuspended::after{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: var(--background-closed-exchange);
            background-image: url("../../../assets/images/Lockk.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px;
            border-radius: 5px;
            display: flex;
            flex-direction: revert;
            justify-content: center;
            align-items: center;
        }
        .trio-table__item-bet-cell.backSuspended.winner:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background-color: var(--background-closed-exchange);
          background-image: url(../../../assets/images/TrophyTeenPattiTest.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 25px;
          border-radius: 5px;
          display: flex;
          flex-direction: revert;
          justify-content: center;
          align-items: center;
        }

        .trio-table__item-bet-cell.bet-lay {
          border: 2px solid var(--lay-border-color-exchange);
        }
        .trio-table__item-bet-cell.laySuspended::after{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: var(--background-closed-exchange);
            background-image: url("../../../assets/images/Lockk.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px;
            border-radius: 5px;
            display: flex;
            flex-direction: revert;
            justify-content: center;
            align-items: center;
          }
        .trio-table__item-bet-cell.laySuspended.winner:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background-color: var(--background-closed-exchange);
          background-image: url(../../../assets/images/TrophyTeenPattiTest.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 25px;
          border-radius: 5px;
          display: flex;
          flex-direction: revert;
          justify-content: center;
          align-items: center;
        }
      }
    .trio-table__row-margin {
      margin: 0;
    }
    .trio-table__row.trio-table__body-row {
      flex-direction: column;
    }
    .trio-table__item {
      margin-bottom: 10px;
      font-size: 12px;
    }
    .trio-table__item.trio-table__body-item {
      width: 100%;
      display: flex;
      .trio-table__item-header {
        width: 45%;
        display: flex;
        align-items: center;
        padding: 0 8px;
        font-size: 10px;
        position: relative;
        justify-content: space-between;
      }
      .trio-table__item-header:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 2px;
        bottom: 2px;
        background: var(--background-secondary-player-exchange);
        border-radius: 5px 0px 0px 5px;
        z-index: 1;
      }
      .trio-table__item-bets-wrapper {
        width: 55%;
        background: transparent;
        padding: 0;
        position: relative;
      }
      .exposure-box-modern {
        position: absolute;
        bottom: unset;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        width: unset;
        font-size: 11px;
        z-index: 1;
        font-weight: 600;
        font-family: 'Poppins';
      }
        .exposure-modern{
          &--pos {
            color:var(--positive-secondary-exchange);
          }
          &--neg {
            color: var(--negative-secondary-exchange);
          }
      }
    }
    .trio-table__body .trio-table__row-margin:first-child .trio-table__body-row .trio-table__body-item {
      width: 100%;
    }
    .trio-table_footer {
      .trio-table__row.trio-table__footer-row {
        flex-wrap: wrap;
      }
      .trio-table__item.trio-table__footer-item {
        width: calc((100% / 3) - 7.5px);

        .exposure-box-modern {
          position: relative;
          bottom: unset;
          top: 0%;
          right: 50%;
          transform: translateX(50%);
          width: unset;
          font-size: 11px;
          font-weight: 600;
          height: 9px;
          font-family: 'Poppins';
        }
          .exposure-modern{
            &--pos {
              color:var(--positive-exchange);
            }
            &--neg {
              color: var(--negative-exchange);
            }
        }
      }
      .footer-bets-wrapper {
        height: 40px;
      }
      .footerSuspended-bets-wrapper {
        height: 40px;
      }
    }
  }
}

.trio-table__item-header__descr{
    display: block;
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
}
.trio-table__item-header__betname{
  font-weight: 400;
  font-size: 10px;
  color: var(--color-exchange);
  font-family: 'Poppins';
  position: relative;
  z-index: 2;
  left: 15px
}
.nonCollapsed{
  position: fixed;
  top: 182px;
  z-index: 15;
  width: 94%;
  transition: linear 0.2s;
  left: 3%;
  right: 3%;
}
.collapsed{
  position: fixed;
  top: 80px;
  z-index: 15;
  width: 94%;
  transition: linear 0.2s;
  left: 3%;
  right: 3%;
}

@media screen and (min-width: 1024px) {
  .trio-table{
    padding: 34px 0 30px 0;
  }
  .trio-table__row-margin.first-row {
    min-height: unset;
    .trio-table__row.trio-table__body-row {
      .trio-table__item.trio-table__body-item {
        width: calc((100% / 3) - 7px);
      }
      .trio-table__item-bets-wrapper {
        max-width: unset;
      }
    }
  }
  .trio-table__item-bets-wrapper{
    max-width: 177px;
    position: relative;
    .exposure{
      width: 100%;
    }
    .exposure-box-modern {
      position: relative;
      right: 0;
      left: unset;
      bottom: -4px;
      font-weight: 600;
      width: 100%;
      font-size: 12.5px;
      font-family: 'Poppins';
    }
      .exposure-modern{
        &--pos {
          color: var(--positive-exchange);
        }
        &--neg {
          color:var(--negative-exchange);
        }
    }
  }
  .trio-table__item-header__betname{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14.5117px;
    line-height: 22px;
    color: #263238;
    margin-left: -33px;
  }
  .long{
    font-size: 12.57px;
  }
  .trio-table__item-header__descr{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14.5117px;
    line-height: 22px;
    color: var(--color-title-exchange);
  }
  .trio-table_footer {
    .trio-table__item.trio-table__footer-item {

      .exposure-box-modern {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 4px;
        font-weight: 600;
        font-size: 12.5px;
        font-family: 'Poppins';
      }
        .exposure-modern{
          &--pos {
            color: var(--positive-exchange);
          }
          &--neg {
            color:var(--negative-exchange);
          }
      }
    }
  }
  .trio-table__item-bet-cell.footer-bet{
    .trio-table__item-bet-cell-price {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 14.51px;
    }
  }
  .trio-table_footer .trio-table__item.trio-table__footer-item {
    .exposure-box-modern {
      position: relative;
      right: 0;
      left: unset;
      bottom: -2px;
      font-weight: 600;
      width: 100%;
      font-size: 12.5px;
    }
  }
}
