
.rouletteLiveResult__winNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-size: 15px;
    text-align: center;
    color: var(--color-secondary-exchange);
  }
  &-center {
    width: 40px;
    height: 40px;
    margin: 10px 5px;
    border: 2px solid var(--color-secondary-exchange);
    border-radius: 1.35px;
    font-weight: 600;
  }
  &-left,
  &-right {
    font-weight: 400;
    border:  solid;
    border-image-slice: 1;
    border-width: 1.35px;
    width: 32px;
    height: 32px;
    border-radius: 1px;
  }
  &-left {
    border-image-source: linear-gradient(270deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 93.75%);
  }
  &-right {
    border-image-source: linear-gradient(90deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 95.31%);
  }
}

//@media (max-width: 1024px) {
  div.rouletteLiveResult__winNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: calc((-100vw / 16 * 9) / 2);
    transform: translate(-50%, -50%);
    &-item {
      font-size: 15px;
    }
    &-center,
    &-left,
    &-right {
      width: 43px;
      height: 43px;
      margin: 10px 5px;
      clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
      position: relative;
      background-color: #FFFFFF;
      &.red {
        &:after {
          content: "";
          width: 40px;
          height: 40px;
          background-color: #FF3131;
          clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
          position: absolute;
          z-index: -1;
        }
      }
      &.black {
        &:after {
          content: "";
          width: 40px;
          height: 40px;
          background-color: #000000;
          clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
          position: absolute;
          z-index: -1;
        }
      }
      &.zero {
        &:after {
          content: "";
          width: 40px;
          height: 40px;
          background-color: #32CDA8;
          clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
          position: absolute;
          z-index: -1;
        }
      }
    }
    &-left,
    &-right {
      width: 33px;
      height: 33px;
      border-radius: 1px;
      &.red,
      &.black,
      &.zero {
        &:after {
          width: 30px;
          height: 30px;
        }
      }
    }
    &-left {
      transform: rotate(-13deg) translateY(5px);
    }
    &-right {
      transform: rotate(16deg) translateY(6px);
    }
  }
@media (min-width: 1024px) {
  div.rouletteLiveResult__winNumber {
    top: -150px;
  }
}






