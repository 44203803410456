@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.casino-32-card {
  padding-bottom: 50px;
  .casino-32-card-table {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    color: var(--white);
    position: relative;
    &__players {
      display: grid;
      grid-template-rows: repeat(4, 1fr);
      grid-row-gap: 10px;
      padding: 33px 10px 30px 10px;
    }
    &__player {
      background: var(--background-player-exchange);
      height: 62px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 8px 30px;
      &-title {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 130px);
        padding-right: 30px;
      }
    }
    &__player-sells {
      display: flex;
      justify-content: space-between;
      width: 130px;
      &-item {
        width: 60px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        font-weight: 700;
        color: #263238;
        position: relative;
      }
      &-item.back {
        background: var(--back-color-exchange);
      }
      &-item.lay {
        background: var(--lay-color-exchange);
      }
    }
    .casino-32-card-table__player-sells-item.lock-ui {
      position: relative;
      //opacity: 0.7;
      pointer-events: none;
      &:hover {
        cursor: unset;
      }
      &:before {
        content: '';
        background-image: url('../../../assets/images/exchangeGames/lock-white.svg');
        background-repeat: no-repeat;
        background-position: center;
        //background-color: rgba(92, 92, 92, 0.6);
        background: var(--background-closed-exchange);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    }
    .casino-32-card-table__player.lock-ui.winner {
      &:before {
        content: '';
        background: url(../../../assets/images/TrophyTeenPattiTest.svg);
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: -70px;
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    }
    //.BetPlacing-container {
    //  position: absolute;
    //  width: calc(100% - 20px);
    //  margin: 0 auto;
    //  z-index: 9;
    //  top: 30px;
    //  right: 0;
    //  left: 0;
    //  bottom: 0;
    //}
    .casino-exposure-box-modern {
      font-size: 11px;
      position: unset;
      font-weight: 600;
      font-family: 'Poppins';
      line-height: 19px;
    }
    .casino-exposure-modern {
      &--pos {
        color: var(--positive-exchange);
      }
      &--neg {
        color: var(--negative-exchange);
      }
    }
  }
}
@media (max-width: 1024px) {
  .casino-32-card.virtual {
    margin-top: -10px;
    padding-top: 10px;
    min-height: calc(100% - 380px);
    background: linear-gradient(-181.68deg, #0B3114 50.02%, #40783B 100%);
    .casino-32-card-table__players {
      padding-top: 10px;
    }
  }
  .casino-32-card.virtual.fullHeight {
    min-height: 100%;
    padding-top: 20px;
    margin-top: unset;
  }
}
@media (min-width: 1024px) {
  .casino-32-card {
    padding: unset;
    .casino-32-card-table {
      font-weight: 500;
      font-size: 14px;
      &__players {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 20px;
        grid-column-gap: 18px;
        padding: 35px 0 30px 0;
      }
      &__player {
        height: 82px;
        border-radius: 10px;
        padding: 12px 20px;
        &-title {
          display: flex;
          padding: unset;
          width: auto;
          height: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      &__player-sells {
        display: flex;
        width: auto;
        &-item {
          width: 77px;
          height: 58px;
          font-weight: 600;
          color: #263238;
        }
        &-item.back {
          margin-right: 10px;
        }
      }
      .casino-exposure-box-modern {
        font-size: 12px;
      }
      .casino-exposure-modern {
        &--pos {
          padding-top: 5px;
        }
        &--neg {
          padding-top: 5px;
        }
      }
    }
  }
}
