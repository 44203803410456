.teenpatti-test-betting {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  margin-top: -10px;
  &__games {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
  }
  &__games-item {
    display: flex;
    flex-direction: column;
    min-width: max-content;
    margin-right: 55px;
  }
  &__games-item-title {
    padding-left: 7px;
    text-align: center;
  }
  &__games-item-cards {
    padding-top: 7px;
    img {
      width: 32px;
      height: 44px;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
      margin-left: 6px;
    }
  }
  .winnerCup {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 17px;
      right: -45px;
      background-image: url(../../../assets/images/TrophyTeenPattiTest.svg);
      background-position: center;
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-size: 25px;
    }
  }
  &__info-column {
    min-width: 160px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 40%;
      text-align: right;
      font-weight: 400;
      color: var(--background-secondary-player-exchange);
      white-space: nowrap;
    }
    &-results {
      width: 60%;
      font-weight: 500;
      color: var(--color-secondary-exchange);
      padding-left: 4px;
    }
  }
}

@media (max-width: 1024px) {
  .teenpatti-test-betting {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 5px;
    &__games {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-evenly;
      margin-top: -10px;
    }
    &__games-item {
      display: flex;
      flex-direction: column;
      min-width: max-content;
      margin-right: 10px;
      margin-top: 10px;
    }

    &__games-item-cards {
      display: flex;
      justify-content: center;

      img {
        width: 26px;
        height: 35px;
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
        margin-left: 6px;
      }
    }
    &__info-row {
      padding: 2px 0;
    }
    .winnerCup {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background-position: center;
        background-image: url(../../../assets/images/TrophyTeenPattiTest.svg);
        height: 50px;
        width: 50px;
        background-repeat: no-repeat;
        background-size: 35px;
        left: -50px;
        right: unset;
        top: 10px;
      }
    }
  }

  .teenpatti-test-betting__info-column {
    padding-top: 38px;
  }
  .teenpatti-test-betting__info-row-title {
    width: unset;
  }
}
