//
//.gameresult{
//    width: 100%;
//    height: 66px;
//    margin-top: -10px;
//    &-greenline{
//      height: 5px;
//      background: #00AF3C;
//    }
//    &-cardsplace{
//      height: 61px;
//      background: #1D1D1D;
//    }
//  }

  //.AmarAkbarAnthonyCardsWrapper{
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //}

.gameresult-cardsplace.cardsplace-amar-akbar {
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AmarAkbarAnthonyCardsContent-total__cards-card {
  width: 24px;
  height: 33px;
}


  
  //.AmarAkbarAnthonyCardsContent{
  //  padding-top: 11px;
  //  display: flex;
  //  justify-content: space-between;
  //  align-items: center;
  //  height: 100%;
  //
  //  &-info{
  //    display: flex;
  //  }
  //  &-name{
  //    font-family: 'Poppins';
  //    font-style: normal;
  //    font-weight: 500;
  //    font-size: 12px;
  //    line-height: 18px;
  //    color: #FFFFFF;
  //    margin: 0 15px;
  //    width: 100%;
  //  }
  //  &-total{
  //    height: 100%;
  //    width: 100%;
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //    &__name{
  //      font-family: 'Poppins';
  //      font-style: normal;
  //      font-weight: 500;
  //      font-size: 12px;
  //      line-height: 18px;
  //      color: #FFFFFF;
  //    }
      //&__cards{
      //  height: 33px;
      //  width: 70px;
      //  display: flex;
      //  justify-content: center;
      //  &-card{
      //    display: inline-block;
      //    height: 100%;
      //    width: 23.5px;
      //    margin-right: 3px;
      //  }
      //}
  //  }
  //}
  //.hidden-card{
  //  display: none;
  //}
