.andar-bahar-last-result {
  margin-top: -40px;
  padding: 30px 24px 35px 24px;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 15px;
  &__player {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 12px;
    color: #101010;
    &-title {
      margin-bottom: 7px;
      position: relative;
    }
    &-cards {
      display: flex;
      flex-wrap: wrap;
      &-card{
        display: block;
        width: 23px;
        height: 33px;
        box-shadow: 0 0 5px grey;
        margin-top: 5px;
      }
      &-card:not(:last-child){
        margin-right: 4px;
      }
    }
  }

  .andar-bahar-last-result__player-title.isWinner:after{
    content: '';
    position: absolute;
    background-image: url('../../../../../assets/images/winnerTropy.svg');
    background-position: center;
    background-size: cover;
    left: 70px;
    top: -5px;
    z-index: 1;
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 1024px) {
  .andar-bahar-last-result {
    grid-row-gap: 20px;
    padding: 50px 24px 35px 24px;
    &__player {
      font-family: 'Roboto';

      &-cards {
        &-card{
          display: block;
          width: 32px;
          height: 46px;
        }
        &-card:not(:last-child){
          margin-right: 4px;
        }
      }
    }
  }
}
