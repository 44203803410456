@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.PokerWrapper{
  // background: #fff;
  padding: 33px 11px 30px 10px;
}

.Poker-table{
  display: flex;
  justify-content: space-between;
  &-box{
    width: 127px;
    height: 100%;
    background: var(--background-player-exchange);
    border-radius: 5px;
    padding: 14px 10px 12px 10px;
    &__playerInfo{
      display: flex;
      padding: 0 5px 0 4px;
      justify-content: space-between;
      &-title{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--white);
      }
      &-exp{
        line-height: 18px;
        position: relative;
      }
    }
    &__odds{
      display: flex;
      width: 100%;
      height: 35px;
      justify-content: space-between;
      margin-top: 13px;
      &-back {
        width: 48%;
        height: 100%;
        //background: var(--back-color-exchange);
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &-price{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          color: #263238;
        }
      }
      //.backClose{
      //  //background: rgba(167, 216, 253, 0.5);
      //  //border-radius: 3px;
      //  //height: 100%;
      //  //width: 48%;
      //  &::after{
      //      content: url("../../../assets/images/tptLock.svg");
      //      display: block;
      //      position: absolute;
      //      left: 0;
      //      top: 0;
      //      height: 100%;
      //      width: 100%;
      //      border-radius: 3px;
      //      display: flex;
      //      flex-direction: revert;
      //      justify-content: center;
      //      align-items: center;
      //  }
      //}
      &-back,
      &-back.backClose {
        background: var(--back-color-exchange);
      }
      .backClose{
        &::after{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background-color: var(--background-closed-exchange);
          //background-image: url("../../../assets/images/Lockk.svg");
          background-image: url("../../../assets/images/tptLock.svg");
          background-repeat: no-repeat;
          background-position: center;
          //background-size: 20px;
          border-radius: 3px;
          //display: flex;
          //flex-direction: revert;
          //justify-content: center;
          //align-items: center;
        }
      }


      &-lay{
        width: 48%;
        height: 100%;
        //background: var(--lay-color-exchange);
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &-price{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          color: #263238;
        }
      }
      //.layClose{
      //  background: rgba(255, 187, 203, 0.5);
      //  border-radius: 3px;
      //  height: 100%;
      //  width: 48%;
      //  &::after{
      //      content: url("../../../assets/images/tptLock.svg");
      //      display: block;
      //      position: absolute;
      //      left: 0;
      //      top: 0;
      //      height: 100%;
      //      width: 100%;
      //      border-radius: 3px;
      //      display: flex;
      //      flex-direction: revert;
      //      justify-content: center;
      //      align-items: center;
      //  }
      //}
      &-lay,
      &-lay.layClose {
        background: var(--lay-color-exchange);
      }
      .layClose {
        &::after{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background-color: var(--background-closed-exchange);
          background-image: url("../../../assets/images/tptLock.svg");
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 3px;
        }
      }
    }
  }
  &-boxInfo{
    background: var(--background-player-exchange);
    border-radius: 5px;
    padding: 10px 11px;
    height: 141px;
    width: 81px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__playerInfo{
      &-title{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: var(--white);
        margin-bottom: 4px;
      }
      &-cards{
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.pokerPlayerCards{
  height: 33px;
  width: 24px;
}

.exposure-box-poker{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  margin-top: 1px;
}

.exposure-poker{
  &--pos{
    color: var(--positive-exchange);
  }
  &--neg{
    color: var(--negative-exchange);
  }
}

.pokerTrophy{
  &::after{
      content: url("../../../assets/images/TrophyTeenPattiTest.svg");
      width: 25px;
      height: 25px;
      position: absolute;
      right: 0;
      top: -4px;
  }
}

@media (max-width: 1024px) {
  .PokerWrapper.virtual {
    margin-top: -10px;
    padding-top: 10px;
    min-height: calc(100% - 380px);
    background: linear-gradient(-181.68deg, #0B312F 50.02%, #3B7874 100%);
  }
  .PokerWrapper.virtual.fullHeight {
    padding-top: 30px;
    min-height: 100%;
  }
}

@media (min-width: 1024px) {
  .PokerWrapper{
    padding: 34px 0 30px 0;
  }
  .Poker-table{
    display: grid;
    grid-template-columns: 2.55fr 2.12fr 2.55fr;
    grid-column-gap: 10px;
    &-box{
      width: 100%;
      height: 81px;
      background: var(--background-player-exchange);
      border-radius: 10px;
      padding: 15px 19px 15px 19px;
      display: flex;
      &__playerInfo{
        width: 100%;
        display: flex;
        padding: unset;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        &-title{
          font-size: 14px;
        }
        &-exp{
          line-height: 18px;
          position: relative;
        }
      }
      &__odds {
        display: flex;
        width: 126px;
        height: 100%;
        justify-content: space-between;
        margin-top: unset;
        &-back {
          width: 58px;
          &-price{
            font-weight: 600;
            font-size: 14px;
            color: #263238;
          }
        }
        .backClose{
          width: 58px;
        }
        &-lay{
          width: 58px;
          margin-left: 10px;
          &-price{
            font-weight: 600;
            font-size: 14px;
            color: #263238;
          }
        }
        .layClose{
          width: 58px;
        }
      }
    }
    &-boxInfo{
      padding: 22px 15px 21px 15px;
      height: 113px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      border-radius: 10px;
      &__playerInfo{
        width: 74px;
        &-title{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: var(--white);
          margin-bottom: 5px;
        }
        &-cards{
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .pokerPlayerCards{
    height: 42px;
    width: 31px;
  }

  .exposure-box-poker{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    margin-top: 1px;
    padding-left: 15px;
  }

  .exposure-poker{
    &--pos{
      color: var(--positive-exchange);
    }
    &--neg{
      color: var(--negative-exchange);
    }
  }

  .Poker-table-box__playerInfo-exp.pokerTrophy {
    height: 25px;
    width: 40px;
    .exposure-box-poker {
     opacity: 0;
    }
  }

  .pokerTrophy {
    &::after{
      content: url("../../../assets/images/TrophyTeenPattiTest.svg");
      width: 25px;
      height: 25px;
      position: absolute;
      right: 0;
      top: 2px;
    }
  }
}


