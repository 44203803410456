.editLastResultPopUp {
  .LastResultPopUp_wrapper {
    //margin-bottom: 50px;
    //position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
    overflow: auto;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-top: 40px;

    .pop-upLastResult {
      //width: 94%;
      width: 100%;
      max-width: 625px;
      background: #ffffff;
      border-radius: 10px;
      //padding: 8px;
      padding: 10px 25px;
      //position: absolute;
      //top: 135px;
      //left: 5%;

      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // top: 40px;
    }

    .pop-upLastResult.Roulette {
      top: 0;
    }

    .pop-upLastResult-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__header {
        padding-top: 10px;

        &__title {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          color: #101010;
        }

        &__title.virtual {
          font-size: 20px;
        }

        &__close {
          position: absolute;
          top: 20px;
          right: 15px;
          cursor: pointer;
        }

        &__gameId {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #101010;
          display: flex;
          justify-content: center;
        }

        &__matchTime {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #000000;
          margin-top: 4px;
          text-align: center;

          &__date {
            font-weight: 500;
          }
        }
      }

      &__results {
        padding-top: 17px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    }
  }

  .pop-upLastResult-content__gameId {
    font-weight: 500;
  }
}

@media (max-width: 1024px) {
  .editLastResultPopUp {
    .LastResultPopUp_wrapper {
      .pop-upLastResult {
        max-width: 94%;
        padding: 8px;
      }

      .pop-upLastResult-content__results {
        padding-top: 40px;
      }
    }
  }
}