@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.hi-low {
  .hi-low-table {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    color: var(--color-secondary-exchange);
    position: relative;
    &__players-wrapper {
      display: grid;
      grid-template-rows: repeat(3, auto);
      grid-row-gap: 15px;
      padding: 33px 13px 0 13px;
    }
    &__player {
      background: var(--background-player-exchange);
      height: 83px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      padding: 10px;
      &-title {
        white-space: nowrap;
      }
      &-price {
        font-weight: 700;
        text-align: center;
        line-height: 1;
      }
      &-price-text {

      }
      .hi-low-table__player-images {
        img {
          height: 100%;
        }
      }
    }
    &__players-group-first {
      display: grid;
      grid-template-columns: repeat(3, 0.83fr );
      grid-column-gap: 10px;
      .hi-low-table__player {
        justify-content: unset;
        padding: 20px;
        &-price {
          padding-top: 11px;
        }
        .hi-low-exposure-box-modern {
          position: absolute;
          left: 50%;
          bottom: 5px;
          transform: translateX(-50%);
        }
      }
    }

    &__players-group-second {
      display: grid;
      grid-template-columns: repeat(2, 1.5fr );
      grid-column-gap: 10px;
      padding: 0 20px;
      .hi-low-table__player {
        height: 80px;
        justify-content: unset;
        padding: 15px 0;
        &-price {
          padding-top: 7px;
        }
        .hi-low-exposure-box-modern {
          position: absolute;
          left: 50%;
          bottom: 5px;
          transform: translateX(-50%);
        }
        &-images {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 24px;
          }
          img:last-child {
            height: 26px;
            margin-left: 10px;
          }
        }
        .hi-low-exposure-box-modern {
          padding-top: 7px;
        }
      }
    }

    &__players-group-cards {
      display: grid;
      grid-template-rows: repeat(3, auto );
      grid-row-gap: 15px;
      padding: 0 20px;
      .hi-low-table__player {
        height: 116px;
        padding: 15px 0 10px 0;
        justify-content: space-between;
        &-images {
          height: 63px;
          img {
            width: 49px;
            height: 63px;
          }
          img:not(:last-child) {
            margin-right: 20px;
          }
        }
        &-price {
          display: flex;
          .hi-low-exposure-box-modern {
            padding-left: 48px;
            padding-top: 1px;
          }
        }
      }
    }
    .hi-low-table__player.lock-ui {
      position: relative;
      pointer-events: none;
      &:hover {
        cursor: unset;
      }
      &:before {
        content: '';
        background-image: url('../../../assets/images/exchangeGames/lock-white.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-color: var(--background-closed-exchange);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        z-index: 1;
      }
      &.winner:before {
        content: '';
        background: url(../../../assets/images/TrophyTeenPattiTest.svg);
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 1;
      }
    }
    .hi-low-table__players-group-first,
    .hi-low-table__players-group-second {
      .hi-low-table__player.lock-ui {
        &:before {
          background-position: 50% 65%;
        }
        &.winner:before {
          bottom: -10px;
        }
      }
    }

    &__players-group-cards {
      .hi-low-table__player.lock-ui.winner:before {
        bottom: -40px;
      }
    }
    .hi-low-table__player.winner {
      .hi-low-table__player-price {
        opacity: 0;
      }
    }
    .hi-low-exposure-box-modern {
      font-size: 11px;
      position: unset;
      font-weight: 600;
      font-family: 'Poppins';
    }
    .hi-low-exposure-modern {
      &--pos {
        color: var(--positive-exchange);
      }
      &--neg {
        color: var(--negative-exchange);
      }
    }
  }
}
@media (max-width: 1024px) {
  .hi-low.virtual {
    margin-top: -10px;
    padding-top: 10px;
    background: linear-gradient(-181.68deg, #655E51 50.02%, #D1C085 100%);
    .hi-low-table__players-wrapper {
      padding-top: 0;
    }
  }
  .hi-low.virtual.fullHeight {
    min-height: 100%;
    margin-top: unset;
  }
}
@media (min-width: 1024px) {
  .hi-low {
    .hi-low-table {
      font-size: 15px;
      &__players-wrapper {
        display: grid;
        grid-template-rows: repeat(2, auto);
        grid-template-columns: 5.27fr 2.03fr;
        grid-row-gap: 19px;
        padding: 33px 0;
      }
      &__player {
        height: 80px;
        border-radius: 10px;
        padding: 10px;
        &-price {
          font-weight: 600;
          font-size: 15px;
          text-align: center;
          line-height: 1;
        }
      }
      &__players-group-first {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 10px;
        margin-right: 14px;
        .hi-low-table__player {
          justify-content: space-between;
          flex-direction: row;
          padding: 0 20px 0 18px;
          &-price {
            padding-top: unset;
          }
          .hi-low-exposure-box-modern {
            position: unset;
            transform: translateY(50%);
          }
        }
      }
      &__players-group-second {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        padding: unset;
        .hi-low-table__player {
          justify-content: center;
          padding: 10px 0 5px 0;
          &-price {
            padding-top: 7px;
          }
          .hi-low-exposure-box-modern {
            position: unset;
            font-size: 11px;
            transform: translateY(3%);
          }
          &-images {
            img {
              height: 23px;
            }
            img:last-child {
              height: 24px;
              margin-left: 10px;
            }
          }
        }
      }

      &__players-group-cards {
        display: grid;
        grid-template-rows: unset;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: unset;
        grid-column-gap: 11px;
        padding: unset;
        grid-column: 1 / 4;
        .hi-low-table__player {
          height: 100px;
          padding: 10px;
          justify-content: space-between;
          &-images {
            height: 60px;
            img {
              width: 47px;
              height: 60px;
            }
            img:not(:last-child) {
              margin-right: 10px;
            }
          }
          &-price {
            display: flex;
            transform: translateY(25%);
            .hi-low-exposure-box-modern {
              padding-left: 48px;
              padding-top: 1px;
            }
          }
        }
      }
      .hi-low-table__player.lock-ui {
        position: relative;
        pointer-events: none;
        &:hover {
          cursor: unset;
        }
        &:before {
          content: '';
          background-image: url('../../../assets/images/exchangeGames/lock-white.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-color: var(--background-closed-exchange);
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 5px;
          z-index: 1;
        }
        &.winner:before {
          content: '';
          background: url(../../../assets/images/TrophyTeenPattiTest.svg);
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          bottom: -20px;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 1;
          z-index: 1;
        }
      }
      .hi-low-table__players-group-second {
        .hi-low-table__player.lock-ui {
          &:before {
            background-position: center;
          }
          &.winner:before {
            bottom: -18px;
          }
        }
      }
      .hi-low-table__players-group-first {
        .hi-low-table__player.lock-ui {
          &:before {
            background-position: center;
          }
          &.winner:before {
            bottom: -1px;
            left: 34%;
          }
        }
      }

      &__players-group-cards {
        .hi-low-table__player.lock-ui.winner:before {
          bottom: -35px;
        }
      }
      .hi-low-exposure-box-modern {
        font-size: 13px;
      }
    }
  }
}
@media (min-width: 1024px) and  (max-width: 1270px) {
  .hi-low-table__players-group-cards {
    .hi-low-table__player-images {
        img {
          width: 35px !important;
          height: 50px;
        }
    }
  }
}
