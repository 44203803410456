@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.ChangePassword {
  --padding-top: 15px;
  --padding-start: 15px;
  --padding-end: 15px;

  &__form {
    width: 100%;
    margin-top: 10px;
  }

  &__list {
    background: none !important;

    ion-item {
      --min-height: 36px;
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --inner-border-width: 0px;

      // ion-input.error {
      //   border: 1px solid var(--ion-color-danger);
      // }
    }

    ion-item + ion-item {
      margin-top: 12px;
    }
  }

  &__button {
    width: 50%;
    margin: 20px 0 auto auto;
    text-transform: none;
    background: var(--payment-primary-color);
    color: #fff;
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    height: 36px;
    font-weight: 500;
    margin-top: 24px;

    &:hover {
      background: var(--cta-primary-darken);
    }

    &:disabled {
      background: var(--cta-disabled);
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__form-error {
    padding: 0 10px 5px;
    margin-top: -2px;
    font-size: 10px;
    color: var(--negative);

    ion-item + & {
      padding: 0 10px 10px;
      margin-top: 9px;
    }
  }

  ion-item {
    --highlight-background: var(--login-highlight-bg);
    --border-radius: 2px;
  }

  ion-input {
    border-radius: 2px;
    height: 100%;
  }

  .native-input {
    border-radius: 2px;
    height: 100%;

    &:focus {
      border: 1px solid var(--login-item-focused-border);
    }
  }
}

.detailed-transaction-menu {
  .transactions-menu {
    position: sticky;
    z-index: 10;
    width: 100%;
    height: 39px;

    padding: 8px 15px;

    background: var(--primary);

    .deposite,
    .withdrawal {
      position: relative;

      height: 100%;
      width: 100%;
      color: #7fa2ba;

      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;

      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;

      &.active {
        color: #fff;
      }
      &.active:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        width: 100%;
        height: 3px;
        background: var(--payment-primary-color);
      }

      &.hideTab {
        display: none;
      }
    }

    .deposite {
      display: inline-block;
      // width: 45px;
      width: auto;
      margin-right: 20px;
    }

    .withdrawal {
      display: inline-block;
      // width: 63px;
      width: auto;
      // color: #7fa2ba;
    }
  }
}

// .transactions-container {
//   margin: 0;
//   padding: 0;
// }

.detailed-transaction-container {
  position: relative;
  width: 100%;
  // height: 100%;

  padding: 16px 24px;
  margin-bottom: 22px;

  background-color: #fff;

  .transaction {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;

    .transaction-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      height: 54px;

      // padding: 16px 24px;
    }

    .transaction_number {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-size: 15px;
      line-height: 22px;

      color: #40424f;

      &__symbol {
        font-weight: 400;

        margin-right: 10px;
      }

      &__number {
        font-weight: 600;
      }
    }

    &_amount {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 27px;
      line-height: 36px;

      color: #40424f;

      .transaction_amount-symbol {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #40424f;
      }
    }

    // .transaction_paymentSystem {
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   align-items: center;
    //   img {
    //     width: 50px;
    //     height: 16px;
    //   }
    // }
  }

  .transaction-status {
    width: 81px;
    height: 22px;

    // margin-left: auto;
    // margin-right: auto;
    // margin-bottom: 9px;
    text-align: center;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;

    border-radius: 2px;
    color: #ffffff;

    &.approved {
      background: #86aa16;
    }

    &.pending {
      background: #e1d800;
    }

    &.rejected {
      background: #ef4848;
    }
  }

  .transaction-date {
    // width: 130px;
    // margin-left: auto;
    // margin-right: auto;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;

    &_date {
      color: #40424f;
      margin-right: 5px;
      margin-left: auto;
    }

    // &_time {
    //   color: gray;
    // }
  }

  // .refreshIcon {
  //   position: absolute;
  //   bottom: 6px;
  //   right: 15px;

  //   width: 14px;
  //   height: 14px;
  // }
}
.account-form.detailedTransaction-container{
  background-color: transparent;
  padding: 0px;
  padding-top: 5px;
}
.reason-container {
  display: flex;
  width: 100%;

  padding: 0px 24px;
  margin-bottom: 22px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  text-align: center;

  color: #000000;

  &_title {
    margin-right: 12px;
  }
}

.detailed-content {
  &__buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    padding: 0 10px;

    &-back {
      width: 94px;
      height: 37px;
      // left: 92px;
      // top: 438px;
      background: var(--payment-primary-color);
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      margin: 0 2px;
      padding-top: 7px;

      border-radius: 3px;
      outline-offset: 4px;

      // &:active {
      //   transform: translateY(2px);
      // }
    }

    &-edit {
      width: 94px;
      height: 37px;
      // left: 92px;
      // top: 438px;
      background: #40424f;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      margin: 0 2px;
      padding-top: 7px;

      border-radius: 3px;
    }


    &-cancel {
      // width: 94px;
      // height: 37px;

      // font-family: 'Lato';
      // font-style: normal;
      // font-weight: 400;
      // font-size: 14px;
      // line-height: 21px;
      // text-align: center;
      // color: #969696;
      // border: 1px solid #969696;
      // border-radius: 3px;
      // margin: 0 2px;
      // padding-top: 7px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #0F23271A;
      border-radius: 50px;
      opacity: 1;
      width: 113px;
      height: 41px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 20px;
      &__text{
      font-size: 14px;
        font-family: 'Roboto';
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .detailedTransaction-container {
    position: relative;
    width: 90%;
    margin-bottom: 22px;
    background-color: #fff;
    padding: 25px 0 20px 25px;
    margin: 0 auto;
    border-radius: 6px;


    .signUpNew_whattsUp_button {
      margin-top: 0 !important;
      margin-bottom: 10px !important;
      background-color: var(--header-background) !important;
    }

    .signUpNew_whattsUp_button-icon {

      &::after {
        background-color: white !important;
      }
    }

    .signUpNew_whattsUp_button-number{
      color: white !important;
    }

    .whatsapp-care-button{
      position: relative;
      height: 50px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      width: 15em;
      background: url("../../assets/images/socials/whatsappSupport.svg");
      background-repeat: no-repeat;
    }

    &-info{
      margin-bottom: 20px;
      &-title{
        text-align: left;
        font-family: 'Roboto';
        font-size: 14px;
        letter-spacing: 0px;
        color: #0F2327;
        opacity: 0.6;
        margin-bottom: 3px;
      }
      &-descr{
        text-align: left;
        font-family: 'Roboto';
        font-size: 18px;
        letter-spacing: 0px;
        color: #0F2327;
        opacity: 1;
      }
    }
  }
}
