@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.andar-bahar {
  position: relative;
  .andar-bahar-table {
    &__body {
      margin-top: 32px;
      padding: 0 10px;
      &-players {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      &-players-header {
        display: flex;
        font-family: Poppins;
        font-weight: 500;
        font-size: 14px;
        color: var(--dark-grey);
        padding-bottom: 3px;
      }
      &-player-row {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        width: 100%;
        grid-row-gap: 10px;
      }
      &-player-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        background-color: var(--background-player-exchange);
        position: relative;
        height: 62px;
        padding: 0 30px;
      }
      &-player-info-text {
        font-family: Poppins;
        font-weight: 500;
        font-size: 12px;
        color: var(--white);
        &.bold {
          font-weight: 700;
        }
      }
      &-player-title {
        display: flex;
        justify-content: space-between;
        width: 50%;
        .andar-bahar-exposure-box-modern {
          transform: translateX(50%);
        }
      }
      &-player-wrapper.lock-ui {
        position: relative;
        pointer-events: none;
        &:hover {
          cursor: unset;
        }
        &:before {
          content: '';
          background: rgba(0, 0, 0, 0.6) url('../../../assets/images/exchangeGames/lock-white.svg') no-repeat center;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 5px;
          z-index: 1;
        }
        &.winner:before {
          content: '';
          background: url(../../../assets/images/TrophyTeenPattiTest.svg);
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          bottom: -2px;
          left: calc((100% - 80px) /2);
          width: 100%;
          height: 100%;
          opacity: 1;
        }
      }
      &-player-wrapper.lock-ui.winner {
        .andar-bahar-table__body-player-price {
          opacity: 0;
        }
      }
    }
  }
  //.BetPlacing-container {
  //  position: absolute;
  //  z-index: 9;
  //  top: -7px;
  //  right: 0;
  //  left: 0;
  //  bottom: 0;
  //  display: flex;
  //  justify-content: center;
  //  align-items: flex-start;
  //  overflow: visible;
  //}

  @media (min-width: 1024px) {
    .andar-bahar-table {
      &__body {
        margin-top: 32px;
        padding: unset;
        margin-bottom: 50px;
        &-players-header {
          font-size: 15px;
          padding-bottom: 5px;
        }
        &-player-row {
          display: grid;
          grid-template-rows: unset;
          grid-template-columns: repeat(2, 1fr);
          grid-row-gap: unset;
          grid-column-gap: 18px;
        }
        &-player-wrapper {
          height: 81px;
          padding: 0 30px;
          border-radius: 10px;
        }
        &-player-info-text {
          font-size: 15px;
          &.bold {
            font-weight: 500;
          }
        }
        &-player-title {
          display: unset;
          width: max-content;
          text-align: center;
          .andar-bahar-exposure-box-modern {
            transform: unset;
            margin-top: 3px;
          }
        }
      }
    }
  }

  .andar-bahar-exposure-box-modern {
    font-size: 12px;
    position: unset;
    font-weight: 600;
    font-family: 'Poppins';
  }
  .andar-bahar-exposure-modern{
    &--pos {
      color: var(--positive-exchange);
    }
    &--neg {
      color: var(--negative-exchange);
    }
  }
}
@media (max-width: 1024px) {
  .andar-bahar.virtual {
    margin-top: -10px;
    padding-top: 10px;
    min-height: calc(100% - 380px);
    background: linear-gradient(-181.68deg, #0B0F31 50.02%, #345493 100%);
    .andar-bahar-table__body-players-header {
      color: var(--white);
    }
    .andar-bahar-table__body {
      margin-top: 0;
    }
  }
  .andar-bahar.virtual.fullHeight {
    min-height: 100%;
    padding-top: 20px;
    margin-top: unset;
  }
}



