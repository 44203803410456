  .trioCardsContent{
    // padding: 5px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    &-total{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &__name{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--color-secondary-exchange);
      }
      &__cards{
        height: 33px;
        width: 80px;
        display: flex;
        justify-content: space-between;
        &-card{
          display: inline-block;
          height: 100%;
          width: 100%;
          margin-right: 3px;
        }
      }
    }
  }

  //@media screen and (min-width: 768px) {
  //  .trioCardsContent{
  //    padding: 0px 56px;
  //    &-total{
  //      width: 90px;
  //      &__name{
  //        font-size: 15px;
  //        line-height: 22px;
  //      }
  //      &__card{
  //        font-size: 25px;
  //      }
  //    }
  //  }
  //  .trioCardsContent-total__cards{
  //    height: 40px;
  //    width: 58px;
  //    padding: 0 0 3px 0;
  //  }
  //}
  //
  //@media (max-width: 1024px) {
  //  div.gameresult {
  //    .gameresult-cardsplace.cardsplace-roulette {
  //      height: 60px;
  //    }
  //  }
  //}

//@media (min-width: 1024px) {
//  .gameresult.gameresult-trio {
//    height: unset;
//    .gameresult-cardsplace {
//      height: 82px;
//    }
//  }
//  .trioCardsContent {
//    justify-content: center;
//  }
//  .trioCardsContent-total {
//    width: 100%;
//    height: 100%;
//  }
//  .trioCardsContent-total__cards {
//    width: unset;
//    height: unset;
//  }
//  .trioCardsContent-total__cards-card {
//    display: inline-block;
//    height: 62px;
//    width: 46px;
//    margin-right: 10px;
//  }
//}
