.pop-upLastResult-content-queen__results{
    margin-top: 15px;
  &-name{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: var(--color-exchange);
  }
  &-cards{
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
    position: relative;
    //margin-top: 15px;
    //margin-bottom: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    &-isWinner{
      display: flex;
      height: 44px;
      justify-content: center;
      align-items: center;
      //margin-top: 15px;
      //margin-bottom: 30px;
      margin-top: 10px;
      margin-bottom: 20px;
      position: relative;
      &::before{
        content: url('../../../../../assets/images/winnerTropy.svg');
        position: absolute;
        left: -55px;
        top: -3px
      }
    }

    &-card {
      display: block;
      width: 32px;
      height: 44px;
      box-shadow: 0px 0px 5px grey;
      margin: 0 7.5px;
    }
    &-card-score {
      position: absolute;
      right: -25px;
      color: #BB8533;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

@media (min-width: 1024px) {
  .pop-upLastResult-content-queen__results {
    display: flex;
    margin-top: 10px;
    margin-bottom: 35px;
    &-item {
      margin: 0 13.5px;
    }
    &-name{
      font-size: 12px;
    }
    &-cards{
      &-isWinner{
        &::before{
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          background-image: url('../../../../../assets/images/winnerTropy.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          left: 50%;
          transform: translateX(-50%);
          top: 50px
        }
      }
      &-card {
        margin: 0 2.5px;
      }
      &-card-score {
        display: none;
      }
    }
  }
}
