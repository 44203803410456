.pop-upLastResult-content__results{
  align-items: baseline;

  &-name{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: var(--color-exchange);
  }
  &-cards{
    display: flex;
    width: 80px;
    height: 44px;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
    &-isWinner{
      display: flex;
      width: 80px;
      height: 44px;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 20px;
      position: relative;
      &::before{
        display: none;
      }
    }
    &-card{
      display: block;
      width: 32px;
      height: 44px;
      box-shadow: 0px 0px 5px grey;
    }
    &-card:not(:last-child) {
      margin-right: 15px;
    }
  }
  .pop-upLastResult-content__results-CasinoMeter-item {
    position: relative;
    width: 100%;
    margin-bottom: 35px;

    .pop-upLastResult-content__results-name {
      padding-right: 15px;
    }
    .pop-upLastResult-content__results-cards {
      margin: 0;
      justify-content: unset;
      flex-wrap: wrap;
      img {
        margin-bottom: 5px;
      }
    }
  }
}
.pop-upLastResult-content__results-CasinoMeter-item {
  .pop-upLastResult-content__results-cards-isWinner {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    width: unset;
    align-items: center;
  }
  .pop-upLastResult-content__results-cards {
    width: unset;
    align-items: center;
  }

  .pop-upLastResult-content__results-name  {
    margin-bottom: 15px;
    text-align: left;
    font-size: 12px;
    line-height: 14px;
  }
}

.winner-CM::before {
  width: 20px;
  height: 20px;
  transform: scale(.5);
  top: 0!important;
  left: 80px!important;
  right: 0!important;
  bottom: 0;
}

.line {
  width: 1px;
  margin-right: 12px;
  margin-left: 30px;
}

.casino-meter {
  &__index-cards {
    background-color: var(--background-player-exchange);
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px;
    img {
      height: 32px;
      width: 25px;
    }
  }
}

.casino-meter__score-wrapper {
  // display: flex;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 12px;
  line-height: 14px;

  color: var(--color-exchange);
  &-score {
    font-weight: 500;
    margin-bottom: 4px;
  }
  &-pos {
    font-weight: 300;
  }
}
.casino-meter-LR-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

@media (max-width: 1023px) {

  .cm-card-wrapper {
    flex-wrap: wrap;
  }
  .casino-meter-LR-wrapper {
    padding: 0 0 30px 0;
  }
  .casino-meter-LR {
      display: block;
      padding-left: 10px;
   .pop-upLastResult-content__results-cards {
      flex-wrap: wrap;
      height: auto;
   }
   .pop-upLastResult-content__results-CasinoMeter-item {
      position: relative;
      margin-bottom: 0;
      transform: unset;
      .pop-upLastResult-content__results-cards-card {
        margin-bottom: 5px;
      }
      .pop-upLastResult-content__results-name {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
  .casino-meter__score-wrapper {
    margin-bottom: 20px;
  }
}
