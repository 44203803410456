//.gameresult{
//  width: 100%;
//  height: 66px;
//  margin-top: -10px;
//  &-greenline{
//    height: 5px;
//    background: #00AF3C;
//  }
//  &-cardsplace{
//    height: 61px;
//    background: #1D1D1D;
//  }
//  .gameresult-cardsplace.cardsplace-roulette {
//    height: 84px;
//  }
//}

.seven-up-down-cards {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  &__player {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 45px;
    &-title {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 12px;
      color: var(--white);
      white-space: nowrap;
      padding-right: 10px;
    }
    &-card {
      height: 33px;
      width: 24px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

//@media (min-width: 1024px) {
//  .seven-up-down-cards {
//    &__player {
//      &-card {
//        height: 61px;
//        width: 45px;
//        padding: 5px;
//      }
//    }
//  }
//}
