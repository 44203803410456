
.poker20CardsContent {
  height: 100%;
  width: 100%;
  max-width: 570px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-player-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 200px;
  }
  &-total__cards-card {
    width: 24px;
    height: 33px;
    margin-right: 5px;
  }
  .poker20CardsContent-total__name {
    font-family: 'Poppins';
   font-weight: 500;
    font-size: 12px;
    color: var(--color-secondary-exchange);
  }
}

@media (max-width: 1024px) {
  div.poker20CardsContent {
    img.poker20CardsContent-total__cards-card {
      width: 24px;
      height: 33px;
      margin-right: 5px;
    }
    .poker20CardsContent-total__name {
      font-size: 12px;
    }
    &-player-wrapper {
      max-width: 135px;
    }
  }
}
//@media (min-width: 1024px) {
//  .gameresult.gameresult-poker20 {
//    height: unset;
//    .gameresult-cardsplace {
//      height: 82px !important;
//    }
//  }
//}
