.trio-betting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  margin-bottom: 20px;
  &__games-item {
    padding: 20px 50px 0 10px;
    display: flex;
    flex-direction: column;
    min-width: max-content;
  }

  &__games-item-cards {
    display: flex;

    img {
      width: 32px;
      height: 44px;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
      margin-left: 6px;
    }
  }

  &__info-column {
    min-width: 210px;
    padding-top: 10px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 55%;
      text-align: right;
      font-weight: 400;
      color: var(--background-secondary-player-exchange);
      white-space: nowrap;
    }
    &-results {
      width: 45%;
      font-weight: 500;
      color: var(--color-secondary-exchange);
      padding-left: 4px;
    }
  }
}


@media (max-width: 1024px) {
  .trio-betting {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 5px;
    &__games-item {
      padding: 20px 0 0 0;
      display: flex;
      flex-direction: column;
      min-width: max-content;
    }

    &__games-item-cards {
      display: flex;
      justify-content: center;

      img {
        width: 26px;
        height: 35px;
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
        margin-left: 6px;
      }
    }
  }

  .trio-betting__info-column {
      padding-top: 30px;
    }
  .trio-betting__info-row-title {
    width: unset;
  }
}
