.OpenBetsList {
  margin-bottom: 20px;
  font-size: 14px;
  &__topFilters {
    display: flex;
    justify-content: space-between;
    padding: 10px 6px 7px 6px;
    color: black;
    margin-bottom: 5px;
    background-color: white;

    &-item {
      display: flex;
      margin-right: 6px;
      align-items: center;
      cursor: pointer;
    }
  }

  &__content {
    position: relative;
    &-competition {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
      &-title {
        margin: 15px 0 0 0;
      }
    }
  }
  .OpenBetsLit__topFilters-item-title{
    font-size: 12px;
    width: max-content;
  }

  .OpenBetsLit__topFilters-item-check {
    width: 12px;
    height: 12px;
    background: rgba(255, 255, 255, 0.01);
    border: 1px solid #dddddd;
    box-sizing: border-box;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    margin-right: 6px;
    &.checked {
      background: #3982f7;
      &::after {
        content: url('../../assets/images/checkMark.svg');
        width: 10px;
        height: 10px;
        position: relative;
        bottom: 4px;
        right: 1px;
        color: white;
      }
    }
  }

  &__title {
    color: var(--white);
    background: var(--cta-primary-darken);
    padding: 8px 6px 8px 34%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
      &:hover{
        background-color: var(--alt-secondary);
      }
  }


  &__title__text {
    color: white;
  }

  &__noitems {
    padding: 8px 12px;
    background: var(--white);
    margin: 0;
  }

  &__unmatchedButtons{
    display: flex;
    width: 100%;
    justify-content: end;
    margin: 10px 0;
    &-cancel{
      width: 80px;
      color: white;
      font-size: 13px;
      height: 28px;
      margin-right: 10px;
      background-color: #bf3e35;
      border-radius: 3px;
      border-bottom: 1px solid #97312a;
      border-right: 1px solid #97312a;
      cursor: pointer;
      &:hover {
        background-color: #cb4c43;
        box-shadow: 1.5px 1.5px 0px 0px #832b24;
      }
      &:active{
        background-color: #b73b33;
        color: #FFFFFF;
        border-top: 1px solid #933029;
        border-left: 1px solid #933029;
        box-shadow: none;
        border-bottom: none;
        border-right: none;
      }
      &:disabled {
        background: #f5f5f5;
        color: #9a9a9a;
        box-shadow: none;
        border: none;
      }
    }
    
    &-reset{
      width: 80px;
      background-color: #183f45;
      color: white;
      font-size: 13px;
      height: 28px;
      border-radius: 3px;
      border-bottom: 1px solid #0b1c1f;
      border-right: 1px solid #0b1c1f;
      cursor: pointer;
      &:hover {
        background-color: #1f5058;
        box-shadow: 1.5px 1.5px 0px 0px #040b0c ;
      }
      &:active{
        background-color: #15383d;
        color: #FFFFFF;
        border-top: 1px solid #0a191b;
        border-left: 1px solid #0a191b;
        box-shadow: none;
        border-bottom: none;
        border-right: none;
      }
      &:disabled {
        background: #f5f5f5;
        color: #9a9a9a;
        box-shadow: none;
        border: none;
      }
    }
  }

}
.OpenBetsList__downButton {
  position: relative;
  // left: 10%;
  width: 15px;
  height: 15px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 8px;
    position: relative;
    transition: all linear 0.3s;
  }
  &.downImg {
    //&::before {
    //  content: '\2304';
    //  position: relative;
    //  left: 3.5px;
    //  top: -7px;
    //  color: #000000;
    //}
  }

  &.upImg {
    svg {
      transform: rotate(180deg);
    }
    //&::before {
    //  content: '\2303';
    //  position: relative;
    //  left: 3.5px;
    //  top: 1px;
    //  color: #000000;
    //}
  }
}
.noBetsItem {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  text-align: center;
  padding: 15px;
  background-color: white;
}
.OpenBetsList__content-itemsRow {
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 18px;
  margin-top: 10px;
  &-market {
    color: #000;
    width: 29%;
    text-align: left;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
  }
  &-odds {
    width: 17%;
  }
  &-stake {
    flex: 1;
  }
  &-profit {
    flex: 1;
  }
  &-runs{
    flex: 1;
  }
}
.OpenBetsList__content-itemsByMarket-allBets {
  display: flex;
  flex-direction: column-reverse;
}
.OpenBetsList__content-itemsByMarket-name{
  margin-top: 15px;
}
@media screen and (max-width: 1024px) {
  .OpenBetsList{
    margin-bottom: 14px;
  }
  .OpenBetsList__title {
    margin: 0 3px;
    padding: 8px 13px;
  }
  .OpenBetsItem.nomatch {
    font-size: 14px;
    font-weight: 400;
    padding: 8px 12px;
}
  .OpenBetsList__title__text {
    color: white;
    font-size: 14px;
    font-weight: 400;
  }

}
@media screen and (max-width: 1150px) {
  .OpenBetsLit__topFilters-item-title{
    font-size: 10px !important;
    width: max-content;
  }
  .OpenBetsLit__topFilters-item-check{
    margin-right: 4px !important;
  }
  .OpenBetsList__topFilters{
    padding: 10px 1px 7px 1px;
  }
}
@media screen and (min-width: 1151px) and (max-width: 1250px) {
  .OpenBetsLit__topFilters-item-title{
    font-size: 11px !important;
    width: max-content;
  }
  .OpenBetsLit__topFilters-item-check{
    margin-right: 4px !important;
  }
  .OpenBetsList__topFilters{
    padding: 10px 1px 7px 1px;
  }
}
