
.casino-meter-table {
  font-family: Poppins;
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .casino-meter-table__row-first {
    margin-bottom: 20px;
    //margin-right: 13px;
  }
  &__row{
    width: calc((100% - 13px) / 2);
  }
  &__row-header {
    text-align: center;
    font-weight: 500;
    font-size: 14.5px;
    margin-bottom: 5px;
    position: relative;
    bottom: -40px;
    z-index: 2;
    color: var(--color-secondary-exchange);
    line-height: 22px;
  }
  &__row-body {
    // display: flex;
    // justify-content: center;
    position: relative;
    .markets__section-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: -15px;
      img {
        width: 45px;
        height: 60px;
        margin-right: 5px;
      }
      img.card-ten {
        width: 46px;
        height: 61px;
      }
    }
  }
  &__player {
    background: var(--background-player-exchange);
    border-radius: 10px;
    width: 100%;
    height: 199px;
    padding: 5px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    &-low {
      height: 199px;
    }
  }
  &__player.lock-ui {
    pointer-events: none;
    cursor: unset;
  }
  &__player.lock-ui:before {
    content: '';
    background: var(--background-closed-exchange) url('../../../../assets/images/exchangeGames/lock-white.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    border-radius: 10px;
  }
  &__player-name,
  &__player-price {
    font-weight: 500;
    font-size: 15px;
    color: var(--color-secondary-exchange);
  }
  &__player-item {
    position: absolute;
    bottom: 5px;
  }
  &__player-name-exposure {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .exposure-box-modern {
    position: absolute;

    bottom: 5px;
    left: -25px;
    margin-top: 3px;
    font-size: 13px;
    line-height: 13px;
    &.exposure-modern--neg {
      color: var(--negative-exchange);
    }
    &.exposure-modern--pos {
      color: var(--positive-exchange);
    }
  }
  &__player.WINNER {
    .casino-meter-table__player-price {
      span {
        opacity: 0;
      }
    }
  }
  &__player.WINNER:before {
    content: '';
    background: url('../../../../assets/images/TrophyTeenPattiTest.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    left: auto;
    right: 22px;
    width: 20px;
    opacity: 1;
    transform: translateY(-50%);
  }
  .casino-meter-table-info {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 15px;
    color: var(--color-title-secondary-exchange);
    width: 100%;
    padding: 5px 0 30px 0;
  }
}

@media (max-width: 1024px) {
  div.casino-meter-table {
    display: block;
    position: relative;
    padding: 0 10px;
    margin-top: 25px;
    &__player {
      height: 90px;
      &-low {
        height: 150px;
      }
    }
    &__row-header {
      font-size: 14px;
      position: unset;
      color: var(--color-title-secondary-exchange);
    }
    &__row {
      width: 100%;
      .markets__section-body {
        margin-bottom: -5px;
        img {
          width: 32px;
          height: 44px;
          margin-right: 5px;
        }
        img.card-ten {
          width: 33px;
          height: 46px;
          padding-top: 1px;
        }
      }
    }
    .casino-meter-table__player-name,
    .casino-meter-table__player-price {
      font-size: 12px;
    }
    .exposure-box-modern {
      font-size: 11px;
    }
    .casino-meter-table__player {
      // height: 60px;
      border-radius: 5px;
    }
    .casino-meter-table__player.lock-ui:before {
      border-radius: 5px;
    }

    .casino-meter-table-info {
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      color: var(--color-title-secondary-exchange);
      width: 100%;
      padding: 5px 0 0 0;
      flex-direction: row;
    }
  }
}
