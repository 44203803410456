.exchangeGames-content {
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: 170px 170px;
  grid-row-gap: 15px;
  grid-column-gap: 10px;
  margin-top: 12px;

  & .exchangeGames-item {
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    // &:nth-child(1){
    //   grid-column: span 2;
    //   width: 100%;
    // }
  }
}

.exchangeGames-img-body {
  width: 100%;
  height: 77px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
}

.inplay-item-list-exchange__header {
  min-height: 32px;
  padding: 5px 0;
  margin-top: 8px;
  --background: white !important;
  --color: var(--text-color);

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  &-logo {
    display: flex;
    align-items: center;

    padding-left: 9px;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
}


.inplay-content__logo-icon {
  position: relative;
  padding-right: 8px;
  font-weight: normal;
  font-family: 'apollo';
  font-style: unset;

  width: 24px;
  height: 24px;

  &--casino:before {
    content: "";
  }

  &--exchange-games:before {
    content: "";
  }

}

.inplay-color-icon {
  color: var(--in-play-block-color);
}


/* @media(min-width: 425px) {
  .exchangeGames-content {
    grid-template-columns: 200px 200px;
    grid-row-gap: 7px;
    & .exchangeGames-img-body {
      height: 91px;
    }
  }
} */


// @media(min-width: 644px) {
//   .exchangeGames-content {
//     grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
//     grid-column-gap: 10px;
//     & .exchangeGames-item {
//       width: 100%;
//       // &:nth-child(1){
//       //   grid-column: span 2;
//       //   width: 100%;
//       // }
//   }
//     // & .exchangeGames-img-body {
//     //   height: 91px;
//     // }
//   }
// }

@media(min-width: 1024px) {
  .exchangeGames-content {
    width: 100%;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-auto-flow: column;
    overflow-x: scroll;
    grid-row-gap: 15px;
    grid-column-gap: 10px;
    margin-top: 12px;
    justify-content: space-between;

    &::-webkit-scrollbar {
      //height: 0.5em;
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

  }

  .exchangeGames-content .exchangeGames-item {
    width: 170px;
  }

  & .exchangeGames-img-body {
    height: 78px;
  }
}

@media screen and (max-width: 1024px) {
  .exchangeGames-content {
    width: 100%;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-auto-flow: column;
    overflow-x: scroll;
    grid-row-gap: 15px;
    grid-column-gap: 10px;
    margin-top: 12px;
    justify-content: space-between;

    // &::-webkit-scrollbar {
    //   display: none;
    // }

  }

  .exchangeGames-content .exchangeGames-item {
    width: 170px;
  }
}
