
@media (max-width: 1024px) {
  .dragon-tiger__main-wrapper {
    position: relative;
  }
  div.dragon-tiger {
    padding: 10px 10px 0 10px;
    row-gap: 35px;
    margin-top: 25px;
    .dragon-tiger__row-first {
      .dragon-tiger__row-cells-wrapper {
        display: grid;
        grid-template-columns: 2fr 1fr 2fr ;
        column-gap: 10px;
        grid-template-rows: 1fr 1fr;
        row-gap: 10px;
      }
      .dragon-tiger__cell-small-suited-tie {
        grid-column-start: 1;
        grid-column-end: 4;
      }
    }
    .dragon-tiger__cell-small {
      min-width: 80px;
    }
    .dragon-tiger__row-second {
      grid-template-columns: 1fr;
      column-gap: 15px;
      row-gap: 35px;
    }
    .dragon-tiger__row-third {
      grid-template-columns: 1fr;
      column-gap: 15px;
      row-gap: 35px;
      .dragon-tiger__row-cells-wrapper {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      .dragon-tiger__cell:nth-child(3) {
        margin-right: 2.5px;
      }
      .dragon-tiger__cell:nth-child(4) {
        margin-left: 2.5px;
      }
      .WINNER:before {
        bottom: 5px;
      }
    }
    .dragon-tiger__row-title {
      font-size: 14px;
    }
    .dragon-tiger__cell {
      border-radius: 5px;
      padding: 5px 8px;
    }
    .dragon-tiger__row-first {
      .dragon-tiger__cell {
        min-height: 62px;
      }
    }
    .dragon-tiger__row-second {
      .dragon-tiger__cell {
        min-height: 80px;
      }
    }
    .dragon-tiger__row-third {
      .dragon-tiger__cell {
        min-height: 80px;
        height: unset;
        padding: 12px 8px;
      }
    }
    .dragon-tiger__text {
      font-size: 12px;
    }
    .dragon-tiger__text.bold {
      font-weight: 700;
    }
    .exposure-box-modern {
      font-size: 10px;
    }
    .dragon-tiger__cell-img-row {
      height: 17px;
      img {
        height: 17px;
        width: 15px;
      }
    }
    .dragon-tiger__cell-card {
      height: 33px;
      img {
        width: 26px;
        height: 33px;
      }
    }
    .dragon-tiger__row-first {
      .WINNER:before {
        right: 20px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .dragon-tiger__main.virtual {
    margin-top: -15px;
    padding-top: 10px;
    background: linear-gradient(-181.68deg, #480E0E 50.02%, #A92C2C 100%);
    .dragon-tiger__row-title {
      color: var(--white);
    }
  }
  .dragon-tiger__main.virtual.fullHeight {
    min-height: 100%;
    margin-top: unset;
  }
}
