@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.BollywoodCasinoWrapper {
    //background: #fff;
    padding-top: 9px;
    padding-bottom: 30px;
}

.BollywoodCasino-table {
    margin: 21px 10px 0px 10px;

    .BollywoodCasino-table__row.BollywoodCasino-table__row-items-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
    }

    &__cards {
        text-align: center;
        margin-bottom: 3px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: var(--color-title-secondary-exchange);
    }

    &__row {
        display: flex;
        justify-content: space-between;

        &-item {
            //width: 49%;
            margin-bottom: 10px;

            &__title {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: var(--color-title-secondary-exchange);
                margin-bottom: 3px;
                white-space: nowrap;
            }

            &__box {
                width: 100%;
                height: 95px;
                background: var(--background-player-exchange);
                border-radius: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 17px 0 17px;
                position: relative;

                &__back {
                    background: var(--back-color-exchange);
                    border-radius: 3px;
                    // height: 100%;
                    height: 57px;
                    //width: 45%;
                    width: calc((100% / 2) - 14px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    &__price {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        text-align: center;
                        color: var(--color-title-exchange);

                    }
                }

                .backSuspended {
                    background: rgba(167, 216, 253, 0.5);
                    border-radius: 3px;

                    // height: 100%;
                    //height: 46px;
                    //width: 45%;
                    &::after {
                        content: url("../../../assets/images/tptLock.svg");
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        border-radius: 3px;
                        display: flex;
                        flex-direction: revert;
                        justify-content: center;
                        align-items: center;
                    }
                }

                &__lay {
                    background: var(--lay-color-exchange);
                    border-radius: 3px;
                    // height: 100%;
                    height: 57px;
                    width: calc((100% / 2) - 14px);
                    //width: 45%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    &__price {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        text-align: center;
                        color: #263238;

                    }
                }

                .laySuspended {
                    //background: rgba(255, 187, 203, 0.5);
                    border-radius: 3px;

                    // height: 100%;
                    //height: 46px;
                    //width: 45%;
                    &::after {
                        content: url("../../../assets/images/tptLock.svg");
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: var(--background-closed-exchange);
                        height: 100%;
                        width: 100%;
                        border-radius: 3px;
                        display: flex;
                        flex-direction: revert;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .winnerBox {
                //border-radius: 3px;
                //height: 62px;
                width: 100%;

                &::after {
                    content: url("../../../assets/images/TrophyTeenPattiTest.svg");
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: revert;
                    justify-content: center;
                    align-items: center;
                    //background: #172c3a;
                    background-color: var(--background-closed-exchange);
                }
            }
        }

        &__specialItem {
            .BollywoodCasino-table__row__specialItem__box__content {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 15px;
            }

            width: 49%;
            margin-bottom: 10px;

            &__flex {
                display: flex;
                justify-content: space-between;
            }

            &__title {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #444444;
                text-align: center;
                margin-bottom: 3px;
            }

            &__box {
                background: var(--background-player-exchange);
                border-radius: 10px;
                //width: 48%;
                width: calc((100% / 2) - 5px);
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                color: var(--color-secondary-exchange);

                &__content {
                    &-name {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        //line-height: 18px;
                        width: 54px;
                        //height: 25px;
                        text-align: center;

                        //margin-bottom: 5px;
                        &-img {
                            display: inline-block;
                            margin: 0 5px 0px 5px;
                        }
                    }

                    &-odds {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        //line-height: 18px;
                        text-align: center;
                    }
                }
            }

            .winnerBoxPlus {
                background: var(--background-player-exchange);
                //border-radius: 10px;
                //width: 48%;
                //height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                color: var(--color-secondary-exchange);

                .exposure-box-bollywoodSp {
                    opacity: 0;
                }

                .BollywoodCasino-table__row__specialItem__box__content-odds {
                    span {
                        position: absolute;
                        right: 14px;
                        top: 21px;
                    }

                }

                &::after {
                    content: url("../../../assets/images/TrophyTeenPattiTest.svg");
                    display: block;
                    position: absolute;
                    right: 14px;
                    top: 50%;
                }
            }

            .boxSuspended {
                overflow: hidden;

                &::after {
                    content: url("../../../assets/images/tptLock.svg");
                    background-color: var(--background-closed-exchange);
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: revert;
                    justify-content: center;
                    align-items: center;
                }
            }

            .boxSuspendedCard {
                overflow: hidden;

                &::after {
                    content: url("../../../assets/images/tptLock.svg");
                    display: block;
                    position: absolute;
                    background-color: var(--background-closed-exchange);
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: revert;
                    justify-content: center;
                    align-items: center;

                }
            }

            .boxSuspendedExp {
                &::after {
                    content: url("../../../assets/images/tptLock.svg");
                    display: block;
                    position: absolute;
                    background-color: var(--background-closed-exchange);
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 3px;
                    display: flex;
                    flex-direction: revert;
                    justify-content: center;
                    align-items: center;

                }
            }

            &__boxCard {
                background: var(--background-player-exchange);
                border-radius: 10px;
                //width: 48%;
                width: calc((100% / 2) - 5px);
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                color: var(--color-secondary-exchange);

                &__content {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 15px;

                    &-name {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 18px;

                        &-img {
                            display: inline-block;
                            margin: 0 5px;
                        }
                    }

                    &-odds {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.cardWinner {
    background: var(--color-secondary-exchange);
    border-radius: 3px;
    height: 63px;
    width: 48px;
    position: relative;
    margin-bottom: 4px;

    &-name {
        position: absolute;
        width: 14px;
        height: 23px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: var(--color-exchange);
        top: 9px;
        left: 17px;
    }

    &-heart {
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        bottom: 3px;
        left: 3px;
    }

    &-diamond {
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        top: 3px;
        right: 3px;
    }

    &-club {
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        bottom: 3px;
        right: 3px;
    }

    &-spade {
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        top: 3px;
        left: 3px;
    }

    &-trophy {
        position: absolute;
        display: block;
        width: 25px;
        height: 25px;
        bottom: 5px;
        right: 11px;
        z-index: 3;
    }
}

.exposure-box-bollywood {
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    top: -9px;
    position: relative;
    height: 0;
}

.exposure-box-bollywoodSp {
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    //padding-top: 3px;
}

.exposure-bollywood {
    &--pos {
        color: var(--positive-exchange);
    }

    &--neg {
        color: var(--negative-exchange);
    }
}

.BollywoodCasino-table__row__specialItem__boxCard__content-odds {
    .hide-exposure {
        opacity: 0;
    }
}


@media (max-width: 1024px) {
    div.BollywoodCasinoWrapper {
        background: var(--color-secondary-exchange);
    }

    div.BollywoodCasino-table {

        .BollywoodCasino-table__row-item__box,
        .BollywoodCasino-table__row__specialItem__box,
        .BollywoodCasino-table__row__specialItem__boxCard {
            border-radius: 5px;
        }

        .BollywoodCasino-table__row-item__box__back__price,
        .BollywoodCasino-table__row-item__box__lay__price,
        .BollywoodCasino-table__row__specialItem__box__content-odds,
        .BollywoodCasino-table__row__specialItem__boxCard__content-odds {
            font-size: 12px;
            font-weight: 700;
        }

        .BollywoodCasino-table__row__specialItem__box__content-name {
            font-size: 12px;
        }

        .BollywoodCasino-table__row.BollywoodCasino-table__row-items-wrapper {
            grid-template-columns: repeat(2, 1fr);
        }


        .hide-exposure {
            display: none;
        }

        .BollywoodCasino-table__row-item__box {
            min-height: 62px;
            height: auto;
        }

        .BollywoodCasino-table__row-item__box__back,
        .BollywoodCasino-table__row-item__box__lay {
            height: 46px;
        }

        .BollywoodCasino-table__row-item__box__back {
            width: calc((100% / 2) - 10px);
        }

        .BollywoodCasino-table__row-item__box {
            align-items: flex-start;
            padding-top: 8px;
        }

        .BollywoodCasino-table__row__specialItem__box__content {
            flex-direction: column;
        }

        .BollywoodCasino-table__row__specialItem__box__content-name {
            margin-bottom: 5px;
            height: 18px;
        }

        .BollywoodCasino-table__row__specialItem {
            .winnerBoxPlus {
                .BollywoodCasino-table__row__specialItem__box__content-odds {
                    span {
                        position: unset;
                        opacity: 0;
                    }
                }
            }

            .winnerBoxPlus:after {
                right: unset;
                top: unset;
                left: 50%;
                transform: translateX(-50%);
                bottom: 10px;
            }
        }

        .BollywoodCasino-table__row__specialItem__boxCard {
            min-height: 95px;
            height: unset;
        }

        .BollywoodCasino-table__row__specialItem__boxCard__content {
            padding-top: 5px;
            flex-direction: column;

            .exposure-box-bollywood {
                height: unset;
            }
        }
    }
}