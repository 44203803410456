.hi-low-cards {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  &__player {
    display: flex;
    align-items: center;
    min-width: 45px;
    &:first-child {
      margin-right: 25px;
    }
    &-title {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 12px;
      color: var(--color-secondary-exchange);
      white-space: nowrap;
      padding-right: 10px;
    }
    &-card {
      height: 33px;
      width: 24px;
      margin-right: 5px;
    }
  }
}
//@media (min-width: 1024px) {
//  .gameresult.gameresult-high-low {
//    height: unset;
//    .gameresult-cardsplace {
//      height: 82px;
//    }
//  }
//  .hi-low-cards {
//    &__player {
//      &:first-child {
//        margin-right: 50px;
//      }
//      &-title {
//        font-size: 15px;
//        padding-right: 20px;
//      }
//      &-card {
//        height: 61px;
//        width: 45px;
//      }
//    }
//  }
//}
