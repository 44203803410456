.hi-low-last-result-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hi-low-last-result {
  padding: 5px 0;
  display: flex;
  align-items: end;
  flex-direction: column;
  &__player {
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 15px;
    color: var(--color-exchange);
    margin-bottom: 37px;
    &-cards {
      margin-left: 15px;
      display: flex;
      &-isWinner{
        display: flex;
        position: relative;
        margin-left: 15px;
        &::before{
          content: '';
          position: absolute;
          width: 28px;
          height: 28px;
          background-image: url('../../../../../assets/images/winnerTropy.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          left: 50px;
          top: 8px
        }
      }
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0 0 5px grey;
      }
    }

  }
}
.hi-low-last-result-winner-pattern {
  width: 100%;
  height: 31px;
  background: var(--background-player-exchange);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.05em;
  color: var(--color-secondary-exchange);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
@media (min-width: 1024px) {
  .hi-low-last-result-wrapper {
    padding-top: 30px;
  }
  .hi-low-last-result {
    padding: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    &__player {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 12px;
      margin-bottom: 55px;
      &-title {
        padding-bottom: 10px;
      }
      &-cards {
        margin-left: unset;
        display: flex;
        &-isWinner{
          display: flex;
          position: relative;
          margin-left: unset;
          &::before{
            content: '';
            width: 28px;
            height: 28px;
            left: 2px;
            top: 55px
          }
        }
        &-card{
          display: block;
          width: 32px;
          height: 46px;
          box-shadow: 0 0 5px grey;
        }
      }

    }
  }
  .hi-low-last-result__player:first-child {
    margin-right: 127px;
  }
}
