.DesktopOpenBetsItem {
  padding: 6px;
  background: #b1d7f9;
  margin: 0 0 4px;
  font-size: 12px;
  position: relative;
  cursor: pointer;
  &__info {
    display: flex;
    justify-content: space-between;
    text-align: center;
    &-market {
      width: 29%;
      text-align: left;
    }
    &-odds {
      width: 17%;
    }
    &-stake {
      flex: 1;
    }
    &-profit {
      flex: 1;
    }
  }
  &__detailedInfo {
    display: flex;
    justify-content: space-between;
    &-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 9px;
      color: #666666;
      margin-top: 7px;
      &-bold {
        font-weight: 700;
        &:nth-child(2) {
          margin: 0 5px;
        }
      }
    }
  }
  &.lay {
    background: #f2cbd4;
  }
  &__matchName {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;

    color: #000000;
  }

  &.nomatch {
    font-size: 14px;
    font-weight: 400;
  }
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: var(--back-color);
    margin-bottom: 6px;
    text-decoration: underline;

    &.lay {
      color: var(--lay-color);
    }
  }
  &__info {
    color: black;
  }

  &__button {
    margin-top: 14px;
    width: 100%;
    height: 36px;
    border-radius: 2px;
    background: var(--cta-secondary);
    color: var(--cta-disabled);
    border: 1px solid var(--cta-disabled);
    &:hover {
      background: var(--cta-secondary-darken);
    }
  }
  &__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 4px;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  }

  &__col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    margin-right: 20px;
    width: 21%;
    font-size: 10px;
    font-weight: 500;

    &-delete {
      display: flex;
      align-items: end;
      justify-content: center;
      width: 100%;
      padding-bottom: 5px;

      &-btn {
        width: 19px;
        height: 19px;
        box-shadow: inset -2px -2px 0px #8d3a31;
        border-radius: 3px;
        background-color: #b2493e;
        cursor: pointer;
        &::after {
          content: 'x';
          color: white;
          position: relative;
          bottom: -1px;
          left: 5.5px;
        }
        &:hover {
          background-color: #b33426;
        }
        &:active {
          box-shadow: inset 2px 2px 0px #8d3a31;
        }
      }
    }

    &--small {
      width: 100px;
      margin-right: 10px;
    }
  }

  &__odd {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    &__input {
      height: 28px;
      width: 50px;
      border-radius: 3px 0 0 3px;
      transition: box-shadow 0.2s ease-out, -webkit-box-shadow 0.2s ease-out;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }

      input.native-input {
        text-align: center;

        font-size: 10px;
      }

      &.incremented {
        -webkit-box-shadow: inset 0 0 8px rgba(68, 10, 71, 0.4);
        box-shadow: inset 0 0 8px rgba(68, 10, 71, 0.4);
      }
      &--rounded {
        border-radius: 2px;
      }
    }

    &__text {
      height: 25px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;

      color: #000000;

      &--small {
        width: 100px;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        margin-right: 10px;
      }
    }
  }

  &__changeodd {
    margin: 0;
    height: 14px;
    width: 14px;
    font-size: 20px !important;
    background-color: #59b6ff;
    color: var(--bet-form-stepper-buttons-color);
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
    padding: 0;

    &.lay {
      background: #ffa3b8;
    }

    &-buttonsCol {
      display: flex;
      flex-direction: column;
      &-minus {
        border-radius: 0 0 3px;
        &::after {
          content: url('../../assets/images/stakeUpArrow.svg');
          position: relative;
          top: -8px;
        }
        &:hover {
          background: #c7d1db;
          box-shadow: 2px 1px 0px rgba(0, 0, 0, 0.25);
        }
        &:active {
          background: #a8afb7;
          box-shadow: inset 2px 2px 0px rgba(0, 0, 0, 0.25);
        }
      }

      &-plus {
        border-radius: 0 3px 0 0;
        &::after {
          content: url('../../assets/images/stakeUpArrow.svg');
          position: relative;
          top: 2px;
          right: 3px;
          display: flex;
          transform: rotate(180deg);
        }
        &:hover {
          background: #c7d1db;
          box-shadow: 2px 1px 0px rgba(0, 0, 0, 0.25);
        }
        &:active {
          background: #81878d;
          box-shadow: inset 2px 2px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }

    &:focus {
      outline: none;
    }

    &__text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    span {
      position: absolute;
      background: var(--primary);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      pointer-events: none;
      border-radius: 50%;
      -webkit-animation: animate 0.7s linear forwards;
      animation: animate 0.7s linear forwards;
    }
  }

  ion-button {
    margin: 0;
    width: 23%;
    height: 28px;
    background: #13272c;
    box-shadow: inset -2px -2px 0px #030404;
    border-radius: 3px;
  }
  &__input {
    height: 28px;
    border-radius: 2px;
    border: 1px solid var(--input-border);
    padding: 9px;
    --padding-end: 13px !important;

    &.has-focus {
      --color: var(--black);
      border: 1px solid var(--gray);
    }
  }

  &__stake {
    width: 28%;
    margin-right: 15px;

    input.native-input {
      &::-webkit-input-placeholder {
        text-align: left;
        font-size: 8px;
      }

      &::-moz-placeholder {
        text-align: left;
        font-size: 8px;
      }

      &:-ms-input-placeholder {
        text-align: left;
        font-size: 8px;
      }

      &::-ms-input-placeholder {
        text-align: left;
        font-size: 8px;
      }

      &::placeholder {
        text-align: left;
        font-size: 8px;
      }
    }

    &__input {
      height: 27px;
      width: 85px;
      border-radius: 3px 0 0 3px;
      transition: box-shadow 0.2s ease-out, -webkit-box-shadow 0.2s ease-out;
      margin-right: 20px;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }

      input.native-input {
        text-align: center;

        font-size: 10px;
      }

      &.incremented {
        -webkit-box-shadow: inset 0 0 8px rgba(68, 10, 71, 0.4);
        box-shadow: inset 0 0 8px rgba(68, 10, 71, 0.4);
      }
      &--rounded {
        border-radius: 2px;
        margin-right: 20px;
        width: 70px;
      }
    }
  }
  .f10 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 9px;
    color: #000000;
  }
  &__unmatchedTitles{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    &-cancel{
      margin-right: 10px;
      font-size: 10;
      font-weight: 500;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  &__removingHover{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
    left: 1px;
    z-index: 3;
    bottom: 1px;
    color: white;
  }
}
