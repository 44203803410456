.selectType{
    background: white;
    height: 40px;
    border: 1px solid #bfafaf;
    padding: 0 10px;
}
// .selectType ::selection{
//   background-color: red;
// }
// select option[value="2"]:checked {
//   background: rgba(100, 100, 100, 0.3);
// }
// option 
.status-dropdown {
  width: 100%;
  height: 34px;
  min-width: 168px;
  font-weight: 400;
  font-size: 14px;
  color: #5F686B;
  cursor: pointer;
  &-selected {
      background-color: #2E1D30;;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 15px;
      position: relative;
  }
  &-selected:after {
      content: "";
      position: absolute;
      right: 10px;
      top: 16px;
      border: 5px solid transparent;
      border-color: #FFFFFF transparent transparent transparent;
  }
  ul, li {
      list-style: none;
      margin: 0;
      padding: 0;
  }
  &-options {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      position: relative;
      background: #27082a;
      z-index: 1;
      li {
          padding: 5px 15px !important;
          background: #2E1D30;
          cursor: pointer;
          height: 32px;
          margin: 2px 0 !important;
      }
  }
}

.filtringDetails{
    background: white;
    width: max-content;
    padding: 5px;
    // margin-top: 9px;
    font-size: 13px;
    border-radius: 5px;
    text-align: end;

    &_detail{
        margin-bottom: 10px;
    }
    &_remove{
        text-decoration: underline;
        margin-top: 3px;
    }
}
