
.tptWrapper{
  margin-bottom: 40px;
}

.tptTitle{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: var(--color-exchange);
  text-align: center;
}
.popUpTPT-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__results{
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &-cards{
      display: flex;
      width: 110px;
      height: 44px;
      justify-content: space-between;
      //margin-top: 15px;
      //margin-bottom: 30px;
      margin-top: 10px;
      margin-bottom: 20px;
      &-isWinner{
        display: flex;
        width: 110px;
        height: 44px;
        justify-content: space-between;
        //margin-top: 15px;
        //margin-bottom: 30px;
        margin-top: 10px;
        margin-bottom: 20px;
        position: relative;
        &::before{
          content: url('../../../../../assets/images/winnerTropy.svg');
          position: absolute;
          left: -70px;
          top: -3px
        }
      }
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0px 0px 5px grey;
      }
    }
    &-description{
      height: 31px;
      width: 100%;
      padding: 0px 10px;
      background: var(--background-player-exchange);
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      &-text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        color: var(--color-secondary-exchange);
        text-transform: uppercase;
      }
    }
  }
}


@media screen and (min-width: 1024px) {
  .tptWrapper{
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-bottom: 40px;
    margin-top: 20px;
  }

  .tptColumn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  }

  .popUpTPT-content__results-cards{
    &-isWinner{
      position: relative;
      &::before{
        content: url('../../../../../assets/images/exchangeGames/TrophyTpt.svg');
        position: absolute;
        left: 41%;
        top: 50px;
      }
    }
  }
  .tptTitle{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-exchange);
  }
}
