.widget-containerNotAuth {
    position: relative;
    height: 246px;
    width: 100%;
    overflow: hidden;
    margin: 10px 0;
}
.widget-preloader {
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 246px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    
    & img {
        width: 50px;
    }
}