.twoCardLastRes {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header{
    padding-top: 10px;
    &__title{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: var(--color-exchange);
      text-transform: uppercase;
    }
    &__gameId{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: var(--color-exchange);
    }
  }
  &__results{
    padding-top: 40px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &-name{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: var(--color-exchange);
      margin-left: -70px;
      margin-top: 12px;
      }
    &-cards{
      display: flex;
      width: 80px;
      height: 44px;
      justify-content: space-between;
      //margin-top: 15px;
      //margin-bottom: 30px;
      margin-top: 10px;
      margin-bottom: 30px;
      &::before{
        // content: 'Player A';
        // position: absolute;
        // left: -80px;
        // top: -3px
      }
      &-isWinner{
        display: flex;
        width: 80px;
        height: 44px;
        justify-content: space-between;
        //margin-top: 15px;
        //margin-bottom: 30px;
        margin-top: 10px;
        margin-bottom: 30px;
        position: relative;
        &::after{
          content: url('../../../../../assets/images/winnerTropy.svg');
          position: absolute;
          left: 90px;
          top: -3px
        }
      }
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0px 0px 5px grey;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .tptWrapper{
    display: flex;
    justify-content: space-between;
    width: 70%;
  }
  .teenpatti2card-results {
    padding-top: 20px;
  }
  .tptColumn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  }

  .twoCardLastRes__results-cards{
    justify-content: space-around;
    &-isWinner{
      position: relative;
      justify-content: space-around;
      &::after{
        content: url('../../../../../assets/images/exchangeGames/TrophyTpt.svg');
        position: absolute;
        left: 30%;
        top: 50px;
      }
    }
  }

  .twoCardLastRes__results-name{
    position: relative;
    top: -40px;
    left: 77px;
    font-size: 12px;
  }
  .tptTitle{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-exchange);
  }
}
