.transfer-statement-page {
  .page-title {
    font-weight: 600;
    font-size: 24px;
    padding-bottom: 15px;
    margin-bottom: 12px;
    border-bottom: 1px solid #9AA1A4;
    width: 100%;
    //max-width: calc(100% - 292px);
  }
}

.transfer-statement-page__content {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: var(--text-color);
  margin: 0 4px 0 0;
  padding-left: 15px;
}

.transfer-statement-page__content-group {
  padding: 5px 10px 13px 13px;
}

.transfer-statement-page__content-header {
  display: flex;
  background: var(--alt-secondary);
  font-style: normal;
  font-weight: normal;
  align-items: center;

  background-color: var(--alt-secondary);
  color: var(--accordion-color);
  padding: 5px 10px 5px 13px;
}

.transfer-statement-page__content--empty {
  font-size: 12px;
  color: black;

  margin: 0;
}

.transfer-statement-page__content-row-date {
  color: var(--btn-text-color);
  width: 25%;
}

.transfer-statement-page__content-row-description {
  color: var(--btn-text-color);
  width: 30%;
}

.transfer-statement-page__content-row-amount {
  color: var(--btn-text-color);
  width: 45%;
  text-align: right;
}

.transfer-statement-page__list-row {

  .transfer-statement-page__content-row-date {
    display: flex;
    align-items: center;
  }

  .transfer-statement-page__content-row-description {
    display: flex;
    align-items: center;
  }

  .transfer-statement-page__content-row-amount {
    font-weight: 700;
  }

  .red {
    color: var(--negative);
  }

  .green {
    color: var(--positive);
  }
}

.transfer-statement-page__row {
  display: flex;
}

.transfer-statement-page__content-row-header {
  display: flex;
  align-items: center;
  background-color: var(--group-header);
  color: var(--transfer-header-color);
  padding: 5px 13px;
}

.transfer-statement-page__row {
  padding: 3px 0;
}


@media screen and (max-width: 1023px) {
  .transfer-statement-page .page-title{
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 16px;
    border-bottom: unset;
    max-width: unset;
    padding-bottom: unset;
  }
  .transfer-statement-page__content--empty {
    font-size: 14px;
    color: #000;
    margin: 0 0 0 18px;
  }
  .transfer-statement-page__content {
    padding-left: unset;
  }
}
