@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.ChangePassword {
  --padding-top: 15px;
  --padding-start: 15px;
  --padding-end: 15px;

  &__form {
    width: 100%;
    margin-top: 10px;
  }

  &__list {
    background: none !important;

    ion-item {
      --min-height: 36px;
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --inner-border-width: 0px;

      // ion-input.error {
      //   border: 1px solid var(--ion-color-danger);
      // }
    }

    ion-item+ion-item {
      margin-top: 12px;
    }
  }

  &__button {
    width: 50%;
    margin: 20px 0 auto auto;
    text-transform: none;
    background: var(--payment-primary-color);
    color: #fff;
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    height: 36px;
    font-weight: 500;
    margin-top: 24px;

    &:hover {
      background: var(--cta-primary-darken);
    }

    &:disabled {
      background: var(--cta-disabled);
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__form-error {
    padding: 0 10px 5px;
    margin-top: -2px;
    font-size: 10px;
    color: var(--negative);

    ion-item+& {
      padding: 0 10px 10px;
      margin-top: 9px;
    }
  }

  ion-item {
    --highlight-background: var(--login-highlight-bg);
    --border-radius: 2px;
  }

  ion-input {
    border-radius: 2px;
    height: 100%;
  }

  .native-input {
    border-radius: 2px;
    height: 100%;

    &:focus {
      border: 1px solid var(--login-item-focused-border);
    }
  }
}

.transactions-container {
  margin: 0;
  padding: 0;
}

.transactions-menu {
  position: sticky;
  z-index: 10;
  width: 100%;
  height: 39px;

  padding: 8px 15px;

  background: #40424f;
  background: var(--event-info-background-color);

  .deposite,
  .withdrawal {
    position: relative;

    height: 100%;
    width: 100%;
    color: #7fa2ba;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    &.activeTr {
      color: #fff;
    }

    &.activeTr:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      width: 100%;
      height: 3px;
      background: var(--payment-primary-color);
    }
  }

  .deposite {
    cursor: pointer;
    display: inline-block;
    // width: 45px;
    // width: auto;
    // margin-right: 20px;
    width: 40%;
    margin-left: 20px;
  }

  .withdrawal {
    cursor: pointer;
    display: inline-block;
    // width: 63px;
    // width: auto;
    width: 40%;
    margin-left: 20px;
    // color: #7fa2ba;
  }
}

.transaction-container {
  position: relative;
  width: 100%;
  // height: 130px;

  padding: 24px 20px 7px 24px;
  margin-bottom: 8px;

  background-color: #fff;

  .transaction {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 15px;
    // padding-left: 10px;

    .transaction_number {
      width: 100%;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-size: 15px;
      line-height: 22px;

      color: #40424f;

      &__symbol {
        font-weight: 400;

        margin-right: 10px;
      }

      &__number {
        font-weight: 600;
      }
    }

    &_amount {
      width: 100%;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 27px;
      line-height: 36px;
      text-align: center;
      color: #40424f;

      .transaction_amount-symbol {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #40424f;
      }
    }

    .transaction_paymentSystem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      // img {
      //   width: 50px;
      //   height: 16px;
      // }

      &_name {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        text-align: center;

        color: #40424f;
      }
    }
  }

  .transaction-status {
    width: 81px;
    height: 22px;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 9px;
    text-align: center;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;

    border-radius: 2px;
    color: #ffffff;

    &.approved {
      background: #86aa16;
    }

    &.pending {
      background: #e1d800;
    }

    &.rejected {
      background: #ef4848;
    }
  }

  .transaction-date {
    width: 130px;
    margin-left: auto;
    margin-right: auto;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;

    &_date {
      color: #40424f;
      margin-right: 5px;
      margin-left: auto;
    }

    &_time {
      color: gray;
    }
  }
}
.transactions-container-information {
  .transactions-container-information__no-deposits {
    width: 100%;
    max-width: 360px;
    //border: 1px solid #0F23271A;
    border-radius: 5px;
    padding: 70px 50px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .transactions-container-information__add-deposit-btn {
    width: 100%;
    max-width: 265px;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    color: var(--white);
    background-color: var(--black);
    margin-top: 35px;
  }

}
.withdraw-account__flex.transaction-page {
  padding-left: 12px;
  padding-top: 12px;
  .withdraw-account__item-checked{
    min-width: 152px;
  }
  .withdraw-account__item {
    min-width: 152px;

    &.add-account__item{
      border-style: dashed;
      .withdraw-account__item-content{
        align-items: center;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: end;
        margin: 0px;
        padding: 5px;
        flex-direction: column;
        .add-icon{
          width: 25px;
          height: 25px;
          position: relative;
          margin-bottom: 5px;
          .vertical{
            position: absolute;
            top: 0;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
            border: 1px dashed #40424f;
          }
          .horizontal{
            position: absolute;
            top: 50%;
            width: 100%;
            left:0;
            transform: translateY(-50%);
            border: 1px dashed #40424f;
          }
        }
      }
    }
  }
  &::-webkit-scrollbar {
    display: block;
  }
}
.refresh {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 15px;
  right: 15px;

  width: 24px;
  height: 24px;
  cursor: pointer;

  // background-color: #6ba47d;

  .refreshIcon {
    width: 14px;
    height: 14px;
  }
}

// Desktop version ----------------------
.desktopView {
  padding: 20px 7%;
  color: black;
  margin-bottom: 45px;

  .transactions-menu {
    height: 80px;
    background-color: #fff;

    margin-bottom: 40px;

    .deposite,
    .withdrawal {
      position: relative;

      height: 100%;
      width: 100%;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 29px;
      line-height: 36px;

      color: #102327;

      display: flex;
      align-items: center;
      text-align: center;
      text-transform: none;

      &.activeTr:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        width: 100%;
        height: 3px;
        background: var(--payment-primary-color);
      }
    }

    .deposite {
      display: inline-block;
      // width: 45px;
      width: auto;
      margin-right: 20px;
    }

    .withdrawal {
      display: inline-block;
      // width: 63px;
      width: auto;
      // color: #7fa2ba;
    }
  }

  .transactions-container-desktop {
    display: flex;
    justify-content: space-around;
    background: #ffffff;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    min-height: 75vh;
  }

  .transactions-container {
    width: 50%;
    // padding: 36px 72px 26px 74px;
    padding: 36px 5% 26px 5%;

    box-shadow: -1px 1px 10px #383838;

    .transactions-menu {
      height: 60px;
      margin-bottom: 44px;
    }

    .transactions-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      // max-height: 771px;
      max-height: 60vh;
      overflow-x: hidden;

      .transactions-item {
        position: relative;
        display: flex;
        align-items: flex-end;
        // max-width: 450px;
        width: 100%;
      }
    }

    .transaction-container {
      max-width: 450px;
      border: 1px solid rgba(64, 66, 79, 0.6);
      border-radius: 5px;
      margin-bottom: 0;
      padding-top: 7px;
      margin-left: auto;
      margin-right: auto;

      .transaction {
        margin-bottom: 0;
      }
    }
  }

  .detailes-container {
    width: 50%;
    // padding: 124px 69px 265px 77px;
    padding: 124px 5% 265px 5%;

    box-shadow: -1px 1px 10px #383838;
    // background-color: teal;

    .detailes-container-content {
      max-width: 450px;
      padding: 0 17px 30px;
      margin-left: auto;
      margin-right: auto;
      background: rgba(107, 164, 125, 0.08);

      .transaction-date {
        width: 180px;
        margin-left: auto;
        margin-right: auto;

        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 19.6906px;
        line-height: 29px;

        &_date {
          color: #40424f;
          margin-right: 5px;
          margin-left: auto;
        }

        &_time {
          color: gray;
        }
      }

      .detailed-transaction-container {
        margin-bottom: 24px;
      }

      .reason-container {
        margin-bottom: 24px;
      }

      .transaction {
        width: 100%;
      }

      .transaction_number__number {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;

        color: #40424f;
      }
    }

    .detailed-content {
      &__buttons {
        display: flex;
        justify-content: center;

        &-back {
          width: 135px;
          height: 45px;
          // left: 92px;
          // top: 438px;
          background: var(--payment-primary-color);
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 26px;
          text-align: center;
          color: #ffffff;
          margin: 0 2px;
          padding-top: 8px;

          border-radius: 3px;
        }

        &-edit {
          width: 135px;
          height: 45px;
          // left: 92px;
          // top: 438px;
          background: #40424f;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 26px;
          text-align: center;
          color: #ffffff;
          margin: 0 2px;
          padding-top: 8px;

          border-radius: 3px;
        }

        &-cancel {
          width: 135px;
          height: 45px;

          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 26px;
          text-align: center;
          color: #969696;
          border: 1px solid #969696;
          border-radius: 3px;
          margin: 0 2px;
          padding-top: 8px;
        }
      }
    }
  }

  .activeItem {
    background: rgba(107, 164, 125, 0.08);
  }

  .pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
  }

  .front {
    display: block;
    position: relative;
    // width: 94px;
    // height: 37px;
    width: 120px;
    height: 45px;

    &.refreshBtn {
      background: var(--payment-primary-color);
    }

    &.editBtn {
      background: #40424f;
    }

    &.cancelBtn {
      background: #fff;
      color: #969696;
      border: 1px solid #969696;
    }

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
    margin: 0 2px;
    padding-top: 8px;

    border-radius: 3px;
    will-change: transform;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
  }

  .pushable:hover {
    filter: brightness(110%);

    .shadow {
      position: absolute;
      top: -2px;
      left: 2px;
      // width: 100%;
      // height: 100%;
      // width: 94px;
      // height: 37px;
      width: 120px;
      height: 45px;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.25);
      will-change: transform;
      transform: translateY(2px);
      transition:
        transform 600ms cubic-bezier(.3, .7, .4, 1);
    }

    .edge {
      position: absolute;
      top: -2px;
      left: 2px;
      // width: 100%;
      // height: 100%;
      // width: 94px;
      // height: 37px;
      width: 120px;
      height: 45px;
      border-radius: 3px;
      // background: linear-gradient(to left,
      //     rgb(82, 0, 27) 0%,
      //     rgb(163, 0, 54) 8%,
      //     rgb(163, 0, 54) 92%,
      //     rgb(82, 0, 27) 100%);

      &.refreshBtn {
        background: var(--payment-primary-color);
      }

      &.editBtn {
        background: #40424f;
      }

      &.cancelBtn {
        background: #fff;
        color: #969696;
      }

      filter: brightness(75%)
    }
  }

  .pushable:hover .front {
    transform: translateY(-6px);
    transition:
      transform 250ms cubic-bezier(.3, .7, .4, 1.5);
  }

  .pushable:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }

  .pushable:hover .shadow {
    transform: translateY(4px);
    transition:
      transform 250ms cubic-bezier(.3, .7, .4, 1.5);
  }

  .pushable:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }

  .pushable:focus:not(:focus-visible) {
    outline: none;
  }
}

@media (max-height: 850px) {
  .desktopView .transactions-container-desktop {
    min-height: 72vh;
  }

  .desktopView .transactions-container .transactions-items {
    max-height: 50vh;
  }
}

@media screen and (min-width: 1024px) {
  .desktopView .transactions-container .transactions-items::-webkit-scrollbar {
    width: 1em;
  }

  .desktopView .transactions-container .transactions-items::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    // outline: 1px solid slategrey;
  }

  .desktopView .transactions-container .transactions-items::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

.evenBox:nth-child(2n) {
  .transaction-container {
    background-color: #DFDFDF;
  }
}

@media screen and (max-width: 1024px) {
  .transactions-container {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items:center;
    &__buttons{
      display: flex;
      height: 48px;
      width: 245px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 5px #0000001A;
      border: 1px solid #2B2B2B1A;
      border-radius: 8px;
      opacity: 1;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px 0 5px;
      &-item{
        width: 121px;
        height: 40px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        text-align: center;
        // font: normal normal medium 14px/19px Roboto;
        font-family: "Roboto";
        font-size: 14px;
        letter-spacing: 0px;
        color: #0F2327;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &-information{
      width: 100%;
      height: auto;
      border: 1px solid #000;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #0F23271A;
      border-radius: 10px;
      opacity: 1;
      margin-top: 20px;
      &-item{
        width: 100%;
        height: 81px;
        border-bottom: 1px solid #7070701A;
        opacity: 1;
        padding: 18px 20px;
        .transactionsInformation{
          width: 100%;
          height: 100%;
          display:flex;
          &-img{
            width: 45px;
            height: 45px;
            border-radius: 500px;
            opacity: 1;
            display:flex;
            justify-content: center;
            align-items:center;
            position: relative;
          }
          &-data{
            margin-left: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &__id{
              text-align: left;
              font-family: 'Roboto';
              font-size: 13px;
              letter-spacing: 0px;
              color: #0F2327;
              opacity: 1;
              font-weight: 600;
            }
            &__dateTime{
              text-align: left;
              font-family: 'Roboto';
              font-size: 11px;
              letter-spacing: 0px;
              color: #0F2327;
              opacity: 0.6;
            }
          }
          &-amount{
            margin-left: 60px;
            display: flex;
            align-items:center;
            font-family: 'Roboto';
            font-size: 14px;
            letter-spacing: 0px;
            color: #0F2327;
            opacity: 1;
          }
          &-arrow{
            margin-left: 21px;
            display: flex;
            align-items: center;
            img{
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  .checkedItem{
    width: 104px;
    height: 40px;
    background: var(--primary) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    text-align: center;
    font-family: "Roboto";
    font-size: 14px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }
  .statusSuccessful{
    background: #F2FFF6 0% 0% no-repeat padding-box;
    border: 1px solid #70707026;
  }
  .statusPending{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #70707026;
  }
  .statusFailed{
    background: #FFF6F7 0% 0% no-repeat padding-box;
    border: 1px solid #70707026;
  }
}

@media screen and (max-width: 380px) {
  .transactions-container-information-item{
    .transactionsInformation-amount{
      margin-left: 50px;
    } 
  }
}
