.popUpTrio-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__results {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &-cards {
      display: flex;
      width: 110px;
      height: 44px;
      justify-content: space-between;
      //margin-top: 15px;
      //margin-bottom: 30px;
      margin-top: 10px;
      margin-bottom: 50px;
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0px 0px 5px grey;
      }
    }
  }
}
