.CasinoGames {
    background: var(--exchange-bg-color);
    &--sportsbook{
        background: var(--black);;
    }

    &__toolbar {
        --min-height: 38px;
        --background: var(--exchange-header-background);
        color: var(--white);

        &__wrapper {
            display: flex;
            justify-content: space-between;
            padding: 0 5px;
        }

        &__title {
            margin-left: 10px;
            line-height: 32px;
            font-weight: 700;

            // &--btn {
            //     cursor: pointer;
            //     display: flex;

            //     &__plus {
            //         font-weight: 700;
            //         padding: 0 10px 0 15px;
            //         font-size: 23px;
            //     }
            // }
        }
    }

    .menu-btn {
        --background: transparent;
        width: 30px;
        height: 30px;
    }

    &__right-icon {
        font-weight: bold;
        transform: rotate(90deg);
        font-size: 22px;
    }

    &__content {
        display: flex;
        flex: 1 0 0;
        align-items: center;
        justify-content: space-around;
        padding: 0 5vw !important;
        height: 100%;
        &__video {
            width: 100%;
            height: calc(100vh - 41px);
        }
    }

    .exchange-game {
        width: 35vw;
        height: 32vw;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        box-shadow: 5px 4px 5px #00000045;
        overflow: hidden;
        margin: auto;
        @media (max-width: 1024px) {
            width: 66vw;
            height: 62vw;
        }
        &__header {
            flex: 0 0 50px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--exchange-header-background);
            color: var(--white);
        }

        &__body {
            flex: 1 0 0;
            background: center/cover;
            background-image: url('../../assets/images/nav_menu_live_casino.png');
        }
    }
}