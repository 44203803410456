.CasinoMeterCardsContent {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .CasinoMeterCardsContent__players-wrapper {
    width: 100%;
    max-width: 650px;
    padding: 5px 15px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: space-between;
  }
  .CasinoMeterCardsContent__player {
    display: flex;
    min-height: 44px;
    align-items: center;
    //margin-bottom: 5px;
    margin-bottom: 0;
    //margin-top: 5px;
    margin-top: 0;
    &-position {
      display: flex;
      //justify-content: center;
      color: var(--color-secondary-exchange);
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      padding: 10px 0;
      &-score {
        color: var(--color-total-exchange);
      }
    }
  }
  .CasinoMeterCardsContent__player.CasinoMeterCardsContent__player-second {
    // flex-direction: row-reverse;

    .CasinoMeterCardsContent__player-cards {
      // margin-left: -5px;
      display: flex;
    }
  }
  .CasinoMeterCardsContent__player-name {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    min-width: 50px;
    color: var(--color-secondary-exchange);
    .info__column-score {
      color: var(--color-total-exchange)
    }
  }
  .CasinoMeterCardsContent__player-cards {
    padding: 0 30px;
    color: var(--color-exchange);
  }
  .CasinoMeterCardsContent__player-cards-card {
    width: 24px;
    height: 33px;
    margin: 0 2.5px;
  }
  .CasinoMeterCardsContent__player-cards-card.joker {
    margin: 0 2.5px 0 10px;
  }
}

@media (max-width: 1199px) {
  div.CasinoMeterCardsContent {
    .CasinoMeterCardsContent__player-cards-card {
      width: 24px;
      height: 33px;
      margin: 0 2.5px;
    }
    .CasinoMeterCardsContent__player-cards-card.joker {
      margin: 0 2.5px 0 10px;
    }
  }
}

@media (max-width: 1024px) {
  div.CasinoMeterCardsContent {
    .CasinoMeterCardsContent__player-cards {
      padding: 0 15px;
    }
    .CasinoMeterCardsContent__player-name {
      font-size: 12px;
    }
  }
}

@media (max-width: 360px) {
  div.CasinoMeterCardsContent {
    .CasinoMeterCardsContent__player-cards-card {
      width: 22px;
      height: 30px;
    }
    .CasinoMeterCardsContent__players-wrapper {
      padding: 0 10px;
    }
    .CasinoMeterCardsContent__player-name {
      font-size: 11px;
    }
    .CasinoMeterCardsContent__player-cards {
      padding: 0 10px;
    }
  }
}
@media (min-width: 1024px) {
  .CasinoMeterCardsContent {
    min-height: 82px;
    .CasinoMeterCardsContent__players-wrapper {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .CasinoMeterCardsContent__player {
      min-width: 50%;
      &-position {
        width: 100%;
        display: flex;
      }
    }
    .CasinoMeterCardsContent__player-cards {
      padding: 5px 15px;
      display: flex;
    }
  }
}
