
.teenpatti-T20-table {
  font-family: Poppins;
  margin-top: 35px;
  padding-bottom: 50px;
  .teenpatti-T20-table__row-first {
    margin-bottom: 20px;
  }
  &__row-header {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 5px;
  }
  &__row-body {
    display: flex;
    justify-content: space-between;
  }
  &__player {
    background: var(--background-player-exchange);
    border-radius: 10px;
    width: calc(50% - 10px);
    height: 84px;
    padding: 5px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  &__player.lock-ui {
    pointer-events: none;
    cursor: unset;
  }
  &__player.lock-ui:before {
    content: '';
    background: var(--background-closed-exchange) url('../../../../assets/images/exchangeGames/lock-white.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    border-radius: 10px;
  }
  &__player-name,
  &__player-price {
    font-weight: 500;
    font-size: 15px;
    color: var(--color-secondary-exchange);
  }
  &__player-name-exposure {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .exposure-box-modern {
    margin-top: 3px;
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
    position: unset;
    left: unset;
    right: unset;
    bottom: unset;
  }
  .exposure-box-modern.exposure-modern--pos {
    color: var(--positive-exchange);
  }
  .exposure-box-modern.exposure-modern--neg {
    color: var(--negative-exchange);
  }

  &__player.WINNER {
    .teenpatti-T20-table__player-price {
      span {
        opacity: 0;
      }
    }
  }
  &__player.WINNER:before {
    content: '';
    background: url('../../../../assets/images/TrophyTeenPattiTest.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    left: auto;
    right: 22px;
    width: 20px;
    opacity: 1;
    transform: translateY(-50%);
  }
}

@media (max-width: 1024px) {
  div.teenpatti-T20-table {
    position: relative;
    padding: 0 10px;
    margin-top: 25px;
    &__row-header {
      font-size: 14px;
    }
    .teenpatti-T20-table__player-name,
    .teenpatti-T20-table__player-price {
      font-size: 12px;
    }
    .teenpatti-T20-table__player-price {
      font-weight: 700;
    }
    .exposure-box-modern {
      font-size: 11px;
    }
    .teenpatti-T20-table__player {
      height: 60px;
      border-radius: 5px;
      padding: 5px 15px;
    }
    .teenpatti-T20-table__player.lock-ui:before {
      border-radius: 5px;
    }
    //.BetPlacing-container {
    //  position: absolute;
    //  z-index: 9;
    //  top: -7px;
    //  right: 0;
    //  left: 0;
    //  bottom: 0;
    //  display: flex;
    //  justify-content: center;
    //  align-items: flex-start;
    //  overflow: visible;
    //}
  }
  div.teenpatti-T20-table.virtual {
    margin-top: -10px;
    padding-top: 30px;
    min-height: calc(100% - 380px);
    background: linear-gradient(-181.68deg, #0B3114 50.02%, #40783B 100%);
    .teenpatti-T20-table__row-header {
      color: var(--color-secondary-exchange);
    }
  }
  .teenpatti-T20-table.virtual.fullHeight {
    min-height: 100%;
    margin-top: unset;
    padding-top: 20px;
  }
}
