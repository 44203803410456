.worli-matka-betting {
  width: 100%;
  display: flex;
  padding-bottom: 15px;
  justify-content: space-between;
  &__games {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__games-item {
    display: flex;
    align-items: center;
    margin: 0 10px;
    &-title {
      padding-left: 7px;
    }
    &-cup {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    &-cards {
      display: flex;
      img {
        width: 32px;
        height: 44px;
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
      }
      img:not(:first-child) {
        margin-left: 6px;
      }
    }
    &-score {
      font-weight: 900;
      font-size: 14px;
      color: var(--positive);
      width: 32px;
      height: 44px;
      border: 2px solid var(--positive);
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
      background: var(--color-secondary-exchange);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__info-column {
    min-width: 80px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 70%;
      text-align: right;
      font-weight: 400;
      color: var(--background-secondary-player-exchange);
    }
    &-results {
      width: 30%;
      font-weight: 500;
      color: var(--color-secondary-exchange);
      padding-left: 4px;
    }
  }
}

@media (max-width: 1024px) {
  .worli-matka-betting {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: unset;
    padding-bottom: unset;
    &__games {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &__games-item {
      padding: 20px 0 0 0;
      display: flex;
      flex-direction: row;
      min-width: max-content;
      margin-right: 10px;
    }
    &__games-item.matka-index {
      padding: 30px 0 0 0;
      display: flex;
      .worli-matka-betting__games-item-cup {
        margin-right: 15px;
      }
      .worli-matka-betting__games-item-score {
        width: 26px;
        height: 35px;
      }
    }

    &__games-item-cards {
      display: flex;
      justify-content: center;

      img {
        width: 26px;
        height: 35px;
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
        margin-left: 6px;
      }
    }
    &__info-row {
      padding: 2px 0;
    }
    .winnerCup {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background-position: center;
        background-image: url(../../../assets/images/TrophyTeenPattiTest.svg);
        height: 50px;
        width: 50px;
        background-repeat: no-repeat;
        background-size: 35px;
        right: -50px;
        left: unset;
        top: 10px;
      }
    }
    .second.winnerCup:before {
      right: unset;
      left: -45px;
    }
  }

  .worli-matka-betting__info-column {
    padding-top: 38px;
  }
  .worli-matka-betting__info-row-title {
    width: unset;
  }
}
