
.loginPopUp {
  pointer-events: all;
  .pop-up_wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: aliceblue;
    z-index: 10;
    // animation: popup .5s;

    .pop-up {
      width: 430px;
      background: var(--registration-bg);
      //background-size: 86%;
      position: absolute;
      max-width: 430px;
      min-height: 354px;
      max-height: 95%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: unset !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .pop-up-content {
      display: flex;
      align-items: start;
      justify-content: end;

      &_title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #000000;
        margin-bottom: 8px;
        display: flex;

      }
      .tabButtons{
        display: flex;
        width: 100%;
        height: 60px;
        &__button{
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 23px;
          color: white;
          z-index: 3;
          position: relative;
          width: 100%;
          text-align: center;
          &.inActiveLeft{
            border-radius: 10px 0 0 0;
            color: var(--btn-text-color);
            background: var(--login-button-color);
            box-shadow: inset -2px -4px 7px rgba(0, 0, 0, 0.25);
          }
          &.inActiveRight{
            border-radius: 0 10px 0 0;
            color:var(--btn-text-color);
            background: var(--login-button-color);
            box-shadow: inset -2px -4px 7px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }

    .pop-up-content {
      &__buttons {
        display: flex;
        justify-content: center;
        &-new {
          justify-content: flex-start;
        }
        &.forgot {
          cursor: pointer;
          justify-self: start;
          margin-top: -25px;
          margin-left: 15%;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 23px;
          text-decoration-line: underline;
          color: var(--login-link-color);
          a{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 23px;
            color: var(--login-link-color);
            margin-left: 15%;
          }
        }
        &.sign {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 23px;
          a {
            color: #7FBA42;
          }
          span {
            color: #272727;
            margin-right: 5px;
          }
        }

        .pushable {
          margin-bottom: 50px;
        }
        .login {
          display: flex;
          position: relative;
          // background-color: #6A9772;
          height: 37px;
          width: 301px;
          text-align: center;
          justify-content: center;
          align-items: center;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 23px;
          color: var(--btn-text-color);
          background: var(--login-button-color);
          //margin-bottom: 50px;
          margin-top: -10px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
        }
        .signUp {
          display: flex;
          position: relative;
          // background-color: #6A9772;
          height: 37px;
          width: 301px;
          text-align: center;
          justify-content: center;
          align-items: center;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 23px;
          color: var(--btn-text-color);
          // margin-bottom: 50px;
          margin-top: 16px;
          background: var(--login-button-color);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
        }
      }
    }
  }
  .logo {
    background:  var(--logo-bg-img) no-repeat;
    background-size: contain;
    position: relative;
    width: 144px;
    // width: var(--login-page-logo-width);
    // height: var(--login-page-logo-height);
    height: 50px;
    margin: 0 auto;
    // margin: var(--login-page-logo-margin);
    // margin: var(--logo-margin);
  }
  input {
    box-shadow: inset 0 0 0 50px var(--registration-bg);
    -webkit-text-fill-color: var(--login-input-text-color);
    caret-color: var(--secondary);
  }
}

.loginPopUpContainer{
  width: 301px;
  margin: 0 auto;
  .loginPopUpContainer-title {
    display: none;
  }

  input {
    box-shadow: inset 0 0 0 50px var(--registration-bg);
    -webkit-text-fill-color: var(--login-input-text-color);
    caret-color: var(--secondary);
  }
}
.signUpStep{
  margin: 0 auto;
}
.passwordDots {
  -webkit-text-security: disc;
}
.OTPText{
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: var(--login-text-color);
}
.OTPSubText{
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: var(--login-text-color-secondary);
  margin-bottom: 4px;
}
.resendOTP{
  font-weight: 600;
  font-size: 8px;
  line-height: 17px;
  color: var(--login-text-color);
  margin-bottom: 10px;
}
.signUpMessageError{
  font-weight: 600;
  font-size: 10px;
  line-height: 17px;
  color: var(--login-error-color);
  margin-bottom: 1px;
}
.confirmPasswordInput{
  margin-top: 16px;
}
.stepThreeTitle{
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: var(--login-text-color);
}
//.allSetImg{
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  margin: 20px 0;
//}
.allSetImg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  position: relative;
  height: 100px;
  img {
    display: none;
  }
  &:after {
    content: "";
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: var(--login-button-text);
    top: 50%;
    transform: translateY(-50%);
    mask-image: url("ForgotPassword/images/allSet.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100px;
  }
}


.rulesEmpty{
  cursor: pointer;
  display: inline-block;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 3px;
  margin-right: 5px;
  border: 1px solid var(--register-button-boarder);
}
.signUpRulesText{
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: var(--login-text-color);
  &-terms{
    color: var(--login-link-color);
    text-decoration: underline;
  }
}
.promotionsContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.promo__inputs-select{
  height: 98px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 6px;
  border: 2px solid #DBDBDB;
  background-color: #FFFFFF;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  color: #313131;
  position: relative;
  &.active {
    border: 2px solid var(--login-link-color) !important;
}
}
.promo__inputs-select-button {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  display: flex;
  justify-content: end;
  cursor: pointer;
}

//.promo__inputs-select-button-circle {
//  height: 14px;
//  width: 14px;
//  border-radius: 50%;
//  border: 2px solid #DBDBDB;
//}
//
//.promo__inputs-select-button-circle-active {
//  height: 14px;
//  width: 14px;
//  border-radius: 50%;
//  border: 2px solid #DBDBDB;
//  background: #FACD40;
//  background-image: url('./SignUpTab/images/promotionSelected.svg');
//  background-repeat: no-repeat;
//  // background-size: 12px;
//  background-position: center;
//}
.promo__inputs-select-button-circle {
  height: 14px;
  width: 14px;
  border-radius: 17%;
  border: 1px solid #DBDBDB;
}
.promo__inputs-select-button-circle-active {
  height: 14px;
  width: 14px;
  border-radius: 17%;
  border: 1px solid #DBDBDB;
  background-color: #6A9772;
  background-image: url('./SignUpTab/images/check.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.promo__inputs-select:hover {
  border: 2px solid var(--login-link-color);
}

.promo__inputs-select-gray {
  color: var(--login-text-color-secondary);
  font-size: 14px;
}

.promo__inputs-select-details {
  color: var(--login-link-color);
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
.detailedPromotions{
  background: white;
  background-size: 86%;
  position: absolute;
  z-index: 3;
  width: 122%;
  height: 122%;
  border-radius: 10px;
  top: -12%;
  left: -12%;
  overflow: auto;
  ol{
    padding: 24px !important;
    background-color: unset !important;
    color: black !important;
  }
  .detailedPromoClose{
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 10px;
    z-index: 1;
  }
}
.forgotTitle{
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: var(--login-text-color);
  margin-bottom: 24px;
}

.closePopUpButton{
  cursor: pointer;
  position: absolute;
  right: -45px;
  svg{
    circle{
      fill: var(--login-button-color)
    }
  }
}
.loginPopUp-info {
  position: relative;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: var(--login-text-color-secondary);
  top: -55px;
  .loginPopUp-info-link {
    color: var(--login-link-color);
    cursor: pointer;
    text-decoration-line: underline;
  }
}

@media screen and (min-width: 1024px) {
  .loginPopUp {
    .pop-up_wrapper {
      position: absolute;
      // top: unset;
      width: 100%;
      height: 100%;
      background: #eeeeee;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;

      .pop-up {
        position: absolute;
        min-height: 354px;
        padding: 26px 26px 17px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 10px;
        animation: popup .5s;
        top: unset;
        left: unset;
        transform: unset;


      }

      .pop-up-content {
        display: flex;
      }
      .pop-up-content__buttons {
          .login {
            width: 300px;
          }

      }
      .loginPopUpContainer.forgot-step {
        margin-bottom: 25px;
      }
    }
  }

// -------------------------
  .pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;

  }

  .front {
    display: block;
    position: relative;
    width: 301px;
    height: 38px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: var(--login-text-color);
    margin: 0 2px;
    padding-top: 0;

    border-radius: 3px;
    will-change: transform;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
    // &.next-button.signUpButton{
    //   background: var(--signUp-button-color);
    // }
  }
  .pushable.next-button.signUpButton{
    .front.refreshBtn.login {
      background: var(--login-button-color);
    }
    //background: var(--login-button-color);
    }

  .pushable:hover {
    filter: brightness(110%);

    .shadow {
      position: absolute;
      top: -2px;
      left: 0px;
      width: 100%;
      height: 27px;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.25);
      will-change: transform;
      transform: translateY(2px);
      transition:
        transform 600ms cubic-bezier(.3, .7, .4, 1);
    }

    .edge {
      position: absolute;
      top: -2px;
      left: 2px;
      width: 94px;
      height: 37px;
      border-radius: 3px;
      filter: brightness(75%)
    }
  }

  .pushable:hover .front {
    transform: translateY(-6px);
    transition:
      transform 250ms cubic-bezier(.3, .7, .4, 1.5);
  }

  .pushable:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }

  .pushable:hover .shadow {
    transform: translateY(4px);
    transition:
      transform 250ms cubic-bezier(.3, .7, .4, 1.5);
  }

  .pushable:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }

  .pushable:focus:not(:focus-visible) {
    outline: none;
  }
  .detailedPromotions-promo-image {
    width: 70%;
    margin: 0 auto;
    padding-top: 20px;
    img {
      width: 100%;
    }
  }

  .RulesRegs {
    color: black;
  }

}

.icons {
  position: absolute;
  right: 0;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  z-index: 2;
}

.login-content__list ion-item {
  //--background: #fff!important;
  --border-width: 0 0 0 0 !important;
--highlight-background: unset !important;
  border-radius: 0px;
  // border: 2px solid #DDDDDD;
}
.b2c {

  ion-item {
    --min-height: 36px;
    --padding-start: 0px;
    --padding-end: 0px;

    --inner-padding-end: 0px;

    --inner-border-width: 0px;


  }

  ion-item+ion-item {
    margin-top: 12px;
  }

  ion-input {
    // --color: var(--login-input-text-color) !important;

    --padding-top: 0px !important;
    --padding-bottom: 0px !important;
    --padding-start: 13px !important;
    --padding-end: 0px !important;

    height: 100%;

    border-radius: 0px;

    background-color: white !important;
    // background-color: var(--registration-bg) !important;

    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 17px !important;
  }
  &_login {
    position: relative;
    margin-bottom: 12px;
    .placeholder {
      color: var(--login-input-text-color);
      position: absolute;
      z-index: 3;
      font-family: 'Lato', sans-serif ;
      font-style: normal ;
      font-weight: normal ;
      font-size: 14px ;
      line-height: 17px ;
      left: 13px;
    }
    ion-input {
      --padding-top: 0px !important;
      --padding-bottom: 0px !important;
      --padding-start: 40px !important;
      --padding-end: 0px !important;

      height: 100%;

      border-radius: 0px;

      //background-color: white !important;
      // background-color: var(--registration-bg) !important;

      font-family: 'Lato', sans-serif !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 14px !important;
      line-height: 17px !important;
    }
  }
}
ion-input.loginInput{
    //border: 2px solid var(--login-input-boarder);
    border-bottom: 2px solid var(--login-button-color);
    position: relative;
    background-color: var(--registration-bg) !important;
    color:#fff;
}
@media screen and (min-width: 1024px) {
  .signUpNew{
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;

    &_icons{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: white;
        margin: 20px;
        font-size: 13px;
      img{
        height: 13%;
        width: 13%;
        margin-right: 6px;
        margin-left: 6px;
      }
    }
  }
}
.signUpNew_wrapper{
  overflow-x: hidden;
  width: 100%;
  padding-bottom: 10px;
  &::-webkit-scrollbar {
    width: 0.3em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: darkgrey;
    // outline: 1px solid slategrey;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}
.signUpNew{
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;

  &_icons{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      color: white;
      margin-top: 20px;
      font-size: 11px;
    img{
      height: 16%;
      width: 16%;
      margin-right: 6px;
      margin-left: 6px;
    }

  }

  span{
    font-family: Poppins;
    color: white;
  }

  &_header{
    background: var(--logo-bg-img) no-repeat;
    background-size: contain;
    width: 145px;
    height: 27px;
    margin: 0 auto;
  }
  &_icons{
    &.eyeOff{
      &::after{
        content: "";
        position: absolute;
        width: 25px;
        height: 15px;
        background-color: var(--login-button-color);
        right: 0;
        top: 15px;
        mask-image: url("../../assets/images/eyeOff.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 25px;
      }
    }
    &.eye{
      &::after{
        content: "";
        position: absolute;
        width: 25px;
        height: 15px;
        background-color: var(--login-button-color);
        right: 0;
        top: 15px;
        mask-image: url("../../assets/images/eye.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 25px;
      }
    }
  } 
  &_closeButton{
    &::after{
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: var(--signUp-input-text-color);
      right: 20px;
      top: 20px;
      mask-image: url('../../assets/images/menu-icons/closePopUp.svg');
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 20px;
    }
  }
}
  .signUpNew_inputContainer{
    position: relative;
    margin-bottom: 27px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    .signUpNew__placeholder {
      color: var(--signUp-input-text-color);;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .signUpNew_inputContainer-input-wrapper {
      position: relative;
    }
    &-input{
      width: 100%;
      height: 50px;
      background: unset;
      box-shadow: unset !important;
      padding: 15px;
      margin: 0;
      border: 0;
      border-radius: 0;
      border: none;
      border-bottom: 2px solid var(--login-button-color);
      color: var(--signUp-button-text);
      font-size: 16px;
      line-height: 20px;
      -webkit-text-fill-color: var(--signUp-input-text-color) !important;
      caret-color: var(--secondary) !important;

      &:focus-visible{
        outline: unset;
      }
      &.phone {
        padding-left: 35px;
      }
    }
    &.lock{
      &::before{
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        background-color: var(--login-text-color);
        top: 15px;
        left: -10px;
        
        // transform: translateY(-50%);
        mask-image: url("SignUpTab/images/lockImg.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 18px;
      }
    }
    &_row{
      display: flex;
      justify-content: space-between;
      margin-top: 3px;
      &-error{
        font-weight: 500;
        font-size: 15px;
        color: var(--login-error-color);
        bottom: -1px;
      } 
      &-counter{
        font-weight: 400;
        font-size: 10px;
        color: var(--login-text-color);
        &.counterDone{
          color: #00A826;
        }
      }
    }
    &_otp{
      margin-top: 7px;
      display: flex;
      justify-content: end;
        &-button{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 85px;
          height: 27px;
          background: var(--login-button-color);
          color: var(--btn-text-color);
          border-radius: 21.25px;
          font-weight: 600;
          font-size: 14px;
          &.otpButtonDisabled{
            background: gray;
          }
        }
    }
    &-resendOTP{
      font-size: 12px;
      font-weight: 500;
      color: var(--login-text-color);
    }
  
}
.signUpNew_info{
    position: unset;
    top: unset;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: var(--login-text-color);
    margin-top: 25px;
    //font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    &-text{
      border-bottom: 1px solid var(--login-button-color);
      padding-bottom: 2px;
    }
    &-link {
      color: var(--login-link-color);
      cursor: pointer;
      text-decoration: underline;
    }
}

.signUpNew-separator{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 13px;
  margin-bottom: 13px;
  &-rightLine{
    height: 1px;
    width: 45%;
    transform: rotate(0.35deg);
    background: linear-gradient(45deg, transparent, var(--login-link-color));
  }
  &-leftLine{
    height: 1px;
    width: 45%;
    transform: rotate(0.35deg);
    background: linear-gradient(45deg, var(--login-link-color), transparent);
  }
  &-or{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 18px;
    color: var(--login-link-color);
    text-align: center;
  }
}
.signUpNew_whattsUp{
  margin: 24px 0;
  &_title{
    margin: 0 10%;
    text-align: center;
    // text-decoration: line-through #00A826;
    // text-decoration: underline #00A826;
    //border-bottom: 1px solid var(--login-button-color);
    //font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;    
    color: var(--login-text-color);
    line-height: 23px;
    //text-decoration: underline;
  }
  &_button{
    display: flex;
    margin: 0 auto;
    width: 295px;
    height: 54px;
    background: var(--login-button-color);
    border-radius: 10px;
    margin-top: 27px;
    align-items: center;
    &-icon{
      position: relative;
      margin-left: 15px;
      &:after {
        content: "";
        position: absolute;
        width: 45px;
        height: 45px;
        background-color: var(--btn-text-color);
        top: 50%;
        
        transform: translateY(-50%);
        mask-image: url("SignUpTab/images/whatsApp.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 45px;
      }
    }
    &-number{
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 16px;
      color: var(--btn-text-color);
      margin-left: 30%;
      
    }
  }
  
}
.blinking {
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0% { opacity: 0;}
  100% { opacity: 1;}
}

.overflow-visible{
  overflow: visible;
  z-index: 2;
}
.remove-pad-start input{
  padding-inline-start: 0px !important;
}
.login {
  &_icons{
    z-index: 5;
    & .loginInput-selectType{
      background-color: transparent;
      position: absolute;
      right: 0;
      top: 10px;
      border: none;
      color: white;
      // width:20px;
      &:focus-visible{
        border:none;
        outline: none;
      }
      & option[value="Mobile"]{
        background-image:url('../../assets/images/mobile-icon.svg');
      }
    }
    .option-menu{
      position:absolute;
      background: var(--registration-bg) 0% 0% no-repeat padding-box;
      border: 2px solid var(--login-button-color);
      right: 0px;
      top: 0px;
      font-size: 16px;
      border-radius: 4px;

      &.hide{
        display: none;
      }
      &.show{
        display: block;
      }
      .option-item{
        display: flex;
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid var(--login-button-color);
        color:#fff;
        &.selected{
          color:var(--login-button-color);

          .mob-icon,.user-icon{
            &::after{
              background-color: var(--login-button-color);
            }
          }
        }
        &:last-child{
          border-bottom: none;
        }
      }
      .title{
        margin: 3px 5px;
      }
      .mob-icon{
        width: 25px;
        position: relative;
        &::after{
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #fff;
          mask-image: url("../../assets/images/mobile-icon.svg");
          mask-repeat: no-repeat;
          mask-position: right;
          left:0;
          mask-size: 22px;
        }
      }
      .user-icon{
        width: 25px;
        position: relative;
        &::after{
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #fff;
          mask-image: url("../../assets/images/toolbar-user-icon.svg");
          mask-repeat: no-repeat;
          mask-position: right;
          left:0;
          mask-size: 16px;
        }
      }
    }
    & .icon{
      position: absolute;
      width:70px;
      height: 85%;
      border: 2px solid var(--login-button-color);
      border-radius: 3px;
      right:0;
      top:0;
      display: flex;
      cursor: pointer;
      .selected-icon{
        width: 50%;
        height: 100%;
        position: relative;
        &::after{
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: var(--login-button-color);
          // mask-image: url("../../assets/images/toolbar-user-icon.svg");
          mask-repeat: no-repeat;
          mask-position: right;
          left:0;
          // mask-size: 16px;
        }

        &.mob{
          &::after{
            mask-image: url("../../assets/images/mobile-icon.svg");
            mask-size: 22px;
          }
        }
        &.user{
          &::after{
            mask-image: url("../../assets/images/toolbar-user-icon.svg");
            mask-size: 16px;
          }
        }
      }
      .arrow-icon{
        width: 50%;
        height: 100%;
        position: relative;
        &::after{
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: var(--login-button-color);
          mask-image: url("../../assets/images/down-arrow.svg");
          mask-repeat: no-repeat;
          mask-position: center;
          left:0;
          mask-size: 12px;
        }
      }
    }
    &.eyeOff{
      &::after{
        content: "";
        position: absolute;
        width: 25px;
        height: 15px;
        background-color: var(--login-button-color);
        right: 0;
        top: 10px;
        mask-image: url("../../assets/images/eyeOff.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 22px;
      }
    }
    &.eye{
      &::after{
        content: "";
        position: absolute;
        width: 25px;
        height: 15px;
        background-color: var(--login-button-color);
        right: 0;
        top: 10px;
        mask-image: url("../../assets/images/eye.svg");
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 22px;
      }
    }
  }
}


@media screen and (min-width: 1024px) {
  .b2c_login.forgot-pass {
    ion-input {
      --padding-start: 15px !important;
    }
  }
}

@media screen and (min-height: 650px) {
  .loginPopUp .pop-up_wrapper {
    overflow: scroll;
  }
}


@media screen and (max-width: 1024px) {
  // .loginPopUp .pop-up_wrapper .pop-up .popUpAnimation{
  //   animation: popup .5s;
  // }
  .loginPopUp {
    // transition: all 0.4s;
    .pop-up_wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #EEEEEE;
      z-index: 10;
      display: block;
      justify-content: center;
      align-items: center;
      overflow-x: hidden;

      .pop-up {
        width: 100%;
        min-height: 100%;
        max-width: unset;
        max-height: unset;
        background: var(--registration-bg);
        //border-radius: 10px;
        padding: 10px 24px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto auto;
        left: unset;
        top: unset;
        transform: unset;

      }
      .pop-up.login-desktop {
        background: rgba(0, 0, 0, 0.4);
      }
      .pop-up-content_title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 10px;
        &-step {
          font-family: 'Poppins';
          font-size: 16px;
          font-weight: 400;
          color: var(--login-text-color);
        }
        img {
          cursor: pointer;
        }
      }
      .loginPopUpContainer {
        width: 100%;
        padding: 0 10px;
        .forgotTitle {
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
          margin-bottom: 170px;
        }
        .signUpTitle {
          font-weight: 600;
          font-size: 25px;
          line-height: 30px;
          margin-bottom: 140px;
          color: var(--login-text-color);
        }
        .OTPText {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 5px;
        }
        .OTPSubText {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--login-text-color-secondary);
          margin-bottom: 10px;
        }
        .stepThreeTitle {
          font-weight: 600;
          font-size: 25px;
          span {
            display: inline-block;
            width: 100%;
          }
          span:first-child {
            margin-bottom: 10px;
          }
        }
        .stepThreeTitle.promo {
          font-size: 20px !important;
          font-weight: 500 !important;
        }
        .signUpRulesText {
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
        }
        .allSetImg {
          margin: 45px 0;
          position: relative;
          height: 100px;
          img {
            display: none;
          }
          &:after {
            content: "";
            position: absolute;
            width: 100px;
            height: 100px;
            background-color: var(--login-button-text);
            top: 50%;
            transform: translateY(-50%);
            mask-image: url("ForgotPassword/images/allSet.svg");
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 100px;
          }
        }
        .terms-conditions {
          text-align: center;
          //margin-bottom: 85px;
        }
        .signUpMessageError {
          position: absolute;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: var(--login-error-color);
          top: 65px;
          &.otpMessage {
            position: relative;
            top: 25px;
            height: 10px;
          }
        }

      }
      .loginPopUpContainer.signUpStep-one {
          margin-bottom: 45px;
      }
      .loginPopUpContainer.focus {
        &.signUpStep-one {
          margin-bottom: 15px;
        }
        .forgotTitle {
          margin-bottom: 20px;
        }
        .signUpTitle {
          margin-bottom: 20px
        }
      }
      .loginPopUp-info {
        position: unset;
        top: unset;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        color: var(--login-text-color);
        margin-top: 20px;
        .loginPopUp-info-link {
          color: var(--login-link-color);
          cursor: pointer;
        }
      }
      .signUpStep-promo-image {
        width: 100%;
        margin-bottom: 10px;
        img {
          width: 110%;
          margin-bottom: 5px;
        }
      }
      .promotionsContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 20px;
        .promo__inputs-select {
          height: 80px;
          width: 100%;
        }
        .promo__inputs-select-gray {
          color: #313131;
          font-weight: 400;
          font-size: 15px;
        }
        .promo__inputs-select-details {
          font-weight: 600;
          font-size: 16px;
          color: var(--login-link-color);
        }
      }
      .pop-up-content__buttons {
          display: flex;
          justify-content: center;
          &-new {
            justify-content: flex-start;
          }
          &.forgot {
            width: 100%;
            justify-self: start;
            margin-top: unset;
            margin-left: unset;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 23px;
            text-decoration-line: underline;
            color: var(--login-link-color);
          }
          &.forgot-step {
            margin-top: 65px;
            width: 100%;
            &.two-buttons {
              margin-top: 10px;
              justify-content: space-between;
            }
          }
        }
      .pushable {
        width: 136px;
        height: 50px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        .front.refreshBtn {
          font-weight: 600;
          font-size: 18px;
          color: var(--login-button-text);

          img {
            margin-left: 7px;
          }

        }
        &.next-button {
          background: var(--login-button-color);
          position: relative;
        }
        //&.next-button.signUpButton{
        //  background: var(--login-button-color);
        //}
        .next-button-icon {
          width: 20px;
          display: inline-block;
          &:after {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: var(--login-button-text);
            top: 50%;
            transform: translateY(-50%);
            mask-image: url("../../../src/assets/images/arrow.svg");
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 9px;
          }
        }
        &.back-button {
          background: transparent;
          box-shadow: unset;
          .front.refreshBtn {
           span {
             color: var(--login-text-color);
           }
          }
        }

      }
    }
  }
  .b2c_login {
    margin-bottom: 30px;
  }
  button:disabled {
    background: #979797 !important;
  }
  .detailedPromotions {
    background: #FFFFFF;
    background-size: 69%;
    position: absolute;
    z-index: 3;
    width: 90%;
    height: 85%;
    border-radius: 10px;
    top: 8%;
    left: 5%;
    /* margin: 0 auto; */
    overflow: auto;

    ol {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 16px;
      color: #000000 !important;
    }
  }
  .promo__inputs-select-button-circle {
    height: 14px;
    width: 14px;
    border-radius: 17%;
    border: 1px solid #DBDBDB;
  }
  .promo__inputs-select-button-circle-active {
    height: 14px;
    width: 14px;
    border-radius: 17%;
    border: 1px solid #DBDBDB;
    background-color: #6A9772;
    background-image: url('./SignUpTab/images/check.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  .login-step-wrapper {
    position: absolute;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    height: 385px;
    width: 90%;
    background: var(--login-popUp-bg);
    border-radius: 10px;
    animation: popup .5s;

    .pop-up-content_title {
      margin: unset;
      padding: 21px 20px 17px 20px;
      .pop-up-content_title-logo {
        background:  var(--logo-bg-img) no-repeat;
        background-size: contain;
        width: 145px;
        height: 27px;
        margin: 0 auto;
      }
    }
    .forgot-pass-button {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      text-decoration-line: underline;
      color: var(--login-link-color);
      padding-top: 10px;
    }
    .pushable.log-in {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      .front.refreshBtn {
        font-weight: 500;
        font-size: 15px;
      }
    }

    .register-button {
      width: 100%;
      height: 38px;
      align-self: center;
      text-align: center;
      display: flex;
      border: 2px solid var(--register-button-boarder);
      background-color: var(--register-button-color);
      border-radius: 3px;
      align-items: center;
      justify-content: center;
      .register-button-link {
        font-weight: 500;
        font-size: 15px;
        color: var(--register-button-text-color);
      }
    }
    .pop-up-content__buttons {
      padding: 15px 0;
      .pushable {
        width: 100%;
        height: 38px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--login-button-color);
      }
    }
    .login-step-content {
      padding: 0 33px;
      .login-step-content__title {
        font-weight: 500;
        font-size: 20px;
        color: var(--login-text-color);
        padding-bottom: 20px;
      }
      .b2c_login {
        margin-bottom: 15px;
      }
    }
  }
  .pushable {
    margin-bottom: unset !important;
  }
  .loginPopUp .pop-up_wrapper .pop-up.login-desktop.modalUp {
    justify-content: start;
  }

  .detailedPromotions-promo-image {
    width: 70%;
    margin: 0 auto;
    padding-top: 20px;
    img {
      width: 100%;
    }
  }

  //ion-item style start
  .login-content__list {
  ion-item {
    --min-height: 60px !important;
    --padding-start: 0px;
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --inner-border-width: 0px;
  }
    .b2c_login {
      .placeholder {
        font-size: 16px;
        color: var(--signUp-input-text-color);
      }
      ion-input {
        // --color: var(--login-input-text-color) !important;
        --padding-start: 45px !important;
        height: 100%;
        font-family: 'Lato', sans-serif !important;
        font-size: 16px !important;
        line-height: 20px !important;
        // background-color: var(--registration-bg) !important;
      }
    }
    .b2c_login.forgot-pass {
      ion-input {
        --padding-start: 15px !important;
        font-size: 16px !important;
      }
      .login-content__list__input-wrapper {
        position: relative;
        margin-bottom: 60px;
        margin-bottom: 45px;
        div.resendOTP {
          position: absolute;
          top: 65px;
          span {
            font-weight: 500;
            font-size: 14px;
            color: var(--login-text-color);
          }
        }
      }
    }
  }
  ion-item.confirmPasswordInput {
    --min-height: 60px !important;
  }
  ion-item.login {
    --min-height: 45px !important;
  }
  //ion-item {
  //  --min-height: 60px !important;
  //  --padding-start: 0px;
  //  --padding-end: 0px;
  //  --inner-padding-end: 0px;
  //  --inner-border-width: 0px;
  //  --background: var(--gray);
  //}
  //ion-item style end
  .signUpNew-separator-or{
    margin: 0 15px;
  }
}

.signUpSpinner{
  position: absolute;
  top: -9px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
 .popUpAnimation{
  animation: popup .5s;
}

.signUpInput{
  color: var(--signUp-input-text-color) !important;
  border: 1px solid var(--signUp-input-text-color) !important;
  // --signUp-input-boarder: gray;
  // --signUp-input-text-color: gray;
}

@keyframes popup {
  0%{
    opacity: 0;
    transform: scale(0.1);
  }
  10%{
    opacity: 0;
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }

}
// #one {
//   transform:scaleY(.01) scaleX(0);
//   animation:unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
//   .modal-background {
//     .modal {
//       transform:scale(0);
//       animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
//     }
//   }
//   &.out {
//     transform:scale(1);
//     animation:unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
//     .modal-background {
//       .modal {
//         animation: zoomOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
//       }
//     }
//   }
// }
@keyframes unfoldIn {
  0% {
    transform:scaleY(.005) scaleX(0);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(1) scaleX(1);
  }
}


@keyframes unfoldOut {
  0% {
    transform:scaleY(1) scaleX(1);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(.005) scaleX(0);
  }
}

@keyframes zoomIn {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
}
