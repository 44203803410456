
.pop-upLastResult-content__results-poker6 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 209px;
  margin-top: -12px;
}

.pop-upLastResult-content__results {
  &-name{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: var(--color-exchange);
  }
  &-cards{
    display: flex;
    width: 80px;
    height: 44px;
    justify-content: space-between;
    &-card{
      display: block;
      width: 32px;
      height: 44px;
      box-shadow: 0px 0px 5px grey;
    }
  }
  .pop-upLastResult-content__poker6-board-cards {
    width: 218px;
  }
  .pop-upLastResult-content__results-poker6-item {
    position: relative;
    margin-bottom: 32px;
    .pop-upLastResult-content__results-namee {
      display: block;
      margin-bottom: 15px;
      text-align: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: var(--color-exchange);
    }
    .pop-upLastResult-content__results-cards {
      margin: 0;
    }
  }
  .pop-upLastResult-content__results-poker6-item.poker6-item-board {
    .pop-upLastResult-content__results-namee{
      width: 100%;
      text-align: center;
      display: block;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: var(--color-exchange);
    }
  }
}

.poker6Result{
  width: 100%;
  &-content{
    width: 100%;
    height: 31px;
    background: var(--background-player-exchange);
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.05em;
    color: var(--color-secondary-exchange);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
}
