
.race20CardsContent {
  padding: 5px 30px;
  .race20CardsContent__players-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12.5%;
    grid-row-gap: 10px;
  }
  .race20CardsContent__player {
    display: flex;
    height: 28px;
    align-items: center;
  }
  .race20CardsContent__player-white-icon {
    padding-right: 30px;
    //width: 30px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .race20CardsContent__player-cards {
    img {
      width: 19px;
      height: 28px;
    }
    img:not(:last-child) {
      margin-right: 5px;
    }
  }
  .race20CardsContent__total-card-points-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12.5%;
    span {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 12px;
      color: var(--color-secondary-exchange);
    }
  }
  .race20CardsContent__total-card-wrapper {
    text-align: right;
  }
}

@media (max-width: 1024px) {
  div.race20CardsContent {
    padding: 5px 10px;
    .race20CardsContent__player-white-icon {
      padding-right: 10px;
      img {
        height: 20px;
      }
    }
    .race20CardsContent__player,
    .race20CardsContent__player-cards {
      height: 28px;
    }
    .race20CardsContent__player-cards {
      img {
        width: 19px;
        height: 28px;
      }
      img:not(:last-child) {
        margin-right: 2.5px;
      }
    }
  }
}
