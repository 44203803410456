
  .TrapCardsWrapper{
    display: flex;
    justify-content: space-around;
  }

  .TrapCardsContent{
    padding-top: 4px;
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    &-info{
      display: flex;
    }
    &-separation{
      padding-left: 4px;
      color: var(--color-secondary-exchange);
      font-size: 12px;
      font-weight: 700;
    }
    &-counter{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: var(--color-total-exchange);
      padding-left: 4px;

    }
    &-name{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: var(--color-secondary-exchange);
    }
    &-total{
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      &__name{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--color-secondary-exchange);
      }
      &__cards{
        display: flex;
        &-card{
          display: inline-block;
          width: 24px;
          height: 33px;
          margin-right: 3px;
        }
      }
    }
  }
  .hidden-card{
    display: none;
  }

  @media screen and  (min-width: 1024px) {
    //.TrapCardsContent{
    //  align-items: flex-start;
    //}
    //.TrapCardsContent-total {
    //  justify-content: flex-start;
    //}
  //  //.gameresult.gameresult-trap {
  //  //  height: unset;
  //  //  .gameresult-cardsplace {
  //  //    height: 82px;
  //  //  }
  //  //}
  //  .TrapCardsWrapper{
  //    display: flex;
  //    justify-content: space-around;
  //    height: 100%;
  //    .TrapCardsContent {
  //      padding: unset;
  //      justify-content: center;
  //    }
  //    .TrapCardsContent-info {
  //      font-family: 'Poppins';
  //      font-style: normal;
  //      font-weight: 500;
  //      font-size: 12px;
  //      margin-bottom: 5px;
  //      color: var(--color-secondary-exchange);
  //      .TrapCardsContent-name,
  //      .TrapCardsContent-separation,
  //      .TrapCardsContent-counter {
  //        font-size: 12px;
  //      }
  //      .TrapCardsContent-counter {
  //        font-weight: 600;
  //      }
  //    }
  //  }
  //  .TrapCardsContent-total {
  //    height: 45px;
  //    width: 100% !important;
  //    display: flex;
  //    justify-content: flex-start;
  //    align-items: unset;
  //  }
  //  .TrapCardsContent-total__cards {
  //    height: unset;
  //    width: unset;
  //    display: flex;
  //    justify-content: unset;
  //  }
  //  .TrapCardsContent-total__cards-card {
  //    width: 24px;
  //    height: 33px;
  //    margin-right: 3px;
  //  }
  }

