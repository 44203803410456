
.gameresult.gameresult-poker-six-player {
  .pokerCardsContent-total__cards {
    height: 33px;
  }
  .pokerCardsContent {
    height: 100%;
    width: 100%;
    max-width: 570px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-player-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 200px;
    }
    &-total__cards-card {
      width: 24px;
      height: 33px;
      margin: 0 2.5px;
    }
    .pokerCardsContent-total__name {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      //line-height: 18px;
      color: var(--color-secondary-exchange);
      margin-right: 20px;
    }
  }

  .pokerCardsContent-total.pokerCardsContent-board{
    display: flex;
    justify-content: center;
    align-items:center;
  }

  @media (max-width: 1024px) {
    div.poker20CardsContent {
      //img.poker20CardsContent-total__cards-card {
      //  width: 24px;
      //  height: 33px;
      //  margin-right: 5px;
      //}
      //.poker20CardsContent-total__name {
      //  font-size: 12px;
      //}
      &-player-wrapper {
        max-width: 135px;
      }
    }
  }

  //@media (min-width: 1024px) {
  //  .gameresult.gameresult-poker-six-player {
  //    height: unset;
  //    .gameresult-cardsplace {
  //      height: 82px;
  //    }
  //  }
  //}

}



