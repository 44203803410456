@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.baccarat {
  .baccarat-table {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    color: var(--color-secondary-exchange);
    padding-bottom: 50px;
    position: relative;
    p {
      margin: unset;
    }
    &__header {
      display: grid;
      grid-template-columns: 0.88fr 0.72fr 0.72fr 0.88fr;
      grid-gap: 10px;
      margin: 33px 13px 32px 12px;
    }
    &__header-item {
      background: var(--background-player-exchange);
      max-height: 60px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      cursor: pointer;
      padding: 9px 0 5px 0;
      &-name{
        font-weight: 400;
        font-size: 10px;
        text-transform: uppercase;
      }
      .baccarat-exposure-modern {
        &--pos {
          color: var(--positive-exchange);
        }
        &--neg {
          background:var(--negative-exchange);
        }
      }

    }
    &__body {
      padding: 0 10px;
      &-players-wrapper {
        display: flex;
        margin-bottom: 5px;
        position: relative;
        align-items: center;
        justify-content: space-between;
      }
      .baccarat-table__body-player {
        display: flex;
        position: relative;
        justify-content: center;
        flex-direction: column;
        .baccarat-exposure-box-modern {
          position: absolute;
          bottom: 133px;
          width: fit-content;
          transform: translate(0, 100%);
        }
      }
      &-player-wrapper {
        background: #3075D0;
        border-radius: 10px 0 0 0;
        width: 50%;
        text-align: right;
        .baccarat-table__body-player {
          padding: 10px 35px 15px 0;
          .baccarat-exposure-box-modern {
            right: 52%;
          }
        }
        .baccarat-table__body-player.player-pair {
          align-items: center;
          height: 50px;
          border-top: 2px solid #FFFFFF;
          padding-right: 35px;
          .baccarat-exposure-box-modern {
            right: 52%;
            left: unset;
            bottom: -3px;
          }
        }
        .baccarat-table__card-rotated {
          transform: rotate(-90deg) translateY(-5px);
        }
      }
      &-banker-wrapper {
        background: #D3393D;
        border-radius: 0 10px 0 0;
        width: 50%;
        .baccarat-table__body-player {
          padding: 10px 0 15px 35px;
          .baccarat-exposure-box-modern {
            left: 52%;
          }
        }
        .baccarat-table__body-player.banker-pair {
          align-items: center;
          height: 50px;
          border-top: 2px solid #FFFFFF;
          padding-left: 35px;
          .baccarat-exposure-box-modern {
            left: 52%;
            bottom: -3px;
          }
        }
        .baccarat-table__card-rotated {
          transform: rotate(90deg) translateY(-5px);
        }
      }
      &-tie-wrapper {
        position: absolute;
        top: -2px;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        width: 7%;
        max-width: 92px;
        min-width: 57px;
        background: #11B24B;
        border: 2px solid #F3F3F3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 50px;
        z-index: 2;
        .baccarat-exposure-box-modern {
          position: absolute;
          left: 50%;
          bottom: -3px;
          width: fit-content;
          transform: translate(-50%, 100%);
        }
      }
    }
    &__body-player-name-price {
      padding: 0 5px;
    }
    &__body-name {
      font-weight: 500;
      font-size: 12px;
    }
    &__body-price {
      font-weight: 700;
      font-size: 12px;
    }
    &__cards {
      margin-top: 10px;
      height: 44px;
      img {
        width: 32px;
        height: 44px;
        margin: 0 5px;
      }
    }
    .baccarat-table__body-player.lock-ui,
    .baccarat-table__header-item.lock-ui {
      position: relative;
      pointer-events: none;
      &:hover {
        cursor: unset;
      }
      &:before {
        content: '';
        background-color: var(--background-closed-exchange);
        background-image: url('../../../assets/images/exchangeGames/lock-white.svg');
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    }
    .baccarat-table__header-item.lock-ui {
      opacity: unset;
      &:before {
        content: '';
        background-color: var(--background-closed-exchange);
        background-image: url('../../../assets/images/exchangeGames/lock-white.svg');
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        border-radius: 5px;
      }
    }
    .baccarat-table__header-item.lock-ui.winner {
      &:before {
        background-color: var(--background-closed-exchange);
        background-image: url(../../../assets/images/TrophyTeenPattiTest.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .lock-ui.winner {
      &:before {
        content: '';
        background-color: var(--background-closed-exchange);
        background: url(../../../assets/images/TrophyTeenPattiTest.svg);
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
      }
    }
    .player-pair.lock-ui {
      &:before {
        background-color: var(--background-closed-exchange);
        background-position-y: center;
        background-position-x: 45%;
      }
    }
    .banker-pair.lock-ui {
      &:before {
        background-color: var(--background-closed-exchange);
        background-position-y: center;
        background-position-x: 55%;
      }
    }
    .banker.lock-ui {
      &:before {
        background-color: var(--background-closed-exchange);
        background-position-y: 20%;
        background-position-x: 80%;
        //top: -25%;
        //left: 30%;
      }
    }
    .player.lock-ui {
      &:before {
        background-color: var(--background-closed-exchange);
        background-position-y: 20%;
        background-position-x: 20%;
      }
    }
    //.BetPlacing-container {
    //  position: absolute;
    //  z-index: 9;
    //  top: -7px;
    //  right: 0;
    //  left: 0;
    //  bottom: 0;
    //  display: flex;
    //  justify-content: center;
    //  align-items: flex-start;
    //  overflow: visible;
    //}
  }
  .baccarat-exposure-box-modern {
    font-size: 11px;
    position: unset;
    font-weight: 600;
    font-family: 'Poppins';
  }
  .baccarat-exposure-modern {
    &--pos {
      color: var(--positive-secondary-exchange);
    }
    &--neg {
      color: var(--negative-secondary-exchange);
    }
  }
  .resultsList {
    margin: unset;
  }
}



@media (min-width: 1024px) {
  .baccarat {
    .baccarat-table {
      font-size: 15px;
      color: #FFFFFF;
      padding-bottom: 50px;
      &__header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 6px;
        margin: 33px 0;
      }
      &__header-item {
        max-height: unset;
        height: 90px;
        padding: 5px 0 5px 0;
        &-name{
          font-weight: 500;
          font-size: 15px;
          text-transform: unset;
        }
      }
      &__body {
        padding: unset;
        .baccarat-table__body-player {
          .baccarat-exposure-box-modern {
            position: absolute;
            bottom: 160px;
            width: fit-content;
            transform: translate(0, 100%);
          }
        }
        &-player-wrapper {
          .baccarat-table__body-player {
            padding: 14px 55px 17px 0;
            .baccarat-exposure-box-modern {
              right: 52%;
            }
          }
          .baccarat-table__body-player.player-pair {
            height: 60px;
            .baccarat-exposure-box-modern {
              right: 52%;
              left: unset;
              bottom: -3px;
            }
          }
          .baccarat-table__card-rotated {
            transform: rotate(-90deg) translateY(-5px);
          }
        }
        &-banker-wrapper {
          border-radius: 0 10px 0 0;
          .baccarat-table__body-player {
            padding: 14px 0 17px 55px;
            .baccarat-exposure-box-modern {
              left: 52%;
            }
          }
          .baccarat-table__body-player.banker-pair {
            height: 60px;
            .baccarat-exposure-box-modern {
              left: 52%;
              bottom: -3px;
            }
          }
          .baccarat-table__card-rotated {
            transform: rotate(90deg) translateY(-5px);
          }
        }
        &-tie-wrapper {
          width: 10%;
          .baccarat-exposure-box-modern {
            position: absolute;
            left: 50%;
            bottom: -3px;
            width: fit-content;
            transform: translate(-50%, 100%);
          }
        }
      }
      &__body-player-name-price {
        padding: 0 5px;
      }
      &__body-name {
        font-size: 15px;
        font-weight: 500;
      }
      &__body-price {
        font-size: 15px;
        font-weight: 700;
      }
      &__cards {
        margin-top: 10px;
        height: 56px;
        img {
          width: 40px;
          height: 56px;
          margin: 0 6px;
        }
      }
    }
    .baccarat-exposure-box-modern {
      font-size: 13px;
      position: unset;
      font-weight: 600;
      font-family: 'Poppins';
    }
    .baccarat-exposure-modern {
      &--pos {
        color: var(--positive-secondary-exchange);
      }
      &--neg {
        color: var(--negative-secondary-exchange);
      }
    }
    .resultsList {
      margin: unset;
    }
  }
}
