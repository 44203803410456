.andar-bahar-betting {
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-right: 30px;
  &__games {
    display: flex;
  }
  &__games-item {
    display: flex;
    flex-direction: column;
    min-width: max-content;
    margin-right: 55px;
  }
  &__games-item-title {
    padding-left: 7px;
  }
  &__games-item-cards {
    padding-top: 7px;
    max-width: 155px;
    img {
      width: 32px;
      height: 44px;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
      margin-left: 6px;
    }
  }
  .winnerCup {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -15px;
      left: 30px;
      background: url('../../../assets/images/TrophyTeenPattiTest.svg');
      background-position: center;
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }
  &__info-column {
    min-width: 80px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 50%;
      text-align: right;
      font-weight: 400;
      color: var(--background-secondary-player-exchange);
      white-space: nowrap;
    }
    &-results {
      width: 50%;
      font-weight: 500;
      color: var(--white);
      padding-left: 4px;
    }
  }
}

@media (max-width: 1280px) {
  div.andar-bahar-betting {
    .andar-bahar-betting__games-item {
      margin-right: 25px;
    }
  }
}

@media (max-width: 1024px) {
  div.andar-bahar-betting {
    margin-right: 0;
    flex-direction: column;

    .andar-bahar-betting__games {
      justify-content: space-between;
    }
    .andar-bahar-betting__info-row {
      margin-top: 30px;
      .andar-bahar-betting__info-row-title,
      .andar-bahar-betting__info-row-results {
        width: unset;
      }
    }
    .andar-bahar-betting__games-item-cards {
      max-width: 130px;
      img {
        width: 26px;
        height: 36px;
        margin-top: 10px;
      }
    }
    .andar-bahar-betting__games-item:nth-child(1) {
      order: 1;
    }
    .andar-bahar-betting__games-item:nth-child(2) {
      order: 3;
    }
    .andar-bahar-betting__games-item:nth-child(3) {
      order: 2;
    }
    .andar-bahar-betting__games-item {
      margin-right: 0;
    }
  }
}
