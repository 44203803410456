.baccarat-cards {
  width: 100%;
  height: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-evenly;
  padding-top: 2px;
  margin: 0 auto;
  &__player {
    display: flex;
    flex-direction: column;
    &-name {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 12px;
      color: var(--color-secondary-exchange);
      white-space: nowrap;
    }

    &-cards {
      height: 33px;
      display: flex;
      &-card {
        height: 33px;
        width: 25px;
        margin-right: 5px;
      }
      &-card.rotated {
        transform: rotate(90deg) translateY(-5px);
      }
    }
  }
  &__player.first-player {
    align-items: end;
    .baccarat-cards__player-cards {
      .baccarat-cards__player-cards-card {
        margin: 0 0 0 5px;
      }
     .baccarat-cards__player-cards-card.rotated {
       transform: rotate(-90deg) translateY(-5px);
     }
    }
  }
}

//@media (min-width: 1024px) {
//  .gameresult.gameresult-baccarat {
//    height: unset;
//    .gameresult-cardsplace {
//      height: 82px;
//    }
//  }
//  .baccarat-cards {
//    &__player {
//      &-name {
//        font-size: 15px;
//      }
//      &-cards {
//        height: 45px;
//        display: flex;
//        &-card {
//          height: 45px;
//          width: 33px;
//          margin-right: 10px;
//        }
//        &-card.rotated {
//          transform: rotate(90deg) translateY(-10px);
//        }
//      }
//    }
//    &__player.first-player {
//      align-items: end;
//      .baccarat-cards__player-cards {
//        .baccarat-cards__player-cards-card {
//          margin: 0 0 0 10px;
//        }
//        .baccarat-cards__player-cards-card.rotated {
//          transform: rotate(-90deg) translateY(-10px);
//        }
//      }
//    }
//  }
//}
