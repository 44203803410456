@import url('https://fonts.googleapis.com/css2?family=Peralta&display=swap');

.bgSlots {
  background: #142326;
  height: 100%;
  width: 100%;
  margin-top: 137px;
}

.Slots-header {
  position: relative;
  height: 576px;
  background: linear-gradient(180deg, #008DC4 0%, #00A2D8 60%, #00A2D8 60.02%, #009FD6 80%, #009FD6 80.65%, #00C2E8 100%);

  &__img {
    width: 100%;
    position: relative;

    &-img {
      width: 100%;
    }

    &-arrow {
      position: absolute;
      left: 50%;
      bottom: 30px;
    }
  }

  &__text {
    position: absolute;
    // margin: 0 auto;
    width: 100%;
    top: 35%;
    left: 48%;
    transform: translate(-50%, -50%);
    // bottom: 0;
    // right: 0;
    // width: 40%;
    // height: 55%;
    // z-index: 2;
    &-name {
      font-family: 'Peralta';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 65px;
      letter-spacing: 0.09em;
      text-align: center;
      color: #FFFFFF;
      text-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &__filter {
    position: absolute;
    width: 90%;
    top: 30px;
    left: 90px;

    &-row {
      display: flex;
      flex-wrap: wrap;
      width: 70%;
      max-width: 880px;

      &-item {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 40px;
        height: 40px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: #029ED2;
        margin: 0 10px 26px 10px;
        cursor: pointer;
      }

      &-search {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        color: #fff;
        height: 40px;
        width: 420px;
        padding-left: 70px;
        position: relative;

        &::placeholder {
          color: #fff;
        }
      }

      &-icon {
        position: absolute;
        top: 11px;
        left: 20px;
        width: 20px;
      }
    }
  }
}

.Slots-content {
  position: relative;
  // background: #142326;
  // height: 100vh;
  padding-top: 30px;
  background: url('../../assets/images/liveCasinoLobby/bgLinesCasino.jpg');
  // background-size: cover;
  position: relative;
  background-size: 100%;

  &__arrow {
    position: absolute;
    top: -30px;
    left: 50%;
  }

  &__menu {
    position: absolute;
    width: 90%;
    max-width: 1300px;
    position: relative;
    //top: 30px;
    overflow: auto;
    margin: auto;
    left: 0;
    right: 0;

    &-games {
      margin-top: 50px;

      &__allgames {
        height: 100%;

        &-title {
          position: relative;
          display: flex;
          justify-content: space-between;
          margin: 50px 0 20px 0;
          &-ref{
            position: absolute;
            top: -170px;
          }

          &-name {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            letter-spacing: 0.09em;
            color: #029ED2;
            text-transform: capitalize;
          }

          &-seeAll {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.09em;
            color: #029ED2;
            cursor: pointer;
          }
        }

        &-items {
          display: flex;
          flex-wrap: wrap;
          height: 440px;
          overflow: hidden;
          // width: 100%;
          // flex-direction: column;
          

          .seeAll {
            height: 100%;
          }

          &-item {
            height: 203px;
            width: 163px;
            margin: 0 5px 19px 5px;
            cursor: pointer;
            // border: 1px solid #fff;
            @media screen and (max-width: 1024px) {
            overflow-x: auto;
          }
        }
      }
    }
  }
  }

  




  .inputContainer {
    position: relative;
    width: 480px;
    height: 41px;
    // margin: 0 auto 40px;
    border-radius: 30px;
    background: #fff;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      background: linear-gradient(to right,
              #6C23A9,
              #F8A0EF,
              #DA9EF6,
              #A7B0FF,
              #AFD2FD,
      );
      border-radius: 30px;
      // z-index: -1;

    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 5%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background: url('../../assets/images/searchIconDesk.svg') no-repeat;
      background-size: cover;
      z-index: 1;

    }

    &__input {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 478px;
      padding-left: 40px;
      border-radius: 30px;
      z-index: 1;
      background: #000;
      color: #fff;
      border: none;

      &:focus, &:active {
        border: none;
      }
    }
  }


  .a {
    position: relative;
    padding: 10px 0px;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    width: 200px;
    height: 40px;
    overflow: hidden;
    border-radius: 40px;
    text-align: center;
    // margin-left: auto;
    // margin-right: auto;
    // margin-bottom: 40px;

    cursor: pointer;
  }

  .a span {
    position: relative;
    // color: #fff;
    // font-size: 20px;
    // font-family: Arial;
    // letter-spacing: 8px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: #FFFFFF;
    z-index: 1;
  }

  .a .liquid {
    position: absolute;
    top: -90px;
    left: 0;
    width: 200px;
    height: 200px;
    background: #6D0CB4;
    box-shadow: inset 0 0 50px rgba(0, 0, 0, .5);
    transition: .5s;
  }

  .a .liquid::after,
  .a .liquid::before {
    content: '';
    width: 200%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -75%);
    background: #000;
    opacity: 0.8;
  }

  .a .liquid::before {
    border-radius: 45%;
    background: rgba(20, 20, 20, 1);
    animation: animate 5s linear infinite;
  }

  .a .liquid::after {
    border-radius: 40%;
    background: rgba(20, 20, 20, .5);
    animation: animate 10s linear infinite;
  }

  .a:hover .liquid {
    top: -120px;

  }

  @keyframes animate {
    0% {
      transform: translate(-50%, -75%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -75%) rotate(360deg);
    }
  }
}

.altBackground {
  background: linear-gradient(#000, 85%, #6c23e0);
  opacity: 0.95;
  color: #f1efea;
  height: 100%;
  border-radius: 8px;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
  }

  span {
    position: absolute;
    font-family: 'Poppins';
    font-weight: 500;
    color: #fff;
    left: 25%;
    right: 25%;
    top: 40%;
    z-index: 1;
    text-align: center;
  }
}


// @media screen and (max-width: 1200px) {
//     .liveCasino{
//         &-header{
//             &__text{
//                 top: 75px;
//             }
//         }
//     }
// }

// @media screen and (max-width: 1440px) {
//     .liveCasino-content{
//         &__img1{
//             top: 20%;
//         }
//         &__img2{
//             top: 65%;
//         }
//     }
// }

// @media screen and (max-width: 1620px) {
//     .liveCasino-content{
//         &__img1{
//             top: 20%;
//         }
//         &__img2{
//             top: 65%;
//         }
//     }
// }

// @media screen and (max-width: 1920px) {
//     .liveCasino-content{
//         &__img1{
//             top: 25%;
//         }
//         &__img2{
//             top: 70%;
//         }
//     }
// }


@media screen and (max-width: 1024px) {
  .bgCasino {
    margin-top: 0;
  }
  // .lamp{
  //   display: none;
  // }
  .liveCasino-header__text {
    top: 40%;
    left: 25%;

    &-name {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: 0.09em;
      // color: #FFFFFF;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &-credo {
      font-family: 'Nadeem';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.09em;
      color: #FFFFFF;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-top: 32px;
    }
  }

  .Slots-content__menu-games__allgames-items{    
    display: flex;
    flex-wrap: wrap;
    height: 470px;
    overflow: auto;
    width: 100%;
    flex-direction: column;
  }  
  
  .liveCasino-content__menu-games__allgames-title-name {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.09em;
    color: #FFFFFF;
  }
  .liveCasino-content__menu-games__allgames {
    margin-top: -25px;
  }
  .liveCasino-content__menu-games__allgames-title-seeAll {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.09em;
    color: #FFFFFF;
  }
  .liveCasino-content__menu-games__allgames-items {
    // justify-content: space-around;
  }
}

.liveCasino-content__menu-filerMobile {
  display: flex;
  width: 100%;
  padding: 0px 10px 0px 10px;
  justify-content: space-between;

  &-column {
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 24px;
      background: #6D0CB4;
      border-radius: 30px;
      margin-bottom: 8px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }
}

.inputContainerMobile {
  position: relative;
  width: 146px;
  height: 22px;
  // margin: 0 auto 40px;
  border-radius: 20px;
  background: #fff;
  text-align: center;
  margin-right: 2px;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    background: linear-gradient(to right,
            #6C23A9,
            #F8A0EF,
            #DA9EF6,
            #A7B0FF,
            #AFD2FD,
    );
    border-radius: 20px;
    // z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(-50%, -50%);
    width: 13px;
    height: 13px;
    background: url('../../assets/images/searchIconDesk.svg') no-repeat;
    background-size: cover;
    z-index: 1;

  }

  &__input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 24px;
    width: 146px;
    padding-left: 45px;
    border-radius: 20px;
    z-index: 1;
    background: #000;
    color: #fff;
    border: none;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #C4C4C4;

    &:focus, &:active {
      border: none;
    }
  }
}

@media screen and (max-width: 375px) {
  .liveCasino-content__menu {
    width: 100%
  }
}


#formContainer {
  width: 100%;
  height: 100%;
}

#canvas {
  width: 100%;
  height: 100%;
  z-index: 5;
}

#formContainer .circles span {
  width: 100px;
  height: 100px;
  line-height: 50px;
  border-radius: 50%;
  position: absolute;
  top: -150px;
}

#formContainer .circles span:nth-child(1) {
  left: 90%;
  animation: down 3s infinite, rotate 5s infinite, shadow2 1s infinite;
}

#formContainer .circles span:nth-child(2) {
  width: 75px;
  height: 75px;
  left: 60%;
  animation: down 5s infinite, rotate 7s infinite, shadow2 1s infinite;
}

#formContainer .circles span:nth-child(3) {
  left: 20%;
  animation: down 4s infinite, rotate 3s infinite, shadow2 1s infinite;
}

#formContainer .circles span:nth-child(4) {
  width: 50px;
  height: 50px;
  left: 10%;
  animation: down 5s infinite, rotate 7s infinite, shadow2 1s infinite;
}

#formContainer .circles span:nth-child(5) {
  width: 60px;
  height: 60px;
  left: 40%;
  animation: down 3s infinite, rotate 3s infinite, shadow2 1s infinite;
}

#formContainer .circles span:nth-child(6) {
  width: 75px;
  height: 75px;
  left: 80%;
  animation: down 4s infinite, rotate 5s infinite, shadow2 1s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}


@keyframes down {
  0% {
    top: -150px;
    opacity: 1;
  }
  30% {
    opacity: 0.8;
  }
  100% {
    top: 90%;
    opacity: 0;
  }
}


@keyframes shadow2 {
  0% {
    box-shadow: 0 0 0 0 #f77;
  }

  50% {
    box-shadow: 0 0 50px 0 #f77;
  }

  100% {
    box-shadow: 0 0 0 0 #f77;
  }
}

.roulettContainer {
  position: absolute;
  transform: rotate3d(1, 1, 1, 45deg);
  bottom: 3%;
  left: 12%;
  width: 301px;
  height: 301px;
  z-index: 1;
  border-radius: 50%;
  box-shadow: -8px 20px 20px 8px #0485b6;

  .insideRouletteS,
  .outsideRouletteS {
    position: absolute;
    width: 300px;
    // left: 18vw;
    // top: 26vh;
  }

  .outsideRouletteS {
    width: 301px;
    // transform: rotate3d(1, 1, 1, 45deg);
  }

  .insideRouletteS {
    animation: spin 17s infinite linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.slotMachine {
  position: absolute;
  // right: 18%;
  // top: 25%;
  right: 10%;
  top: 32%;
  z-index: 1;

  &__textContainer {
    background: #ffb334;
    position: absolute;
    left: 29%;
    top: 107px;
    height: 36px;
    width: 124px;
    /* transform: rotate3d(4, 3, 1, 2deg); */
    transform: rotateX(-19deg) rotateY(12deg) rotateZ(5deg);
  }

  &__freeSpins {
    position: absolute;
    left: 3%;
    top: 14%;
    font-size: 18px;
    text-align: center;
    width: 112px;
    // transform: rotate3d(1, 1, 1, 3deg);
    text-transform: uppercase;
    background-image: linear-gradient(90deg, #34d7eb, #2eceef, #088bb3, #2d5c8d, #3c78c3);

    background-size: 200% auto;
    -webkit-background-clip: text;
    color: transparent;
    animation: textclip 3s linear infinite;
    display: inline-block;
    // font-size: 45px;
  }

  &__topTextContainer {
    position: absolute;
    // background: #211f1d;
    // opacity: 0.1;
    left: 29%;
    top: 49px;
    height: 47px;
    width: 134px;
    transform: rotateX(-7deg) rotateY(4deg) rotateZ(0deg);

    background-image: linear-gradient(180deg, #ffbb34, #ff9000);
  }

  &__starsTop {
    width: 133px;
    display: flex;
    justify-content: space-between;

    &-star {
      font-size: 8px;
      color: white;
      animation: blink1 0.5s infinite;
      text-shadow: 2px 1px 5px white;

      &:nth-child(odd) {
        color: #00C5E5;
        animation: blink2 0.5s infinite;

      }
    }
  }

  &__starsBottom {
    position: absolute;
    top: 36px;
    display: flex;
    width: 133px;
    justify-content: space-between;
  }

  &__mainText {
    position: absolute;
    left: 10px;
    top: 15px;
    color: #426DFB;
    // font-size: 9vw;
    & b {
      // font: 400 19vh "Vibur";
      color: #fee;
      text-shadow: 0 -4px 10px, 0 0 2px, 0 0 1px #ff4444, 0 0 5px #ff4444, 0 0 0.1em #ff4444, 0 3px 3px #000;
    }

    & b span {
      animation: blink44 linear infinite 2s;
    }

    & b span:nth-of-type(2) {
      animation: blink44 linear infinite 3s;
    }

    @keyframes blink44 {
      78% {
        color: inherit;
        text-shadow: inherit;
      }
      79% {
        color: #333;
      }
      80% {

        text-shadow: none;
      }
      81% {
        color: inherit;
        text-shadow: inherit;
      }
      82% {
        color: #333;
        text-shadow: none;
      }
      83% {
        color: inherit;
        text-shadow: inherit;
      }
      92% {
        color: #333;
        text-shadow: none;
      }
      93% {
        color: inherit;
        text-shadow: inherit;
      }
    }

  }
}


@keyframes blink1 {
  0% {
    color: white
  }
  50% {color: #00C5E5}
  100% {
    color: white
  }
}

@keyframes blink2 {
  0% {
    color: #00C5E5
  }
  50% {
    color: white
  }
  100% {
    color: #00C5E5
  }

}


.doors {
  display: flex;
  position: absolute;
  top: 31%;
  left: -10%;
  width: 373px;
  height: fit-content;
}

.door {

  // background: #fafafa;
  background-image: url('./d2.png');
  width: 41px;
  overflow: hidden;
  border-radius: 5px;
  margin: 5px;
  top: -22%;
  left: 59%;
  position: absolute;
  top: 1px;
  height: 82px;

  &:nth-child(2) {
    left: 47%;
    top: 0;
    height: 80px;

  }

  &:nth-child(3) {
    left: 35%;
    top: -2px;
    height: 80px;

  }
}


.boxes {
  /* transform: translateY(0); */
  transition: transform 1s ease-in-out;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;

}

.buttons {
  margin: 1rem 0 2rem 0;
  position: relative;
  top: -110px;
  left: 114px;
  transform: rotateX(-2deg) rotateY(12deg) rotateZ(6deg);
  z-index: 3;

}

// button {
//   cursor: pointer;
//   // font-size: 1.2rem;
//   margin: 0 0.2rem;
//   border: none;
//   border-radius: 5px;
//   background: #06bde3;
//     box-shadow: 2px 2px 5px 1px #0b3942;
//   padding: 10px 15px;
// }

.info {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

//   20%, 24%, 55% {
//       text-shadow: none;
//   }
// }

.Slots-header__filterMobile {
  padding: 0 32px;
  position: absolute;
  bottom: 10px;
  width: 100%;

  &-search {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-bottom: 8px;

    &-input {
      width: 150px;
      height: 24px;
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid #FFFFFF;
      border-radius: 20px;
      color: #fff;
      padding: 0 0 4px 35px;
      display: flex;
      align-items: center;
      position: relative;

      &::placeholder {
        color: #fff;
        font-size: 11px;
      }
    }

    &-icon {
      position: absolute;
      top: 6px;
      right: 160px;
      width: 13px;
    }
  }

  &-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-item {
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 40px;
      width: 150px;
      height: 24px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      color: #029ED2;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 6px;
      z-index: 2;
    }
  }
}

@media screen and (max-width: 1023px) {
  .Slots-header {
    position: relative;

    &__text {
      position: absolute;
      top: 48px;
      left: 32px;
      width: 140px;
      transform: none;

      &-name {
        font-family: 'Peralta';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.09em;
        color: #FFFFFF;
        text-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
  .Slots-content__menu-games__allgames-title {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 24px 0px 20px 10px;
    max-width: 326px;
    &-ref{
      position: absolute;
      top: -17px;
    }

    &-name {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.09em;
      color: #029ED2;
    }

    &-seeAll {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.09em;
      color: #029ED2;
    }
  }
  .Slots-content__arrow {
    display: none;
  }
}

// @media screen and (max-width: 384px) {
//   .Slots-content__menu-games__allgames-items-item {
//     height: 203px;
//     width: 158px;
//   }
// }
@media screen and (max-width: 500px) {
  .Slots-content__menu-games__allgames-items {
    // justify-content: center;
    height: calc(((((100vw / 3) - 35px) / 0.78) * 2) + 55px);
  }
  .Slots-content__menu-games__allgames-items-item {
    //height: 185px;
    //width: 145px;
    width: calc((100vw / 3) - 35px);
    height: calc(((100vw / 3) - 35px) / 0.78);
    .altBackground {
      span {
        font-size: 14px;
        top: 50%;
        left: 50%;
        right: unset;
        transform: translate(-50%, -65%);
        line-height: 1.1;
      }
      .img-gamename-slots {
        bottom: 11px;
        left: 5px;
        font-size: 8px;
      }
    }
  }
}
@media screen and (max-width: 330px) {
  .Slots-content__menu-games__allgames-items-item {
    height: 170px;
    width: 125px;
  }
}

@media screen and (max-width: 1200px) {
  .Slots-header__filter-row {
    max-width: 440px;
  }
}

@media screen and (min-width: 1346px) {
  .Slots-content__menu-games__allgames-title {
    display: flex;
    justify-content: space-between;
    margin: 50px 0 20px 6px;
    max-width: 1200px;
  }
}

@media screen and (max-width: 1345px) {
  .Slots-content__menu-games__allgames-title {
    display: flex;
    justify-content: space-between;
    margin: 50px 0 20px 6px;
    max-width: 1020px;
  }
}

@media screen and (max-width: 1153px) {
  .Slots-content__menu-games__allgames-title {
    display: flex;
    justify-content: space-between;
    margin: 50px 0 20px 6px;
    max-width: 850px;
  }
  .Slots-content__menu {
    max-width: 870px;
  }
}

Slots-content {
  .mobile {
    background: url('../../assets/images/exchange_games_bg_mobile.jpg');
    background-size: contain;
    margin-top: 55px;
    background-size: 100%;
  }
}

.additionalItem {
  background: rgba(108, 106, 230, 0.623);
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: 'Lato';
}

@media screen and (max-width: 391px) {
  .mobile {
    margin-top: 0px;
    padding-top: 20px
  }
  .Slots-header__filterMobile {
    bottom: 10px;
  }
}

@media screen and (max-width: 577px) {
  .Slots-content__menu-games__allgames-title {
    max-width: 331px;
    margin-right: 5px;
  }
  .Slots-content__menu {
    //max-width: 350px;
    top: 0;
    overflow: unset;
    width: 100%;
    padding: 0 15px;
  }
}

@media screen and (max-width: 1024px) {
  .Slots-header{
    height: 470px;
  }
  .slotMachine__topTextContainer {
    position: absolute;
    left: 26%;
    top: 40px;
    height: 47px;
    width: 134px;
  }
  .slotMachine__mainText{
    left: 5px;
  }
  .slotMachine__freeSpins{
    top: 23%;
  }
  .slotMachine__textContainer {
    left: 28%;
    top: 87px;
  }
  .slotMachine {
    right: -24px;
    top: -2%;

    img {
      width: 290px;
    }
  }

}

.altBackground {
  background: linear-gradient(#000, 85%, #6c23e0);
  opacity: 0.95;
  color: #f1efea;
  height: 100%;
  border-radius: 8px;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
  }

  .img-gamename-slots {
    position: absolute;
    z-index: 2;
    color: #fff;
    bottom: 20px;
    left: 10px;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Lato';
  }

  span {
    position: absolute;
    font-family: 'Poppins';
    font-weight: 500;
    color: #fff;
    left: 20%;
    right: 20%;
    top: 40%;
    z-index: 1;
    text-align: center;
  }
}

// styles for game names during the search
@media screen and (max-width: 1023px) {
  .Slots-content__menu-games__allgames-items-item {
    .img-gamename-slotsWithSearch {
      position: absolute;
      z-index: 2;
      color: #fff;
      bottom: 15px;
      left: 5px;
      font-size: 8px;
      font-weight: 600;
      font-family: 'Lato';
    }
  }
}

@media screen and (min-width: 1024px) {
  .Slots-content__menu-games__allgames-items-item {
    .img-gamename-slotsWithSearch {
      position: absolute;
      z-index: 2;
      color: #fff;
      bottom: 25px;
      left: 10px;
      font-size: 12px;
      font-weight: 600;
      font-family: 'Lato';
    }
  }
}
