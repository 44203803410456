.gameresult.gameresult-t20-teenpatti {
  height: 66px;
  .gameresult-cardsplace {
    height: 61px;
  }
}
.teenpattiT20CardsContent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .teenpattiT20CardsContent__players-wrapper {
    width: 100%;
    max-width: 650px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .teenpattiT20CardsContent__player {
    display: flex;
    align-items: center;
  }
  .teenpattiT20CardsContent__player.teenpattiT20CardsContent__player-second {
    flex-direction: row-reverse;
  }
  .teenpattiT20CardsContent__player-name {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    color: var(--color-secondary-exchange);
  }
  .teenpattiT20CardsContent__player-cards {
    padding: 0 30px;
    height: 33px;
  }
  .teenpattiT20CardsContent__player-cards-card {
    width: 24px;
    height: 33px;
    margin: 0 5px;
  }
}


@media (max-width: 1024px) {
  div.teenpattiT20CardsContent {
    .teenpattiT20CardsContent__player-cards {
      padding: 0 15px;
    }
    .teenpattiT20CardsContent__player-cards-card {
      margin: 0 2.5px;
    }
  }
}

@media (max-width: 360px) {
  div.teenpattiT20CardsContent {
    //.teenpattiT20CardsContent__player-cards-card {
    //  width: 22px;
    //  height: 30px;
    //}
    .teenpattiT20CardsContent__players-wrapper {
      padding: 0 10px;
    }
    .teenpattiT20CardsContent__player-cards {
      padding: 0 10px;
    }
  }
}


