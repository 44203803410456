  .TPTCardsWrapper{
    display: flex;
    justify-content: space-around;
  }

  .TPTCardsContent{
    padding-top: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    &-name{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: var(--color-secondary-exchange);
      margin-right: 5px;
    }
    &-total{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &__name{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--color-secondary-exchange);
      }
      &__cards{
        height: 33px;
        width: 80px;
        display: flex;
        justify-content: space-between;
        &-card{
          display: inline-block;
          height: 100%;
          width: 100%;
          margin-right: 3px;
        }
      }
    }
  }
  //
  //@media screen and (min-width: 768px) {
  //  .TPTCardsContent{
  //    padding: 0px 56px;
  //    &-total{
  //      width: 90px;
  //      &__name{
  //        font-size: 15px;
  //        line-height: 22px;
  //      }
  //      &__card{
  //        font-size: 25px;
  //      }
  //    }
  //  }
  //  .TPTCardsContent-total__cards{
  //    height: 40px;
  //    width: 58px;
  //    padding: 0 0 3px 0;
  //  }
  //}

  //@media (max-width: 1024px) {
  //  div.gameresult {
  //    .gameresult-cardsplace.cardsplace-roulette {
  //      height: 60px;
  //    }
  //  }
  //}

//@media screen and (min-width: 1024px) {
//  .gameresult.gameresult-teenpatti-test {
//    height: unset;
//    .gameresult-cardsplace {
//      height: 82px;
//    }
//  }
//    .TPTCardsContent {
//      padding: 12px 0 0 0;
//      div.TPTCardsContent-name {
//        font-size: 15px;
//        margin-right: 15px;
//      }
//    }
//  .TPTCardsContent-total {
//    width: 100%;
//  }
//  .TPTCardsContent-total__cards {
//    height: 61px;
//    width: 100%;
//    .TPTCardsContent-total__cards-card {
//      height: 61px;
//      width: 45px;
//    }
//    .TPTCardsContent-total__cards-card:not(:last-child) {
//      margin-right: 10px;
//    }
//  }
//}
