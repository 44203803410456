
.rouletteLastResult__content-item {
  width: 42px;
  height: 42px;
  //background: #DC3A24;
  border: 1.4px solid #FFFFFF;
  border-radius: 7px;
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 22px;
  color: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.5);
}

.rouletteLastResult__content-item.Green {
  background: #32CDA8;
}
.rouletteLastResult__content-item.Black {
  background: #1D1D1D;
}
.rouletteLastResult__content-item.Red {
  background: #DC3A24;
}
