.BollywoodCasinoLastRes {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header{
    padding-top: 10px;
    &__title{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: var(--color-exchange);
      text-transform: uppercase;
    }
    &__gameId{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: var(--color-exchange);
    }
  }
  &__results{
    //padding-top: 40px;
    margin-top: -25px;
    // padding-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &-name{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: var(--color-exchange);
      margin-left: -70px;
      margin-top: 12px;
      }
    &-cards-one{
      display: flex;
      width: 130px;
      height: 44px;
      justify-content: center;
      position: relative;
      //margin-top: 15px;
      //margin-bottom: 30px;
      margin-top: 50px;
      margin-bottom: 50px;
      &::before{
        content: "";
        width: 35px;
        height: 44px;
        display: block;
        background-image: url('../../../../../assets/images/winnerTropy.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-right: 25px;
      }
      &-card{
        display: block;
        width: 32px;
        height: 44px;
        box-shadow: 0px 0px 5px grey;
        //&:before {
        //  width: 50px;
        //  height: 50px;
        //  background-color: green;
        //}
      }
    }
  }

  &__results-winner-pattern {
  width: 100%;
  height: 31px;
  background: var(--background-player-exchange);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.05em;
  color: var(--color-secondary-exchange);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  }
}
