@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.SixPlayerPokerWrapper{
  padding: 29px 11px 30px 10px;
}

.SixPlayerPoker-table{
  &-options{
    display: flex;
    justify-content: space-between;
    height: 35px;
    margin: 0px 6px 14px 6px;
    // color: #fff;
    &-hands{
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color-title-secondary-exchange);
      color:  var(--color-secondary-exchange);
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
    }
    &-patterns{
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      // background: #444444;
      // color: #fff;
      font-family: 'Poppins';
      font-style: normal;
      // font-weight: 700;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #263238;
    }
  }
  &-cardsContent{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &-cardsBox{
      width: 49%;
      margin-bottom: 14px;
      &-title{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #263238;
        margin-bottom: 3px;
        text-align: center;
      }
      &-wrapper{
        width:100%;
        height: 74px;
        background: var(--background-player-exchange);
        border-radius: 5px;
        display: flex;
        position: relative;
        color: var(--color-secondary-exchange);
        &-cards{
          width: 50%;
          height: 100%;
          //padding: 15px 0 15px 14px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .SixPlayerPokerCard{
            height: 44px;
            width: 32px;
          }
          .SixPlayerPokerCard:first-child{
            margin-right: 15px;
          }
        }
        &-odds{
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items:center;
          flex-direction: column;
          &-price{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            // color: #FFFFFF;
          }
        }
      }
      .boxSuspended{
        background: #172c3a;
        border-radius: 3px;
        color: var(--color-secondary-exchange);
        &::after{
            content: url("../../../assets/images/tptLock.svg");
            display: block;
            position: absolute;
            left: 0;
            top: 00px;
            height: 100%;
            width: 100%;
            border-radius: 3px;
            display: flex;
            flex-direction: revert;
            justify-content: center;
            align-items: center;

        }
      }
    }
  }
  &-patterns{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &-box{
      background: var(--background-player-exchange);
      border-radius: 5px;
      height: 55px;
      width: 49%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      margin-bottom: 15px;
      color: var(--color-secondary-exchange);
      position: relative;
      &-nameExp{
        &-name{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          // color: #FFFFFF;
        }
        &-exp{

        }
      }
      &-odds{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        // color: #FFFFFF;
      }
    }
    .boxSuspended{
      background: #172c3a;
      border-radius: 3px;
      color: var(--color-secondary-exchange);
      &::after{
          content: url("../../../assets/images/tptLock.svg");
          display: block;
          position: absolute;
          background-color: var(--background-closed-exchange);
          left: 0;
          top: 00px;
          height: 100%;
          width: 100%;
          border-radius: 3px;
          display: flex;
          flex-direction: revert;
          justify-content: center;
          align-items: center;

      }
    }
  }
}

.exposure-box-sixPlayerPoker{
  // text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  margin-top: 5px;
}

.exposure-sixPlayerPoker{
  &--pos{
      color: var(--positive-exchange);
  }
  &--neg{
      color: var(--negative-exchange);
  }
}

.nonCollapsed{
  position: fixed;
  top: 182px;
  z-index: 15;
  width: 94%;
  transition: linear 0.2s;
  left: 3%;
  right: 3%;


}
.collapsed{
  position: fixed;
  top: 80px;
  z-index: 15;
  width: 94%;
  transition: linear 0.2s;
  left: 3%;
  right: 3%;
}


@media (max-width: 1024px) {
  .SixPlayerPokerWrapper.virtual {
    margin-top: -10px;
    padding-top: 10px;
    min-height: calc(100% - 380px);
    background: linear-gradient(-181.68deg, #0B312F 50.02%, #3B7874 100%);
  }
  .SixPlayerPoker.virtual.fullHeight {
    padding-top: 30px;
    min-height: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .SixPlayerPoker-table{
    height: 500px;
  }
  .SixPlayerPoker-table-cardsContent-cardsBox{
    width: 33%;
    &-wrapper{
      border-radius: 9.66146px;
    }
    .boxSuspended{
      border-radius: 9.66146px;
    }
    &-title{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14.4922px;
      line-height: 22px;
      color: #263238;
    }
  }
  .SixPlayerPoker-table-cardsContent-cardsBox-wrapper-odds-price{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14.4922px;
    line-height: 22px;
  }
  .SixPlayerPoker-table-cardsContent-cardsBox-wrapper-cards{
    //padding: 15px 0 15px 43px;
  }
  .SixPlayerPoker-table-patterns{
    margin-top: 35px;
    height: 62px;
    &-box{
      width: 32%;
      border-radius: 9.66146px;
      margin-bottom: 18px;
      height: 62px;
      &-nameExp-name{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14.4922px;
        line-height: 22px;
      }
      &-odds{
        text-align: end;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14.4922px;
        line-height: 22px;
      }
    }
    .boxSuspended{
      border-radius: 9.66146px;
    }
  }
  .exposure-box-sixPlayerPoker{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12.5599px;
  }
}
