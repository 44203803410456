
.gameresult{
  //width: 100%;
  //height: 86px;
  //margin-top: -10px;
  //&-greenline{
  //  height: 5px;
  //  background: #00AF3C;
  //}
  //&-cardsplace{
  //  height: 82px;
  //  background: #1D1D1D;
  //}
  //.gameresult-cardsplace.cardsplace-roulette {
  //  height: 84px;
  //}
}
.gameresult.gameresult-andar-bahar {
  height: unset;
  .gameresult-cardsplace {
    height: 66px;
  }
}
.andar-bahar-cards {
  width: 100%;
  height: 100%;
  max-width: 742px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 20px 5px 20px;
  &__player {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    justify-content: flex-end;
    padding-bottom: 2px;

    &.second-player {
      align-items: end;
      .andar-bahar-cards__player-cards {
        justify-content: end;
      }
    }
    &-title {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 12px;
      color: #FFFFFF;
      white-space: nowrap;
    }
    &-cards {
      height: 33px;
      width: 115px;
      display: flex;
      &-card {
        width: 25px;
        height: 33px;
      }
      &-card:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
  &__player.index-card {
    justify-content: end;
    align-items: center;
    //margin-bottom: 2px;
    .andar-bahar-cards__player-card {
      width: 32px;
      height: 44px;
    }
  }
}
@media (min-width: 1024px) {

  .andar-bahar-cards {
    max-width: unset;
    padding: 0 37px 5px 37px;
    &__player {
      //&-title {
      //  font-weight: 500;
      //  font-size: 15px;
      //  padding-bottom: 5px;
      //}
      &-cards {
        //height: 44px;
        //width: 115px;
        //&-card {
        //  height: 44px;
        //  width: 32px;
        //}
        &-card:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
    //&__player.index-card {
    //  .andar-bahar-cards__player-card {
    //    width: 45px;
    //    height: 61px;
    //  }
    //}
  }
}
