@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.trap-table {
  padding: 43px 10px 0 10px;

  &__item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-size: 12px;
      position: relative;
      &-header {
          height: 36px;
          flex-grow: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 15px;
          font-size: 10px;
          color: var(--color-exchange);
          background: var(--background-secondary-player-exchange);
          border-radius: 5px 0px 0px 5px;
          margin-right: -2px;

          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
      }
      &-bets-wrapper {
          display: flex;
          justify-content: space-between;
          gap: 0 10px;
      }
      &-bet-cell {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          line-height: 1;
          border-radius: 5px;
          width: 94px;
          height: 40px;
          border-radius: 5px;
          &-price {
              font-family: Poppins;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 18px;
              color: #444444;
          }
      }
      .bet-back,
      .bet-back.lock-ui {
        background: var(--back-color-exchange);
          border: 2px solid var(--back-border-color-exchange);
        overflow: hidden;
      }


      .bet-lay,
      .bet-lay.lock-ui {
        background: var(--lay-color-exchange);
          border: 2px solid var(--lay-border-color-exchange);
      }
  }
  .trap-table__seven-row-item.lock-ui::after,
  .bet-lay.lock-ui::after,
  .trap-tableDesk__row-item-odds-back.lock-ui:after,
  .trap-tableDesk__row-item-odds-lay.lock-ui:after,
  .bet-back.lock-ui:after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--background-closed-exchange);
    background-image: url("../../../assets/images/tptLock.svg");
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    display: flex;
    flex-direction: revert;
    justify-content: center;
    align-items: center;
  }

  .trap-table__seven-row-item.winner:after,
  .trap-tableDesk__row-item-odds-back.winner:after,
  .trap-tableDesk__row-item-odds-lay.winner:after,
  .bet-lay.winner:after,
  .bet-back.winner:after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--background-closed-exchange);
    background-image: url(../../../assets/images/TrophyTeenPattiTest.svg);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    display: flex;
    flex-direction: revert;
    justify-content: center;
    align-items: center;
  }

  &__seven-row {
      background: radial-gradient(circle at 50% 50%, #72bbef 46px, #a7d8fd 0);
      background-position: center center;
      background-size: 100%;
      background-repeat: no-repeat;

      border-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      position: relative;
      border: 2px solid var(--back-border-color-exchange);
      height: 65px;

      display: flex;
      justify-content: space-around;
      margin: 15px 0;

      text-align: center;
      font-size: 12px;
      line-height: 18px;
      color: var(--color-exchange);

      &-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;

          &-odd {
              font-family: Poppins;
              font-style: normal;
              font-weight: 600;
          }
          &-title {
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
          }
      }
      .lock-ui::after, .trophy-ui::after {
          border-radius: 10px;

}
      &-center {
          width: 87px;
      }
  }

  &__picture {
      background: var(--background-player-exchange);
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 15px;
      position: relative;
      .exposure-box-trap {
        font-size: 11px;
        font-weight: 600;
        font-family: 'Poppins';
        line-height: 20px;
        z-index: 10;
        position: absolute;
        right: 16%;
        bottom: 3px;
      }
      &-cards {
          display: flex;
          gap: 5px 10px;
          flex-wrap: wrap;
          position: relative;
          & img {
              width: 32px;
          }
      }
      &-bets-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 0 10px;
        .exposure-box-trap {
          bottom: 0;
        }
      .exposure-trap--pos,
      .exposure-trap--neg {
        position: relative;
        display: flex;
        justify-content: center;
        grid-column: 1/3;
        height: 12px;
      }
      }
      &-bet-cell {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          line-height: 1;
          border-radius: 5px;
          width: 60px;
          height: 46px;

          &-price {
              font-family: Poppins;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 18px;
              color: #444444;
          }
        &.bet-back {
          background-color: var(--back-color-exchange);
        }
        &.bet-lay {
          background-color: var(--lay-color-exchange);
        }
      }
  }
  .seven {
      width: 87px;
      top: -93%;
      left: 50%;
      height: 87px;
      background: var(--background-player-exchange);
      border-radius: 50%;
      border: 5px solid #fff;
      position: absolute;
      transform: translate(-50%, 50%);
      z-index: 1;

      display: flex;
      justify-content: center;
      align-items: center;
      span {
          font-family: Gemunu Libre;
          font-style: normal;
          font-weight: bold;
          font-size: 64px;
          line-height: 69px;

          color: #cfd8dc;
      }
  }
}

.nonCollapsed{
    position: fixed;
    top: 182px;
    z-index: 15;
    width: 94%;
    transition: linear 0.2s;
    left: 3%;
    right: 3%;


  }
  .collapsed{
    position: fixed;
    top: 80px;
    z-index: 15;
    width: 94%;
    transition: linear 0.2s;
    left: 3%;
    right: 3%;
  }

.exposure-box-trap {
  font-size: 11px;
  font-weight: 600;
  font-family: 'Poppins';
  line-height: 20px;
  z-index: 10;
}

.exposure-trap{
    &--pos {
        color: var(--positive-secondary-exchange);
    }
    &--neg {
        color: var(--negative-secondary-exchange);
    }
}

.trap-table__picture-bets-wrapper {
  position: relative;
  .exposure-box-trap {
    right: 50%;
    transform: translateX(50%);
    //bottom: -20px;
  }
  .exposure-trap {
    text-align: center;
    &--pos {
      color: var(--positive-exchange);
    }
  }
}

.desk{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

// .desktop{
//     width: 42%;
// }

.trapWrapperDesk{
    margin: 41px 0 50px 0;
}

.trap-tableDesk{
    &__row{
        display: flex;
        justify-content: space-between;
        &-item{
            background: var(--background-player-exchange);
            border-radius: 9.70052px;
            height: 81px;
            width: 364px;
            padding: 11.5px 19.4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 49%;
            &-title{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14.5508px;
                line-height: 22px;
                color: var(--color-secondary-exchange);
            }
            &-odds{
                width: 165px;
                height: 58px;
                display: flex;
                justify-content: space-between;
                &-back{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: var(--back-color-exchange);
                    border-radius: 2.91016px;
                    height: 100%;
                    width: 77.5px;
                    position: relative;
                    &-price{
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14.5508px;
                        line-height: 22px;
                        text-align: center;
                        color: #263238;
                    }
                }
                &-lay{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: var(--lay-color-exchange);
                    border-radius: 2.91016px;
                    height: 100%;
                    width: 77.5px;
                    position: relative;
                    &-price{
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14.5508px;
                        line-height: 22px;
                        text-align: center;
                        color: #263238;
                    }
                }
            }
        }
    }
}

//.lock-ui::after{
//    content: url("../../../assets/images/tptLock.svg");
//    display: block;
//    position: absolute;
//    left: 0;
//    top: 0;
//    height: 100%;
//    width: 100%;
//    background-color: var(--background-closed-exchange);
//    border-radius: 3px;
//    display: flex;
//    flex-direction: revert;
//    justify-content: center;
//    align-items: center;
//}

//.layTrapSusp::after{
//    content: url("../../../assets/images/tptLock.svg");
//    display: block;
//    position: absolute;
//    left: 0;
//    top: 0;
//    height: 100%;
//    width: 100%;
//    background-color: var(--background-closed-exchange);
//    border-radius: 3px;
//    display: flex;
//    flex-direction: revert;
//    justify-content: center;
//    align-items: center;
//}

@media screen and (min-width: 1024px) {
    .trap-table {
        padding: 23px 0 0 0;
        width: 100%;
        &__seven-row {
            height: 81px;
            width: 45%;
            background: var(--background-player-exchange);
            border: 0px ;
            color: var(--color-secondary-exchange);
            font-weight: 600;
            font-size: 14.5508px;
            line-height: 22px;
            margin: 5px 0 ;
        }
        .seven{
            width: 95px;
            height: 95px;
            top: -69%;
        }
        &__picture{
            height: 95px;
            width: 53%;
            border-radius: 9.70052px;
            position: relative;
            &-cards{
                img{
                    width: 47.53px;
                    height: 61.11px;
                }
            }
            &-bets{
                &-wrapper{
                    width: 165px;
                    height: 58px;
                }
            }
            exposure-box-trap {
                font-size: 11px;
              //   width: auto;
              //   bottom: -3px;
              //   right: 50%;
              //   transform: translateX(50%);
                font-weight: 600;
                font-family: 'Poppins';
                line-height: 20px;
                z-index: 10;
                position: absolute;
                right: 16%;
                bottom: 3px;
              }
        }
    }
    .trap-table__picture-bet-cell {
        width: 100%;
        height: 58px;
        &.bet-back{
            //height: 100%;
            //width: 100%;
            //font-weight: 600;
            //font-size: 14.5508px;
            //line-height: 22px;
          background-color: var(--back-color-exchange);
        }
        &.bet-lay{
            //height: 100%;
            //width: 100%;
          background-color: var(--lay-color-exchange);
        }
        //.bet-back_suspended::after{
        //    content: url("../../../assets/images/Lockk.svg");
        //    display: block;
        //    position: absolute;
        //    left: 0;
        //    top: 0;
        //    height: 100%;
        //    width: 100%;
        //    // background-color: rgba(208, 235, 255, 0.6);
        //    background-color: rgba(171, 173, 174, 0.5);
        //    border-radius: 3px;
        //    display: flex;
        //    flex-direction: revert;
        //    justify-content: center;
        //    align-items: center;
        //}
        //  .bet-lay_suspended::after{
        //    content: url("../../../assets/images/Lockk.svg");
        //    display: block;
        //    position: absolute;
        //    left: 0;
        //    top: 0;
        //    height: 100%;
        //    width: 100%;
        //    // background-color: rgba(255, 226, 231, 0.6);
        //    background-color: rgba(144, 144, 144, 0.5);
        //    border-radius: 3px;
        //    display: flex;
        //    flex-direction: revert;
        //    justify-content: center;
        //    align-items: center;
        //}

    }
  .trap-table__seven-row-item.winner:after,
  .trap-tableDesk__row-item-odds-back.winner:after,
  .trap-tableDesk__row-item-odds-lay.winner:after,
  .bet-lay.winner:after,
  .bet-back.winner:after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--background-closed-exchange);
    background-image: url(../../../assets/images/TrophyTeenPattiTest.svg);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    display: flex;
    flex-direction: revert;
    justify-content: center;
    align-items: center;
  }
  .trap-table__seven-row-item.lock-ui::after,
  .bet-lay.lock-ui::after,
  .trap-tableDesk__row-item-odds-back.lock-ui:after,
  .trap-tableDesk__row-item-odds-lay.lock-ui:after,
  .bet-back.lock-ui:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--background-closed-exchange);
    background-image: url("../../../assets/images/tptLock.svg");
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    display: flex;
    flex-direction: revert;
    justify-content: center;
    align-items: center;
  }
    .trap-table__picture-bet-cell{
        &-price{
            font-weight: 600;
            font-size: 14.5508px;
            line-height: 22px;
        }
    }
  .exposure-trap {
    &--pos {
      color: var(--positive-exchange);
    }
    &--neg {
      color: var(--negative-exchange);
    }
  }
/*    .trap-table__picture .exposure-box-trap {
        right: 21%;
    }*/

}
@media screen and (max-width: 1170px) {
    .trap-table{
        &__picture{
            &-cards{
                img{
                    width: 30px;
                    height: 40.8px;
                }
            }
        }
    }
}
