@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.ChangePassword {
  --padding-top: 15px;
  --padding-start: 15px;
  --padding-end: 15px;

  &__form {
    width: 100%;
    margin-top: 10px;
  }

  &__list {
    background: none !important;

    ion-item {
      --min-height: 36px;
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --inner-border-width: 0px;

      // ion-input.error {
      //   border: 1px solid var(--ion-color-danger);
      // }
    }

    ion-item + ion-item {
      margin-top: 12px;
    }
  }

  .withdraw-input-content.account-number-input-wrapper {
    margin-bottom: 25px;
  }

  &__button {
    width: 50%;
    margin: 20px 0 auto auto;
    text-transform: none;
    background: var(--payment-primary-color);
    color: #fff;
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    height: 36px;
    font-weight: 500;
    margin-top: 20px;

    &:hover {
      background: var(--cta-primary-darken);
    }

    &:disabled {
      background: var(--cta-disabled);
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__form-error {
    padding: 0 10px 5px;
    margin-top: -2px;
    font-size: 10px;
    color: var(--negative);

    ion-item + & {
      padding: 0 10px 10px;
      margin-top: 9px;
    }
  }

  ion-item {
    --highlight-background: var(--login-highlight-bg);
    --border-radius: 2px;
  }

  ion-input {
    border-radius: 2px;
    height: 100%;
  }

  .native-input {
    border-radius: 2px;
    height: 100%;

    &:focus {
      border: 1px solid var(--login-item-focused-border);
    }
  }
}

.page-title {
  font-family: 'Lato';
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  align-items: center;
  color: #000000;
  margin-bottom: 16px;
}

.page-subtitle {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  align-items: center;
  color: #000000;
}
.page-subtitle.notes {
  p {
    padding:  0;
    margin: 0;
    line-height: 15px;
  }
  color: var(--negative-secondary-exchange);
  padding-bottom: 10px;
  margin-top: 1px;
  font-size: 11px;
}

.withdraw-account {
  //width: 100vw;
  //height: 75px;
  //margin-left: -3px;
  //overflow-x: auto;
  //margin-bottom: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
  &__flex {
    display: flex;
    // width: fit-content;
    overflow-y: hidden;
    padding-bottom: 10px;
    &::-webkit-scrollbar {
      background: #EFEFEF;
      border-radius: 1px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      //height: 3px;
      background: #5e5d5d;
      border-radius: 1px;
      // outline: 1px solid slategrey;
    }
  }
  &__item {
    height: 75px;
    width: 152px;
    opacity: 0.5;
    border: 1px solid #40424f;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 8px;
    &-checked {
      border: 1px solid var(--payment-primary-color);
      opacity: 1;
      height: 75px;
      width: 152px;
      margin-right: 8px;
      &:last-child {
        margin-right: 30px;
      }
    }
    &:last-child {
      margin-right: 30px;
    }
    &-content {
      margin: 4px 3px 3px 3px;
      position: relative;
      &__account {
        font-family: 'Source Sans Pro';
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
      }
      &__IFSC {
        font-family: 'Source Sans Pro';
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        text-transform: lowercase;
        &:first-letter {
          text-transform: uppercase;
        }
        .check-account {
          position: absolute;
          right: 2px;
          top: 5px;
          width: 1.3em;
          height: 1.3em;
          background-color: white;
          border-radius: 50%;
          vertical-align: middle;
          border: 1px solid #b5b5b5;
          appearance: none;
          -webkit-appearance: none;
          outline: none;
          cursor: pointer;
        }
        .check-account:checked {
          background-color: var(--payment-primary-color);
          background-image: url('../../assets/images/checkIcon.svg');
          background-repeat: no-repeat;
          background-position: center;
        }
        .delete-account {
          cursor: pointer;
          position: absolute;
          right: 2px;
          width: 1.1em;
          height: 1.1em;
        }
      }
    }
  }
}
.withdraw-input-content {
  margin-bottom: 8px;
  //margin-top: 8px;
}
.withdraw-textarea {
  height: 45px;
  width: 100%;
  margin-top: 14px;
  background: #ffffff;
  border: 1px solid #40424f;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0px 16px;
  font-family: 'Lato';
  font-size: 14px;
  line-height: 21px;
  color: #40424f;
  &__currency {
    position: relative;
    top: -33px;
    float: right;
    right: 14px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #40424f;
  }
  &__description {
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 14px;
    color: var(--payment-primary-color);
    margin-top: -10px;
  }
}
.hidden-accnumber {
  -webkit-text-security: disc;
}
.withdraw-submit {
  height: 35px;
  width: 100%;
  background: var(--payment-primary-color);
  border-radius: 3px;
  margin-top: 24px;
  margin-bottom: 90px;
  &__text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    //line-height: 21px;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
    padding-top: 0px;
  }
}
.rulesAccept {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-top: 10px;
  &__checkboxW {
    position: absolute;
    z-index: unset !important;
    opacity: 0;
    width: 22px;
    height: 22px;
  }
  &__text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #40424f;
    &-underline {
      cursor: pointer;
      color: var(--payment-primary-color);
      text-decoration: underline;
    }
  }
}

.rulesAccept__checkboxW + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  margin-top: 3px;
  margin-right: 10px;
}
.rulesAccept__checkboxW + label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid var(--payment-primary-color);
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.rulesAccept__checkbox:checked + label::before {
  content: url('../../assets/images/checkIcon.svg');
  display: flex;
  justify-content: center;
  background-color: var(--payment-primary-color);
  border-radius: 2px;
  background-size: 17px;
}

.rulesAcceptNew {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-top: 30px;
  &__checkboxWNew {
    position: absolute;
    z-index: unset !important;
    opacity: 0;
    width: 22px;
    height: 22px;
  }
  &__text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #40424f;
    &-underline {
      cursor: pointer;
      color: var(--payment-primary-color);
      text-decoration: underline;
    }
  }
}

.rulesAcceptNew__checkboxWNew + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  margin-top: 3px;
  margin-right: 10px;
}
.rulesAcceptNew__checkboxWNew + label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid var(--payment-primary-color);
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.rulesAcceptNew__checkbox:checked + label::before {
  content: url('../../assets/images/checkIcon.svg');
  display: flex;
  justify-content: center;
  background-color: var(--payment-primary-color);
  border-radius: 2px;
  background-size: 17px;
}

.validation-message {
  font-family: 'Lato';
  font-size: 13px;
  line-height: 21px;
  align-items: center;
  color: #e90038;
  height: 15px;
  width: 100%;
}

.withdraw-amaount-buttons {
  display: none;
}

.withdraw-desktop__leftpage {
  display: flex;
  justify-content: space-between;
}

.page-help {
  // visibility: hidden;
  margin-top: 14px;
}

.withdrawalNotValidBtn {
  height: 35px;
  width: 100%;
  background: rgb(116, 112, 112);
  border-radius: 3px;
  margin-top: 24px;
  margin-bottom: 90px;
}
.buttons-flex{
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.withdraw-account__newitem{
  height: 35px;
  width: 49%;
  margin-top: 10px;
  background: var(--payment-primary-color);
  display: flex;
  align-items:center;
  justify-content: center;
  border-radius: 2px;
  color: white;
  &-title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
}

// -------------------------
.buttons-flex {

  .pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    width: 49%;
  }

  .front {
    display: block;
    position: relative;
    width: 100%;
    height: 35px;
  
    &.refreshBtn {
      background: var(--payment-primary-color);
    }
  
    &.editBtn {
      background: #40424f;
    }
  
    &.cancelBtn {
      background: #fff;
      color: #969696;
      border: 1px solid #969696;
    }
  
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    margin: 0 2px;
    padding-top: 6px;
  
    border-radius: 3px;
    will-change: transform;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
  }
  
  .pushable:hover {
    filter: brightness(110%);
  
    .shadow {
      position: absolute;
      top: -2px;
      left: 2px;
      width: 100%;
      height: 35px;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.25);
      will-change: transform;
      transform: translateY(2px);
      transition:
        transform 600ms cubic-bezier(.3, .7, .4, 1);
    }
  
    .edge {
      position: absolute;
      top: -2px;
      left: 2px;
      width: 100%;
      height: 35px;
      border-radius: 3px;
  
      &.refreshBtn {
        background: var(--payment-primary-color);
      }
  
      &.editBtn {
        background: #40424f;
      }
  
      &.cancelBtn {
        background: #fff;
        color: #969696;
      }
  
      filter: brightness(75%)
    }
  }
  
  .pushable:hover .front {
    transform: translateY(-6px);
    transition:
      transform 250ms cubic-bezier(.3, .7, .4, 1.5);
  }
  
  .pushable:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
  
  .pushable:hover .shadow {
    transform: translateY(4px);
    transition:
      transform 250ms cubic-bezier(.3, .7, .4, 1.5);
  }
  
  .pushable:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }
  
  .pushable:focus:not(:focus-visible) {
    outline: none;
  }
}

.submitBtnW {
position: relative;
  .submitValidBtn {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    width: 100%;
    height: 35px;
    margin-top: 20px;
    margin-bottom: 90px;
    
  }
  
  .front {
    display: block;
    position: relative;
    width: 100%;
    height: 35px;

    background: var(--payment-primary-color);

    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    margin: 0 2px;
    padding-top: 6px;
  
    border-radius: 3px;
    will-change: transform;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
  }
  
  .submitValidBtn:hover {
    filter: brightness(110%);
  
    .shadow {
      position: absolute;
      top: -2px;
      left: 2px;
      width: 100%;
      height: 35px;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.25);
      will-change: transform;
      transform: translateY(2px);
      transition:
        transform 600ms cubic-bezier(.3, .7, .4, 1);
    }
  
    .edge {
      position: absolute;
      top: -2px;
      left: 2px;
      width: 100%;
      height: 35px;
      border-radius: 3px;

      background: var(--payment-primary-color);

      filter: brightness(75%)
    }
  }
  
  .submitValidBtn:hover .front {
    transform: translateY(-6px);
    transition:
      transform 250ms cubic-bezier(.3, .7, .4, 1.5);
  }
  
  .submitValidBtn:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
  
  .submitValidBtn:hover .shadow {
    transform: translateY(4px);
    transition:
      transform 250ms cubic-bezier(.3, .7, .4, 1.5);
  }
  
  .submitValidBtn:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }
  
  .submitValidBtn:focus:not(:focus-visible) {
    outline: none;
  }

  .withdrawalNotValidBtn {
    position: absolute;
    top: -4px;
    left: 2px;
    margin-top: 20px;
    margin-bottom: 90px;
  }

  .withdraw-submitNew {
    height: 35px;
    width: 100%;
    background: var(--payment-primary-color);
    border-radius: 3px;
    margin-top: 20px;
    margin-bottom: 90px;
  
    &__text {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      padding-top: 0px;
    }
  }
}

// Empty field check styles
.ChangePassword {
  .withdraw-textarea__currency {
    left: -35px;
  }
  .withdraw-input-content {
    input {
      outline: none;
    }
  }

  .rulesAccept.withdraw__empty-input,
  .page-subtitle.withdraw__empty-input {
    position: relative;
    .input-error-message {
      position: absolute;
      //top: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      transform: translateY(70%);
      font-size: 12px;
      color: #FF8787;
    }
    input.withdraw-textarea {
      border: 1px solid #FF8787;
    }
  }
  .rulesAccept.withdraw__empty-input {
    label:before {
      border: 1px solid #FF8787;

    }
  }
}
// End Empty field check styles


// ------------------------------------------------

@media screen and (min-width: 1024px) {
  .withdraw-desktop {
    display: flex;
    justify-content: center;
    color: black;
    margin-bottom: 90px;
    &__leftpage {
      height: 900px;
      width: 50%;
      background: #fff;
      box-shadow: -1px 1px 10px rgb(56, 56, 56);
      display: flex;
      justify-content: space-between;
      .page-title {
        padding: 30px 0 0 52px;
        font-family: 'Lato';
        font-weight: 700;
        font-size: 29px;
        line-height: 36px;
        color: #102327;
      }
    }
    &__rightpage {
      padding: 0 15px;
      height: 900px;
      width: 50%;
      background: #fff;
      box-shadow: 1px 1px 10px rgb(56, 56, 56);
      position: relative;
      &-inputcontent {
        //margin: 0 130px;
        width: 100%;
        max-width: 495px;
        margin: 0 auto;
      }
    }
  }
  .page-help {
    padding: 50px 52px 0 0px;
    visibility: visible;
    img {
      margin-left: 3px;
      margin-bottom: -3px;
    }
    &__text {
      font-family: 'Lato';
      font-weight: 500;
      font-size: 16.0952px;
      line-height: 19px;
      align-items: center;
      text-decoration-line: underline;
      color: #000000;
      cursor: pointer;
    }
  }
  .page-subtitle {
    font-size: 18px;
  }
  .page-subtitle.notes {
    padding-bottom: 10px;
    padding: 0px 0 0 52px;
    font-size: 13px;
  }
  .subtitle-hidden {
    display: none;
  }
  .withdraw-account {
    //margin: 39px 0 19px 25px;
    //margin: 0px 0px 0px 25px;
    //width: 45vw;
    width: 100%;
    max-width: 495px;
    margin: 0 auto 10px auto;
  }

  .withdraw-amaount-buttons {
    width: 100%;
    display: flex;
    margin-top: 5px;
    margin-left: -2px;
    justify-content: space-between;
    &__value {
      width: 90px;
      height: 29px;
      background: var(--payment-primary-color);
      border-radius: 3px;
      color: #fff;
      font-family: 'Lato';
      font-weight: 700;
      font-size: 9px;
      line-height: 11px;
      padding: 7px 24px;
      margin-right: 5px;
    }
  }
  .withdraw-input-content {
    margin-bottom: 10px;
  }
  .buttons-flex{
    display: flex;
    width: 300px;
    margin: 0 auto;
    margin-bottom: 10px;
    justify-content: space-between;
    //margin-top: 50px;
    margin-top: 40px;
  }

  // -------------------
  .withdraw-amaount-buttons {
    .pushable {
      position: relative;
      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;
      outline-offset: 4px;
      transition: filter 250ms;
      width: 32%;
      // margin-top: 4px;
    }
  
    .front {
      display: block;
      position: relative;
      width: 100%;
      height: 29px;
  
      &.refreshBtn {
        background: var(--payment-primary-color);
      }
  
      &.editBtn {
        background: #40424f;
      }
  
      font-style: normal;
      font-family: 'Lato';
      font-weight: 700;
      font-size: 9px;
      line-height: 11px;
      text-align: center;
      color: #ffffff;
      margin: 0 2px;
      padding-top: 9px;
  
      border-radius: 3px;
      will-change: transform;
      transform: translateY(-4px);
      transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
    }
  
    .pushable:hover {
      filter: brightness(110%);
  
      .shadow {
        position: absolute;
        top: -2px;
        left: 2px;
        width: 100%;
        height: 29px;
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.25);
        will-change: transform;
        transform: translateY(2px);
        transition:
          transform 600ms cubic-bezier(.3, .7, .4, 1);
      }
  
      .edge {
        position: absolute;
        top: -2px;
        left: 2px;
        width: 100%;
        height: 29px;
        border-radius: 3px;
  
        &.refreshBtn {
          background: var(--payment-primary-color);
        }
  
        &.editBtn {
          background: #40424f;
        }
  
        &.cancelBtn {
          background: #fff;
          color: #969696;
        }
  
        filter: brightness(75%)
      }
    }
  
    .pushable:hover .front {
      transform: translateY(-6px);
      transition:
        transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }
  
    .pushable:active .front {
      transform: translateY(-2px);
      transition: transform 34ms;
    }
  
    .pushable:hover .shadow {
      transform: translateY(4px);
      transition:
        transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }
  
    .pushable:active .shadow {
      transform: translateY(1px);
      transition: transform 34ms;
    }
  
    .pushable:focus:not(:focus-visible) {
      outline: none;
    }
  
  }
}

@media screen and (min-width: 1366px) {
  .withdraw-desktop {
    display: flex;
    justify-content: center;
    &__leftpage {
      height: 900px;
      width: 40%;
      background: #fff;
      box-shadow: -1px 1px 10px rgb(56, 56, 56);
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      .page-title {
        padding: 30px 0 0 52px;
        font-family: 'Lato';
        font-weight: 700;
        font-size: 29px;
        line-height: 36px;
        color: #102327;
      }
    }
    &__rightpage {
      margin-top: 15px;
      height: 900px;
      width: 40%;
      background: #fff;
      box-shadow: 1px 1px 10px rgb(56, 56, 56);
      position: relative;
      &-inputcontent {
        //margin: 0 130px;
        width: 100%;
        max-width: 495px;
        margin: 0 auto;
      }
    }
  }

  .page-subtitle {
    font-size: 18px;
  }
  .subtitle-hidden {
    display: none;
  }
  .withdraw-account {
    //margin: 39px 0 19px 25px;
    //margin: 0px 0px 0px 25px;
    //width: 35vw;
    width: 100%;
    max-width: 495px;
    margin: 0 auto 10px auto;
  }
  .withdraw-amaount-buttons {
    display: flex;
    margin-top: 5px;
    &__value {
      width: 102px;
      height: 29px;
      background: var(--payment-primary-color);
      border-radius: 3px;
      color: #fff;
      font-family: 'Lato';
      font-weight: 700;
      font-size: 9px;
      line-height: 13px;
      padding: 7px 30px;
      margin-right: 5px;
    }
  }
  .withdraw-input-content {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1440px) {
  .withdraw-amaount-buttons {
    display: flex;
    margin-top: 5px;
    &__value {
      width: 110px;
      height: 29px;
      background: var(--payment-primary-color);
      border-radius: 3px;
      color: #fff;
      font-family: 'Lato';
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
      padding: 7px 30px;
      margin-right: 5px;
    }
  }
}

.scrollOff{
  overflow: hidden !important;
}

@media screen and (max-width:1024px) {
  .withdraw-account__flex::-webkit-scrollbar {
    display: none;
  }
}
// @media screen and (min-height: 1200px) and (min-width:1024px) {
//   .withdraw-desktop__leftpage {
//     // height: 75vh;
//     height: 900px;

//   }
//   .withdraw-desktop__rightpage {
//     height: 900px;
//   }
// }
