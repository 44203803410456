
.gameresult-cardsplace.cardsplace-sicbo {
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  .sicboCardsContent-total {
    .sicboCardsContent-total__card {
      margin: 0 6px;
      width: 37px;
      height: 36px;
    }
  }
}
  .sicboCardsContent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: calc((-100vw / 16 * 9) / 2);
    transform: translate(-50%, -50%);
  }
@media (min-width: 1024px) {
  .sicboCardsContent {
    top: -150px;
  }
}
