.sicbo-betting {
  .sicbo-betting__card {
    width: 30px;
    height: 30px;
    margin: 0 5px;
  }
  width: 100%;
  min-width: 350px;
  display: flex;
  justify-content: space-between;
  &__games {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
  }
  &__result-dice {
    margin: 0 5px;
  }
  &__info-column {
    min-width: 180px;
    padding-right: 5px;
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 65%;
      text-align: right;
      font-weight: 400;
      color: var(--background-secondary-player-exchange);
    }
    &-results {
      width: 35%;
      font-weight: 500;
      color: var(--white);
      padding-left: 4px;
    }
  }
}

@media (max-width: 1280px) {
  div.sicbo-betting {
    min-width: unset;
    flex-direction: column;

    .sicbo-betting__games {
      margin-bottom: 15px;
    }
    .sicbo-betting__info-row-title,
    .sicbo-betting__info-row-results {
      width: unset;
    }
  }
}
