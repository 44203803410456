.MyMarketsFab {
  bottom: 20px;
  right: 20px;

  &__button {
    --background: var(--fab-background);
    right: 20px;
    bottom: 10px;
    width: 60px;
    height: 60px;
    white-space: normal;
    line-height: 20px;
    font-size: 30px;
    --box-shadow: 0;
  }

  &__icon {
    height: 38px;
    font-family: 'apollo';

    &:before {
      content: '';
      width: 24px;
      height: 24px;
    }
  }
}