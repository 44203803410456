.poker6-player-betting {
  display: flex;
  &__games {
    display: flex;
    margin-right: 50px;
  }
  &__games-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0 37px 0;
  }
  &__games-item-cards {
    margin: 0 7px;
    width: 32px;
    img {
      width: 32px;
      height: 44px;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.5));
      margin-top: 8px;
    }
  }
  .winnerCup {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: -2px;
      background: url('../../../assets/images/TrophyTeenPattiTest.svg');
      background-position: center;
      height: 50px;
      width: 50px;
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }

  &__info-column {
    min-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 40px;
    &-wrapper {
      width: 100%;
    }
    &-item-board {
      min-width: max-content;
      .poker6-player-betting__games-item-title {
        padding-left: 10px;
      }
      .poker6-player-betting__games-item-cards {
        display: flex;
        padding-top: 10px;
        min-width: max-content;
        img {
          margin: 0 3px;
        }
      }
    }
  }
  &__info-row {
    width: 100%;
    display: flex;
    font-size: 10px;
    &-title {
      width: 60%;
      text-align: right;
      font-weight: 400;
      color: var(--background-secondary-player-exchange);
      white-space: nowrap;
    }
    &-results {
      width: 40%;
      font-weight: 500;
      color: var(--white);
      padding-left: 4px;
    }
  }
}

@media (max-width: 1024px) {
  div.poker6-player-betting {
    flex-direction: column;
    .poker6-player-betting__games {
      margin-right: 0;
      justify-content: center;
    }
    .poker6-player-betting__info-column {
      flex-direction: column-reverse;
      padding-bottom: 15px;
    }
    .poker6-player-betting__info-column-item-board {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .poker6-player-betting__info-row-title,
    .poker6-player-betting__info-row-results {
      width: unset;
    }

    .poker6-player-betting__games-item-cards {
      margin: 5px 5px 0 5px;
      width: 26px;

      img {
        width: 26px;
        height: 36px;
        margin: 0 0 5px 0;
      }
    }
    .poker6-player-betting__info-column-item-board {
      .poker6-player-betting__games-item-cards {
        img {
          margin: 0 5px 5px 5px;
        }
      }
    }
  }
}

