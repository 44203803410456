@import '../../styles.scss';
.bonus-container{
}
.bonus-modal{
  z-index: 1;
  &__btn {
    @include full-width-button();
    height: 45px;

    &--cancel {
      --background: var(--cta-secondary);
      --color: var(--cta-disabled);
      --background-hover: var(--cta-secondary-darken);
      --background-hover-opacity: 1;
      border: 1px solid var(--cta-disabled);
    }

    &--place {
      background: var(--secondary);
      color: var(--btn-text-color);
      border-radius: 2px;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 4px;
      transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1), background-color .3s cubic-bezier(.4, 0, .2, 1), color .3s cubic-bezier(.4, 0, .2, 1), -webkit-box-shadow .3s cubic-bezier(.4, 0, .2, 1);

      &.nb {
        margin-top: 0;
      }

      &:hover {
        background: var(--cta-primary-darken);
      }

      &:disabled {
        background: var(--cta-disabled);
        cursor: default;
      }

      &:focus {
        outline: none;
      }
    }

  }

  &__confirm {
    display: flex;
    justify-content: start;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    &-checkbox {
      width: 20px;
      height: 20px;
      background: #ffffff;
      border: 2px solid #000;
      box-sizing: border-box;
      margin-right: 10px;
      &.checked {
        &::after {
          content: 'L';
          color: black;
          font-size: 20px;
          display: inline-block;
          position: relative;
          transform: matrix(-0.7, -0.71, -0.71, 0.7, 0, 0);
          bottom: 6px;
          left: 3px;
        }
      }
    }
    &-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 14px;
      color: #000000;
    }
  }
  .conditions{
    height: 251px;
    overflow: auto;
  }
  .modal-footer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.DesktopBetPlacing__bottomBtns-placeBet {
  &:disabled {
    color: white;
  }
}

