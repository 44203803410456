//.gameresult{
//  width: 100%;
//  height: 66px;
//  margin-top: -10px;
//  &-greenline{
//    height: 5px;
//    background: #00AF3C;
//  }
//  &-cardsplace{
//    height: 61px;
//    background: #1D1D1D;
//  }
//  .gameresult-cardsplace.cardsplace-roulette {
//    height: 84px;
//  }
//}

.dragon-tiger-cards {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  &__player {
    display: flex;
    align-items: center;
    min-width: 45px;
    &:first-child {
      margin-right: 25px;
    }
    &-title {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 12px;
      color: var(--white);
      white-space: nowrap;
      padding-right: 10px;
    }
    &-card {
      height: 33px;
      width: 24px;
      margin-right: 5px;
    }
  }
}
@media (min-width: 1024px) {
  .dragon-tiger-cards {
    &__player {
      &:first-child {
        margin-right: 40px;
      }
      &-title {
        //font-size: 15px;
        padding-right: 20px;
      }
      //&-card {
      //  height: 61px;
      //  width: 45px;
      //  padding: 5px;
      //}
    }
  }
}
