@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.queen-table {
  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 7px;
    padding-top: 50px;

    &-column {
      border-radius: 10px;
      background-color: var(--background-player-exchange);
      position: relative;
      padding: 10px 5px;
    }

    &-tr {
      display: flex;
      align-items: center;
      justify-content: center;
      .exposure-box-queen {
        position: absolute;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        width: auto;
        right: 48%;
        top: 20px;
      }
      .exposure-queen {
        &--pos {
          color: var(--positive-exchange);
        }
        &--neg {
          color: var(--negative-exchange);
        }
      }
    }

    &-tr-header-name {
      font-family: Poppins;
      font-size: 13px;
      color: var(--color-title-exchange);
      position: absolute;
      left: 50%;
      top: -25px;
      transform: translateX(-50%);
    }

    &-td {
      display: grid;
      justify-items: center;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      gap: 5px 10px;
    }
  }

  .square {
    border-radius: 5px;
    position: relative;
    width: 75px;
    height: 35px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--blue,
    &--blueSuspended {
      background: var(--back-color-exchange);
    }
    &--rose,
    &--roseSuspended {
      background: var(--lay-color-exchange);
    }
    &--blueSuspended::after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-closed-exchange);
      background-image: url("../../../assets/images/Lockk.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15px;
      border-radius: 5px;
      display: flex;
      flex-direction: revert;
      justify-content: center;
      align-items: center;
    }

    &--roseSuspended::after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: var(--background-closed-exchange);
      background-image: url("../../../assets/images/Lockk.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15px;
      border-radius: 5px;
      display: flex;
      flex-direction: revert;
      justify-content: center;
      align-items: center;
    }

    &-inner-text {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: var(--color-title-exchange);
    }
  }
  // .exposure-box-modern {
  //   grid-row: 2;
  //   grid-column: 1 / 3;
  // }

  // .amountGreen{
  //   font-family: 'Poppins';
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 11px;
  //   line-height: 16px;
  //   color: #38FF7C;
  //   margin-left: 80px;
  // }
  // .amountRed{
  //   font-family: 'Poppins';
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 11px;
  //   line-height: 16px;
  //   color: #FF8787;
  //   margin-left: 80px;
  // }

  @media (max-width: 768px) {
    padding: 0 5px;
    &__body {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 10px;
      padding-top: 10px;

      &-column {
        padding: 9px 10px;
        border-radius: 5px;
      }

      &-tr {
        justify-content: space-between;
      }

      &-tr-header {
        display: flex;
        flex-direction: column;
      }

      &-tr-header-name {
        position: relative;
        top: unset;
        left: unset;
        transform: none;
        color: var(--color-secondary-exchange);
      }

      &-td {
        display: flex;
        gap: 10px;
      }
    }

    .square {
      &-inner-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: variable-exists(--color-title-exchange);
      }
    }
  }
}

.nonCollapsed{
  position: fixed;
  top: 182px;
  z-index: 15;
  width: 94%;
  transition: linear 0.2s;
  left: 3%;
  right: 3%;
}
.collapsed{
  position: fixed;
  top: 80px;
  z-index: 15;
  width: 94%;
  transition: linear 0.2s;
  left: 3%;
  right: 3%;
}

.exchGamesBet{
  position: fixed;
  top: 9px;
}

@media screen and (min-width: 768px) {
  .queenWrapper{
    background: transparent;
  }
  .exposure-queen{
    &--pos {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6px;
      color: var(--positive-exchange);
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
    }
    &--neg {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6px;
      color: var(--negative-exchange);
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
    }
  }
  .queen-table__body-column {
    padding: 10px 5px 5px 5px;
  }
}

@media screen and (min-width: 1024px) {
  .queen-table {
    &__body {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 3px;
      padding-top: 55px;
      .queen-table__body-column {
        padding: 10px 0;
        border-radius: 6px;
        min-height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      .queen-table__body-td {
        gap: 0 6px;
        .square {
          border-radius: 3px;
          position: relative;
          width: 46px;
          height: 25px;
          text-align: center;
          overflow: hidden;
        }
      }
    }
  }

  .exposure-queen {
    &--pos {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6px;
      color: var(--positive-exchange);
      font-weight: 600;
      font-size: 13px;
      height: 6px;
      padding-top: 3px;
    }
    &--neg {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6px;
      color: var(--negative-exchange);
      font-weight: 600;
      font-size: 13px;
      height: 6px;
      padding-top: 3px;
    }
  }

  .square.square--blueSuspended,
  .square.square--roseSuspended {
    .square-inner-text {
      display: none;
    }
  &:after {
    border-radius: 0;
    background-size: 13px;
  }
  }
}

