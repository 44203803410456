
.pop-upLastResult-content__results-race20-items-wrapper {
  padding-bottom: 30px;
  padding-top: 20px;
}
.pop-upLastResult-content__results-race20-item {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  position: relative;
  .pop-upLastResult-content__results-race20-icon {
    //padding-right: 18px;
    position: absolute;
    left: -17px;
    top: 50%;
    transform: translate(-100%, -50%);
    img {
      width: 24px;
      height: 24px;
    }
  }
  .pop-upLastResult-content__results-race20-cards {
    display: flex;
    position: relative;
    img {
      width: 32px;
      height: 46px;
      box-shadow: 0px 0px 5px grey;
    }
    img:not(:last-child) {
      margin-right: 8px;
    }
  }
  .pop-upLastResult-content__results-race20-cards.WINNER:before {
    content: '';
    background: url('../../../../../assets/images/TrophyTeenPattiTest.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    left: auto;
    right: -15px;
    width: 20px;
    height: 30px;
    opacity: 1;
    transform: translate(100%, -50%);
  }
}


@media (max-width: 1024px) {
  div.pop-upLastResult-content__results-race20-items-wrapper {
    padding-bottom: 40px;
    padding-top: unset;
  }
  .pop-upLastResult-content__results-race20-item {
    .pop-upLastResult-content__results-race20-icon {

      img {
        width: 18.5px;
        height: 18.5px;
      }
    }
    .pop-upLastResult-content__results-race20-cards {
      display: flex;
      position: relative;
      img {
        width: 23px;
        height: 32px;
      }
    }
  }
}
