@import '../../styles.scss';

.BetPlacing {
  padding: 0 9px 8px;
  margin-bottom: 8px;
  background: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--gray);
  position: relative;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;

  &-container {
    overflow: hidden;
    margin-top: -2px;
    z-index: 2;
    position: relative;
  }

  &--back {
    border: 1px solid var(--back-background);
    border-bottom-width: 7px;
  }

  &--lay {
    border: 1px solid var(--lay-background);
    border-bottom-width: 7px;
  }

  &__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 4px;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    &--actions {
      margin-top: 9px;
      margin-bottom: 6px;
    }

    &-oddchanged {
      background: #FEF8D3;
      border: 1px solid #F6D69B;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 2px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 10px 0;

      @media (max-width: 360px) {
        font-size: 14px;
      }

      @media (max-width: 320px) {
        font-size: 12px;
      }
    }
  }

  &__col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: calc(50% - 2px);
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    &--large {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
    }

    &--small {
      width: 100px;
      margin-right: 10px;
    }
  }

  &__addstake {
    @include full-width-button($borderColor: none, );
    width: calc(100%/3 - 2px);
    --background: var(--bet-form-stake-buttons-bg-color);
    height: 28px;
    --border-radius: 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    --color: var(--bet-form-stake-buttons-color);
  }

  &__btn {
    @include full-width-button();
    height: 45px;

    &--cancel {
      --background: var(--cta-secondary);
      --color: var(--cta-disabled);
      --background-hover: var(--cta-secondary-darken);
      --background-hover-opacity: 1;
      border: 1px solid var(--cta-disabled);
    }

    &--place {
      background: var(--secondary);
      color: var(--btn-text-color);
      border-radius: 2px;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 4px;
      transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1), background-color .3s cubic-bezier(.4, 0, .2, 1), color .3s cubic-bezier(.4, 0, .2, 1), -webkit-box-shadow .3s cubic-bezier(.4, 0, .2, 1);

      &.nb {
        margin-top: 0;
      }

      &:hover {
        background: var(--cta-primary-darken);
      }

      &:disabled {
        background: var(--cta-disabled);
        cursor: default;
      }

      &:focus {
        outline: none;
      }
    }

  }

  &__profit {
    margin-top: 3px;
    font-size: 11px;
  }

  &__delay {
    position: absolute;
    right: 3px;
    top: 5px;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  &__input {
    height: 35px;
    border-radius: 2px;
    border: 1px solid var(--input-border);
    padding: 9px;
    --padding-end: 13px !important;

    &.has-focus {
      --color: var(--black);
      border: 1px solid var(--gray);
    }
  }

  &__stake {
    width: 100%;

    input.native-input {
      text-align: center;

      &::-webkit-input-placeholder {
        text-align: left;
      }

      &::-moz-placeholder {
        text-align: left;
      }

      &:-ms-input-placeholder {
        text-align: left;
      }

      &::-ms-input-placeholder {
        text-align: left;
      }

      &::placeholder {
        text-align: left;
      }
    }
  }

  &__odd {
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    &__input {
      border-radius: 0;
      transition: box-shadow .2s ease-out, -webkit-box-shadow .2s ease-out;

      input.native-input {
        text-align: center;
      }

      &.incremented {
        -webkit-box-shadow: inset 0 0 8px rgba(68, 10, 71, .4);
        box-shadow: inset 0 0 8px rgba(68, 10, 71, .4)
      }

      &--rounded {
        border-radius: 2px;
      }
    }

    &__text {
      height: 35px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      &--small {
        width: 100px;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        margin-right: 10px;
      }
    }
  }

  &__changeodd {
    margin: 0;
    height: 35px;
    width: 35px;
    font-size: 20px !important;
    background-color: var(--bet-form-stepper-buttons-bg-color);
    color: var(--bet-form-stepper-buttons-color);
    transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1), background-color .3s cubic-bezier(.4, 0, .2, 1), color .3s cubic-bezier(.4, 0, .2, 1), -webkit-box-shadow .3s cubic-bezier(.4, 0, .2, 1);
    position: relative;
    overflow: hidden;
    padding: 0;

    &--minus {
      border-radius: 3px 0 0 3px;
    }

    &--plus {
      border-radius: 0 3px 3px 0;
    }

    &:focus {
      outline: none;
    }

    //&:hover {
    //  //background: #f4f4f4;
    //  background:  rgb(119, 119, 119);
    //}

    &__text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    span {
      position: absolute;
      background: var(--primary);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      pointer-events: none;
      border-radius: 50%;
      -webkit-animation: animate .7s linear forwards;
      animation: animate .7s linear forwards;
    }
  }

  ion-button {
    margin: 0;
  }

  .oneClickToggle {
    --handle-background-checked: var(--white);
    border: 1px solidvar(--gray);
    background-color: var(--positive);
    --background-checked: var(--gray);

    &.toggle-checked {
      --background-checked: var(--positive);
      border: 1px solid var(--positive);
      --handle-box-shadow: 0px 0px 0px 1px var(--positive);

      &::after {
        content: "on";
        color: var(--white);
        background: var(--positive);
      }
    }
  }

  .small1 {
    width: 60px;
  }

  .small2 {
    width: 40px;
  }
}

.f10 {
  font-size: 10px;
}

.apl-icon-info-stopwatch {
  margin-bottom: 1px;

  &:before {
    font-style: normal;
    font-family: 'apollo';
    content: "";
  }
}

@-webkit-keyframes animate {
  0% {
    width: 0px;
    height: 0px;
    opacity: .7;
  }

  100% {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

@keyframes animate {
  0% {
    width: 0px;
    height: 0px;
    opacity: .7;
  }

  100% {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

@media screen and (min-width: 1024px) {
  .BetPlacing__changeodd:hover {
    background: rgb(119, 119, 119);
  }
}

@media (max-width: 1023px) {
  .BetPlacing__changeodd:active {
    background: rgb(119, 119, 119);
  }
}

@media (max-width: 1024px) {
  .ExchangeGamePage {
    .BetPlacing-container__wrapper {
      //width: 100%;
      //max-width: 425px;
      width: 80% !important;
      transition: linear 0.2s;
      z-index: 15;
      left: 50% !important;
      position: fixed;
      transform: translateX(-50%) !important;
    }

    .BetPlacing-container__wrapper.non-collapsed {
      top: 140px;
    }

    .BetPlacing-container__wrapper.non-collapsed.notifications {
      top: 175px;
    }

    .BetPlacing-container__wrapper.collapsed {
      top: 80px;
    }
  }
}

@media (max-width: 500px) {
  .ExchangeGamePage {
    div.BetPlacing-container__wrapper {
      width: 90% !important;
    }
  }
}

.BetPlacing-container.confirm {
  height: 230px;
  position: relative;

  .BetPlacing-confirm {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    .BetPlacing-confirm__pop-up {
      width: 70%;
      height: 65%;
      background-color: #FFFFFF;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .BetPlacing-confirm__pop-up__name {
        justify-content: center;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #000000;
        margin-bottom: 50px;
      }

      .BetPlacing-confirm__pop-up__buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 220px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }

      .BetPlacing-confirm__pop-up__buttons {
        &-cancel {
          width: 80px;
          height: 28px;
          border-radius: 3px;
          cursor: pointer;
          background: var(--cta-secondary);
          color: var(--cta-disabled);
          border: 1px solid var(--cta-disabled);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-submit {
          background-color: #6a9772;
          width: 80px;
          height: 28px;
          border-radius: 3px;
          cursor: pointer;
          color: #FFFFFF;
          font-weight: 700;
        }
      }
    }
  }

}

.fixedPosition {
  position: fixed;
}
